import {Props, State} from "./GroupAccordion.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import React from "react";
import {DialogContextValue} from "services/DialogContext/DialogContext.interfaces";
import {translations} from "../../../translations";
import {useGroupCache} from "../../../hooks/cache/groups/useGroupCache";

export const initialState: State = {}

export const reducer = (config: {
    props: Props,
    peopleShiftsDialog: DialogContextValue["peopleShiftsDialog"],
    groupConfigurationDialog: DialogContextValue["groupConfigurationDialog"],
    orderGroupsDialog: DialogContextValue["orderGroupsDialog"],
    addStaffs: ReturnType<typeof useGroupCache>["addStaffs"],
    selectPeopleDialog: DialogContextValue["selectPeopleDialog"],
}) => ({
    showPeopleList: (_, [e]: [React.SyntheticEvent]) => {
        e.stopPropagation();
        const {groupId} = config.props;
        config.peopleShiftsDialog.dispatch("open", {groupId})();
    },
    showStatistics: (_, [e]: [React.SyntheticEvent]) => {
        e.stopPropagation();
    },
    addPerson: async (_, [e]: [React.SyntheticEvent]) => {
        e.stopPropagation();
        config.selectPeopleDialog.dispatch("open", {
            multiple: true,
            title: translations.get("AddPeople"),
            callback: usersIds => config.addStaffs(usersIds)
        })();
    },
    moveGroup: (_, [e]: [React.SyntheticEvent]) => {
        e.stopPropagation();
        const {shopId, groupId} = config.props;
        config.orderGroupsDialog.dispatch("open", {shopId, groupId})();
    },
    showSettings: (_, [e]: [React.SyntheticEvent], groupId: string) => {
        e.stopPropagation();
        config.groupConfigurationDialog.dispatch("open")(groupId);
    },
}) satisfies MagicReducerObject<State>;