import React, {memo, ReactElement} from "react";
import {Props} from "./ActivitiesPart.interfaces";
import {Immutable, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ActivitiesPart.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ActivitiesPart.styles.scss";
import {AddIcon, Button, Flex} from "@fluentui/react-northstar";
import {translations} from "translations";
import {ActivitiesTable} from "components/tables/ActivitiesTable/ActivitiesTable";
import {useDialogContext} from "../../../../../services/DialogContext/DialogContext";
import {useShopCache} from "../../../../../hooks/cache/useShopsCache";
import {ShopShiftActivityWithShopId} from "../../../../tables/ActivitiesTable/ActivitiesTable.interfaces";

export const ActivitiesPart = memo((props: Props): ReactElement | null => {
    const {shopActivityDialog} = useDialogContext();
    const [state, dispatch] = useMagicReducer(reducer({
        shopId: props.shopId,
        shopActivityDialog,
    }), initialState);
    const {shop} = useShopCache(props.shopId);

    const items: Immutable<Array<ShopShiftActivityWithShopId>> = shop?.activities.map(a => ({
        ...a,
        shopId: props.shopId
    }) satisfies ShopShiftActivityWithShopId) ?? [];

    return (
        <Flex fill column gap={"gap.smaller"} styles={{height: "fit-content", padding: "0 25px"}}>
            <ActivitiesTable items={items} shopId={props.shopId}/>
            <Button
                fluid
                tinted
                className={"no-shrink"}
                icon={<AddIcon/>}
                content={translations.get("AddActivity")}
                onClick={dispatch("addActivity")}
            />
        </Flex>
    )
}, CompareModule.areObjectsEqual);