import {FormItem, useForm} from "@witivio_teamspro/northstar-form";
import React, {useLayoutEffect, useMemo} from "react";
import {translations} from "translations";
import {CustomUserLegalRules} from "interfaces/GroupData";
import {ArrowRightIcon, Button, Flex, SaveIcon, Text, TrashCanIcon} from "@fluentui/react-northstar";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {DialogContextValue} from "../../services/DialogContext/DialogContext.interfaces";
import {useDialogContext} from "../../services/DialogContext/DialogContext";
import {useUserCache} from "../cache/useUsersCache";
import {useGroupCache} from "../cache/groups/useGroupCache";
import {ContractType} from "../../interfaces/ContractType";

type FormMandatoryKeys =
    keyof Omit<CustomUserLegalRules, "groupId" | "userId" | "periodOfContract">
    | "contractStartDate"
    | "contractEndDate"
    | "badge";

const contractTypes = Object.keys(ContractType).filter((key: string | number) => !isNaN(Number(key)))
    .map(key => Number(key) as ContractType);

export const usePersonLegalRulesForm = (groupId: string, userId: string) => {
    const {confirmDeleteDialog} = useDialogContext();
    const {group, upsertCustomUserLegalRules, deleteCustomUserLegalRules} = useGroupCache(groupId);
    const {user} = useUserCache(userId);
    const [isSaving, setIsSaving] = React.useState<boolean>(false);

    const userRules = group?.legalRules.customRules.find(r => r.userId === userId);

    useLayoutEffect(function onGroupOrPersonChange() {
        if (!group) return;
        if (userRules) form.setFieldsInitialValues({...userRules});
        else form.setFieldsInitialValues({
            maximumDailyWorkingHours: undefined,
            maximumWeeklyWorkingHours: undefined,
            maximumDailyOvertimeHours: undefined,
            maximumWeeklyOvertimeHours: undefined,
            maximumWorkingSundaysPerMonth: undefined,
            contractType: undefined,
            contractStartDate: undefined,
            contractEndDate: undefined,
            badge: true,
        })
        form.reset();
        setIsSaving(false);
    }, [groupId, userId]);

    const items: Record<FormMandatoryKeys, FormItem> = useMemo(() => ({
        maximumConsecutiveWorkingDays: {
            type: "input",
            inputMode: "numeric",
            min: 0,
            max: 365,
            required: true,
            label: translations.get("MaximumConsecutiveWorkingDays"),
            initialValue: 7,
            disabled: true,
            placeholder: translations.get("ToDefine")
        },
        minimalBreakHoursBetweenShifts: {
            type: "input",
            inputMode: "numeric",
            min: 0,
            max: 24,
            required: true,
            label: translations.get("MaximumBreakHoursBetweenShifts"),
            initialValue: 11,
            disabled: true,
            placeholder: translations.get("ToDefine")
        },
        maximumDailyWorkingHours: {
            type: "input",
            inputMode: "numeric",
            min: 0,
            max: 24,
            inputIcon: <Text styles={{color: "darkgray"}} content={translations.get("PerDay").toLowerCase()}/>,
            clearable: false,
            placeholder: translations.get("ToDefine")
        },
        maximumWeeklyWorkingHours: {
            type: "input",
            inputMode: "numeric",
            min: 0,
            max: 100,
            inputIcon: <Text styles={{color: "darkgray"}} content={translations.get("PerWeek").toLowerCase()}/>,
            clearable: false,
            placeholder: translations.get("ToDefine")
        },
        maximumDailyOvertimeHours: {
            type: "input",
            inputMode: "numeric",
            min: 0,
            max: 24,
            inputIcon: <Text styles={{color: "darkgray"}} content={translations.get("PerDay").toLowerCase()}/>,
            clearable: false,
            placeholder: translations.get("ToDefine")
        },
        maximumWeeklyOvertimeHours: {
            type: "input",
            inputMode: "numeric",
            min: 0,
            max: 100,
            inputIcon: <Text styles={{color: "darkgray"}} content={translations.get("PerWeek").toLowerCase()}/>,
            clearable: false,
            placeholder: translations.get("ToDefine")
        },
        maximumWorkingSundaysPerMonth: {
            type: "input",
            inputMode: "numeric",
            min: 0,
            max: 4,
            label: translations.get("MaximumWorkingSundaysPerMonth"),
            placeholder: translations.get("ToDefine")
        },
        contractType: {
            type: "dropdown",
            items: contractTypes,
            renderSelectedItem: (item) => translations.get(ContractType[item as ContractType]),
            label: translations.get("ContractType"),
            placeholder: translations.get("EnterContractType"),
        },
        contractStartDate: {
            type: "datePicker",
            placeholder: translations.get("EnterStartDate"),
        },
        contractEndDate: {
            type: "datePicker",
            placeholder: translations.get("EnterEndDate"),
        },
        badge: {
            type: "checkbox",
            toggle: true,
            label: translations.get("Badge"),
        }
    }), []);

    const form = useForm({
        disabled: isSaving,
        items,
    });

    const isNewRules = !group?.legalRules.customRules.find(r => r.userId === userId);

    const {
        maximumDailyWorkingHours, maximumDailyOvertimeHours, maximumWeeklyOvertimeHours, maximumWorkingSundaysPerMonth,
        maximumWeeklyWorkingHours, badge, contractType, minimalBreakHoursBetweenShifts,
        maximumConsecutiveWorkingDays, contractStartDate, contractEndDate
    } = form.formItems;

    const renderForm = () => (
        <Flex column fill gap={"gap.medium"} className={"overflow-scroll"}>
            {maximumConsecutiveWorkingDays}
            {minimalBreakHoursBetweenShifts}
            <Flex column>
                <Text content={translations.get("MaximumWorkingHours")}/>
                <Flex gap={"gap.small"}>
                    {maximumDailyWorkingHours}
                    {maximumWeeklyWorkingHours}
                </Flex>
            </Flex>
            <Flex column>
                <Text content={translations.get("MaximumOvertimeHours")}/>
                <Flex gap={"gap.small"}>
                    {maximumDailyOvertimeHours}
                    {maximumWeeklyOvertimeHours}
                </Flex>
            </Flex>
            {maximumWorkingSundaysPerMonth}
            <Flex column>
                <Text content={translations.get("PeriodOfContract")}/>
                <Flex vAlign={"end"} gap={"gap.small"}>
                    {contractStartDate}
                    <ArrowRightIcon styles={{color: "darkgray", marginBottom: "8px"}}/>
                    {contractEndDate}
                </Flex>
            </Flex>
            {contractType}
            {badge}
        </Flex>
    )

    const renderSaveButton = (callback: (item: Immutable<CustomUserLegalRules> | undefined) => void) => (
        <Button
            primary
            disabled={!form.isValid || !form.state.hasChanged || isSaving}
            loading={isSaving}
            content={translations.get("Save")}
            icon={<SaveIcon size={"large"} outline/>}
            onClick={handleUpsertCustomUserLegalRules({
                groupId,
                userId,
                callback,
                upsertCustomUserLegalRules,
                formState: form.state,
                setIsSaving
            })}
        />
    );

    const renderDeleteButton = (callback: () => void) => !userRules ? null : (
        <Button
            className={isSaving ? "" : "delete-btn"}
            text
            disabled={isSaving}
            content={translations.get("Delete")}
            icon={<TrashCanIcon outline/>}
            onClick={handleDeleteCustomUserLegalRules({
                callback,
                deleteCustomUserLegalRules,
                userId,
                confirmDeleteDialog,
                userName: user?.displayName ?? "?",
            })}
        />
    );

    return {
        ...form,
        renderForm,
        isNewRules,
        renderSaveButton,
        renderDeleteButton,
    };
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleUpsertCustomUserLegalRules = (config: {
    groupId: string | undefined,
    userId: string | undefined,
    callback?: ((item: Immutable<CustomUserLegalRules> | undefined) => void) | undefined,
    upsertCustomUserLegalRules: ReturnType<typeof useGroupCache>["upsertCustomUserLegalRules"],
    formState: ReturnType<typeof usePersonLegalRulesForm>["state"],
    setIsSaving: React.Dispatch<React.SetStateAction<boolean>>,
}) => async () => {
    const {upsertCustomUserLegalRules, formState, userId, callback, setIsSaving, groupId} = config;
    const newCustomThreshold = generateCustomUserLegalRules(groupId, userId, formState);
    setIsSaving(true);
    await upsertCustomUserLegalRules(newCustomThreshold);
    callback?.(newCustomThreshold);
    setIsSaving(false);
}

const handleDeleteCustomUserLegalRules = (config: {
    userId: string | undefined,
    callback?: (() => void) | undefined,
    deleteCustomUserLegalRules: ReturnType<typeof useGroupCache>["deleteCustomUserLegalRules"],
    confirmDeleteDialog: DialogContextValue["confirmDeleteDialog"],
    userName: string,
}) => () => {
    const {deleteCustomUserLegalRules, userId, callback, confirmDeleteDialog, userName} = config;
    if (!userId) return;
    confirmDeleteDialog.dispatch("open", {
        title: translations.get("DeleteCustomRules"),
        subtitle: translations.get("AreYouSureToDeleteCustomRulesOfName", {name: userName}),
        callback: async () => {
            await deleteCustomUserLegalRules(userId);
            callback?.();
        }
    })();
}

const generateCustomUserLegalRules = (
    groupId: string | undefined,
    userId: string | undefined,
    formState: ReturnType<typeof usePersonLegalRulesForm>["state"],
): Immutable<CustomUserLegalRules> | undefined => {
    if (!userId || !groupId) return;
    return {
        groupId: groupId,
        userId: userId,
        contractType: formState.data.contractType,
        minimalBreakHoursBetweenShifts: Number(formState.data.minimalBreakHoursBetweenShifts),
        maximumConsecutiveWorkingDays: Number(formState.data.maximumConsecutiveWorkingDays),
        maximumDailyOvertimeHours: Number(formState.data.maximumDailyOvertimeHours),
        maximumDailyWorkingHours: Number(formState.data.maximumDailyWorkingHours),
        maximumWeeklyOvertimeHours: Number(formState.data.maximumWeeklyOvertimeHours),
        maximumWeeklyWorkingHours: Number(formState.data.maximumWeeklyWorkingHours),
        maximumWorkingSundaysPerMonth: Number(formState.data.maximumWorkingSundaysPerMonth),
        contractStartDate: formState.data.contractStartDate,
        contractEndDate: formState.data.contractEndDate,
        badge: formState.data.badge,
    };
}