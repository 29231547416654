import React, {memo, ReactElement} from "react";
import {Props} from "./SharePlanningDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./SharePlanningDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import "./SharePlanningDialog.styles.scss";
import {Dialog} from "../Dialog/Dialog";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import {translations} from "translations";
import {useSharePlanningForm} from "hooks/forms/useSharePlanningForm";
import {ShareIcon} from "assets/icons";
import {usePlanningPreshiftsCountCache} from "../../../hooks/cache/usePlanningPreshiftsCountCache";

export const SharePlanningDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    const form = useSharePlanningForm(state.shopId, state.startDate, state.endDate);

    const {
        preshiftsCount,
        isLoading: isPreshiftsCountLoading,
    } = usePlanningPreshiftsCountCache(state.shopId, form.state.data.startDate, form.state.data.endDate);

    const disableShareButton = isPreshiftsCountLoading || !preshiftsCount;

    return (
        <Dialog
            width={500}
            icon={<ShareIcon/>}
            onClose={dispatch("reset", form)}
            title={translations.get("SharePlanning")}
            externalRef={dialogRef}
            showOverflow
            content={
                <Flex fill column gap={"gap.medium"}>
                    {form.renderForm()}
                    <Flex styles={{gap: "5px", height: "20px"}}>
                        <Text weight={"semibold"} content={translations.get("ShiftsToShare") + " : "}/>
                        {isPreshiftsCountLoading ?
                            <Loader size={"small"} styles={{transform: "scale(0.7)"}}/>
                            :
                            <Text content={preshiftsCount?.toString() || "0"}/>
                        }
                    </Flex>
                </Flex>
            }
            footer={
                <Flex fill vAlign={"center"} space={"between"}>
                    {form.formItems.notifyMembers}
                    {form.renderShareButton(dialogRef.dispatch("close"), disableShareButton)}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);