import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./GroupsPart.interfaces";
import {Immutable, MagicDispatch, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./GroupsPart.reducer";
import {CompareModule} from "modules/Compare.module";
import "./GroupsPart.styles.scss";
import {AddIcon, Button, Flex, Loader} from "@fluentui/react-northstar";
import {PeopleButton} from "components/buttons/PeopleButton/PeopleButton";
import {GroupAccordion} from "components/others/GroupAccordion/GroupAccordion";
import {translations} from "translations";
import {useDialogContext} from "services/DialogContext/DialogContext";
import {useShopGroupsCache} from "../../../../../hooks/cache/groups/useShopGroupsCache";
import {GroupModule} from "../../../../../modules/Group.module";

export const GroupsPart = memo((props: Props): ReactElement | null => {
    const {groupDialog} = useDialogContext();
    const {groups, isLoading: areGroupsLoading, deleteStaff} = useShopGroupsCache(props.shopId);
    const [state, dispatch] = useMagicReducer(reducer({deleteStaff}), initialState);

    const addGroupButton = useMemo(() => (
        <Button
            className={"no-shrink"}
            fluid tinted icon={<AddIcon/>}
            content={translations.get("AddGroup")}
            onClick={groupDialog.dispatch("open", props.shopId)}
        />
    ), []);

    const orderedGroups = useMemo(() => {
        return GroupModule.sortGroupsByOrder(groups);
    }, [groups]);

    if (areGroupsLoading) return (
        <Flex fill vAlign={"center"} hAlign={"center"}>
            <Loader/>
        </Flex>
    )

    return (
        <Flex fill column gap={"gap.medium"} styles={{height: "fit-content", padding: "0 25px"}}>
            {orderedGroups?.map(g => (
                <GroupAccordion key={g.id} card shopId={props.shopId} groupId={g.id}>
                    {renderGroupPeople(g.id, dispatch, g.usersIds)}
                </GroupAccordion>
            ))}
            {addGroupButton}
        </Flex>
    )
}, CompareModule.areObjectsEqual);

const renderGroupPeople = (
    groupId: string,
    dispatch: MagicDispatch<typeof reducer>,
    userIds: Immutable<Array<string>>
) => {
    return (
        <Flex fill className={"people-buttons-grid"}>
            {userIds.map((userId) => (
                <PeopleButton
                    fluid key={userId} userId={userId}
                    onDelete={dispatch("deleteStaff", {groupId, staffId: userId})}
                />
            ))}
        </Flex>
    )
}