import {useQueries, useQuery} from "react-query";
import {ReportApi} from "../../apis/Report/ReportApi";

export const userReportCacheKey = "userReport";

export const useUserReportCache = (userId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {

    const {
        data: userReport,
        isLoading
    } = useQuery(getUserReportCacheKey(userId, startDate, endDate), () => ReportApi.getUserReport(userId, startDate, endDate), {
        staleTime: Infinity,
        enabled: !!userId && !!startDate && !!endDate
    });

    return {
        userReport,
        isLoading,
    }
}

export const useUsersReportsCache = (userIds: Array<string>, startDate: string | undefined, endDate: string | undefined) => {

    const results = useQueries(userIds.map(userId => ({
        queryKey: getUserReportCacheKey(userId, startDate, endDate),
        queryFn: () => ReportApi.getUserReport(userId, startDate, endDate),
        staleTime: Infinity,
        enabled: !!userId
    })));

    const usersReports = results.map(r => r.data);

    const isLoading = results.some(r => r.isLoading);

    return {
        usersReports,
        isLoading,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getUserReportCacheKey = (userId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    return [userReportCacheKey, userId, startDate, endDate];
}