import {translations} from "../../translations";
import React, {CSSProperties, useMemo} from "react";
import {useUserReportCache} from "../cache/useUserReportCache";
import {KpiPropsUnion} from "../../components/others/Kpi/Kpi.interfaces";
import {TimeModule} from "modules/Time.module";
import {Flex, Text} from "@fluentui/react-northstar";

const numberKpiHeight = "140px";

const numberKpisStyles: CSSProperties = {
    minHeight: numberKpiHeight,
    maxHeight: numberKpiHeight,
}

export const useUserReport = (userId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    const {userReport, isLoading: isUserReportLoading} = useUserReportCache(userId, startDate, endDate);

    const totalShiftsCount = useMemo(() => userReport?.shiftsCount.reduce((acc, curr) => acc + curr.count, 0) ?? 0, [userReport?.shiftsCount]);

    return {
        isLoading: isUserReportLoading,
        userReportItems: {
            absencesPerType: {
                title: translations.get("AbsencesPerType"),
                type: "pieChart",
                labels: userReport?.absencesCount.map(a => a.name) ?? [],
                values: userReport?.absencesCount.map(a => a.count) ?? [],
                colors: userReport?.absencesCount.map(a => a.color) ?? [],
                labelPosition: "left",
                padding: {top: 10, bottom: 10, left: 0, right: 0},
                styles: {
                    minHeight: "250px",
                }
            },
            shifts: {
                title: translations.get("Shifts"),
                type: "pieChart",
                labels: userReport?.shiftsCount.map(a => a.name) ?? [],
                values: userReport?.shiftsCount.map(a => a.count) ?? [],
                colors: userReport?.shiftsCount.map(a => a.color) ?? [],
                labelPosition: "left",
                padding: {top: 10, bottom: 10, left: 0, right: 0},
                formatLabel: (value: number) => {
                    const percentage = Number(((value / totalShiftsCount) * 100).toFixed(0));
                    if (percentage <= 20) return "";
                    return percentage + "%"
                },
                styles: {
                    minHeight: "250px",
                }
            },
            workingHours: {
                type: "number",
                subtitle: translations.get("WorkingHoursSingularPlural"),
                value: TimeModule.getLabelFromDecimalHours((userReport?.hours ?? 0) + (userReport?.usedRetrieveHours ?? 0)),
                color: "darkgray",
                styles: numberKpisStyles,
                content: !userReport?.usedRetrieveHours ? null : (
                    <Flex hAlign={"center"}>
                        <Text styles={{color: "darkgray"}}
                              content={translations.get("IncludingRetrieveHours", {count: userReport?.usedRetrieveHours})}/>
                    </Flex>
                ),
            },
            overtimeHours: {
                type: "number",
                subtitle: translations.get("OvertimeHoursSingularPlural"),
                value: TimeModule.getLabelFromDecimalHours(userReport?.overtime ?? 0),
                color: "rgb(115, 193, 250)",
                styles: numberKpisStyles,
            },
            delays: {
                type: "number",
                subtitle: translations.get("DelaysSingularPlural"),
                value: userReport?.delays,
                color: "orange",
                styles: numberKpisStyles,
            },
            unClocked: {
                type: "number",
                subtitle: translations.get("UnclockedShiftsSingularPlural"),
                value: userReport?.unClocked,
                color: "red",
                styles: numberKpisStyles,
            },
            weekEndOff: {
                type: "number",
                subtitle: translations.get("WeekEndOff"),
                value: userReport?.weekendsOff,
                styles: numberKpisStyles,
            },
            sundayWorked: {
                type: "number",
                subtitle: translations.get("SundayWorkedSingularPlural"),
                value: userReport?.sundays,
                styles: numberKpisStyles,
            },
            publicHolidaysWorked: {
                type: "number",
                subtitle: translations.get("PublicHolidaysWorkedSingularPlural"),
                value: userReport?.publicHolidays,
                styles: numberKpisStyles,
            },
            hoursToRetrieve: {
                type: "number",
                subtitle: translations.get("HoursToRetrieve"),
                value: TimeModule.getLabelFromDecimalHours((userReport?.hoursToRetrieve ?? 0) - (userReport?.usedRetrieveHours ?? 0)),
                styles: numberKpisStyles,
            },
        } satisfies Record<string, KpiPropsUnion>
    }
}