import {MagicReducerExternalRef} from "@witivio_teamspro/use-reducer";
import {reducer} from "./SettingsDialog.reducer";

export enum SettingsDialogTab {
    Global = 0,
    Shops = 1,
    Categories = 2,
    Activities = 3,
}

export type State = {
    activeTab: SettingsDialogTab,
}

export type Props = {
    externalRef: MagicReducerExternalRef<typeof reducer>,
}