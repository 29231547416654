import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./Toolbar.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Toolbar.reducer";
import {CompareModule} from "modules/Compare.module";
import "./Toolbar.styles.scss";
import {AddIcon, Button, Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../../translations";
import {useDialogContext} from "services/DialogContext/DialogContext";
import {useUserShopCache} from "../../../hooks/cache/useShopsCache";
import {ShopIcon} from "../../../assets/icons";

export const Toolbar = memo((props: Props): ReactElement | null => {
    const {shop} = useUserShopCache();
    const {createRequestDialog} = useDialogContext();
    const [] = useMagicReducer(reducer, initialState);

    const newRequestButton = useMemo(() => (
        <Button
            primary icon={<AddIcon/>}
            content={translations.get("NewRequest")}
            onClick={createRequestDialog.dispatch("open")}
            disabled={!shop}
        />
    ), [shop]);

    const shopButton = useMemo(() => !shop ? null : (
        <Flex gap={"gap.smaller"}>
            <ShopIcon/>
            <Text content={shop?.name}/>
        </Flex>
    ), [shop]);

    return (
        <Flex className={"toolbar"} vAlign={"center"} gap={"gap.small"} space={"between"}>
            {newRequestButton}
            {shopButton}
        </Flex>
    )
}, CompareModule.areObjectsEqual);