import {ITableColumn, ITableItem} from "components/tables/Table/Table.interfaces";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {translations} from "translations";
import {ComponentSlotStyle, Flex, Skeleton, Text} from "@fluentui/react-northstar";
import React, {useMemo} from "react";
import {UserShiftsCountData} from "../GroupShiftsTable.interfaces";
import {UserThumbnail} from "components/others/UserThumbnail/UserThumbnail";
import {useShopCache} from "hooks/cache/useShopsCache";
import {ShopShiftCategory, ShopShiftCategoryType} from "interfaces/ShopData";
import {ColorModule} from "modules/Color.module";
import {useUsersCache} from "hooks/cache/useUsersCache";
import {GraphUserData} from "services/GraphService/GraphService.interfaces";

export const useGroupShiftsTableColumns = (shopId: string | undefined, items: ITableItem<UserShiftsCountData>[]): Array<ITableColumn<Immutable<UserShiftsCountData> | null>> => {
    const {shop} = useShopCache(shopId);
    const usersIds = items.map(i => i?.id) as string[];
    const {users} = useUsersCache(usersIds);

    const visibleCategories = shop?.categories.filter(c => c.type === ShopShiftCategoryType.Shift && c.showInList) ?? [];

    return useMemo(() => [
        generateNameColumn(users),
        ...visibleCategories.map(generateShiftsCategoryColumn(visibleCategories.length)),
        generateMonthlyShiftsColumn(),
    ], [users, visibleCategories]);
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const generateNameColumn = (users: Immutable<GraphUserData>[] | undefined): ITableColumn<Immutable<UserShiftsCountData> | null> => ({
    field: "name",
    label: translations.get("Name"),
    icon: <div style={{marginLeft: "5px"}}/>,
    labelStyles: {
        border: 0,
    },
    render: item => {
        const user = users?.find(u => u.id === item?.id);
        const fullName = user?.displayName ?? "";
        return (
            <Flex fill className={"overflow-hidden"} title={fullName} gap={"gap.small"} vAlign={"center"}
                  styles={{marginLeft: "5px"}}>
                <UserThumbnail userId={item?.id} size={"small"}/>
                <Text truncated content={fullName}/>
            </Flex>
        )
    },
    sort: (a, b) => {
        const aUser = users?.find(u => u.id === a?.id);
        const bUser = users?.find(u => u.id === b?.id);
        const aString = "" + aUser?.displayName;
        const bString = "" + bUser?.displayName;
        return aString.localeCompare(bString);
    },
    maxWidth: "25%",
    minWidth: "150px",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Avatar size={"small"} className={"no-shrink"}/>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
});

const generateShiftsCategoryColumn = (categoriesLength: number) => (category: Immutable<ShopShiftCategory>, index: number): ITableColumn<Immutable<UserShiftsCountData> | null> => {
    const rgbColor = ColorModule.hexToRgb(category.color);
    const backgroundColor = `rgba(${rgbColor?.r}, ${rgbColor?.g}, ${rgbColor?.b}, 0.25) !important`;
    const headerStyles: ComponentSlotStyle = {
        color: "white",
        backgroundColor: category.color,
        overflow: "hidden",
        borderTopLeftRadius: index === 0 ? "6px" : "0",
        borderTopRightRadius: index === categoriesLength - 1 ? "6px" : "0",
    }
    const getCategoryShiftsCount = (item: Immutable<UserShiftsCountData> | null) => item?.categories.find(c => c.id === category.key)?.count ?? 0;
    return {
        field: "category" + category.key,
        label: (sortIndicator) => (
            <Flex fill hAlign={"center"} vAlign={"center"} className={"group-shifts-table-header"}
                  styles={headerStyles}>
                <Text content={category.name}/>
                {sortIndicator}
            </Flex>
        ),
        labelClassName: "group-shifts-table-label",
        render: item => (
            <Flex fill hAlign={"center"} vAlign={"center"}>
                <Text content={getCategoryShiftsCount(item)}/>
            </Flex>
        ),
        sort: (a, b) => getCategoryShiftsCount(a) - getCategoryShiftsCount(b),
        minWidth: "100px",
        maxWidth: `${50 / categoriesLength}%`,
        skeleton: (
            <Skeleton animation="pulse" className={"overflow-hidden"}>
                <Flex fill>
                    <Skeleton.Text width="100%"/>
                </Flex>
            </Skeleton>
        ),
        itemStyles: {
            color: "black",
            backgroundColor,
            overflow: "hidden",
        }
    }
}

const generateMonthlyShiftsColumn = (): ITableColumn<Immutable<UserShiftsCountData> | null> => ({
    field: "monthly-shifts",
    label: () => (
        <Flex fill column hAlign={"center"} vAlign={"center"} className={"monthly-shifts-column-header"}>
            <Flex fill vAlign={"center"} hAlign={"center"} className={"top-part"}>
                <Text content={translations.get("CurrentMonthWorked")} truncated/>
            </Flex>
            <Flex fill space={"around"} className={"bottom-part"}>
                <Flex fill hAlign={"center"} vAlign={"center"}>
                    <Text content={translations.get("Sundays")} truncated/>
                </Flex>
                <Flex fill hAlign={"center"} vAlign={"center"}>
                    <Text content={translations.get("PublicHolidays")} truncated/>
                </Flex>
            </Flex>
        </Flex>
    ),
    labelClassName: "group-shifts-table-label",
    render: item => (
        <Flex fill hAlign={"center"} vAlign={"center"} className={"monthly-shifts-column-item-parts"}>
            <Flex fill hAlign={"center"} vAlign={"center"}>
                <Text content={item?.monthlyWorkedSundays ?? 0}/>
            </Flex>
            <Flex fill hAlign={"center"} vAlign={"center"}>
                <Text content={item?.monthlyWorkedHolidays ?? 0}/>
            </Flex>
        </Flex>
    ),
    minWidth: "180px",
    maxWidth: "25%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex fill>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
    itemClassName: `monthly-shifts-column-item`,
});