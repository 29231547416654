import {Immutable} from "@witivio_teamspro/use-reducer";
import {Shift} from "../../../classes/Shift";

export type State = {
    doubleClickTimeout: NodeJS.Timeout | undefined,
}

export type Props = {
    shift: Immutable<Shift> | undefined,
    currentShopId?: string | undefined,
    userId?: string | undefined,
    format?: ShiftCardFormat | undefined,
    transparent?: boolean | undefined,
    readonly?: boolean | undefined,
    showAvatar?: boolean | undefined,
    square?: boolean | undefined,
    onClick?: (() => void) | undefined,
    onCopy?: (() => void) | undefined,
    onPaste?: (() => void) | undefined,
    addCard?: boolean | undefined,
    date?: string | undefined,
    showDate?: boolean | ShowDateOptions | undefined,
    small?: boolean | undefined,
}

export enum ShiftEditType {
    Occurence, Series
}

export type ShowDateOptions = {
    showMonth: boolean,
}

export enum ShiftCardFormat {
    Mini, Small, Classic
}