import {State} from "./Header.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {startTransition} from "react";

export const getVisibleCells = () => {
    return Math.round(window.innerWidth / 100);
}

export const initialState: State = {
    visibleCells: getVisibleCells(),
}

export const reducer = {
    windowResize: ({state, setState}) => {
        const visibleCells = getVisibleCells();
        if (state.visibleCells === visibleCells) return;
        startTransition(() => setState({visibleCells}));
    }
} satisfies MagicReducerObject<State>;