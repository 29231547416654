import {Recurrence} from "../components/others/ShiftRecurrence/ShiftRecurrence.interfaces";
import {Time} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/TimePicker/TimePicker";

export type ShiftActivity = {
    key: string,
    start: Time,
    end: Time,
}

export enum ShiftType {
    Shift = 0,
    Absence = 1,
}

export type ShiftClocking = {
    start: Time,
    end: Time,
}

export type ShiftData = {
    id: string,
    type?: ShiftType | undefined,
    shopId?: string | undefined,
    userId?: string | undefined,
    categoryId?: string | undefined,
    customWording?: string | undefined,
    date?: string | undefined,
    start?: Time | undefined,
    end?: Time | undefined,
    clocking?: ShiftClocking | undefined,
    recurrence?: Recurrence | undefined,
    notes?: string | undefined,
    activities?: Array<ShiftActivity> | undefined,
    preShift?: PreShiftData | undefined,
}

export type PreShiftData = Omit<ShiftData, "preShift">;