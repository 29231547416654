import {FilterItemData, FilterItemsData, IFilterItem, Props, State} from "./Filter.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import React from "react";
import {InputProps, PopupProps} from "@fluentui/react-northstar";
import {ObjectModule} from "modules/Object.module";
import {CompareModule} from "modules/Compare.module";
import {HistoryService} from "services/HistoryService/HistoryService.hook";

export const initialState = (props: Props): State => {
    const historyState = HistoryService.getComponentState<State>(props.id + "_filter");
    return {
        open: false,
        items: [],
        defaultItems: [],
        data: historyState?.data,
    }
}

const saveState = (id: string, state: Immutable<State>) => {
    const {data} = state;
    HistoryService.saveComponentState(id + "_filter", {data}, 300);
}

export const reducer = (props: Props) => ({
    setItems: ({setState}, [items]: [Immutable<Array<IFilterItem>>]) => {
        setState({items: ObjectModule.deepClone(items)});
    },
    setDefaultItems: ({setState}, [items]: [Immutable<Array<IFilterItem>>]) => {
        setState({defaultItems: ObjectModule.deepClone(items)}, false);
    },
    toggleCheck: ({state, setState}, [itemKey]: [string]) => {
        setState({items: toggleItemCheck(state.items, itemKey)});
    },
    open: ({state, setState}) => {
        if (state.open) return;
        setState({open: true});
    },
    close: ({state, setState}) => {
        if (!state.open) return;
        setState({open: false});
    },
    toggleOpen: ({state, setState}) => {
        setState({open: !state.open});
    },
    reset: ({state, setState}, _, keepCheckedValues: boolean = false) => {
        let items = state.defaultItems;
        if (items.length === 0) return;
        if (keepCheckedValues) items = applyPrevDataCheckedState(state.data, items);
        setState({items, data: getItemsCheckedState(items)});
        saveState(props.id, state);
    },
    handlePopupHoverOpenChange: (reducerData, [e, data]: [React.SyntheticEvent, PopupProps | undefined]) => {
        if (e.type !== "mouseleave") return;
        if (data?.open) return;
        reducer(props).close(reducerData);
    },
    handleResetFilters: (reducerData, [e]: [React.SyntheticEvent]) => {
        const {state} = reducerData;
        e.stopPropagation();
        reducer(props).reset(reducerData, undefined);
        const checkedItems = getItemsCheckedState(state.items);
        props.onItemChecked(checkedItems);
    },
    handleToggleItem: (reducerData, [e]: [React.SyntheticEvent], extraArgs: [string, string | undefined]) => {
        const {state, setState} = reducerData;
        const [itemKey, parentItemKey] = extraArgs;
        e.stopPropagation();
        const canBeToggled = !state.items.find(i => i.key === itemKey)?.input?.hideCheckbox;
        if (!canBeToggled) return;
        let newItems = ObjectModule.deepClone(state.items);
        const parentItem = parentItemKey ? findItemInList(newItems, parentItemKey) : null;
        const isParentUniqueMenu = parentItem?.menu?.unique;
        if (isParentUniqueMenu) {
            if (!parentItem.menu) throw new Error("Parent item is not a menu");
            let item = findItemInList(parentItem.menu.items, itemKey);
            if (item?.isChecked) return;
            parentItem.menu.items = clearFilters([...parentItem.menu.items]);
            item = findItemInList(parentItem.menu.items, itemKey);
            if (!item) throw new Error("Can't find filter item with key : " + itemKey);
            item.isChecked = true;
        } else {
            const item = findItemInList(newItems, itemKey);
            if (!item) throw new Error("Can't find filter item with key : " + itemKey);
            item.isChecked = !item.isChecked;
        }
        const checkedItems = getItemsCheckedState(newItems);
        setState({items: newItems, data: checkedItems});
        props.onItemChecked(checkedItems);
        saveState(props.id, state);
    },
    handleFilterItemInputValueChange: (reducerData, [, data]: [React.SyntheticEvent | undefined, InputProps | undefined], itemKey: string) => {
        const {state, setState} = reducerData;
        const value = data?.value ?? "";
        const newItems = ObjectModule.deepClone(state.items);
        const item = findItemInList(newItems, itemKey);
        if (!item) throw new Error("Can't find filter item with key : " + itemKey);
        if (!item.input) throw new Error("Can't update filter item input value with key : " + itemKey);
        if (!!item.input.validate && !item.input.validate(value)) return;
        item.input.value = value;
        const checkedItems = getItemsCheckedState(newItems);
        setState({items: newItems, data: checkedItems});
        props.onItemChecked(checkedItems);
        saveState(props.id, state);
    }
}) satisfies MagicReducerObject<State>;

const toggleItemCheck = (prevItems: Immutable<Array<IFilterItem>>, itemKey: string): Array<IFilterItem> => {
    const newItems = ObjectModule.deepClone(prevItems);
    const item = newItems.find(i => i.key === itemKey);
    if (!item) throw new Error("Can't find filter item with key : " + itemKey);
    item.isChecked = !item.isChecked;
    return newItems;
}

const findItemInList = <T extends IFilterItem | undefined, A extends Array<T>>(items: A, itemKey: string): T | undefined => {
    let foundItem: T | undefined = undefined;
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (!item) continue;
        if (item.key === itemKey) foundItem = item;
        else if (!!item.menu) {
            const menuItem = findItemInList(item.menu.items, itemKey);
            if (!!menuItem) foundItem = menuItem as T;
        }
        if (!!foundItem) break;
    }
    return foundItem;
}

const findItemInData = <T extends FilterItemData | Record<string, FilterItemData>>(data: FilterItemsData, itemKey: string): T | undefined => {
    const keys = Object.keys(data);
    let foundItem: T | undefined = undefined;
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const item = key ? data[key] : undefined;
        if (key === itemKey) foundItem = item as T;
        else if (typeof item === "object") {
            const menuItem = findItemInData(item as any, itemKey);
            if (!!menuItem) foundItem = menuItem as T;
        }
        if (!!foundItem) break;
    }
    return foundItem;
}

const clearFilters = (items: Array<IFilterItem>): Array<IFilterItem> => {
    return items.map(item => {
        if (!item.menu) {
            item.isChecked = false;
            return item;
        }
        item.menu.items = clearFilters(item.menu.items);
        return item;
    });
}

export const hasFiltersChanged = (defaultItems: Immutable<Array<IFilterItem>>, items: Immutable<Array<IFilterItem>>) => {
    const defaultItemsCheckedState = getItemsCheckedState(defaultItems);
    const currentItemsCheckedState = getItemsCheckedState(items);
    return !CompareModule.areObjectsEqual(defaultItemsCheckedState, currentItemsCheckedState);
}

const getItemsCheckedState = (items: Immutable<Array<IFilterItem>>): FilterItemsData => {
    const data: Parameters<Props["onItemChecked"]>[0] = {};
    items.forEach(item => {
        if ("menu" in item) {
            const subData: Record<string, FilterItemData> = {};
            item.menu?.items?.forEach(subItem => {
                subData[subItem.key] = {
                    isChecked: subItem.isChecked ?? false,
                    inputValue: subItem.input?.value,
                };
            })
            data[item.key] = subData;
        } else {
            data[item.key] = {
                isChecked: item.isChecked ?? false,
                inputValue: item.input?.value,
            };
        }
    })
    return data;
}

const applyPrevItemsCheckedState = (prevItems: Immutable<Array<IFilterItem>>, nextItems: Immutable<Array<IFilterItem>>) => {
    let clonedPrevItems = ObjectModule.deepClone(prevItems);
    let clonedNextItems = ObjectModule.deepClone(nextItems);
    clonedNextItems.forEach(item => {
        if ("menu" in item) {
            const prevItem = findItemInList(clonedPrevItems, item.key);
            if (!prevItem) return;
            item.menu?.items?.forEach(subItem => {
                const prevSubItem = findItemInList(prevItem.menu?.items ?? [], subItem.key);
                if (!prevSubItem) return;
                subItem.isChecked = prevSubItem.isChecked ?? false;
            })
        } else {
            const prevItem = findItemInList(clonedPrevItems, item.key);
            if (!prevItem) return;
            item.isChecked = prevItem.isChecked ?? false;
        }
    })
    return clonedNextItems;
}

const applyPrevDataCheckedState = (prevData: Immutable<FilterItemsData> | undefined, nextItems: Immutable<Array<IFilterItem>>) => {
    if (!prevData) return nextItems;
    let clonedNextItems = ObjectModule.deepClone(nextItems);
    clonedNextItems.forEach(item => {
        if ("menu" in item) {
            const prevItem = findItemInData(prevData, item.key);
            if (!prevItem) return;
            item.menu?.items?.forEach(subItem => {
                const prevSubItem = findItemInData(prevData, subItem.key);
                if (!prevSubItem) return;
                subItem.isChecked = ("isChecked" in prevSubItem && prevSubItem.isChecked as boolean) ?? false;
            })
        } else {
            const prevItem = findItemInData(prevData, item.key);
            if (!prevItem) return;
            item.isChecked = ("isChecked" in prevItem && prevItem.isChecked as boolean) ?? false;
        }
    })
    return clonedNextItems;
}