import {Props, SelectedCellData, State} from "./Planning.interfaces";
import {Immutable, ImmutableObject, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Viewport} from "../../../hooks/useViewport";
import {MouseEvent, startTransition} from "react";
import {useShiftsCache} from "../../../hooks/cache/useShiftsCache";
import {GroupData} from "../../../interfaces/GroupData";
import {PlanningDateRangeCache} from "../../../hooks/cache/usePlanningDateRangeCache";
import {Shift} from "../../../classes/Shift";
import {PopupMenuButton} from "../../../components/buttons/PopupMenuButton/PopupMenuButton";
import {DialogContextValue} from "../../../services/DialogContext/DialogContext.interfaces";
import {translations} from "../../../translations";
import {PasteIcon} from "../../../assets/icons";
import {ShopData, ShopType} from "../../../interfaces/ShopData";
import {ErrorModule} from "../../../components/others/ErrorBoundary/ErrorBoundary";

export const initialState: State = {
    scrollbarWidth: 0,
    planningGroupsContainer: null,
    selectedCells: {},
    clipboardCells: undefined,
    orderedStaffsIds: [],
    rightClickPosition: undefined,
}

export const reducer = (config: {
    props: Props,
    replaceUserShifts: ReturnType<typeof useShiftsCache>["replaceUserShifts"],
    planningDateRange: PlanningDateRangeCache,
    contextMenuPopupRef: MagicReducerRef<typeof PopupMenuButton>,
    confirmDeleteDialog: DialogContextValue["confirmDeleteDialog"],
    deleteShifts: ReturnType<typeof useShiftsCache>["deleteShifts"],
    confirmDialog: DialogContextValue["confirmDialog"],
    shop: ImmutableObject<ShopData> | undefined
}) => ({
    planningGroupsContainerRef: ({setState}, [ref]: [HTMLDivElement | null]) => {
        setState({planningGroupsContainer: ref}, false);
    },
    setScrollbarWidth: ({state, setState}, [width]: [number]) => {
        if (state.scrollbarWidth === width) return;
        setState({scrollbarWidth: width});
    },
    clearSelectedShifts: ({state, setState}) => {
        if (Object.keys(state.selectedCells).length === 0) return;
        setState({selectedCells: {}});
    },
    handleSelectCells: ({state, setState}, [cellData]: [Immutable<SelectedCellData> | undefined], key: string) => {
        const selectedCells = {...state.selectedCells};
        if (!selectedCells[key] && !cellData) return;
        if (!cellData) delete selectedCells[key];
        else {
            const formattedCellData = {...cellData} as SelectedCellData;
            if (config.shop?.type !== ShopType.RotatingStaff)
                formattedCellData.shifts = formattedCellData.shifts.map(s => s?.getShopId() !== config.props.shopId ? undefined : s);
            selectedCells[key] = formattedCellData;
        }
        startTransition(() => setState({selectedCells}));
    },
    copySelectedCells: ({state, setState}) => {
        if (Object.keys(state.selectedCells).length === 0) return;
        setState({clipboardCells: {...state.selectedCells}, selectedCells: {}});
    },
    deleteSelectedCells: ({state, setState}) => {
        if (Object.keys(state.selectedCells).length === 0) return;
        const shiftsIds = Object.values(state.selectedCells)
            .flatMap(cellData => cellData.shifts.map(s => s?.getId()))
            .filter(Boolean) as Array<string>;
        if (shiftsIds.length === 0) return;
        config.confirmDeleteDialog.dispatch("open", {
            title: translations.get(shiftsIds.length === 1 ? "DeleteShift" : "DeleteShifts"),
            subtitle: translations.get(shiftsIds.length === 1 ?
                "AreYouSureToDeleteSelectedShift" : "AreYouSureToDeleteShifts", {count: shiftsIds.length}),
            callback: async () => {
                await config.deleteShifts({shiftsIds});
                setState({selectedCells: {}});
            },
        })();
    },
    pasteClipboardShifts: ({state}) => {
        if (!state.clipboardCells || !state.selectedCells || !config.planningDateRange.selectedRange) return;
        const firstSelectedEntry = Object.entries(state.selectedCells)[0];
        if (!firstSelectedEntry) return;
        const [, selectedCellData] = firstSelectedEntry;
        const userIdIndex = state.orderedStaffsIds.indexOf(selectedCellData.userId);
        if (userIdIndex === -1) return;
        const orderedUsersIds = state.orderedStaffsIds.slice(userIdIndex);
        const hasExistingShifts = Object.values(state.selectedCells).flatMap(cellData => cellData.shifts).filter(Boolean).length > 0;
        const callback = (confirmed: boolean) => {
            if (!confirmed || !state.clipboardCells) return;
            Object.entries(state.clipboardCells).forEach(async ([, clipboardCellData], index) => {
                if (!orderedUsersIds[index]) return;
                const exceptions = await config.replaceUserShifts({
                    userId: orderedUsersIds[index]!,
                    dateRange: config.planningDateRange.selectedRange,
                    shifts: clipboardCellData.shifts,
                    beginReplaceDate: selectedCellData.starDate,
                });
                if (exceptions.length > 0) ErrorModule.showErrorDialog({
                    title: translations.get("RuleNotRespected"),
                    subtitle: translations.get(exceptions[0]!),
                });
            });
        }
        if (!hasExistingShifts) return callback(true);
        config.confirmDialog.dispatch("open", {
            title: translations.get("ReplaceShifts"),
            subtitle: translations.get("PasteShiftsWarningMessage"),
            buttonTitle: translations.get("Paste"),
            buttonIcon: <PasteIcon/>,
            callback,
        })();
    },
    keyDown: (reducerData, [e]: [KeyboardEvent]) => {
        e.stopPropagation();
        const isCtrlPressed = e.ctrlKey || e.metaKey;
        if (isCtrlPressed && e.key === "c") reducer(config).copySelectedCells(reducerData);
        if (isCtrlPressed && e.key === "v") reducer(config).pasteClipboardShifts(reducerData);
    },
    updateStaffsOrder: ({setState}, _, groups: Immutable<Array<GroupData>> | undefined) => {
        const usersIds = groups?.flatMap(g => g.usersIds);
        setState({orderedStaffsIds: usersIds ?? []}, false);
    },
    copySingleShift: ({setState}, [shift]: [Immutable<Shift>], key: string) => {
        if (!shift.getUserId() || !shift.getDate()) return;
        const clipboardCells: State["clipboardCells"] = {
            [key]: {
                userId: shift.getUserId()!,
                shifts: [shift],
                starDate: shift.getDate()!,
                endDate: shift.getDate()!,
            }
        };
        setState({clipboardCells}, false);
    },
    pasteFromShift: (reducerData, [shift]: [Immutable<Shift>], key: string) => {
        if (!shift.getUserId() || !shift.getDate()) return;
        const selectedCells: State["selectedCells"] = {
            [key]: {
                userId: shift.getUserId()!,
                shifts: [shift],
                starDate: shift.getDate()!,
                endDate: shift.getDate()!,
            }
        };
        const {state, setState} = reducerData;
        const prevSelectedCells = state.selectedCells;
        setState({selectedCells}, false);
        reducer(config).pasteClipboardShifts(reducerData);
        setState({selectedCells: prevSelectedCells}, false);
    },
    rightClick: (reducerData, [e]: [MouseEvent | undefined]) => {
        const {state, setState} = reducerData;
        if (!e) return;
        e.preventDefault();
        if (Object.keys(state.selectedCells).length === 0) return;
        const rect = state.planningGroupsContainer?.getBoundingClientRect();
        if (!rect) return;
        const x = e.clientX - rect.left;
        const y = e.clientY - (rect.top - (state.planningGroupsContainer?.scrollTop ?? 0));
        setState({rightClickPosition: {x, y}});
        const selectedCells = state.planningGroupsContainer?.querySelectorAll(".cell.selected");
        const selectedCellsRects = Array.from(selectedCells ?? []).map(cell => cell.getBoundingClientRect());
        const isClickInsideSelectedCells = selectedCellsRects.some(rect => {
            return e.clientX >= rect.left && e.clientX <= rect.right && e.clientY >= rect.top && e.clientY <= rect.bottom;
        });
        if (!isClickInsideSelectedCells) reducer(config).clearSelectedShifts(reducerData);
        else config.contextMenuPopupRef.dispatch("open")();
    }
}) satisfies MagicReducerObject<State>;

export const isStaffVisible = (parentViewport: Viewport) => (item: HTMLDivElement | null): boolean => {
    if (!item) return false;
    const scrollContainerTop = Math.round(window.innerHeight - (parentViewport?.height ?? 0));
    const rect = item?.getBoundingClientRect();
    const gap = rect.height;
    const yTopMin = Math.round(rect.top - scrollContainerTop + gap);
    const yTopMax = Math.round(rect.top + rect.height - scrollContainerTop + gap);
    const isVisibleFromTop = yTopMin >= 0 || yTopMax >= 0;
    const yBottomMin = Math.round(rect.bottom - rect.height - gap);
    const yBottomMax = Math.round(rect.bottom - gap);
    const isVisibleFromBottom = yBottomMin <= window.innerHeight || yBottomMax <= window.innerHeight;
    return isVisibleFromTop && isVisibleFromBottom;
}