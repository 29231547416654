import React, {memo, ReactElement} from "react";
import {Props} from "./ShopShiftCategoryDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShopShiftCategoryDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {AddIcon, EditIcon, Flex} from "@fluentui/react-northstar";
import "./ShopShiftCategoryDialog.styles.scss";
import {useShopCategoryForm} from "hooks/forms/useShopCategoryForm";
import {translations} from "translations";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";

export const ShopShiftCategoryDialog = memo((props: Props): ReactElement | null => {
    const {confirmCancelChangesDialog} = useDialogContext();
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({
        dialogRef,
        confirmCancelChangesDialog
    }), initialState, props.externalRef);
    const form = useShopCategoryForm(state.shopId, state.type, state.category);

    return (
        <Dialog
            showOverflow
            externalRef={dialogRef}
            width={400}
            icon={!state.category ? <AddIcon outline/> : <EditIcon outline/>}
            title={translations.get(!state.category ? "AddCategory" : "EditCategory")}
            onClose={dispatch("reset", form)}
            onBeforeClose={dispatch("beforeClose", !form.isSaving && form.state.hasChanged)}
            content={form.renderForm()}
            footer={
                <Flex fill hAlign={"end"}>
                    {form.renderSaveButton(dialogRef.dispatch("close"))}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);