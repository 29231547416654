import {Time} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/TimePicker/TimePicker";
import {ShiftType} from "./ShiftData";

export enum RequestType {
    Swap, UpdateClocking, TimeOff, WeekSummary
}

export type RequestResponse = {
    managerId: string,
    accepted: boolean,
    note: string | undefined,
    date: string,
}

export type BaseRequestData = {
    id: string,
    type: RequestType,
    shopId: string,
    userId: string,
    response: RequestResponse | undefined,
    date: string,
}

export type SwapRequestShiftData = {
    id: string,
    type: ShiftType,
    userId: string,
    shopId: string,
    categoryId: string,
    date: string,
    start: Time,
    end: Time,
    customWording?: string | undefined,
}

export type WeekSummaryUserData = {
    userId: string,
    totalWorkedHours: number,
    totalLegalHours: number,
    availableRetrieveHours: number,
    usedRetrieveHours: number,
}

export type SwapRequestData = BaseRequestData & {
    type: RequestType.Swap,
    fromShift: SwapRequestShiftData,
    toShift: SwapRequestShiftData,
    toUserResponse: boolean | undefined,
    note: string,
}

export type UpdateClockingRequestData = BaseRequestData & {
    type: RequestType.UpdateClocking,
    shiftId: string,
    prevStartTime: Time | undefined,
    prevEndTime: Time | undefined,
    startTime: Time | undefined,
    endTime: Time | undefined,
    justification: string,
    clockedOutsideStore: boolean,
}

export type TimeOffRequestData = BaseRequestData & {
    type: RequestType.TimeOff,
    justification: string,
    isAllDay: boolean,
    startDate: string,
    endDate: string,
    startTime: Time | undefined,
    endTime: Time | undefined,
}

export type WeekSummaryRequestData = BaseRequestData & {
    type: RequestType.WeekSummary,
    usersData: Array<WeekSummaryUserData>,
    weekDate: string,
}

export type RequestData = SwapRequestData | UpdateClockingRequestData | TimeOffRequestData | WeekSummaryRequestData;