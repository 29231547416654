import type {FC} from 'react';
import React, {memo} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import "./Card.styles.scss";
import {CardProps, DragItem} from "./Card.interfaces";
import {Flex, Text} from "@fluentui/react-northstar";
import {useGroupCache} from "hooks/cache/groups/useGroupCache";
import {CompareModule} from "../../../../modules/Compare.module";

export const Card: FC<CardProps> = memo((
    {groupId, moveGroup, findGroupIndex, highlight, disabled}
) => {
    const {group} = useGroupCache(groupId);

    const originalIndex = findGroupIndex(groupId);

    const [{isDragging}, drag] = useDrag(() => ({
        type: "group-card",
        item: {groupId, originalIndex},
        canDrag: !disabled,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const {groupId: droppedId, originalIndex} = item;
            const didDrop = monitor.didDrop();
            if (!didDrop) moveGroup(droppedId, originalIndex);
        },
    }), [groupId, originalIndex, moveGroup, disabled]);

    const [, drop] = useDrop(() => ({
        accept: "group-card",
        canDrop: () => !disabled,
        hover({groupId: draggedId}: DragItem) {
            if (draggedId === groupId) return;
            const groupIndex = findGroupIndex(groupId);
            if (groupIndex !== -1) moveGroup(draggedId, groupIndex);
        },
    }), [findGroupIndex, moveGroup, disabled]);

    const className = [
        "group-order-card",
        highlight && "group-order-card-highlighted",
    ].filter(Boolean).join(" ");

    const opacity = disabled ? 0.5 : isDragging ? 0 : 1;
    return (
        <Flex ref={(node) => drag(drop(node))} vAlign={"center"} className={className} style={{opacity}}>
            <Text content={originalIndex + 1}/>
            <Text styles={{marginTop: "-3px"}} weight={"bold"} size={"large"} content={group?.name}/>
        </Flex>
    )
}, CompareModule.areObjectsEqual);