import React, {memo, ReactElement} from "react";
import {Props} from "./PersonLegalRulesDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./PersonLegalRulesDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Flex} from "@fluentui/react-northstar";
import "./PersonLegalRulesDialog.styles.scss";
import {usePersonLegalRulesForm} from "hooks/forms/usePersonLegalRulesForm";
import {useUserCache} from "hooks/cache/useUsersCache";
import {translations} from "translations";
import {UserThumbnail} from "components/others/UserThumbnail/UserThumbnail";

export const PersonLegalRulesDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);
    const {user} = useUserCache(state.userId);

    const personLegalRulesForm = usePersonLegalRulesForm(state.groupId, state.userId);

    return (
        <Dialog
            onClose={dispatch("reset")}
            externalRef={dialogRef}
            width={500}
            icon={
                <Flex styles={{marginTop: "2px"}}>
                    <UserThumbnail userId={state.userId} size={"smaller"}/>
                </Flex>
            }
            title={user?.displayName + " - " + translations.get("LegalRules")}
            content={
                <Flex fill>
                    {personLegalRulesForm.renderForm()}
                </Flex>
            }
            footer={
                <Flex hAlign={"end"} {...(!personLegalRulesForm.isNewRules && {space: "between"})} gap={"gap.small"}>
                    {personLegalRulesForm.renderDeleteButton(dialogRef.dispatch("close"))}
                    {personLegalRulesForm.renderSaveButton(dialogRef.dispatch("close"))}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);