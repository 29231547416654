import {ReactElement} from "react";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Settings.reducer";
import "./Settings.styles.scss";
import {ErrorIcon, Flex, Text} from "@fluentui/react-northstar";
import {Toolbar} from "./Toolbar/Toolbar";
import {ShopsTable} from "components/tables/ShopsTable/ShopsTable";
import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {translations} from "../../translations";
import {useUserRolesCache} from "../../hooks/cache/useUserRoleCache";
import {LoadingPage} from "../LoadingPage/LoadingPage";

export const Settings = (): ReactElement => {
    const {isOnMobile} = useMsTeamsSelector("isOnMobile");
    const {userRoles, isLoading} = useUserRolesCache();
    const [] = useMagicReducer(reducer, initialState);

    if (isLoading) return <LoadingPage/>;

    if (!userRoles.administrator) return (
        <Flex fill column vAlign={"center"} hAlign={"center"} gap={"gap.smaller"}>
            <ErrorIcon size={"largest"} styles={{transform: "scale(2)", paddingBottom: "15px"}}/>
            <Text size={"larger"} weight={"semibold"} content={translations.get("NotAuthorized")}/>
            <Text size={"large"} align={"center"} content={translations.get("YouDontHaveTheRightToAccessThisPage")}/>
        </Flex>
    )

    if (isOnMobile) return (
        <Flex fill vAlign={"center"} hAlign={"center"}>
            <Text size={"large"} content={translations.get("NotAvailableOnMobile")}/>
        </Flex>
    )

    return (
        <Flex fill column>
            <Toolbar/>
            <Flex fill styles={{paddingTop: "10px"}}>
                <ShopsTable/>
            </Flex>
        </Flex>
    )
}