import {ErrorModule} from "../components/others/ErrorBoundary/ErrorBoundary";
import CryptoJS from "crypto-js";

const encryptValue = (value: string, secretKey: string | undefined) => {
    if (!secretKey) {
        ErrorModule.showErrorAlert("Secret key is not defined");
        return;
    }
    const base64SecretKey = CryptoJS.enc.Base64.parse(secretKey);
    const iv = CryptoJS.lib.WordArray.random(128 / 8); // 16 bytes IV for AES
    const encrypted = CryptoJS.AES.encrypt(value, base64SecretKey, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    return {
        iv: CryptoJS.enc.Base64.stringify(iv),
        hexValue: encrypted.ciphertext.toString(CryptoJS.enc.Hex),
        ciphertext: encrypted.ciphertext,
    };
};

const decryptValue = (encryptedHex: string, secretKey: string, base64Iv: string) => {
    if (!secretKey) {
        throw new Error("Secret key is not defined");
    }
    if (!encryptedHex || !base64Iv) {
        throw new Error("Encrypted data or IV is not defined");
    }
    const base64SecretKey = CryptoJS.enc.Base64.parse(secretKey);
    const iv = CryptoJS.enc.Base64.parse(base64Iv);
    const decrypted = CryptoJS.AES.decrypt(
        {ciphertext: CryptoJS.enc.Hex.parse(encryptedHex)} as CryptoJS.lib.CipherParams,
        base64SecretKey,
        {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
};

export const CryptoModule = {
    encryptValue,
    decryptValue,
}