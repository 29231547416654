import {State} from "./ActivitiesPart.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {DialogContextValue} from "../../../../../services/DialogContext/DialogContext.interfaces";

export const initialState: State = {}

export const reducer = (config: {
    shopId: string,
    shopActivityDialog: DialogContextValue["shopActivityDialog"]
}) => ({
    addActivity: () => {
        config.shopActivityDialog.dispatch("open", {
            shopId: config.shopId,
            activity: undefined,
        })();
    }
}) satisfies MagicReducerObject<State>;