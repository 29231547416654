import React, {memo, ReactElement} from "react";
import {Props} from "./RequestPreviewItem.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./RequestPreviewItem.reducer";
import {CompareModule} from "modules/Compare.module";
import "./RequestPreviewItem.styles.scss";
import {Flex, PersonIcon, Text} from "@fluentui/react-northstar";
import {UserThumbnail} from "../../UserThumbnail/UserThumbnail";
import moment from "moment";
import {useUserCache} from "../../../../hooks/cache/useUsersCache";

export const RequestPreviewItem = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState);
    const {user} = useUserCache(props.userId);

    return (
        <Flex className={"request-preview-item"} gap={"gap.small"} styles={{maxWidth: props.width ?? "400px"}}>
            {renderAvatar(props.userId)}
            <Flex className={"request-preview-item-card"} column>
                <Flex space={"between"} vAlign={"center"} styles={{marginTop: "-3px"}}>
                    <Text content={props.title ?? user?.displayName} weight={"semibold"}/>
                    {props.date &&
                        <Text size={"small"} styles={{color: "darkgray"}} content={moment(props.date).calendar()}/>}
                </Flex>
                <Flex className={"overflow-hidden"}>
                    {props.content}
                </Flex>
            </Flex>
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderAvatar = (userId: string | undefined) => {
    if (userId) return <UserThumbnail userId={userId} size={"medium"}/>
    return (
        <Flex vAlign={"center"} hAlign={"center"} className={"request-preview-item-default-avatar"}>
            <PersonIcon outline/>
        </Flex>
    )
}