import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {UserRole} from "../../interfaces/UserRole";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";

const getUserRoles = () => new Promise<Array<UserRole>>(async resolve => {
    try {
        const response = await AxiosConfig.instance.get("/users/me/roles");
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get user role", error);
        return resolve([UserRole.Staff]);
    }
});

export const UserApi = {
    getUserRole: getUserRoles
}