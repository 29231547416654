import {State} from "./UpdateClockingDialog.interfaces";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Shift} from "../../../classes/Shift";

export const initialState: State = {
    currentShopId: "",
    shift: undefined,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, {currentShopId, shift}: {
        currentShopId: string | undefined,
        shift: Immutable<Shift>
    }) => {
        if (!currentShopId) return;
        setState({currentShopId, shift});
        config.dialogRef.dispatch("open")();
    },
    reset: ({setState}) => {
        setState({...initialState});
    },
}) satisfies MagicReducerObject<State>;