import {ITableColumn, ITableColumnWidth} from "../../Table/Table.interfaces";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {ShopShiftCategory, ShopShiftCategoryType} from "interfaces/ShopData";
import {translations} from "translations";
import {AcceptIcon, CloseIcon, Flex, Skeleton, Text} from "@fluentui/react-northstar";
import React from "react";
import moment from "moment";

export const useCategoriesTableColumns = (
    shopId: string,
    handleEditCategory: (category: Immutable<ShopShiftCategory> | null) => void,
    type: ShopShiftCategoryType,
) => {
    const columns: Array<ITableColumn<Immutable<ShopShiftCategory> | null>> = [
        generateNameColumn(shopId, handleEditCategory, type),
        generateStartTimeColumn(type),
        generateEndTimeColumn(type),
        ...(type === ShopShiftCategoryType.Absence ? [] : [generateBadgeColumn()]),
        generateShowInListColumn(type),
    ]
    return columns;
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getColumnsMaxWidth = (type: ShopShiftCategoryType): Record<string, ITableColumnWidth> => {
    if (type === ShopShiftCategoryType.Absence) return {
        name: "30%",
        startTime: "25%",
        endTime: "23%",
        badge: "0",
        showInList: "22%",
    }
    return {
        name: "25%",
        startTime: "20%",
        endTime: "18%",
        badge: "15%",
        showInList: "22%",
    }
}

const generateNameColumn = (
    shopId: string,
    handleEditCategory: (category: Immutable<ShopShiftCategory> | null) => void,
    type: ShopShiftCategoryType,
): ITableColumn<Immutable<ShopShiftCategory> | null> => ({
    field: "name",
    label: translations.get("Name"),
    render: item => {
        const className = [
            "overflow-hidden",
            item?.shopId !== shopId && "cursor-disabled",
        ].filter(Boolean).join(" ");
        return (
            <Flex fill className={className} title={item?.name} gap={"gap.small"} vAlign={"center"}
                  styles={{marginLeft: "5px"}}>
                <Flex className={"category-color-container"} style={{backgroundColor: item?.color}}/>
                <Text truncated content={item?.name}/>
            </Flex>
        )
    },
    sort: (a, b) => {
        const aString = "" + a?.name;
        const bString = "" + b?.name;
        return aString.localeCompare(bString);
    },
    minWidth: "100px",
    maxWidth: getColumnsMaxWidth(type).name!,
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Shape width={"16px"} height={"16px"} className={"no-shrink"}/>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
    onClick: handleEditCategory,
});

const generateStartTimeColumn = (type: ShopShiftCategoryType): ITableColumn<Immutable<ShopShiftCategory> | null> => ({
    field: "startTime",
    label: translations.get("StartTime"),
    render: item => !item ? "" : moment(item?.start).format("LT"),
    sort: (a, b) => {
        return ((a?.start.hour ?? 0) + (a?.start.minutes ?? 0)) - ((b?.start.hour ?? 0) + (b?.start.minutes ?? 0));
    },
    minWidth: "100px",
    maxWidth: getColumnsMaxWidth(type).startTime!,
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
});

const generateEndTimeColumn = (type: ShopShiftCategoryType): ITableColumn<Immutable<ShopShiftCategory> | null> => ({
    field: "endTime",
    label: translations.get("EndTime"),
    render: item => !item ? "" : moment(item?.end).format("LT"),
    sort: (a, b) => {
        return ((a?.end.hour ?? 0) + (a?.end.minutes ?? 0)) - ((b?.end.hour ?? 0) + (b?.end.minutes ?? 0));
    },
    minWidth: "100px",
    maxWidth: getColumnsMaxWidth(type).endTime!,
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
});

const generateBadgeColumn = (): ITableColumn<Immutable<ShopShiftCategory> | null> => ({
    field: "badge",
    label: translations.get("Badge"),
    render: item => item?.badge ? <AcceptIcon outline/> : <CloseIcon outline/>,
    sort: (a) => a?.badge ? 1 : -1,
    minWidth: "100px",
    maxWidth: "15%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
});

const generateShowInListColumn = (type: ShopShiftCategoryType): ITableColumn<Immutable<ShopShiftCategory> | null> => ({
    field: "showInList",
    label: translations.get(type === ShopShiftCategoryType.Shift ? "DisplayDistribution" : "InReport"),
    render: item => item?.showInList ? <AcceptIcon outline/> : <CloseIcon outline/>,
    sort: (a) => a?.showInList ? 1 : -1,
    minWidth: "100px",
    maxWidth: getColumnsMaxWidth(type).showInList!,
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
});