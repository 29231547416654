import {State} from "./StaffPart.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {useGroupCache} from "../../../../../hooks/cache/groups/useGroupCache";
import {translations} from "../../../../../translations";
import {DialogContextValue} from "../../../../../services/DialogContext/DialogContext.interfaces";

export const initialState: State = {}

export const reducer = (config: {
    addStaffs: ReturnType<typeof useGroupCache>["addStaffs"],
    deleteStaff: ReturnType<typeof useGroupCache>["deleteStaff"],
    selectPeopleDialog: DialogContextValue["selectPeopleDialog"],
}) => ({
    addStaff: async () => {
        config.selectPeopleDialog.dispatch("open", {
            multiple: true,
            title: translations.get("AddPeople"),
            callback: usersIds => config.addStaffs(usersIds)
        })();
    },
    deleteStaff: (_, __, userId: string) => {
        config.deleteStaff(userId);
    }
}) satisfies MagicReducerObject<State>;