import {State} from "./ActivitiesTable.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import React from "react";
import {InputProps} from "@fluentui/react-northstar";
import {UseShopCache} from "hooks/cache/useShopsCache";
import {ShopData, ShopShiftActivity} from "interfaces/ShopData";

export const initialState: State = {}

export const reducer = (config: {
    shop: Immutable<ShopData> | undefined,
    upsertActivity: UseShopCache["upsertActivity"]
}) => ({
    updateName: async (
        _,
        [, data]: [React.SyntheticEvent, InputProps & { value: string } | undefined],
        activityId: string | undefined
    ) => {
        if (!activityId) return;
        const name = (data?.value ?? "") + "";
        const activity = config.shop?.activities.find(a => a.key === activityId);
        if (!activity) return;
        const newActivity: ShopShiftActivity = {...activity, name};
        await config.upsertActivity(newActivity);
    },
}) satisfies MagicReducerObject<State>;