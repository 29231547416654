export const translations = {
    locale: "en",
    get: (id: string, parameters?: Record<string, string | number>): string => {
        if (!id) return "";
        const object = (translations as any)[translations.locale] ?? (translations as any).en;
        let translation = object[id] ?? (translations as any).en[id] ?? "";
        if (!translation) {
            console.error("Missing translation for '" + id + "'");
            translation = id;
        }
        if (parameters) {
            for (const param in parameters)
                translation = translation.replaceAll("{" + param + "}", parameters[param] + "");
        }
        return translation;
    },
    en: {
        TokenExpired: "Token expired",
        PleaseRefreshTab: "Please refresh tab",
        AnErrorOccured: "An error occured",
        Consent: "Consent",
        NeedToConsent: "Need to consent",
        ClickButtonToContinue: "Click button to continue",
        NewShift: "New shift",
        Today: "Today",
        Filter: "Filter",
        View: "View",
        SharePlanning: "Share planning",
        CopyPlanning: "Copy planning",
        PrintPlanning: "Print planning",
        ExportPlanning: "Export planning",
        Configuration: "Configuration",
        Category: "Category",
        Group: "Group",
        Staff: "Staff",
        People: "People",
        Shift: "Shift",
        Day: "Day",
        Week: "Week",
        Month: "Month",
        Year: "Year",
        Custom: "Custom",
        StaffCount: "Staff count",
        DayNotes: "Day notes",
        Filters: "Filters",
        Reset: "Reset",
        Name: "Name",
        Opening: "Opening",
        Middle: "Middle",
        Closing: "Closing",
        CurrentMonthWorked: "Current month worked",
        Sundays: "Sundays",
        PublicHolidays: "Public holidays",
        Absence: "Absence",
        Type: "Type",
        Store: "Store",
        SelectAStore: "Select a store",
        SelectAStaff: "Select a staff",
        ShiftType: "Shift type",
        SelectAType: "Select a type",
        CustomWording: "Custom wording",
        EnterCustomWording: "Enter custom wording",
        SelectADate: "Select a date",
        StartTime: "Start time",
        EndTime: "End time",
        Recurring: "Recurring",
        Notes: "Notes",
        EnterNotesAboutTheShift: "Enter notes about the shift",
        Date: "Date",
        ShiftActivities: "Shift activities",
        AddActivity: "Add activity",
        Save: "Save",
        Notify: "Notify",
        StartDate: "Start date",
        EndDate: "End date",
        ShareWith: "Share with",
        SelectWhoWillReceiveANotification: "Select who will receive a notification",
        CopyScheduleGroups: "Copy schedule groups",
        SelectGroupsToCopy: "Select groups to copy",
        DestinationDate: "Destination date",
        Copy: "Copy",
        SelectCopyHour: "Select copy hour",
        YouCopyRangeFromTo: "You copy the range {from} to the range {to}",
        Export: "Export",
        EnterName: "Enter name",
        PostalAddress: "Postal address",
        EnterAddress: "Enter address",
        ManagerSingularPlural: "Manager(s)",
        SelectOneOrMoreManagers: "Select one or more managers",
        Shop: "Shop",
        Categories: "Categories",
        Activities: "Activities",
        Groups: "Groups",
        Shifts: "Shifts",
        Absences: "Absences",
        AddGroup: "Add group",
        GroupConfiguration: "{group} configuration",
        ShiftThresholds: "Shift thresholds",
        LegalRules: "Legal rules",
        Sunday: "Sunday",
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        SelectedShift: "Selected shift",
        WeekThresholds: "Week thresholds",
        CustomThresholds: "Custom thresholds",
        AddCustomThreshold: "Add custom threshold",
        CustomShiftThreshold: "Custom shift threshold",
        Delete: "Delete",
        MaximumConsecutiveWorkingDays: "Maximum consecutive working days",
        MaximumBreakHoursBetweenShifts: "Maximum break hours between shifts",
        MaximumDailyWorkingHours: "Maximum daily working hours",
        MaximumWeeklyWorkingHours: "Maximum weekly working hours",
        AddPersonRules: "Add person rules",
        MaximumWorkingSundaysPerMonth: "Maximum working sundays per month",
        Status: "Status",
        EnterStatus: "Enter status",
        ContractType: "Contract type",
        EnterStartDate: "Enter start date",
        EnterEndDate: "Enter end date",
        MaximumWorkingHours: "Maximum working hours",
        MaximumOvertimeHours: "Maximum overtime hours",
        PeriodOfContract: "Period of contract",
        AddStaff: "Add staff",
        SearchStaff: "Search staff",
        NewGroup: "New group",
        Add: "Add",
        NameAlreadyExists: "Name already exists",
        Badge: "Badge",
        InList: "In list",
        No: "No",
        Yes: "Yes",
        AddCategory: "Add category",
        NoResult: "No result",
        WorkingOutside: "Working outside",
        NotifyAffectedMembers: "Notify affected members",
        Share: "Share",
        EditShift: "Edit shift",
        UpdateClocking: "Update clocking",
        Paste: "Paste",
        StartHour: "Start hour",
        SelectAnHour: "Select an hour",
        EndHour: "End hour",
        Chat: "Chat",
        Call: "Call",
        OpeningHours: "Opening hours",
        Cancel: "Cancel",
        PerDay: "Per day",
        PerWeek: "Per week",
        SelectPublicHolidays: "Select public holidays",
        AddShop: "Add shop",
        NewShop: "New shop",
        EditShop: "Edit shop",
        Collaborators: "Collaborators",
        EditActivity: "Edit activity",
        ImportPlanning: "Import planning",
        ImportYourPlanning: "Import your planning",
        PleaseSelectAPlanning: "Please select a planning",
        DownloadTemplate: "Download template",
        SelectAPlanning: "Select a planning",
        ImportationOfPlanningInProgress: "Importation of planning in progress",
        PleaseWaitAMoment: "Please wait a moment",
        PlanningImportedWithSuccess: "Planning imported with success",
        YourPlanningHasBeenUpdated: "Your planning has been updated",
        BigStore: "Department store",
        OwnStore: "Street store",
        SelectType: "Select type",
        InvalidTime: "Invalid time",
        Color: "Color",
        EditCategory: "Edit category",
        Edit: "Edit",
        CategoryAlreadyExists: "Category already exists",
        DeleteCategory: "Delete category",
        AreYouSureToDeleteThisCategory: "Are you sure to delete <strong>{name}</strong> category?",
        ActivityAlreadyExists: "Activity already exists",
        DeleteActivity: "Delete activity",
        AreYouSureToDeleteThisActivity: "Are you sure to delete <strong>{name}</strong> activity?",
        DeleteCustomThreshold: "Delete custom threshold",
        AreYouSureToDeleteThisCustomThreshold: "Are you sure to delete <strong>{name}</strong> custom threshold?",
        DeleteCustomRules: "Delete custom rules",
        AreYouSureToDeleteCustomRulesOfName: "Are you sure to delete custom rules of <strong>{name}</strong>?",
        Attributed: "Attributed",
        RepeatEach: "Repeat each",
        OrderGroups: "Order groups",
        DeleteShop: "Delete shop",
        AreYouSureToDeleteShop: "Are you sure to delete <strong>{name}</strong> shop?",
        SelectStaff: "Select staff",
        French: "French",
        LinkedTeam: "Linked team",
        LinkTeamToThisShop: "Link team to this shop",
        WorksAMaximumOfXHoursPerWeek: "Works a maximum of {hours} hours per week",
        WorksAMaximumOfXHoursPerDay: "Works a maximum of {hours} hours per day",
        DeleteShift: "Delete shift",
        AreYouSureToDeleteShift: "Are you sure to delete <strong>{name}</strong> shift?",
        WelcomeInYourShop: "Welcome in your shop",
        CreateYourFirstGroup: "Create your first group",
        SelectedCategory: "Selected category",
        SelectACategory: "Select a category",
        NoCategoryAvailable: "No category available",
        CreateYourFirstCategory: "Create your first category",
        NotesOfDay: "Notes of {day}",
        OnlyMyShop: "Only my shop",
        Search: "Search",
        PleaseTryAgain: "Please try again",
        CustomLegalRules: "Custom legal rules",
        EnterContractType: "Enter contract type",
        ToDefine: "To define",
        Include: "Include",
        OpenShifts: "Open shifts",
        "Time(s)": "Time(s)",
        SearchUser: "Search user",
        Print: "Print",
        Report: "Report",
        AbsencesPerType: "Absences per type",
        WorkingHoursSingularPlural: "Working hour(s)",
        OvertimeHoursSingularPlural: "Overtime hour(s)",
        DelaysSingularPlural: "Delay(s)",
        SundayWorkedSingularPlural: "Sunday worked",
        PublicHolidaysWorkedSingularPlural: "Public holiday(s) worked",
        HoursToRetrieve: "Hours to retrieve",
        AbsencesSingularPlural: "Absence(s)",
        NoDataAvailable: "No data available",
        InReport: "In report",
        ShiftDetails: "Shift details",
        AbsenceDetails: "Absence details",
        NewAbsence: "New absence",
        EditAbsence: "Edit absence",
        DeleteAbsence: "Delete absence",
        AreYouSureToDeleteAbsence: "Are you sure to delete <strong>{name}</strong> absence?",
        Settings: "Settings",
        General: "General",
        Shops: "Shops",
        ShopType: "Shop type",
        RetrieveHoursMultipliers: "Retrieve hours multipliers",
        AddRule: "Add rule",
        OvertimeTriggeredAfter: "Overtime triggered after",
        DelayTriggeredAfter: "Delay triggered after",
        MinutesSingularPlural: "Minute(s)",
        EnterMinutes: "Enter minutes",
        NewRequest: "New request",
        InProgress: "In progress",
        Done: "Done",
        SwapRequestTitle: "{name} requested swap",
        ClockingUpdateRequestTitle: "{name} requested clocking update",
        TimeOffRequestTitle: "{name} will be absent",
        SelectARequest: "Select a request",
        AddANoteOptional: "Add a note (optional)",
        Deny: "Deny",
        Approve: "Approve",
        ManagerResponse: "Manager response",
        PendingFinalApproval: "Pending final approval",
        RequestApproved: "Request approved",
        SwapWith: "Swap with",
        ClockedOutOutsideStore: "Clocked-out outside store",
        Justification: "Justification",
        ShiftEndTime: "Shift end time",
        NewClockingTime: "New clocking time",
        RequestDenied: "Request denied",
        TimeOff: "Time off",
        Swap: "Swap",
        Reason: "Reason",
        SelectAReason: "Select a reason",
        AddANote: "Add a note",
        AllDay: "All day",
        SendRequest: "Send request",
        SelectAShift: "Select a shift",
        AvailableShifts: "Available shifts",
        NoShiftAvailable: "No shift available",
        IWantToExchangeThisShift: "I want to exchange this shift",
        AgainstThisShift: "Against this shift",
        EnterJustification: "Enter justification",
        ShiftToFix: "Shift to fix",
        NoRequest: "No request",
        Period: "Period",
        ShiftHours: "Shift hours",
        Undefined: "Undefined",
        YouUpdatePastShift: "You update past shift",
        ThinkToUpdateClockingIfNecessary: "Think to update clocking if necessary",
        YouUpdatePastAbsence: "You update past absence",
        NotifyManager: "Notify manager",
        ForTheDate: "For the {date}",
        Clocking: "Clocking",
        NextShift: "Next shift",
        AllMyShifts: "All my shifts",
        SearchStaffPlanning: "Search staff planning",
        SearchShopPlanning: "Search shop planning",
        MyShop: "My shop",
        ClockIn: "Clock in",
        ClockOut: "Clock out",
        InvalidQrCode: "Invalid QR code",
        DoYouWantToForceClocking: "Do you want to force clocking?",
        Continue: "Continue",
        Confirm: "Confirm",
        PleaseExplainTheReason: "Please explain the reason",
        RealClockingTime: "Real clocking time",
        NoUpcomingShift: "No upcoming shift",
        MyReport: "My report",
        Loading: "Loading",
        TodayNotes: "Today notes",
        NoNotes: "No notes",
        NoActivities: "No activities",
        Owner: "Owner",
        SwapShift: "Swap shift",
        NotAvailableOnMobile: "Not available on mobile",
        PreviousClockingTime: "Previous clocking time",
        ClockingUpdateRequestOutsideStoreTitle: "{name} clocked out of the store",
        RequestProcessed: "Request processed",
        Processed: "Processed",
        LinkedShiftSingularPlural: "Linked shift(s)",
        NoLinkedShift: "No linked shift",
        Rename: "Rename",
        RenameGroup: "Rename group",
        DeleteGroup: "Delete group",
        AreYouSureToDeleteThisGroup: "Are you sure to delete <strong>{name}</strong> group?",
        SelectPeople: "Select people",
        SelectPerson: "Select person",
        AddPeople: "Add people",
        CancelChanges: "Cancel changes",
        AreYouSureToCancelChanges: "Are you sure to cancel changes?",
        NotAuthorized: "Not authorized",
        YouDontHaveTheRightToAccessThisPage: "You don't have the right to access this page",
        Until: "Until",
        Occurence: "Occurence",
        Series: "Series",
        UpdateOf: "Update of",
        EditSeries: "Edit series",
        DeleteSeries: "Delete series",
        AreYouSureToDeleteSeries: "Are you sure to delete <strong>{name}</strong> series?",
        ByModifyingRecurrenceOccurrencesWillBeReplaced: "By modifying the recurrence all occurrences will be replaced",
        DeleteShifts: "Delete shifts",
        AreYouSureToDeleteShifts: "Are you sure to delete <strong>{count}</strong> shifts?",
        AreYouSureToDeleteSelectedShift: "Are you sure to delete selected shift?",
        UnclockedShifts: "Unclocked shifts",
        UnclockedShiftsSingularPlural: "Unclocked shift(s)",
        ConfirmImport: "Confirm import",
        ConfirmImportWarningMessage: "Please note that data already entered in the schedule will be overwritten.",
        Import: "Import",
        ReplaceShifts: "Replace shifts",
        PasteShiftsWarningMessage: "Are you sure to paste shifts? Some of them will be replaced.",
        NoShopFound: "No shop found",
        CreateYourFirstShopInSettingsTab: "Create your first shop in settings tab",
        HeadOffice: "Head office",
        ThereCanOnlyBeOneHeadOffice: "There can only be one head office",
        RotatingStaff: "Rotating staff",
        AwaitingApproval: "Awaiting approval",
        AwaitingYourApproval: "Awaiting your approval",
        ProcessingInProgress: "Processing in progress",
        ShiftsToShare: "Shifts to share",
        ShiftInProgressNotBadgeable: "Shift in progress not badgeable",
        DisplayDistribution: "Display distribution",
        AddPerson: "Add person",
        UserIsNotUnderActiveContract: "User is not under active contract",
        MaximumDailyPlannedHoursReached: "Maximum daily planned hours reached",
        MaximumWeeklyPlannedHoursReached: "Maximum weekly planned hours reached",
        MaximumWorkingSundaysReached: "Maximum working sundays reached",
        MinimumBreakHoursNotRespected: "Minimum break hours not respected",
        MaximumConsecutiveWorkingDaysReached: "Maximum consecutive working days reached",
        RuleNotRespected: "Rule not respected",
        Alert: "Alert",
        DaysSingularPlural: "Day(s)",
        HoursSingularPlural: "Hour(s)",
        EnterDaysCount: "Enter days count",
        EnterHours: "Enter hours",
        MinimalBreakHoursBetweenShifts: "Minimal break hours between shifts",
        PermanentContract: "Permanent",
        FixedTermContract: "Fixed term",
        InternContract: "Intern",
        FreelanceContract: "Freelance",
        FixedPriceContract: "Fixed price contract",
        InternCannotWorkSunday: "Intern cannot work sunday",
        WeekSummaryRequestTitle: "Week summary of {date}",
        LegalWorkingHours: "Legal working hours",
        WorkingHoursInferiorToLegalHours: "Working hours inferior to legal hours",
        WeekSummary: "Week summary",
        WorkingHoursCorrection: "Working hours correction",
        AvailableHoursToRetrieve: "Available hours to retrieve",
        UsedRetrievedHours: "Used retrieved hours",
        Apply: "Apply",
        NotEnoughRetrieveHoursMessage: "An absence will be counted because the number of retrieve hours is insufficient to make up for the missing hours.",
        System: "System",
        IncludingRetrieveHours: "Including {count} retrieve hour(s)",
    },
    fr: {
        TokenExpired: "Token expiré",
        PleaseRefreshTab: "Veuillez rafraîchir l'onglet",
        AnErrorOccured: "Une erreur est survenue",
        Consent: "Consentir",
        NeedToConsent: "Vous devez donner votre accord",
        ClickButtonToContinue: "Cliquez sur le bouton pour continuer",
        NewShift: "Nouveau shift",
        Today: "Aujourd'hui",
        Filter: "Filtrer",
        View: "Affichage",
        SharePlanning: "Partager le planning",
        CopyPlanning: "Copier le planning",
        PrintPlanning: "Imprimer le planning",
        ExportPlanning: "Exporter le planning",
        Configuration: "Configuration",
        Category: "Catégorie",
        Group: "Groupe",
        Staff: "Personnel",
        People: "Personnes",
        Shift: "Shift",
        Day: "Jour",
        Week: "Semaine",
        Month: "Mois",
        Year: "Année",
        Custom: "Personnalisé",
        StaffCount: "Nombre de personnel",
        DayNotes: "Notes de la journée",
        Filters: "Filtres",
        Reset: "Réinitialiser",
        Name: "Nom",
        Opening: "Ouverture",
        Middle: "Milieu",
        Closing: "Fermeture",
        CurrentMonthWorked: "Mois en cours travaillé",
        Sundays: "Dimanches",
        PublicHolidays: "Jours fériés",
        Absence: "Absence",
        Type: "Type",
        Store: "Boutique",
        SelectAStore: "Sélectionnez une boutique",
        SelectAStaff: "Sélectionnez un membre du personnel",
        ShiftType: "Type de shift",
        SelectAType: "Sélectionnez un type",
        CustomWording: "Libellé personnalisé",
        EnterCustomWording: "Saisissez un libellé personnalisé",
        SelectADate: "Sélectionnez une date",
        StartTime: "Heure de début",
        EndTime: "Heure de fin",
        Recurring: "Récurrent",
        Notes: "Notes",
        EnterNotesAboutTheShift: "Saisissez des notes sur le shift",
        Date: "Date",
        ShiftActivities: "Activités du shift",
        AddActivity: "Ajouter une activité",
        Save: "Sauvegarder",
        Notify: "Notifier",
        StartDate: "Date de début",
        EndDate: "Date de fin",
        ShareWith: "Partager avec",
        SelectWhoWillReceiveANotification: "Sélectionnez qui recevra une notification",
        CopyScheduleGroups: "Copier les groupes de planning",
        SelectGroupsToCopy: "Sélectionnez les groupes à copier",
        DestinationDate: "Date de destination",
        Copy: "Copier",
        SelectCopyHour: "Sélectionner l'heure à copier",
        YouCopyRangeFromTo: "Vous copiez l'interval du {from} au {to}",
        Export: "Exporter",
        EnterName: "Saisissez un nom",
        PostalAddress: "Adresse postale",
        EnterAddress: "Saisissez une adresse",
        ManagerSingularPlural: "Manager(s)",
        SelectOneOrMoreManagers: "Sélectionnez un ou plusieurs managers",
        Shop: "Boutique",
        Categories: "Catégories",
        Activities: "Activités",
        Groups: "Groupes",
        Shifts: "Shifts",
        Absences: "Absences",
        AddGroup: "Ajouter un groupe",
        GroupConfiguration: "Configuration de {group}",
        ShiftThresholds: "Seuils de shift",
        LegalRules: "Règles légales",
        Sunday: "Dimanche",
        Monday: "Lundi",
        Tuesday: "Mardi",
        Wednesday: "Mercredi",
        Thursday: "Jeudi",
        Friday: "Vendredi",
        Saturday: "Samedi",
        SelectedShift: "Shift sélectionné",
        WeekThresholds: "Seuils de semaine",
        CustomThresholds: "Seuils personnalisés",
        AddCustomThreshold: "Ajouter un seuil personnalisé",
        CustomShiftThreshold: "Seuil de shift personnalisé",
        Delete: "Supprimer",
        MaximumConsecutiveWorkingDays: "Nombre maximum de jours de travail consécutifs",
        MaximumBreakHoursBetweenShifts: "Nombre maximum d'heures de pause entre les shifts",
        MaximumDailyWorkingHours: "Nombre maximum d'heures de travail par jour",
        MaximumWeeklyWorkingHours: "Nombre maximum d'heures de travail par semaine",
        AddPersonRules: "Ajouter des règles personnelles",
        MaximumWorkingSundaysPerMonth: "Nombre maximum de dimanches travaillés par mois",
        Status: "Statut",
        EnterStatus: "Saisissez un statut",
        ContractType: "Type de contrat",
        EnterStartDate: "Saisissez une date de début",
        EnterEndDate: "Saisissez une date de fin",
        MaximumWorkingHours: "Nombre maximum d'heures de travail",
        MaximumOvertimeHours: "Nombre maximum d'heures supplémentaires",
        PeriodOfContract: "Période de contrat",
        AddStaff: "Ajouter du personnel",
        SearchStaff: "Rechercher du personnel",
        NewGroup: "Nouveau groupe",
        Add: "Ajouter",
        NameAlreadyExists: "Le nom existe déjà",
        Badge: "Badge",
        InList: "Dans la liste",
        No: "Non",
        Yes: "Oui",
        AddCategory: "Ajouter une catégorie",
        NoResult: "Aucun résultat",
        WorkingOutside: "Travaille ailleurs",
        NotifyAffectedMembers: "Notifier les membres concernés",
        Share: "Partager",
        EditShift: "Modifier le shift",
        UpdateClocking: "Corriger le pointage",
        Paste: "Coller",
        StartHour: "Heure de début",
        SelectAnHour: "Sélectionnez une heure",
        EndHour: "Heure de fin",
        Chat: "Chat",
        Call: "Appeler",
        OpeningHours: "Heures d'ouverture",
        Cancel: "Annuler",
        PerDay: "Par jour",
        PerWeek: "Par semaine",
        SelectPublicHolidays: "Sélectionnez les jours fériés",
        AddShop: "Ajouter une boutique",
        NewShop: "Nouvelle boutique",
        EditShop: "Modifier la boutique",
        Collaborators: "Collaborateurs",
        EditActivity: "Modifier l'activité",
        ImportPlanning: "Importer le planning",
        ImportYourPlanning: "Importez votre planning",
        PleaseSelectAPlanning: "Veuillez sélectionner un planning",
        DownloadTemplate: "Télécharger le modèle",
        SelectAPlanning: "Sélectionnez un planning",
        ImportationOfPlanningInProgress: "Importation du planning en cours",
        PleaseWaitAMoment: "Veuillez patienter",
        PlanningImportedWithSuccess: "Planning importé avec succès",
        YourPlanningHasBeenUpdated: "Votre planning a été mis à jour",
        BigStore: "Grande boutique",
        OwnStore: "Boutique sur rue",
        SelectType: "Sélectionnez un type",
        InvalidTime: "Temps invalide",
        Color: "Couleur",
        EditCategory: "Modifier la catégorie",
        Edit: "Modifier",
        CategoryAlreadyExists: "La catégorie existe déjà",
        DeleteCategory: "Supprimer la catégorie",
        AreYouSureToDeleteThisCategory: "Êtes-vous sûr de vouloir supprimer la catégorie <strong>{name}</strong> ?",
        ActivityAlreadyExists: "L'activité existe déjà",
        DeleteActivity: "Supprimer l'activité",
        AreYouSureToDeleteThisActivity: "Êtes-vous sûr de vouloir supprimer l'activité <strong>{name}</strong> ?",
        DeleteCustomThreshold: "Supprimer le seuil personnalisé",
        AreYouSureToDeleteThisCustomThreshold: "Êtes-vous sûr de vouloir supprimer le seuil personnalisé <strong>{name}</strong> ?",
        DeleteCustomRules: "Supprimer les règles personnalisées",
        AreYouSureToDeleteCustomRulesOfName: "Êtes-vous sûr de vouloir supprimer les règles personnalisées de <strong>{name}</strong> ?",
        Attributed: "Attribué",
        RepeatEach: "Répéter chaque",
        OrderGroups: "Ordonner les groupes",
        DeleteShop: "Supprimer la boutique",
        AreYouSureToDeleteShop: "Êtes-vous sûr de vouloir supprimer la boutique <strong>{name}</strong> ?",
        SelectStaff: "Sélectionnez du personnel",
        French: "Français",
        LinkedTeam: "Équipe liée",
        LinkTeamToThisShop: "Liez une équipe à cette boutique",
        WorksAMaximumOfXHoursPerWeek: "Travaille un maximum de {hours} heures par semaine",
        WorksAMaximumOfXHoursPerDay: "Travaille un maximum de {hours} heures par jour",
        DeleteShift: "Supprimer le shift",
        AreYouSureToDeleteShift: "Êtes-vous sûr de vouloir supprimer le shift <strong>{name}</strong> ?",
        WelcomeInYourShop: "Bienvenue dans votre boutique",
        CreateYourFirstGroup: "Créez votre premier groupe",
        SelectedCategory: "Catégorie sélectionnée",
        SelectACategory: "Sélectionnez une catégorie",
        NoCategoryAvailable: "Aucune catégorie disponible",
        CreateYourFirstCategory: "Créez votre première catégorie",
        NotesOfDay: "Notes du {day}",
        OnlyMyShop: "Uniquement ma boutique",
        Search: "Rechercher",
        PleaseTryAgain: "Veuillez réessayer",
        CustomLegalRules: "Règles légales personnalisées",
        EnterContractType: "Saisissez le type de contrat",
        ToDefine: "À définir",
        Include: "Inclure",
        OpenShifts: "Shifts ouverts",
        "Time(s)": "Fois",
        SearchUser: "Rechercher un utilisateur",
        Print: "Imprimer",
        Report: "Rapport",
        AbsencesPerType: "Absences par type",
        WorkingHoursSingularPlural: "Heure(s) travaillée(s)",
        OvertimeHoursSingularPlural: "Heure(s) supplémentaire(s)",
        DelaysSingularPlural: "Retard(s)",
        SundayWorkedSingularPlural: "Dimanche(s) travaillé(s)",
        PublicHolidaysWorkedSingularPlural: "Jour(s) férié(s) travaillé(s)",
        WeekEndOff: "Week-end de repos",
        HoursToRetrieve: "Heures à récupérer",
        AbsencesSingularPlural: "Absence(s)",
        NoDataAvailable: "Aucune donnée disponible",
        InReport: "Dans le rapport",
        ShiftDetails: "Détails du shift",
        AbsenceDetails: "Détails de l'absence",
        NewAbsence: "Nouvelle absence",
        EditAbsence: "Modifier l'absence",
        DeleteAbsence: "Supprimer l'absence",
        AreYouSureToDeleteAbsence: "Êtes-vous sûr de vouloir supprimer l'absence <strong>{name}</strong> ?",
        Settings: "Paramètres",
        General: "Généraux",
        Shops: "Boutiques",
        ShopType: "Type de boutique",
        RetrieveHoursMultipliers: "Multiplicateurs d'heures de récupération",
        AddRule: "Ajouter une règle",
        OvertimeTriggeredAfter: "Heure supplémentaire déclenchée après",
        DelayTriggeredAfter: "Retard déclenché après",
        MinutesSingularPlural: "Minute(s)",
        EnterMinutes: "Saisissez les minutes",
        NewRequest: "Nouvelle demande",
        InProgress: "En cours",
        Done: "Terminé",
        SwapRequestTitle: "{name} demande un échange",
        ClockingUpdateRequestTitle: "{name} souhaite modifier un pointage",
        TimeOffRequestTitle: "{name} va être absent",
        SelectARequest: "Sélectionnez une demande",
        AddANoteOptional: "Ajouter une note (optionnel)",
        Deny: "Refuser",
        Approve: "Accepter",
        ManagerResponse: "Réponse du manager",
        PendingFinalApproval: "En attente de la réponse finale",
        RequestApproved: "Demande approuvée",
        SwapWith: "Échange avec",
        ClockedOutOutsideStore: "Pointage en dehors de la boutique",
        Justification: "Justification",
        ShiftEndTime: "Heure de fin du shift",
        NewClockingTime: "Nouvelle heure de pointage",
        RequestDenied: "Demande refusée",
        TimeOff: "Congés",
        Swap: "Échanger",
        Reason: "Raison",
        SelectAReason: "Sélectionnez une raison",
        AddANote: "Ajouter une note",
        AllDay: "Journée entière",
        SendRequest: "Envoyer la demande",
        SelectAShift: "Sélectionnez un shift",
        AvailableShifts: "Shifts disponibles",
        NoShiftAvailable: "Aucun shift disponible",
        IWantToExchangeThisShift: "Je souhaite échanger ce shift",
        AgainstThisShift: "Contre ce shift",
        EnterJustification: "Saisissez une justification",
        ShiftToFix: "Shift à corriger",
        NoRequest: "Aucune demande",
        Period: "Période",
        ShiftHours: "Heures du shift",
        Undefined: "Non définie",
        YouUpdatePastShift: "Vous mettez à jour un shift passé",
        ThinkToUpdateClockingIfNecessary: "Pensez à mettre à jour le pointage si nécessaire",
        YouUpdatePastAbsence: "Vous mettez à jour une absence passée",
        NotifyManager: "Notifier le manager",
        ForTheDate: "Pour le {date}",
        Clocking: "Pointage",
        NextShift: "Prochain shift",
        AllMyShifts: "Tous mes shifts",
        SearchStaffPlanning: "Rechercher le planning d'un personnel",
        SearchShopPlanning: "Rechercher le planning d'une boutique",
        MyShop: "Ma boutique",
        ClockIn: "Pointer l'entrée",
        ClockOut: "Pointer la sortie",
        InvalidQrCode: "QR code invalide",
        DoYouWantToForceClocking: "Voulez-vous forcer le pointage ?",
        Continue: "Continuer",
        Confirm: "Valider",
        PleaseExplainTheReason: "Veuillez expliquer la raison",
        RealClockingTime: "Heure réelle de pointage",
        NoUpcomingShift: "Aucun shift à venir",
        MyReport: "Mon rapport",
        Loading: "Chargement",
        TodayNotes: "Notes du jour",
        NoNotes: "Aucune note",
        NoActivities: "Aucune activité",
        Owner: "Propriétaire",
        SwapShift: "Échanger le shift",
        NotAvailableOnMobile: "Non disponible sur mobile",
        PreviousClockingTime: "Heure de pointage précédente",
        ClockingUpdateRequestOutsideStoreTitle: "{name} a pointé en dehors de la boutique",
        RequestProcessed: "Demande traitée",
        Processed: "Traité",
        LinkedShiftSingularPlural: "Shift(s) lié(s)",
        NoLinkedShift: "Aucun shift lié",
        Rename: "Renommer",
        RenameGroup: "Renommer le groupe",
        DeleteGroup: "Supprimer le groupe",
        AreYouSureToDeleteThisGroup: "Êtes-vous sûr de vouloir supprimer le groupe <strong>{name}</strong> ?",
        SelectPeople: "Sélectionnez des personnes",
        SelectPerson: "Sélectionnez une personne",
        AddPeople: "Ajouter des personnes",
        CancelChanges: "Annuler les modifications",
        AreYouSureToCancelChanges: "Êtes-vous sûr d'annuler les modifications ?",
        NotAuthorized: "Non autorisé",
        YouDontHaveTheRightToAccessThisPage: "Vous n'avez pas le droit d'accéder à cette page",
        Until: "Jusqu'au",
        Occurence: "Occurence",
        Series: "Série",
        UpdateOf: "Mise à jour de",
        EditSeries: "Modifier la série",
        DeleteSeries: "Supprimer la série",
        AreYouSureToDeleteSeries: "Êtes-vous sûr de vouloir supprimer la série <strong>{name}</strong> ?",
        ByModifyingRecurrenceOccurrencesWillBeReplaced: "En modifiant la récurrence toutes les occurrences seront remplacées",
        DeleteShifts: "Supprimer les shifts",
        AreYouSureToDeleteShifts: "Êtes-vous sûr de vouloir supprimer <strong>{count}</strong> shifts ?",
        AreYouSureToDeleteSelectedShift: "Êtes-vous sûr de vouloir supprimer le shift sélectionné ?",
        UnclockedShifts: "Shifts non pointés",
        UnclockedShiftsSingularPlural: "Shift(s) non pointé(s)",
        ConfirmImport: "Confirmer l'importation",
        ConfirmImportWarningMessage: "Veuillez noter que les données déjà saisies dans le planning seront écrasées.",
        Import: "Importer",
        ReplaceShifts: "Remplacer les shifts",
        PasteShiftsWarningMessage: "Êtes-vous sûr de coller les shifts ? Certains seront remplacés.",
        NoShopFound: "Aucune boutique existante",
        CreateYourFirstShopInSettingsTab: "Créez votre première boutique dans l'onglet paramètres",
        HeadOffice: "Siège",
        ThereCanOnlyBeOneHeadOffice: "Il ne peut y avoir qu'un seul siège",
        RotatingStaff: "Staff tournant",
        AwaitingApproval: "En attente de son accord",
        AwaitingYourApproval: "En attente de votre accord",
        ProcessingInProgress: "Traitement en cours",
        ShiftsToShare: "Shifts à partager",
        ShiftInProgressNotBadgeable: "Shift en cours non badgable",
        DisplayDistribution: "Afficher la répartition",
        AddPerson: "Ajouter une personne",
        UserIsNotUnderActiveContract: "L'utilisateur n'est pas sous contrat actif",
        MaximumDailyPlannedHoursReached: "Nombre maximum d'heures planifiées par jour atteint",
        MaximumWeeklyPlannedHoursReached: "Nombre maximum d'heures planifiées par semaine atteint",
        MaximumWorkingSundaysReached: "Nombre maximum de dimanches travaillés atteint",
        MinimumBreakHoursNotRespected: "Nombre minimum d'heures de pause non respecté",
        MaximumConsecutiveWorkingDaysReached: "Nombre maximum de jours de travail consécutifs atteint",
        RuleNotRespected: "Règle non respectée",
        Alert: "Alerte",
        DaysSingularPlural: "Jour(s)",
        HoursSingularPlural: "Heure(s)",
        EnterDaysCount: "Saisissez le nombre de jours",
        EnterHours: "Saisissez les heures",
        MinimalBreakHoursBetweenShifts: "Nombre minimal d'heures de pause entre les shifts",
        PermanentContract: "CDI",
        FixedTermContract: "CDD",
        InternContract: "Stagiaire",
        FreelanceContract: "Freelance",
        FixedPriceContract: "Forfait",
        InternCannotWorkSunday: "Un stagiaire ne peut pas travailler le dimanche",
        WeekSummaryRequestTitle: "Résumé de la semaine du {date}",
        LegalWorkingHours: "Heures légales",
        WorkingHoursInferiorToLegalHours: "Heures travaillées inférieures aux heures légales",
        WeekSummary: "Résumé de la semaine",
        WorkingHoursCorrection: "Correction des heures de travail",
        AvailableHoursToRetrieve: "Heures de récupération disponibles",
        UsedRetrievedHours: "Heures de récupération utilisées",
        Apply: "Appliquer",
        NotEnoughRetrieveHoursMessage: "Une absence sera comptabilisée car le nombre d'heures de récupération est insuffisant pour compenser les heures manquantes.",
        System: "Système",
        IncludingRetrieveHours: "Dont {count} heure(s) de récup.",
    }
}