import {State} from "./GroupDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {ConfirmCancelChangesDialog} from "../ConfirmCancelChangesDialog/ConfirmCancelChangesDialog";

export const initialState: State = {
    shopId: "",
    isLoading: false,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
    confirmCancelChangesDialog: MagicReducerRef<typeof ConfirmCancelChangesDialog>,
}) => ({
    open: ({setState}, _, shopId: string) => {
        setState({shopId, isLoading: false});
        config.dialogRef.dispatch("open")();
    },
    close: ({setState}) => {
        setState({shopId: ""});
    },
    addGroup: async ({setState}, _, {name, index, addGroupToShop}: {
        name: string,
        index: number,
        addGroupToShop: (name: string, index: number) => Promise<void>
    }) => {
        setState({isLoading: true});
        await addGroupToShop(name, index);
        config.dialogRef.dispatch("close")();
    },
    beforeClose: ({state}, [callback]: [(close: boolean) => void], hasChanged: boolean) => {
        if (state.isLoading || !hasChanged) return callback(true);
        config.confirmCancelChangesDialog.dispatch("open", callback)();
    }
}) satisfies MagicReducerObject<State>;