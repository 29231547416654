import {State} from "./CategoriesPart.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {DialogContextValue} from "../../../../../services/DialogContext/DialogContext.interfaces";
import {ShopShiftCategoryType} from "../../../../../interfaces/ShopData";

export const initialState: State = {}

export const reducer = (config: {
    shopId: string,
    shopShiftCategoryDialog: DialogContextValue["shopShiftCategoryDialog"]
}) => ({
    addCategory: ({}, _, type: ShopShiftCategoryType) => {
        config.shopShiftCategoryDialog.dispatch("open", {
            shopId: config.shopId,
            type,
            category: undefined,
        })();
    }
}) satisfies MagicReducerObject<State>;