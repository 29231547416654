import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import React, {useEffect, useState} from "react";
import {useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "../../translations";
import {ArrowRightIcon, Button, Flex, SendIcon} from "@fluentui/react-northstar";
import moment from "moment";
import {useUserShopCache} from "../cache/useShopsCache";
import {useRequestsCache} from "../cache/useRequestsCache";
import {RequestType, TimeOffRequestData} from "../../interfaces/RequestData";
import {Time} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/TimePicker/TimePicker";
import {FormModule} from "../../modules/Form.module";

export const useTimeOffRequestForm = () => {
    const {shop} = useUserShopCache();
    const {userId, isOnMobile} = useMsTeamsSelector("userId", "isOnMobile");
    const {createRequest} = useRequestsCache();
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const form = useForm({
        disabled: isSaving,
        items: {
            ...FormModule.generateDateRangeItems({showLabel: true, required: true}),
            startTime: {
                type: "timePicker",
                required: showTimePicker,
                label: translations.get("StartTime"),
                clearable: false,
            },
            endTime: {
                type: "timePicker",
                required: showTimePicker,
                label: translations.get("EndTime"),
                clearable: false,
            },
            allDay: {
                type: "checkbox",
                toggle: true,
                initialValue: true,
                label: translations.get("AllDay"),
            },
            justification: {
                type: "textArea",
                required: true,
                label: translations.get("Justification"),
                placeholder: translations.get("EnterJustification"),
                minLength: 10,
                maxLength: 300,
                minHeight: "100px"
            }
        }
    });

    const isAllDay = form.state.data.allDay;

    useEffect(function onAllDayChange() {
        setShowTimePicker(!isAllDay);
    }, [isAllDay]);

    const {startDate, endDate, justification, allDay, startTime, endTime} = form.formItems;

    const renderSendButton = (callback: () => void) => (
        <Button
            className={"no-shrink"}
            primary icon={<SendIcon outline/>}
            content={translations.get("NotifyManager")}
            disabled={!form.isValid || isSaving}
            loading={isSaving}
            fluid={isOnMobile}
            onClick={handleSendRequest(userId, shop?.id, form.state.data, createRequest, setIsSaving, callback, form.reset)}
        />
    )

    const renderForm = () => (
        <Flex column fill gap={"gap.medium"}>
            {allDay}
            {showTimePicker ?
                <Flex column gap={"gap.small"}>
                    <Flex vAlign={"end"} gap={"gap.small"}>
                        {startDate}
                        {startTime}
                    </Flex>
                    <Flex vAlign={"end"} gap={"gap.small"}>
                        {endDate}
                        {endTime}
                    </Flex>
                </Flex>
                :
                <Flex vAlign={"end"} gap={"gap.small"}>
                    {startDate}
                    <ArrowRightIcon styles={{color: "darkgray", marginBottom: "8px"}}/>
                    {endDate}
                </Flex>
            }
            {justification}
        </Flex>
    )

    return {
        ...form,
        renderForm,
        renderSendButton
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleSendRequest = (
    userId: string,
    shopId: string | undefined,
    formData: ReturnType<typeof useTimeOffRequestForm>["state"]["data"],
    createRequest: ReturnType<typeof useRequestsCache>["createRequest"],
    setIsSaving: React.Dispatch<React.SetStateAction<boolean>>,
    callback: () => void,
    reset: () => void,
) => async () => {
    if (!shopId) return;
    const request = generateRequest(userId, shopId, formData);
    setIsSaving(true);
    await createRequest(request);
    reset();
    callback();
    setIsSaving(false);
}

const generateRequest = (
    userId: string,
    shopId: string,
    formData: ReturnType<typeof useTimeOffRequestForm>["state"]["data"],
): TimeOffRequestData => {
    const isAllDay = formData.allDay as boolean;
    return {
        id: "",
        type: RequestType.TimeOff,
        shopId,
        date: moment().toISOString(false),
        startTime: isAllDay ? undefined : formData.startTime as Time,
        endTime: isAllDay ? undefined : formData.endTime as Time,
        isAllDay: isAllDay,
        justification: formData.justification as string,
        startDate: formData.startDate as string,
        endDate: formData.endDate as string,
        userId: userId,
        response: undefined,
    }
}