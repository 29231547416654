import {Props, State} from "./Toolbar.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {DateRange} from "components/others/DateRangeSelector/DateRangeSelector.interfaces";
import {PlanningView} from "../Planning/Planning.interfaces";
import {FilterItemsData} from "../../../components/others/Filter/Filter.interfaces";
import {usePlanningDateRangeCache} from "../../../hooks/cache/usePlanningDateRangeCache";

export const initialState: State = {
    selectedShopId: "",
    filterData: undefined,
    view: PlanningView.People,
}

export const reducer = (config: {
    props: Props,
    setPlanningDateRange: ReturnType<typeof usePlanningDateRangeCache>["setPlanningDateRange"],
}) => ({
    switchShop: ({setState}, [shopId]: [string]) => {
        setState({selectedShopId: shopId});
        config.props.onSwitchShop(shopId);
    },
    dateRangeChange: ({}, [selectedDate, selectedRange]: [string, Immutable<DateRange>]) => {
        config.setPlanningDateRange({selectedRange, selectedDate});
    },
    changeView: ({state, setState}, _, view: PlanningView) => {
        if (state.view === view) return;
        setState({view});
        config.props.onViewChange(view);
    },
    filtersChange: ({setState}, [data]: [FilterItemsData]) => {
        setState({filterData: data}, false);
        config.props.onFiltersChange(data);
    }
}) satisfies MagicReducerObject<State>;