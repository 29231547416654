import {State} from "./Requests.interfaces";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {RequestData} from "interfaces/RequestData";
import {RequestPreviewSideView} from "./RequestPreviewSideView/RequestPreviewSideView";

export const initialState: State = {
    selectedRequest: undefined,
}

export const reducer = (config: {
    isOnMobile: boolean,
    requestPreviewSideViewRef: MagicReducerRef<typeof RequestPreviewSideView>,
}) => ({
    selectRequest: ({setState}, _, request: Immutable<RequestData> | undefined) => {
        setState({selectedRequest: request});
        if (!config.isOnMobile || !request?.id) return;
        config.requestPreviewSideViewRef.dispatch("open", request?.id)();
    },
}) satisfies MagicReducerObject<State>;