import React, {ReactElement, useLayoutEffect} from "react";
import {Props} from "./Accordion.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Accordion.reducer";
import "./Accordion.styles.scss";
import {Button, ChevronDownIcon, ChevronEndIcon, ComponentSlotStyle, Flex, Text} from "@fluentui/react-northstar";

export const Accordion = (props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState(props));

    const expandable = props.expandable !== false;

    useLayoutEffect(function observeChildHeight() {
        if (!expandable) return;
        const firstChild = state.accordionContentContainer?.firstElementChild as Element | null;
        if (!firstChild) return;
        const resizeObserver = new ResizeObserver(() => dispatch("setContentHeight")(firstChild.clientHeight));
        resizeObserver.observe(firstChild);
        return () => resizeObserver.unobserve(firstChild);
    }, [state.renderContent, expandable]);

    const visibilityButton = !expandable ? null : (
        <Button
            styles={{marginRight: "-3px"}}
            iconOnly text
            icon={state.open ? <ChevronDownIcon/> : <ChevronEndIcon/>}
        />
    )

    const accordionHeaderClassName = [
        "accordion-header",
        !expandable && "accordion-header-not-expandable",
    ].filter(Boolean).join(" ");

    const header = (
        <Flex ref={dispatch("accordionHeaderRef")} className={accordionHeaderClassName} vAlign={"center"}
              onClick={expandable ? dispatch("toggleOpen") : undefined}>
            <Flex vAlign={"center"} styles={{gap: "8px"}} className={"no-shrink"}>
                {visibilityButton}
                {props.icon && <props.icon width={20} height={20} style={{marginTop: "2px"}}/>}
                {typeof props.title === "string" ?
                    <Text size={"large"} className={"no-shrink"} content={props.title}
                          weight={props.card ? "semibold" : "bold"}/>
                    :
                    props.title
                }
            </Flex>
            {props.headerContent}
        </Flex>
    )

    const accordionClassName = [
        "accordion",
        props.card && "accordion-card"
    ].filter(Boolean).join(" ");

    const contentContainerStyles: ComponentSlotStyle = {
        height: getContentHeight(state.accordionHeader?.offsetHeight, state.contentHeight, state.open, expandable),
    }

    const contentStyles: ComponentSlotStyle = {
        height: !expandable ? "100%" : "fit-content",
        padding: (!props.card || props.noPadding) ? "0" : "5px 15px 15px 15px",
    }

    return (
        <Flex column className={accordionClassName}>
            {header}
            <Flex fill column ref={dispatch("accordionContentContainerRef")}
                  className={"accordion-content-container"} styles={contentContainerStyles}>
                {state.renderContent && props.content &&
                    <Flex fill styles={contentStyles} className={"accordion-content"}>
                        {props.content}
                    </Flex>
                }
            </Flex>
        </Flex>
    )
}

export const getContentHeight = (headerHeight: number | undefined, contentHeight: number, open: boolean, expandable: boolean) => {
    if (!expandable) {
        const headerPadding = 5;
        return `calc(100% - ${headerHeight}px - ${headerPadding * 2}px)`;
    }
    return (open ? contentHeight : 0) + "px"
}