import React, {memo, ReactElement} from "react";
import {Props} from "./UpdateClockingDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./UpdateClockingDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import "./UpdateClockingDialog.styles.scss";
import {translations} from "translations";
import {useUpdateClockingForm} from "hooks/forms/useUpdateClockingForm";
import {Flex} from "@fluentui/react-northstar";
import {ShiftCard} from "components/others/ShiftCard/ShiftCard";
import {TimerIcon} from "../../../assets/icons";

export const UpdateClockingDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    const form = useUpdateClockingForm(state.shift);

    return (
        <Dialog
            externalRef={dialogRef}
            width={500}
            onClose={dispatch("reset")}
            icon={TimerIcon}
            title={translations.get("UpdateClocking")}
            content={
                <Flex fill column gap={"gap.small"}>
                    <ShiftCard
                        readonly
                        showAvatar
                        showDate
                        currentShopId={state.currentShopId}
                        shift={state.shift}
                        userId={state.shift?.getUserId()}
                    />
                    {form.renderForm()}
                </Flex>
            }
            footer={
                <Flex fill hAlign={"end"}>
                    {form.renderSaveButton(dialogRef.dispatch("close"))}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);