import React, {memo, ReactElement, useEffect, useMemo} from "react";
import {Props} from "./RenameGroupDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./RenameGroupDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import "./RenameGroupDialog.styles.scss";
import {translations} from "../../../translations";
import {useForm} from "@witivio_teamspro/northstar-form";
import {Button, EditIcon, Flex} from "@fluentui/react-northstar";
import {useGroupCache} from "../../../hooks/cache/groups/useGroupCache";
import {useShopGroupsCache} from "../../../hooks/cache/groups/useShopGroupsCache";

export const RenameGroupDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);
    const {group, rename} = useGroupCache(state.groupId);
    const {groups} = useShopGroupsCache(group?.shopId);

    useEffect(function onGroupChange() {
        form.setFieldsInitialValues({name: group?.name});
        form.reset();
    }, [group]);

    const forbiddenNames = useMemo(() => {
        return groups?.filter(g => g.id !== state.groupId).map(group => group.name.toLowerCase()) ?? [];
    }, [state.groupId, groups]);

    const form = useForm({
        disabled: state.isRenaming,
        items: {
            name: {
                type: "input",
                inputMode: "text",
                minLength: 3,
                maxLength: 50,
                required: true,
                clearable: false,
                validate: name => !forbiddenNames.includes((name + "").toLowerCase().trim()),
                errorMessage: translations.get("NameAlreadyExists"),
                autoFocus: true,
                placeholder: translations.get("EnterName"),
            }
        }
    });

    const name = form.state.data.name as string;

    const renameButton = (
        <Button
            primary
            icon={<EditIcon outline/>}
            content={translations.get("Rename")}
            disabled={!form.isValid || !form.state.hasChanged || !groups || state.isRenaming}
            loading={state.isRenaming}
            onClick={dispatch("rename", {name, renameGroup: rename})}
        />
    )

    return (
        <Dialog
            externalRef={dialogRef}
            width={400}
            onClose={dispatch("reset")}
            title={translations.get("RenameGroup")}
            content={form.renderForm()}
            footer={
                <Flex fill hAlign={"end"}>
                    {renameButton}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);