import {State} from "./CustomShiftThresholdDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {useCustomShiftThresholdForm} from "../../../hooks/forms/useCustomShiftThresholdForm";

export const initialState: State = {
    groupId: "",
    customThresholdId: "",
    categoryId: "",
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, {groupId, customThresholdId = "", categoryId}: {
        groupId: string,
        customThresholdId?: string | undefined,
        categoryId: string,
    }) => {
        setState({groupId, customThresholdId, categoryId});
        config.dialogRef.dispatch("open")();
    },
    reset: ({setState}, _, form: ReturnType<typeof useCustomShiftThresholdForm>) => {
        setState({...initialState});
        form.reset();
    }
}) satisfies MagicReducerObject<State>;