import {State} from "./ShopShiftsSideView.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {SideView} from "../../../../components/dialogs/SideView/SideView";

export const initialState: State = {
    shopId: "",
}

export const reducer = (config: {
    sideViewRef: MagicReducerRef<typeof SideView>,
}) => ({
    open: ({setState}, _, shopId: string) => {
        setState({shopId});
        config.sideViewRef.dispatch("open")();
    },
}) satisfies MagicReducerObject<State>;