import React, {memo, ReactElement} from "react";
import {Props} from "./SwapShiftSideView.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./SwapShiftSideView.reducer";
import {CompareModule} from "modules/Compare.module";
import "./SwapShiftSideView.styles.scss";
import {SideView} from "../../../../../components/dialogs/SideView/SideView";
import {translations} from "../../../../../translations";
import {SwapPart} from "../../../../../components/dialogs/CreateRequestDialog/parts/SwapPart/SwapPart";
import {Flex} from "@fluentui/react-northstar";
import {useMsTeamsSelector} from "../../../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";

export const SwapShiftSideView = memo((props: Props): ReactElement | null => {
    const {userId} = useMsTeamsSelector("userId");
    const sideViewRef = useMagicReducerRef(SideView);
    const [state] = useMagicReducer(reducer({sideViewRef}), initialState, props.externalRef);

    const isUserShift = state.shift?.getUserId() === userId;

    return (
        <SideView externalRef={sideViewRef} title={translations.get("SwapShift")} style={{zIndex: 30}}>
            <Flex fill style={{padding: "15px"}}>
                <SwapPart
                    key={state.renderKey}
                    defaultFromShift={isUserShift ? state.shift : undefined}
                    defaultToShift={isUserShift ? undefined : state.shift}
                    onClose={sideViewRef.dispatch("close")}
                    setBeforeClose={() => 0}
                />
            </Flex>
        </SideView>
    )
}, CompareModule.areObjectsEqual);