import React, {memo, ReactElement, useLayoutEffect} from "react";
import {Props} from "./SwapPart.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./SwapPart.reducer";
import {CompareModule} from "modules/Compare.module";
import "./SwapPart.styles.scss";
import {Flex} from "@fluentui/react-northstar";
import {useSwapRequestForm} from "../../../../../hooks/forms/useSwapRequestForm";
import {useDialogContext} from "../../../../../services/DialogContext/DialogContext";

export const SwapPart = memo((props: Props): ReactElement | null => {
    const {confirmCancelChangesDialog} = useDialogContext();
    const form = useSwapRequestForm({
        defaultFromShift: props.defaultFromShift,
        defaultToShift: props.defaultToShift,
    });
    const [, dispatch] = useMagicReducer(reducer({confirmCancelChangesDialog, form}), initialState);

    useLayoutEffect(function onFormStateChanged() {
        props.setBeforeClose(dispatch("beforeClose"));
        return () => props.setBeforeClose(undefined);
    }, []);

    return (
        <Flex fill column gap={"gap.medium"}>
            {form.renderForm()}
            <Flex hAlign={"end"} className={"no-shrink"}>
                {form.renderSendButton(props.onClose)}
            </Flex>
        </Flex>
    )
}, CompareModule.areObjectsEqual);