import {Immutable} from "@witivio_teamspro/use-reducer";
import {AxiosResponse} from "axios";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";
import {ObjectModule} from "../../modules/Object.module";
import {ShiftData} from "../../interfaces/ShiftData";
import {Shift} from "../../classes/Shift";
import {BusinessRuleException} from "../../interfaces/BusinessRuleException";

const create = async (shift: Immutable<Shift>): Promise<{
    shiftsIds: Array<string>,
    exceptions: Array<BusinessRuleException>
} | undefined> => {
    try {
        if (!shift.getShopId()) throw new Error();
        const searchParams = new URLSearchParams({shopId: shift.getShopId()!}).toString();
        const response = await AxiosConfig.instance.post(`/shift?${searchParams}`, shift.getPreShift());
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't create shift", error);
        return;
    }
}

const update = async (shopId: string | undefined, shiftId: string, fields: Immutable<Partial<ShiftData>>, updateSeries: boolean): Promise<{
    exceptions: Array<BusinessRuleException>
} | undefined> => {
    try {
        if (!shopId) throw new Error();
        const searchParams = new URLSearchParams({shopId, updateSeries: updateSeries.toString()}).toString();
        const updatedFieldsArray = ObjectModule.mapUpdatedFields(fields);
        if (updatedFieldsArray.length === 0) return {exceptions: []};
        const response = await AxiosConfig.instance.put(`/shifts/${shiftId}?${searchParams}`, updatedFieldsArray);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't update shift", error);
        return;
    }
}

const updateClocking = async (shopId: string | undefined, shiftId: string, clocking: Immutable<ShiftData["clocking"]>): Promise<boolean> => {
    try {
        if (!shopId) throw new Error();
        const searchParams = new URLSearchParams({shopId}).toString();
        await AxiosConfig.instance.put(`/shifts/${shiftId}/clocking?${searchParams}`, clocking);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't update shift clocking", error);
        return false;
    }
}

const deleteShift = async (shopId: string | undefined, shiftId: string, deleteSeries: boolean): Promise<boolean> => {
    try {
        if (!shopId) throw new Error();
        const searchParams = new URLSearchParams({shopId, deleteSeries: deleteSeries.toString()}).toString();
        await AxiosConfig.instance.delete(`/shifts/${shiftId}?${searchParams}`);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't delete shift", error);
        return false;
    }
}

const notifyShift = async (shopId: string | undefined, shiftId: string): Promise<boolean> => {
    try {
        if (!shopId) throw new Error();
        const searchParams = new URLSearchParams({shopId}).toString();
        await AxiosConfig.instance.get(`/shifts/${shiftId}/notify?${searchParams}`);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't notify shift", error);
        return false;
    }
}

const getById = async (shiftId: string): Promise<Shift | undefined> => {
    try {
        if (!shiftId) throw new Error();
        const response: AxiosResponse<ShiftData> = await AxiosConfig.instance.get(`/shifts/${shiftId}`);
        return new Shift(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get shift", error);
        return undefined;
    }
}

const getByUserGroup = async (startDate: string, endDate: string, includeUserShifts: boolean = false): Promise<Array<Shift>> => {
    try {
        const searchParams = new URLSearchParams({
            startDate,
            endDate,
            includeUserShifts: includeUserShifts + ""
        }).toString();
        const response: AxiosResponse<Array<ShiftData>> = await AxiosConfig.instance.get(`/shifts/user-groups?${searchParams}`);
        return response.data.map(data => new Shift(data));
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get user groups shifts", error);
        return [];
    }
}

const getNextUserShift = async (): Promise<Shift | undefined> => {
    try {
        const response: AxiosResponse<ShiftData> = await AxiosConfig.instance.get(`/shifts/user/next`);
        if (!response.data) return undefined;
        return new Shift(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get next user shift", error);
        return undefined;
    }
}

export const ShiftApi = {
    create,
    update,
    deleteShift,
    notifyShift,
    updateClocking,
    getById,
    getByUserGroup,
    getNextUserShift,
}