import React, {memo, ReactElement} from "react";
import {Props} from "./ShiftActivityDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShiftActivityDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import "./ShiftActivityDialog.styles.scss";
import {useShiftActivityForm} from "hooks/forms/useShiftActivityForm";
import {translations} from "translations";
import {Flex} from "@fluentui/react-northstar";

export const ShiftActivityDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);
    const form = useShiftActivityForm(state.shopId, state.activity);

    return (
        <Dialog
            showOverflow
            onClose={dispatch("reset", form)}
            externalRef={dialogRef}
            width={300}
            title={translations.get(!state.activity ? "AddActivity" : "EditActivity")}
            content={form.renderForm()}
            footer={
                <Flex fill hAlign={"end"}>
                    {form.renderDoneButton(dispatch("addActivity"))}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);