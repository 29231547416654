import {MagicReducerExternalRef} from "@witivio_teamspro/use-reducer";
import {reducer} from "./ImportPlanningDialog.reducer";

export enum ImportPlanningStep {
    Select, Import, Result
}

export type State = {
    shopId: string,
    step: ImportPlanningStep,
    isSuccess: boolean,
}

export type Props = {
    externalRef: MagicReducerExternalRef<typeof reducer>,
}