import {ImportPlanningStep, State} from "./ImportPlanningDialog.interfaces";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {IFilePickerFile} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/FilePicker/FilePicker";
import {PlanningApi} from "../../../apis/Planning/PlanningApi";
import {useShiftsCache} from "../../../hooks/cache/useShiftsCache";

export const initialState: State = {
    shopId: "",
    step: ImportPlanningStep.Select,
    isSuccess: true,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
    selectedDate: string,
    refetchAllUserShifts: ReturnType<typeof useShiftsCache>["refetchAllUserShifts"],
}) => ({
    open: ({setState}, _, shopId: string) => {
        setState({...initialState, shopId});
        config.dialogRef.dispatch("open")();
    },
    fileSelected: ({state, setState}, [file]: [Immutable<IFilePickerFile>]) => {
        setState({step: ImportPlanningStep.Import});
        const handleResult = (isSuccess: boolean) => {
            setState({step: ImportPlanningStep.Result, isSuccess});
            setTimeout(config.dialogRef.dispatch("close"), isSuccess ? 2000 : 5000);
            if (isSuccess) config.refetchAllUserShifts().then();
        }
        PlanningApi.importPlanning(state.shopId, file).then(success => handleResult(success));
    }
}) satisfies MagicReducerObject<State>;