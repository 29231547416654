import React, {memo, ReactElement} from "react";
import {Props} from "./RequestPreviewSideView.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./RequestPreviewSideView.reducer";
import {CompareModule} from "modules/Compare.module";
import "./RequestPreviewSideView.styles.scss";
import {SideView} from "../../../components/dialogs/SideView/SideView";
import {RequestPreview} from "../../../components/others/RequestPreview/RequestPreview";
import {useRequestsCache} from "../../../hooks/cache/useRequestsCache";
import {RequestModule} from "../../../modules/Request.module";
import {useUserCache} from "../../../hooks/cache/useUsersCache";
import {Flex, Loader} from "@fluentui/react-northstar";
import {translations} from "../../../translations";

export const RequestPreviewSideView = memo((props: Props): ReactElement | null => {
    const sideViewRef = useMagicReducerRef(SideView);
    const {requests, isLoading: areRequestsLoading} = useRequestsCache();
    const [state, dispatch] = useMagicReducer(reducer({sideViewRef}), initialState, props.externalRef);
    const request = requests?.find(r => r.id === state.requestId);
    const {user, isLoading: isUserLoading} = useUserCache(request?.userId);

    const isLoading = areRequestsLoading || isUserLoading;

    const title = isLoading ? translations.get("Loading") : RequestModule.generateRequestTitle(request, user?.displayName);

    return (
        <SideView externalRef={sideViewRef} title={title} style={{zIndex: 10}} onClose={props.onClose}>
            {isLoading ?
                <Flex fill vAlign={"center"} hAlign={"center"}>
                    <Loader/>
                </Flex>
                :
                <RequestPreview requestId={state.requestId}/>
            }
        </SideView>
    )
}, CompareModule.areObjectsEqual);