import {HEX} from "interfaces/Color";

const getContrastingTextColor = (hexColor: string): 'black' | 'white' => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    const luminosity = 0.299 * r + 0.587 * g + 0.114 * b;
    return luminosity > 186 ? 'black' : 'white';
}

const getColorFromIndex = (index: number): string => {
    const randomHue = (index * 137.508) % 360;
    const randomSaturation = 80 + (index * 4) % 21;
    const randomLightness = 40 + (index * 4) % 41;
    return `hsl(${randomHue}, ${randomSaturation}%, ${randomLightness}%)`;
};

const hexToRgb = (hex: HEX) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1]!, 16),
        g: parseInt(result[2]!, 16),
        b: parseInt(result[3]!, 16)
    } : undefined;
}

export const ColorModule = {
    getContrastingTextColor,
    getColorFromIndex,
    hexToRgb,
}