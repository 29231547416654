import {QueryClient, useQuery, useQueryClient} from "react-query";
import {UserApi} from "../../apis/User/UserApi";
import {UserRole} from "../../interfaces/UserRole";
import {useMemo} from "react";
import {ShopsCache} from "./useShopsCache";
import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {ShopType} from "../../interfaces/ShopData";

export const useUserRolesCache = (enabled: boolean = true) => {
    const {userId} = useMsTeamsSelector("userId");
    const queryClient = useQueryClient();

    const {data, isLoading} = useQuery("user-role", UserApi.getUserRole, {
        staleTime: Infinity,
        enabled,
    });

    const userRoles = useMemo(() => ({
        administrator: hasUserRole(data, UserRole.Administrator),
        staff: hasUserRole(data, UserRole.Staff),
        manager: hasUserRole(data, UserRole.Manager),
        superManager: hasUserRole(data, UserRole.SuperManager),
        rotatingStaffManager: hasUserRole(data, UserRole.RotatingStaffManager),
    }), [data]);

    return {
        isLoading,
        userRoles,
        isOnlyRole: isOnlyRole(data),
        canUpdateShop: canUpdateShop(queryClient, data, userId),
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const hasUserRole = (userRoles: Array<UserRole> | undefined, role: UserRole) => {
    return userRoles?.includes(role);
}

const isOnlyRole = (userRoles: Array<UserRole> | undefined) => (role: UserRole) => {
    return userRoles?.length === 1 && userRoles.includes(role);
}

const canUpdateShop = (queryClient: QueryClient, userRoles: Array<UserRole> | undefined, userId: string) => (shopId: string | undefined) => {
    if (!userRoles) return false;
    if (userRoles.length === 1 && userRoles.includes(UserRole.Staff)) return false;
    if ([UserRole.Administrator, UserRole.SuperManager].some(role => userRoles?.includes(role))) return true;
    if (!shopId) return false;
    const shop = ShopsCache.getLocalShop(queryClient, shopId);
    if (!shop) return false;
    const isManagerOfShop = userRoles.includes(UserRole.Manager) && shop.managersIds.includes(userId);
    const isRotatingStaffManager = isManagerOfShop && shop.type === ShopType.RotatingStaff;
    return isManagerOfShop || isRotatingStaffManager;
}