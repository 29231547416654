import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {App} from './App';
import {Provider} from 'react-redux';
import store from './redux/store';
import {QueryClient, QueryClientProvider} from 'react-query';
import {UseFormSettingsProvider} from "@witivio_teamspro/northstar-form";

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    window.document.getElementById('root') as HTMLElement
);

root.render(
    <UseFormSettingsProvider>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <App/>
            </QueryClientProvider>
        </Provider>
    </UseFormSettingsProvider>
);