import {AxiosResponse} from "axios";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";
import {SettingsData} from "../../interfaces/SettingsData";

const get = async () => {
    try {
        const response: AxiosResponse<Immutable<SettingsData>> = await AxiosConfig.instance.get("/settings");
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get settings", error);
        return undefined;
    }
}

const update = async (settings: Immutable<SettingsData>) => {
    try {
        await AxiosConfig.instance.put("/settings", settings);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't update settings", error);
        return false;
    }
}

export const SettingsApi = {
    get,
    update,
}