import {useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "../../translations";
import {ShopType} from "../../interfaces/ShopData";
import React, {useEffect, useLayoutEffect} from "react";
import {
    AddIcon,
    ArrowRightIcon,
    Button,
    Flex,
    FormLabel,
    Input,
    InputProps,
    Text,
    TrashCanIcon
} from "@fluentui/react-northstar";
import {SettingsData, ShopRetrieveHoursRule, ShopSettings} from "../../interfaces/SettingsData";
import {Time} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/TimePicker/TimePicker";
import {TimeModule} from "../../modules/Time.module";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {CompareModule} from "../../modules/Compare.module";
import {useSettingsCache} from "../cache/useSettingsCache";

const shopTypes = Object.keys(ShopType).filter((key: string | number) => !isNaN(Number(key)))
    .map(key => Number(key) as ShopType);

export const useShopSettingsForm = () => {
    const {settings, updateSettings: update} = useSettingsCache();
    const [shopsRetrieveHoursRules, setShopsRetrieveHoursRules] = React.useState<Immutable<Array<ShopRetrieveHoursRule>>>([]);
    const [shopsSettings, setShopsSettings] = React.useState<Immutable<Array<ShopSettings>>>([]);

    useEffect(() => {
        if (!settings) return;
        const initialShopType = ShopType.BigStore;
        const initialShopTypeSettings = settings.shopsSettings.find(setting => setting.type === initialShopType);
        form.setFieldsInitialValues({
            shopType: initialShopType,
            overtimeTriggerMinutes: settings?.overtimeTriggerMinutes,
            delayTriggerMinutes: settings?.delayTriggerMinutes,
            maximumConsecutiveWorkingDays: settings?.maximumConsecutiveWorkingDays,
            minimalBreakHoursBetweenShifts: settings?.minimalBreakHoursBetweenShifts,
            maximumWeeklyWorkingHours: initialShopTypeSettings?.maximumWeeklyWorkingHours,
            maximumDailyWorkingHours: initialShopTypeSettings?.maximumDailyWorkingHours,
        });
        form.reset();
        setShopsRetrieveHoursRules(settings?.shopsRetrieveHoursRules ?? []);
        setShopsSettings(settings?.shopsSettings ?? []);
    }, [settings]);

    const form = useForm({
        items: {
            overtimeTriggerMinutes: {
                type: "input",
                inputMode: "numeric",
                required: true,
                label: translations.get("OvertimeTriggeredAfter"),
                placeholder: translations.get("EnterMinutes"),
                clearable: false,
                inputIcon: <Text styles={{color: "darkgray"}}
                                 content={translations.get("MinutesSingularPlural").toLowerCase()}/>,
            },
            delayTriggerMinutes: {
                type: "input",
                inputMode: "numeric",
                required: true,
                label: translations.get("DelayTriggeredAfter"),
                placeholder: translations.get("EnterMinutes"),
                clearable: false,
                inputIcon: <Text styles={{color: "darkgray"}}
                                 content={translations.get("MinutesSingularPlural").toLowerCase()}/>,
            },
            maximumConsecutiveWorkingDays: {
                type: "input",
                inputMode: "numeric",
                required: true,
                label: translations.get("MaximumConsecutiveWorkingDays"),
                placeholder: translations.get("EnterDaysCount"),
                clearable: false,
                inputIcon: <Text styles={{color: "darkgray"}}
                                 content={translations.get("DaysSingularPlural").toLowerCase()}/>,
            },
            minimalBreakHoursBetweenShifts: {
                type: "input",
                inputMode: "numeric",
                required: true,
                label: translations.get("MinimalBreakHoursBetweenShifts"),
                placeholder: translations.get("EnterHours"),
                clearable: false,
                inputIcon: <Text styles={{color: "darkgray"}}
                                 content={translations.get("HoursSingularPlural").toLowerCase()}/>,
            },
            maximumWeeklyWorkingHours: {
                type: "input",
                inputMode: "decimal",
                required: true,
                label: translations.get("MaximumWeeklyWorkingHours"),
                placeholder: translations.get("EnterHours"),
                clearable: false,
                inputIcon: <Text styles={{color: "darkgray"}}
                                 content={translations.get("HoursSingularPlural").toLowerCase()}/>,
            },
            maximumDailyWorkingHours: {
                type: "input",
                inputMode: "decimal",
                required: true,
                label: translations.get("MaximumDailyWorkingHours"),
                placeholder: translations.get("EnterHours"),
                clearable: false,
                inputIcon: <Text styles={{color: "darkgray"}}
                                 content={translations.get("HoursSingularPlural").toLowerCase()}/>,
            },
            shopType: {
                type: "dropdown",
                required: true,
                initialValue: ShopType.BigStore,
                items: shopTypes,
                renderSelectedItem: item => translations.get(ShopType[item as ShopType]),
                placeholder: translations.get("SelectAType"),
                label: translations.get("ShopType"),
                clearable: false,
            }
        }
    });

    const {shopType, maximumWeeklyWorkingHours, maximumDailyWorkingHours} = form.state.data;

    useLayoutEffect(() => {
        let currentShopTypeSettings = shopsSettings.find(setting => setting.type === shopType as ShopType);
        if (!currentShopTypeSettings) {
            currentShopTypeSettings = {
                type: shopType as ShopType,
                maximumWeeklyWorkingHours: 0,
                maximumDailyWorkingHours: 0
            }
            setShopsSettings(prev => [...prev, currentShopTypeSettings as ShopSettings]);
        }
        form.setFieldsValues({
            maximumWeeklyWorkingHours: currentShopTypeSettings?.maximumWeeklyWorkingHours,
            maximumDailyWorkingHours: currentShopTypeSettings?.maximumDailyWorkingHours,
        });
    }, [shopType]);

    useLayoutEffect(() => {
        const currentShopTypeSettings = shopsSettings.find(setting => setting.type === shopType as ShopType);
        if (!currentShopTypeSettings) return;
        if (maximumWeeklyWorkingHours === undefined || maximumDailyWorkingHours === undefined) return;
        const newShopSettings: ShopSettings = {...currentShopTypeSettings};
        newShopSettings.maximumWeeklyWorkingHours = Number(maximumWeeklyWorkingHours || 0);
        newShopSettings.maximumDailyWorkingHours = Number(maximumDailyWorkingHours || 0);
        setShopsSettings(prev => [...prev.filter(setting => setting.type !== shopType), newShopSettings]);
    }, [maximumWeeklyWorkingHours, maximumDailyWorkingHours]);

    const currentShopTypeRules = shopsRetrieveHoursRules.filter(rule => rule.type === shopType as ShopType);

    const renderRetrieveHoursMultiplicationForm = () => (
        <Flex fill column gap={"gap.medium"} className={"overflow-hidden"}>
            {form.formItems.shopType}
            <Flex gap={"gap.medium"}>
                {form.formItems.maximumWeeklyWorkingHours}
                {form.formItems.maximumDailyWorkingHours}
            </Flex>
            <Flex column gap={"gap.smaller"} className={"overflow-hidden"}>
                <FormLabel content={translations.get("RetrieveHoursMultipliers")}/>
                <Flex column gap={"gap.small"} className={"overflow-scroll"}>
                    {currentShopTypeRules?.map((rule, index) => (
                        renderRuleForm(setShopsRetrieveHoursRules, rule, shopType as ShopType, index)
                    ))}
                    <Button
                        className={"no-shrink"}
                        fluid tinted
                        content={translations.get("AddRule")}
                        icon={<AddIcon/>}
                        onClick={handleAddRule(shopType as ShopType, setShopsRetrieveHoursRules)}
                    />
                </Flex>
            </Flex>
        </Flex>
    )

    const renderForm = () => (
        <Flex column gap={"gap.medium"} className={"overflow-hidden"}>
            <Flex gap={"gap.medium"}>
                {form.formItems.overtimeTriggerMinutes}
                {form.formItems.delayTriggerMinutes}
            </Flex>
            <Flex gap={"gap.medium"}>
                {form.formItems.maximumConsecutiveWorkingDays}
                {form.formItems.minimalBreakHoursBetweenShifts}
            </Flex>
        </Flex>
    )

    const handleUpdateSettings = () => updateSettings(
        settings, form.state.data, shopsRetrieveHoursRules, shopsSettings, update
    );

    return {
        ...form,
        renderRetrieveHoursMultiplicationForm,
        renderForm,
        updateSettings: handleUpdateSettings,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderRuleForm = (
    setShopsRetrieveHoursRules: React.Dispatch<React.SetStateAction<Immutable<Array<ShopRetrieveHoursRule>>>>,
    rule: ShopRetrieveHoursRule,
    type: ShopType,
    index: number
) => {
    const hour = `${rule.time.hour}`.padStart(2, "0");
    const minutes = `${rule.time.minutes}`.padStart(2, "0");
    const timeValue = `${hour}:${minutes}`;
    return (
        <Flex key={type + index + ""} fill gap={"gap.small"} vAlign={"center"}>
            <Input
                fluid
                type={"time"}
                inputMode={"numeric"}
                autoComplete={"off"}
                value={timeValue}
                onChange={handleUpdateRuleTime(type, setShopsRetrieveHoursRules, index)}
            />
            <ArrowRightIcon styles={{color: "darkgray"}}/>
            <Input
                fluid
                type={"number"}
                inputMode={"decimal"}
                placeholder={"0"}
                min={0.0}
                max={10.0}
                step={0.25}
                value={rule.multiplication}
                autoComplete={"off"}
                onChange={handleUpdateRuleMultiplication(type, setShopsRetrieveHoursRules, index)}
            />
            <Button
                iconOnly text
                icon={<TrashCanIcon styles={{color: "red !important"}} outline/>}
                styles={{marginLeft: "-5px", marginRight: "-5px"}}
                onClick={handleDeleteRule(type, setShopsRetrieveHoursRules, index)}
            />
        </Flex>
    )
}

const handleAddRule = (
    type: ShopType | undefined,
    setShopsRetrieveHoursRules: React.Dispatch<React.SetStateAction<Immutable<Array<ShopRetrieveHoursRule>>>>,
) => () => {
    if (type === undefined) return;
    setShopsRetrieveHoursRules(prev => {
        const shopTypeRules = prev.filter(rule => rule.type === type);
        const prevRule = shopTypeRules[shopTypeRules.length - 1];
        const newRule: ShopRetrieveHoursRule = {
            type,
            time: {hour: prevRule?.time.hour === undefined ? 0 : (prevRule.time.hour + 1), minutes: 0},
            multiplication: prevRule?.multiplication === undefined ? 0 : prevRule.multiplication + 0.25,
        }
        shopTypeRules.push(newRule);
        return [...prev.filter(rule => rule.type !== type), ...shopTypeRules];
    });
}

const handleUpdateRuleTime = (
    type: ShopType | undefined,
    setShopsRetrieveHoursRules: React.Dispatch<React.SetStateAction<Immutable<Array<ShopRetrieveHoursRule>>>>,
    ruleIndex: number,
) => (
    _: React.SyntheticEvent<Element, Event>, inputData: (InputProps | undefined)
) => {
    if (type === undefined) return;
    const value = !inputData?.value ? "00:00" : inputData?.value + "";
    const [hour, minutes] = value.split(":");
    const time: Time = {
        hour: Number(hour),
        minutes: Number(minutes),
    }
    setShopsRetrieveHoursRules(prev => {
        const shopTypeRules = prev.filter(rule => rule.type === type);
        const existingRule = shopTypeRules[ruleIndex];
        if (!existingRule) return prev;
        shopTypeRules[ruleIndex] = {...existingRule, time};
        const prevRule = shopTypeRules[ruleIndex - 1];
        const nextRule = shopTypeRules[ruleIndex + 1];
        if (ruleIndex > 0 && TimeModule.isInferior(time, prevRule?.time)) {
            const prevRule = shopTypeRules[ruleIndex - 1]!;
            const prevRuleTime = {...prevRule.time};
            TimeModule.correctTimeRange({start: prevRuleTime, end: time, isStartUpdated: false});
            shopTypeRules[ruleIndex - 1] = {...prevRule, time: prevRuleTime};
        } else if (ruleIndex < shopTypeRules.length - 1 && TimeModule.isInferior(nextRule?.time, time)) {
            const nextRule = shopTypeRules[ruleIndex + 1]!;
            const nextRuleTime = {...nextRule.time};
            TimeModule.correctTimeRange({start: time, end: nextRuleTime, isStartUpdated: true});
            shopTypeRules[ruleIndex + 1] = {...nextRule, time: nextRuleTime};
        }
        return [...prev.filter(rule => rule.type !== type), ...shopTypeRules];
    });
}

const handleUpdateRuleMultiplication = (
    type: ShopType | undefined,
    setShopsRetrieveHoursRules: React.Dispatch<React.SetStateAction<Immutable<Array<ShopRetrieveHoursRule>>>>,
    ruleIndex: number,
) => (
    _: React.SyntheticEvent<Element, Event>, inputData: (InputProps | undefined)
) => {
    if (type === undefined) return;
    const multiplication = Number(inputData?.value ?? 0);
    setShopsRetrieveHoursRules(prev => {
        const shopTypeRules = prev.filter(rule => rule.type === type);
        const existingRule = shopTypeRules[ruleIndex];
        if (!existingRule) return prev;
        shopTypeRules[ruleIndex] = {...existingRule, multiplication};
        const prevRule = shopTypeRules[ruleIndex - 1];
        const nextRule = shopTypeRules[ruleIndex + 1];
        if (prevRule && ruleIndex > 0 && multiplication <= prevRule?.multiplication) {
            let newMultiplication = prevRule.multiplication - 0.25;
            if (newMultiplication < 0) newMultiplication = 0;
            shopTypeRules[ruleIndex - 1] = {...prevRule, multiplication: newMultiplication};
        } else if (nextRule && ruleIndex < shopTypeRules.length - 1 && multiplication >= nextRule?.multiplication) {
            let newMultiplication = nextRule.multiplication + 0.25;
            if (newMultiplication > 10) newMultiplication = 10;
            shopTypeRules[ruleIndex + 1] = {...nextRule, multiplication: newMultiplication};
        }
        return [...prev.filter(rule => rule.type !== type), ...shopTypeRules];
    });
}

const handleDeleteRule = (
    type: ShopType | undefined,
    setShopsRetrieveHoursRules: React.Dispatch<React.SetStateAction<Immutable<Array<ShopRetrieveHoursRule>>>>,
    ruleIndex: number,
) => () => {
    if (type === undefined) return;
    setShopsRetrieveHoursRules(prev => {
        let shopTypeRules = prev.filter(rule => rule.type === type);
        delete shopTypeRules[ruleIndex];
        shopTypeRules = shopTypeRules.filter(Boolean);
        return [...prev.filter(rule => rule.type !== type), ...shopTypeRules];
    });
}

const updateSettings = async (
    settings: Immutable<SettingsData> | undefined,
    formData: ReturnType<typeof useShopSettingsForm>["state"]["data"],
    shopsRetrieveHoursRules: Immutable<Array<ShopRetrieveHoursRule>>,
    shopsSettings: Immutable<Array<ShopSettings>>,
    update: ReturnType<typeof useSettingsCache>["updateSettings"]
) => {
    if (!settings) return;
    const newSettings: Immutable<SettingsData> = {
        id: "settings",
        overtimeTriggerMinutes: Number(formData.overtimeTriggerMinutes),
        delayTriggerMinutes: Number(formData.delayTriggerMinutes),
        maximumConsecutiveWorkingDays: Number(formData.maximumConsecutiveWorkingDays),
        minimalBreakHoursBetweenShifts: Number(formData.minimalBreakHoursBetweenShifts),
        shopsRetrieveHoursRules,
        shopsSettings
    }
    const hasChanged = !CompareModule.areObjectsEqual(settings, newSettings, true);
    if (!hasChanged) return;
    await update(newSettings);
}