import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {AxiosResponse} from "axios";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {ShopData, ShopOpeningHours, ShopShiftActivity, ShopShiftCategory,} from "interfaces/ShopData";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";
import {GroupData} from "../../interfaces/GroupData";
import {ObjectModule} from "../../modules/Object.module";

const create = async (shop: Immutable<ShopData>): Promise<{
    shopId: string,
    collaboratorsCount: number
} | undefined> => {
    try {
        const response: AxiosResponse = await AxiosConfig.instance.post("/shops", shop);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't create shop", error);
        return undefined;
    }
}

const update = async (shopId: string, fields: Immutable<Partial<ShopData>>): Promise<boolean> => {
    try {
        const eligibleFieldsForUpdate: Array<keyof ShopData> = [
            "name", "type", "postalAddress", "publicHolidaysId", "linkedTeamId", "managersIds", "openingHours"
        ];
        const updatedFieldsArray = ObjectModule.mapUpdatedFields(fields, eligibleFieldsForUpdate);
        if (updatedFieldsArray.length === 0) return true;
        await AxiosConfig.instance.put(`/shops/${shopId}`, updatedFieldsArray);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't update shop", error);
        return false;
    }
}

const deleteShop = async (shopId: string) => {
    try {
        await AxiosConfig.instance.delete(`/shops/${shopId}`);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't delete shop", error);
        return false;
    }
}

const addManagers = async (shopId: string, managerIds: Array<string>): Promise<boolean> => {
    try {
        if (managerIds.length === 0) return true;
        const tasks = managerIds.map(managerId => AxiosConfig.instance.get(`/shops/${shopId}/manager/add/${managerId}`));
        await Promise.all(tasks);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't add managers to shop", error);
        return false;
    }
}

const deleteManagers = async (shopId: string, managerIds: Array<string>): Promise<boolean> => {
    try {
        if (managerIds.length === 0) return true;
        const tasks = managerIds.map(managerId => AxiosConfig.instance.get(`/shops/${shopId}/manager/remove/${managerId}`));
        await Promise.all(tasks);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't delete managers of shop", error);
        return false;
    }
}

const getById = async (id: string | undefined) => {
    if (!id) return;
    try {
        const response: AxiosResponse<Immutable<ShopData>> = await AxiosConfig.instance.get("/shops/" + id);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get shop by id " + id, error);
        return undefined;
    }
}

const getGroupsById = async (id: string | undefined) => {
    if (!id) return;
    try {
        const response: AxiosResponse<Immutable<Array<GroupData>>> = await AxiosConfig.instance.get("/shops/" + id + "/staffgroups");
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get shop groups by id " + id, error);
        return [] as Immutable<Array<GroupData>>
    }
}

const getRotatingStaffGroups = async () => {
    try {
        const response: AxiosResponse<Immutable<Array<GroupData>>> = await AxiosConfig.instance.get("/shops/rotating-staff/staffgroups");
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get head office groups", error);
        return [] as Immutable<Array<GroupData>>;
    }
}

const getAll = async () => {
    try {
        const response: AxiosResponse<Immutable<Array<ShopData>>> = await AxiosConfig.instance.get("/shops");
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get all shops", error);
        return [];
    }
}

const updateOpeningHours = async (shopId: string, openingHours: Immutable<ShopOpeningHours>) => {
    try {
        await AxiosConfig.instance.post(`/shops/${shopId}/openinghours/add`, openingHours);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't update shop opening hours", error);
        return false;
    }
}

const createCategory = async (shopId: string, category: ShopShiftCategory): Promise<string> => {
    try {
        const response = await AxiosConfig.instance.post(`/shops/${shopId}/categories`, category);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't create shop category", error);
        return "";
    }
}

const updateCategory = async (shopId: string, categoryId: string, fields: Partial<ShopShiftCategory>): Promise<boolean> => {
    try {
        const updatedFieldsArray = ObjectModule.mapUpdatedFields(fields);
        if (updatedFieldsArray.length === 0) return true;
        await AxiosConfig.instance.put(`/shops/${shopId}/categories/${categoryId}`, updatedFieldsArray);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't update shop category", error);
        return false;
    }
}

const deleteCategory = async (shopId: string, categoryId: string): Promise<boolean> => {
    try {
        await AxiosConfig.instance.delete(`/shops/${shopId}/categories/${categoryId}`);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't delete shop category", error);
        return false;
    }
}

const createActivity = async (shopId: string, activity: ShopShiftActivity): Promise<string> => {
    try {
        const response = await AxiosConfig.instance.post(`/shops/${shopId}/activities`, activity);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't create shop activity", error);
        return "";
    }
}

const updateActivity = async (shopId: string, activityId: string, fields: Partial<ShopShiftActivity>): Promise<boolean> => {
    try {
        const updatedFieldsArray = ObjectModule.mapUpdatedFields(fields);
        if (updatedFieldsArray.length === 0) return true;
        const name = updatedFieldsArray.find(i => i.field === "name");
        if (!name) return true;
        await AxiosConfig.instance.put(`/shops/${shopId}/activities/${activityId}`, name.value);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't update shop activity", error);
        return false;
    }
}

const deleteActivity = async (shopId: string, activityId: string): Promise<boolean> => {
    try {
        await AxiosConfig.instance.delete(`/shops/${shopId}/activities/${activityId}`);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't delete shop activity", error);
        return false;
    }
}

const getUserShop = async () => {
    try {
        const response: AxiosResponse<Immutable<ShopData> | undefined> = await AxiosConfig.instance.get("/shops/user");
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get shop by user", error);
        return undefined;
    }
}

export const ShopApi = {
    create,
    update,
    deleteShop,
    getById,
    getAll,
    getGroupsById,
    addManagers,
    deleteManagers,
    updateOpeningHours,
    createCategory,
    createActivity,
    deleteCategory,
    updateCategory,
    updateActivity,
    deleteActivity,
    getUserShop,
    getRotatingStaffGroups,
}