import React, {memo, ReactElement} from "react";
import {Props} from "./ShopAccordion.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShopAccordion.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ShopAccordion.styles.scss";
import {Flex} from "@fluentui/react-northstar";
import {useShopCache} from "../../../hooks/cache/useShopsCache";
import {Accordion} from "../Accordion/Accordion";

export const ShopAccordion = memo((props: Props): ReactElement | null => {
    const {shop} = useShopCache(props.shopId);
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    const content = !shop ? undefined : (
        <Flex fill column className={"shop-accordion-content"}>
            {props.children}
        </Flex>
    )

    return (
        <Flex className={"shop-accordion"}>
            <Accordion
                title={shop?.name ?? ""}
                content={content}
                defaultOpen={props.defaultOpen}
                headerContent={props.headerContent}
            />
        </Flex>
    )
}, CompareModule.areObjectsEqual);