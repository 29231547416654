import {Props, State} from "./BadgeErrorDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {useBadgeErrorForm} from "../../../hooks/forms/useBadgeErrorForm";
import {BadgeRequest} from "../../../interfaces/BadgeRequest";
import {Time} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/TimePicker/TimePicker";
import {BadgeApi} from "../../../apis/Badge/BadgeApi";

export const initialState: State = {
    shiftId: "",
    isStart: true,
    showForm: false,
    isSubmitting: false,
    callback: undefined,
}

export const reducer = (config: {
    props: Props,
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, {shiftId, isStart, callback}: {
        shiftId: string,
        isStart: boolean,
        callback: State["callback"]
    }) => {
        setState({...initialState, isStart, callback, shiftId});
        config.dialogRef.dispatch("open")();
    },
    close: ({state, setState}, _, form: ReturnType<typeof useBadgeErrorForm>) => {
        setState({...state, showForm: false});
        form.reset();
        if (!state.isSubmitting) state.callback?.(undefined);
    },
    showForm: ({setState}) => {
        setState({showForm: true});
    },
    forceClocking: async ({state, setState}, _, form: ReturnType<typeof useBadgeErrorForm>) => {
        const {justification, time} = form.state.data;
        const request: BadgeRequest = {
            shiftId: state.shiftId,
            qrCodeValue: undefined,
            isStart: state.isStart,
            justification: justification as string,
            realClockingTime: time as Time,
        }
        setState({isSubmitting: true});
        const clocking = await BadgeApi.badge(request);
        config.dialogRef.dispatch("close")();
        state.callback?.(clocking);
    },
}) satisfies MagicReducerObject<State>;