import React, {useEffect} from "react";
import {Flex, Text} from "@fluentui/react-northstar";
import {translations} from "translations";
import * as microsoftTeams from "@microsoft/teams-js";

export const Configuration = () => {

    useEffect(() => {
        microsoftTeams.pages.config.registerOnSaveHandler(async event => {
            await microsoftTeams.pages.config.setConfig({
                contentUrl: "https://" + window.location.host,
            });
            event.notifySuccess();
        });
        microsoftTeams.pages.config.setValidityState(true);
    }, []);

    return (
        <Flex fill column vAlign={"center"} hAlign={"center"} gap={"gap.medium"}>
            <Text content={translations.get("ClickSaveToContinue")} size={"larger"}/>
        </Flex>
    )
}