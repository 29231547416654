import React, {memo, ReactElement, useCallback, useMemo} from "react";
import {Props, ShopShiftActivityWithShopId} from "./ActivitiesTable.interfaces";
import {Immutable, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ActivitiesTable.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ActivitiesTable.styles.scss";
import {useShopCache} from "hooks/cache/useShopsCache";
import {EditIcon, Flex, LockIcon, MoreIcon, TrashCanIcon} from "@fluentui/react-northstar";
import {Table} from "../Table/Table";
import {useActivitiesTableColumns} from "./hooks/useActivitiesTableColumns";
import {TableProps} from "../Table/Table.interfaces";
import {translations} from "../../../translations";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";

export const ActivitiesTable = memo((props: Props): ReactElement | null => {
    const {shopActivityDialog, confirmDeleteDialog} = useDialogContext();
    const {shop, upsertActivity, deleteActivity} = useShopCache(props.shopId);
    const [state, dispatch] = useMagicReducer(reducer({shop, upsertActivity}), initialState);

    const handleEditActivity = useCallback((activity: Immutable<ShopShiftActivityWithShopId> | null) => {
        if (!activity || activity.shopId !== props.shopId) return;
        shopActivityDialog.dispatch("open", {
            shopId: props.shopId,
            activity,
        })();
    }, [props.shopId]);

    const columns = useActivitiesTableColumns(props.shopId, handleEditActivity);

    const actions: TableProps<ShopShiftActivityWithShopId>["actions"] = useMemo(() => ({
        items: [
            {
                label: translations.get("Edit"),
                icon: <EditIcon outline/>,
                onClick: handleEditActivity,
            },
            {
                label: translations.get("Delete"),
                icon: <TrashCanIcon outline/>,
                onClick: c => confirmDeleteDialog.dispatch("open", {
                    title: translations.get("DeleteActivity"),
                    subtitle: translations.get("AreYouSureToDeleteThisActivity", {name: c?.name ?? ""}),
                    callback: () => deleteActivity(c?.key),
                })(),
            }
        ],
        icon: renderActionsIcon(props.shopId),
        disabled: i => i?.shopId !== props.shopId,
    }), [handleEditActivity, props.shopId]);

    if (!props.items.length) return null;

    return (
        <Flex fill className={"shop-categories-table"}>
            <Table<Immutable<ShopShiftActivityWithShopId>>
                items={props.items}
                columns={columns}
                actions={actions}
                sortLocally
            />
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderActionsIcon = (shopId: string) => (items: Immutable<ShopShiftActivityWithShopId> | null) => {
    if (shopId !== items?.shopId) return <LockIcon/>;
    return <MoreIcon/>;
}