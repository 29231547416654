import {memo, ReactElement} from "react";
import {Props} from "./ConfirmDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ConfirmDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {AcceptIcon, Button, Flex, Text} from "@fluentui/react-northstar";
import "./ConfirmDialog.styles.scss";
import {translations} from "../../../translations";

export const ConfirmDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    return (
        <Dialog
            externalRef={dialogRef}
            width={500}
            title={state.title}
            onClose={dispatch("cancel")}
            content={<Text content={state.subtitle}/>}
            footer={
                <Flex fill hAlign={"end"}>
                    <Button
                        primary={state.primaryButton || true}
                        content={state.buttonTitle || translations.get("Confirm")}
                        icon={state.buttonIcon || <AcceptIcon outline/>}
                        onClick={dispatch("confirm")}
                    />
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);