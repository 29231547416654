import {useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "translations";
import {Alert, ArrowRightIcon, Button, Flex, SaveIcon, Text, TrashCanIcon} from "@fluentui/react-northstar";
import React, {ReactElement, useCallback, useEffect, useLayoutEffect, useMemo} from "react";
import {ActivitiesList} from "components/dialogs/ShiftDialog/ActivitiesList/ActivitiesList";
import {ShiftActivity, ShiftType} from "interfaces/ShiftData";
import {Time} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/TimePicker/TimePicker";
import {Immutable, MagicReducerRef, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {useShopsCache} from "../cache/useShopsCache";
import {ShopShiftCategory, ShopShiftCategoryType, ShopType} from "interfaces/ShopData";
import {FormModule} from "../../modules/Form.module";
import {useUserCache} from "../cache/useUsersCache";
import {ShiftRecurrence} from "../../components/others/ShiftRecurrence/ShiftRecurrence";
import {Recurrence} from "../../components/others/ShiftRecurrence/ShiftRecurrence.interfaces";
import {DialogContextValue} from "../../services/DialogContext/DialogContext.interfaces";
import {useShiftsCache} from "../cache/useShiftsCache";
import {useDialogContext} from "../../services/DialogContext/DialogContext";
import {CompareModule} from "../../modules/Compare.module";
import {Shift} from "../../classes/Shift";
import {GraphService} from "../../services/GraphService/GraphService";
import {useShopGroupsCache} from "../cache/groups/useShopGroupsCache";
import {TimerIcon, WarningIcon} from "../../assets/icons";
import {TimeModule} from "../../modules/Time.module";
import {useUserRolesCache} from "../cache/useUserRoleCache";
import {ShiftEditType} from "../../components/others/ShiftCard/ShiftCard.interfaces";
import {useSettingsCache} from "../cache/useSettingsCache";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";

export const useShiftForm = (props: {
    currentShopId: string | undefined,
    shift: Immutable<Shift> | undefined,
    userId: string | undefined,
    initialDate: string | undefined,
    readOnly: boolean | undefined,
}) => {
    const {currentShopId, shift, userId, initialDate} = props;
    const {confirmDeleteDialog} = useDialogContext();
    const {shops} = useShopsCache();
    const {user} = useUserCache(userId ?? shift?.getUserId());
    const {upsertShift, deleteShift} = useShiftsCache();
    const {canUpdateShop} = useUserRolesCache();
    const {categories: settingsCategories} = useSettingsCache();
    const {groups, groupsIds, isLoading: areGroupsLoading} = useShopGroupsCache(currentShopId);
    const [shiftCategories, setShiftCategories] = React.useState<Immutable<Array<ShopShiftCategory>>>([]);
    const [activities, setActivities] = React.useState<Immutable<Array<ShiftActivity>>>([]);
    const shiftRecurrenceRef = useMagicReducerRef(ShiftRecurrence);
    const [isSaving, setIsSaving] = React.useState<boolean>(false);
    const [hasRecurrenceChanged, setHasRecurrenceChanged] = React.useState<boolean>(false);

    const currentShop = useMemo(() => shops?.find(s => s.id === currentShopId), [currentShopId, shops]);

    const currentShopUsersIds = useMemo(() => groups?.flatMap(g => g.usersIds), [groupsIds, areGroupsLoading]);

    const readOnly = !canUpdateShop(currentShopId) || (props.readOnly ?? false);

    useLayoutEffect(function onShiftChange() {
        form.setFieldsInitialValues({
            type: shift ? shift.getType() : ShiftType.Shift,
            store: shift ? shift.getShopId() : currentShopId,
            staff: shift?.getUserId() ? [shift!.getUserId()!] : userId ? [userId] : [],
            category: shift ? shift.getCategoryId() : undefined,
            customWording: shift ? shift.getCustomWording() : undefined,
            date: shift ? shift.getDate() : initialDate,
            startTime: shift ? shift.getStart() : undefined,
            endTime: shift ? shift.getEnd() : undefined,
            notes: shift ? shift.getNotes() : undefined,
            recurring: shift ? !!shift.getRecurrence() : false,
            allDay: !shift?.getStart() && !shift?.getEnd(),
        });
        form.reset();
        recurrenceForm.reset();
        setActivities(shift?.getActivities() ?? []);
        setHasRecurrenceChanged(false);
    }, [currentShopId, shift, userId, initialDate]);

    const isDoneShift = shift?.isDone() ?? false;

    const recurrenceForm = useForm({
        readOnly,
        disabled: isSaving,
        items: {
            updateOf: {
                type: "radioGroup",
                items: [
                    {
                        label: translations.get("Occurence"),
                        value: ShiftEditType.Occurence,
                    },
                    {
                        label: translations.get("Series"),
                        value: ShiftEditType.Series,
                    }
                ],
                required: true,
                initialValue: 0,
                label: translations.get("UpdateOf"),
            },
        }
    });

    const recurrenceId = shift?.getRecurrence()?.id;

    const canUpdateRecurrence = !recurrenceId || (!!recurrenceId && recurrenceForm.state.data.updateOf === ShiftEditType.Series);

    const form = useForm({
        readOnly,
        disabled: isSaving,
        items: {
            type: {
                type: "radioGroup",
                items: [
                    {
                        label: translations.get("Shift"),
                        value: ShiftType.Shift,
                    },
                    {
                        label: translations.get("Absence"),
                        value: ShiftType.Absence,
                    }
                ],
                required: true,
                initialValue: 0,
            },
            store: {
                type: "dropdown",
                label: translations.get("Store"),
                required: true,
                items: shops?.map(s => s.id) ?? [],
                renderItem: (id) => shops?.find(s => s.id === id)?.name ?? "",
                renderSelectedItem: (id) => shops?.find(s => s.id === id)?.name ?? "",
                placeholder: translations.get("SelectAStore"),
                clearable: false,
                disabled: currentShop?.type !== ShopType.RotatingStaff,
            },
            staff: {
                type: "peoplePicker",
                required: true,
                label: translations.get("Staff"),
                openOrgWideSearchInChatOrChannel: true,
                fetchInitialUsers: (ids) => GraphService.getUsersAsync(ids),
                initialValue: !user ? undefined : [user.id],
                fetchUsers: async (search) => {
                    if (!search) return GraphService.getUsersAsync(currentShopUsersIds?.slice(0, 5) ?? []);
                    const users = await GraphService.searchForUsersAsync(search);
                    return users.filter(u => currentShopUsersIds?.includes(u.id));
                },
                placeholder: translations.get("SelectAStaff"),
                clearable: false,
            },
            category: {
                type: "dropdown",
                label: translations.get("Type"),
                required: true,
                items: shiftCategories.map(s => s.key),
                renderItem: (id) => shiftCategories.find(s => s.key === id)?.name ?? "",
                renderSelectedItem: (id) => shiftCategories.find(s => s.key === id)?.name ?? "",
                placeholder: translations.get("SelectAType"),
                clearable: false,
            },
            customWording: {
                type: "input",
                inputMode: "text",
                label: translations.get("CustomWording"),
                placeholder: translations.get("EnterCustomWording"),
            },
            date: {
                type: "datePicker",
                required: true,
                placeholder: translations.get("SelectADate"),
                clearable: false,
                minDate: new Date(),
            },
            ...FormModule.generateTimeRangeItems({
                required: false,
                showLabel: false,
            }),
            recurring: {
                type: "checkbox",
                toggle: true,
                label: translations.get("Recurring"),
                disabled: !canUpdateRecurrence,
            },
            notes: {
                type: "textArea",
                label: translations.get("Notes"),
                placeholder: translations.get("EnterNotesAboutTheShift"),
                minHeight: "60px",
                maxHeight: "120px",
            },
            allDay: {
                type: "checkbox",
                toggle: true,
                initialValue: true,
                label: translations.get("AllDay"),
            }
        },
    });

    const {type, store, category, customWording} = form.state.data;

    useEffect(function onSelectedShopOrTypeChange() {
        if (!store && shiftCategories.length > 0) {
            setShiftCategories([]);
            if (category !== undefined) form.setFieldsValues({category: undefined});
            return;
        }
        const shopFilteredCategories = shops?.find(s => s.id === store)?.categories?.filter(c => c.type === type) ?? [];
        if (type === ShiftType.Absence) {
            const settingsAbsences = settingsCategories?.filter(c => c.type === ShopShiftCategoryType.Absence) ?? [];
            shopFilteredCategories.unshift(...settingsAbsences);
        }
        setShiftCategories(shopFilteredCategories);
        const isCategoryExisting = shopFilteredCategories.some(c => c.key === category);
        if (!isCategoryExisting && category !== undefined) form.setFieldsValues({category: undefined});
    }, [store, type, settingsCategories, currentShop]);

    useEffect(function onSelectedCategoryChange() {
        if (shiftCategories.length === 0) return;
        const shopCategory = shops?.find(s => s.id === store)?.categories.find(c => c.key === category);
        if (!shopCategory) return;
        form.setFieldsValues({
            startTime: shopCategory.start,
            endTime: shopCategory.end,
        });
    }, [category]);

    const handleUpdateActivity = useCallback((activity: Immutable<ShiftActivity> | undefined) => {
        if (!activity) return;
        setActivities(prevActivities => {
            const newActivities = [...prevActivities];
            const index = newActivities.findIndex(a => a.key === activity.key);
            if (index >= 0) {
                newActivities[index] = activity;
                return newActivities;
            }
            return [...newActivities, activity];
        });
    }, []);

    const handleDeleteActivity = useCallback((activityId: string) => {
        setActivities(prevActivities => prevActivities.filter(a => a.key !== activityId));
    }, []);

    const renderForm = () => form.state.data.type === ShiftType.Shift ?
        renderShiftForm(
            form.state.data, form.formItems, activities, handleUpdateActivity, handleDeleteActivity, shiftRecurrenceRef,
            isSaving, shift, readOnly, recurrenceForm.formItems.updateOf, canUpdateRecurrence, hasRecurrenceChanged,
            setHasRecurrenceChanged
        )
        :
        renderAbsenceForm(form.state.data, form.formItems, shift, isDoneShift, readOnly);

    const hasActivitiesChanged = useMemo(() => {
        return !CompareModule.areArraysEqual(activities, shift?.getActivities() ?? [], true);
    }, [shift?.getActivities(), activities]);

    const hasChanged = hasActivitiesChanged || form.state.hasChanged || hasRecurrenceChanged;

    const isSeriesSelected = recurrenceForm.state.data.updateOf === ShiftEditType.Series;

    const renderSaveButton = (callback: () => void, fluid: boolean = false) => (
        <Button
            className={"no-shrink"}
            primary icon={<SaveIcon outline size={"large"}/>}
            fluid={fluid}
            content={translations.get("Save")}
            disabled={!form.isValid || !hasChanged || isSaving}
            loading={isSaving}
            onClick={handleSaveShift({
                shift,
                formState: form.state,
                activities,
                recurrenceRef: shiftRecurrenceRef,
                callback,
                upsertShift,
                setIsSaving,
                isSeriesSelected,
            })}
        />
    );

    const shiftName = !customWording ? shiftCategories.find(s => s.key === category)?.name : customWording as string;

    const renderDeleteButton = (callback: () => void) => (!shift || readOnly) ? null : (
        <Button
            content={translations.get("Delete")}
            icon={<TrashCanIcon outline/>}
            text
            disabled={isSaving}
            className={"no-shrink " + (isSaving ? "" : "delete-btn")}
            onClick={handleDeleteShift({
                shiftId: shift.getId(),
                shiftName: shiftName,
                deleteShift,
                confirmDeleteDialog,
                callback,
                type: shift.getType(),
                isSeriesSelected,
            })}
        />
    );

    return {
        ...form,
        renderForm,
        activities,
        renderSaveButton,
        renderDeleteButton,
        isSaving,
        isSeriesSelected,
        hasChanged,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleSaveShift = (config: {
    shift: Immutable<Shift> | undefined,
    formState: ReturnType<typeof useShiftForm>["state"],
    activities: Immutable<Array<ShiftActivity>>,
    recurrenceRef: MagicReducerRef<typeof ShiftRecurrence>,
    upsertShift: ReturnType<typeof useShiftsCache>["upsertShift"],
    setIsSaving: (value: boolean) => void,
    callback: () => void,
    isSeriesSelected: boolean,
}) => async () => {
    const {shift, upsertShift, setIsSaving, callback, recurrenceRef, activities, formState, isSeriesSelected} = config;
    setIsSaving(true);
    const newShift = generateShift(shift, formState, activities, recurrenceRef.state);
    const exceptions = await upsertShift({prevShift: shift, newShift: newShift, isSeriesSelected});
    if (exceptions.length > 0) {
        ErrorModule.showErrorDialog({
            title: translations.get("RuleNotRespected"),
            subtitle: translations.get(exceptions[0]!),
        });
    } else callback();
    setIsSaving(false);
}

const handleDeleteShift = (config: {
    shiftId: string | undefined,
    callback: () => void,
    deleteShift: ReturnType<typeof useShiftsCache>["deleteShift"],
    confirmDeleteDialog: DialogContextValue["confirmDeleteDialog"],
    shiftName: string | undefined,
    type: ShiftType | undefined,
    isSeriesSelected: boolean,
}) => async () => {
    const {
        shiftId,
        shiftName,
        deleteShift,
        confirmDeleteDialog,
        callback,
        type,
        isSeriesSelected,
    } = config;
    if (!shiftId || !shiftName) return;
    const isShift = type === undefined || type === ShiftType.Shift;
    confirmDeleteDialog.dispatch("open", {
        title: translations.get(isShift ? (isSeriesSelected ? "DeleteSeries" : "DeleteShift") : "DeleteAbsence"),
        subtitle: translations.get(isShift ? (isSeriesSelected ? "AreYouSureToDeleteSeries" : "AreYouSureToDeleteShift") : "AreYouSureToDeleteAbsence", {name: shiftName}),
        callback: async () => {
            await deleteShift({shiftId, deleteSeries: isSeriesSelected});
            callback();
        }
    })();
}

const generateShift = (
    currentShift: Immutable<Shift> | undefined,
    formState: ReturnType<typeof useShiftForm>["state"],
    activities: Immutable<Array<ShiftActivity>>,
    recurrence: Immutable<Recurrence> | null
): Immutable<Shift> => {
    const {
        type,
        staff,
        category,
        customWording,
        date,
        startTime,
        endTime,
        notes,
        store,
        recurring,
        allDay
    } = formState.data;
    const shift = new Shift({id: currentShift?.getId() ?? ""});
    const isAbsence = type === ShiftType.Absence;
    shift.setFields({
        shopId: store as string,
        type: type as ShiftType,
        userId: staff?.[0] as string,
        categoryId: category as string,
        clocking: undefined,
        customWording: isAbsence ? "" : customWording as string,
        date: date as string,
        end: (isAbsence && allDay) ? undefined : endTime as Time,
        start: (isAbsence && allDay) ? undefined : startTime as Time,
        notes: notes as string,
        activities: isAbsence ? [] : [...activities] as Array<ShiftActivity>,
        recurrence: isAbsence ? undefined : (!recurring ? undefined : recurrence ?? currentShift?.getRecurrence()),
    });
    return shift;
}

const renderShiftForm = (
    formData: ReturnType<typeof useShiftForm>["state"]["data"],
    formItems: ReturnType<typeof useShiftForm>["formItems"],
    activities: Immutable<Array<ShiftActivity>>,
    onUpdateActivity: (activity: Immutable<ShiftActivity> | undefined) => void,
    onDeleteActivity: (activityId: string) => void,
    shiftRecurrenceRef: MagicReducerRef<typeof ShiftRecurrence>,
    isSaving: boolean,
    shift: Immutable<Shift> | undefined,
    readOnly: boolean,
    updateOfRadioGroup: ReactElement | null,
    canUpdateRecurrence: boolean,
    hasRecurrenceChanged: boolean,
    setHasRecurrenceChanged: (hasChanged: boolean) => void,
) => {
    const {type, store, staff, category, customWording, date, startTime, endTime, recurring, notes} = formItems;
    const showClockingWarning = shift?.isDone() && (
        !TimeModule.isEqual(shift?.getStart(), formData.startTime) ||
        !TimeModule.isEqual(shift?.getEnd(), formData.endTime)
    );
    const showNotes = !readOnly || (readOnly && formData.notes);
    const showRecurrence = !readOnly;
    const showRecurrenceForm = !readOnly && formData.recurring && canUpdateRecurrence;
    const showRecurrenceWarning = !readOnly && formData.recurring && canUpdateRecurrence && shift?.isRecurring() && hasRecurrenceChanged;
    const showCustomWording = !readOnly || (readOnly && formData.customWording);
    const showEditWarning = !readOnly && shift?.isDone();
    const showType = !readOnly && !shift;
    const showUpdateType = !readOnly && shift?.isRecurring();
    return (
        <Flex column fill gap={"gap.small"}>
            {showEditWarning && renderDoneShiftWarning(formData.type as ShiftType)}
            {showType && type}
            {showUpdateType && updateOfRadioGroup}
            {store}
            {staff}
            {category}
            {showCustomWording && customWording}
            <Flex column>
                <Text>
                    {translations.get("Date")}<span style={{color: "red"}}> *</span>
                </Text>
                <Flex vAlign={"center"} gap={"gap.small"}>
                    {date}
                    {startTime}
                    <ArrowRightIcon style={{color: "darkgray"}}/>
                    {endTime}
                </Flex>
            </Flex>
            {showClockingWarning && renderUpdateClockingWarning()}
            {showRecurrence && recurring}
            {showRecurrenceForm &&
                <ShiftRecurrence
                    externalRef={shiftRecurrenceRef}
                    shiftDate={formData.date}
                    initialRecurrence={shift?.getRecurrence()}
                    disabled={isSaving}
                    onChange={setHasRecurrenceChanged}
                />
            }
            {showRecurrenceWarning && renderUpdateRecurrenceWarning()}
            {showNotes && notes}
            <ActivitiesList
                items={activities}
                shopId={formData.store as string}
                onUpdateActivity={onUpdateActivity}
                onDelete={onDeleteActivity}
                disabled={isSaving}
                readOnly={readOnly}
            />
        </Flex>
    )
}

const renderAbsenceForm = (
    formData: ReturnType<typeof useShiftForm>["state"]["data"],
    formItems: ReturnType<typeof useShiftForm>["formItems"],
    shift: Immutable<Shift> | undefined,
    isDoneShift: boolean,
    readOnly: boolean,
) => {
    const {type, staff, category, date, startTime, endTime, notes, allDay} = formItems;
    const showDoneShiftWarning = !readOnly && isDoneShift;
    const showType = !readOnly && !shift;
    const showNotes = !readOnly || (readOnly && formData.notes);
    return (
        <Flex column fill gap={"gap.small"}>
            {showDoneShiftWarning && renderDoneShiftWarning(formData.type as ShiftType)}
            {showType && type}
            {staff}
            {category}
            <Flex column>
                <Text>
                    {translations.get("Date")}<span style={{color: "red"}}> *</span>
                </Text>
                <Flex vAlign={"center"} gap={"gap.small"}>
                    {date}
                    {!formData.allDay &&
                        <>
                            {startTime}
                            <ArrowRightIcon style={{color: "darkgray"}}/>
                            {endTime}
                        </>
                    }
                    {!readOnly &&
                        <Flex className={"no-shrink"}>
                            {allDay}
                        </Flex>
                    }
                </Flex>
            </Flex>
            {showNotes && notes}
        </Flex>
    )
}

const renderDoneShiftWarning = (type: ShiftType | undefined) => (
    <Alert
        warning icon={<WarningIcon/>}
        content={translations.get(type === ShiftType.Shift ? "YouUpdatePastShift" : "YouUpdatePastAbsence")}
    />
)

const renderUpdateClockingWarning = () => (
    <Alert warning icon={<TimerIcon/>} content={translations.get("ThinkToUpdateClockingIfNecessary")}/>
)

const renderUpdateRecurrenceWarning = () => (
    <Alert warning icon={<WarningIcon/>} content={translations.get("ByModifyingRecurrenceOccurrencesWillBeReplaced")}/>
)