import {useForm} from "@witivio_teamspro/northstar-form";
import {ArrowRightIcon, Button, Flex, SaveIcon, Text} from "@fluentui/react-northstar";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {Time} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/TimePicker/TimePicker";
import React, {useLayoutEffect} from "react";
import moment from "moment";
import {TimerIcon} from "assets/icons";
import {FormModule} from "../../modules/Form.module";
import {translations} from "../../translations";
import {useShiftsCache} from "../cache/useShiftsCache";
import {useShiftForm} from "./useShiftForm";
import {Shift} from "../../classes/Shift";

export const useUpdateClockingForm = (shift: Immutable<Shift> | undefined) => {
    const [isSaving, setIsSaving] = React.useState(false);
    const {updateClocking} = useShiftsCache();

    useLayoutEffect(function onShiftChange() {
        if (!shift) return;
        form.setFieldsInitialValues({
            startTime: shift.getClocking()?.start,
            endTime: shift.getClocking()?.end,
        });
        form.reset();
    }, [shift]);

    const form = useForm({
        disabled: isSaving,
        items: FormModule.generateTimeRangeItems({required: true, showLabel: true}),
    });

    const {startTime, endTime} = form.formItems;

    const renderSaveButton = (callback: () => void) => (
        <Button
            primary icon={<SaveIcon outline size={"large"}/>}
            content={translations.get("Save")}
            disabled={!form.isValid || !form.state.hasChanged || isSaving}
            loading={isSaving}
            onClick={handleSaveShift({
                shift,
                formState: form.state,
                callback,
                updateClocking,
                setIsSaving
            })}
        />
    );

    const renderForm = () => (
        <Flex column fill gap={"gap.small"}>
            <Flex vAlign={"end"} gap={"gap.small"}>
                {startTime}
                <ArrowRightIcon styles={{color: "darkgray", marginBottom: "8px"}}/>
                {endTime}
                {renderDiffLabel(form.state.data.startTime, form.state.data.endTime)}
            </Flex>
        </Flex>
    )

    return {
        ...form,
        renderForm,
        renderSaveButton
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleSaveShift = (config: {
    shift: Immutable<Shift> | undefined,
    formState: ReturnType<typeof useShiftForm>["state"],
    updateClocking: ReturnType<typeof useShiftsCache>["updateClocking"],
    setIsSaving: (value: boolean) => void,
    callback: () => void,
}) => async () => {
    const {shift, updateClocking, setIsSaving, callback, formState} = config;
    if (!shift) return;
    setIsSaving(true);
    const newShift = shift.updateClocking({
        start: formState.data.startTime as Time,
        end: formState.data.endTime as Time,
    });
    await updateClocking({shift: newShift});
    setIsSaving(false);
    callback();
}

const renderDiffLabel = (startTime: Time | undefined, endTime: Time | undefined) => {
    const start = moment(((startTime?.hour ?? 0) + "").padStart(2, "0") + ":" + ((startTime?.minutes ?? 0) + "").padStart(2, "0"), "HH:mm");
    const end = moment(((endTime?.hour ?? 0) + "").padStart(2, "0") + ":" + ((endTime?.minutes ?? 0) + "").padStart(2, "0"), "HH:mm");
    const diff = moment.duration(end.diff(start));
    const hoursDiff = (diff.hours() + "").padStart(2, "0");
    const minutesDiff = (diff.minutes() + "").padStart(2, "0");
    const label = `${hoursDiff.padStart(2, "0")}:${minutesDiff.padStart(2, "0")}:00`;
    return (
        <Flex styles={{gap: "5px"}} className={"no-shrink"}>
            <TimerIcon color={"darkgray"}/>
            <Text styles={{color: "darkgray", marginBottom: "6px"}} content={label}/>
        </Flex>
    )
}