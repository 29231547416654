import React, {memo, ReactElement} from "react";
import {Props} from "./PeopleShiftsDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./PeopleShiftsDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import "./PeopleShiftsDialog.styles.scss";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Flex, Loader} from "@fluentui/react-northstar";
import {useUsersCache} from "hooks/cache/useUsersCache";
import {GroupShiftsTable} from "components/tables/GroupShiftsTable/GroupShiftsTable";
import {UserShiftsCountData} from "components/tables/GroupShiftsTable/GroupShiftsTable.interfaces";
import {ShiftsCache, useGroupShiftsCountCache} from "hooks/cache/useShiftsCache";
import {ITableItem} from "components/tables/Table/Table.interfaces";
import {useGroupCache} from "hooks/cache/groups/useGroupCache";
import {DateRangeSelector} from "../../others/DateRangeSelector/DateRangeSelector";
import {usePlanningDateRangeCache} from "../../../hooks/cache/usePlanningDateRangeCache";
import {useQueryClient} from "react-query";

export const PeopleShiftsDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const dateRangeRef = useMagicReducerRef(DateRangeSelector);
    const {planningDateRange} = usePlanningDateRangeCache();
    const [state, dispatch, render] = useMagicReducer(reducer({
        dialogRef,
        dateRangeRef,
        planningDateRange,
    }), initialState, props.externalRef);
    const {group, isLoading: isGroupLoading} = useGroupCache(state.groupId);
    const {isLoading: areUsersLoading} = useUsersCache(group?.usersIds);
    const queryClient = useQueryClient();

    const startDate = dateRangeRef.state?.selectedRange.startDate;
    const endDate = dateRangeRef.state?.selectedRange.endDate;
    const {shifts, isLoading: areShiftsLoading} = useGroupShiftsCountCache(state.groupId, startDate, endDate);

    const items = (shifts ?? []) as ITableItem<UserShiftsCountData>[];

    const loading = isGroupLoading || areUsersLoading || areShiftsLoading;

    return (
        <Dialog
            width={1000}
            externalRef={dialogRef}
            title={group?.name}
            onOpen={dispatch("setDateRangeDate")}
            onClose={ShiftsCache.removeAllGroupShifts(queryClient)}
            headerElement={
                <DateRangeSelector
                    externalRef={dateRangeRef}
                    className={"planning-date-range"}
                    onDateRangeChange={render}
                />
            }
            content={
                <Flex fill>
                    {loading ?
                        renderLoader(group?.usersIds.length)
                        :
                        <Flex fill className={"fade"}>
                            <GroupShiftsTable shopId={group?.shopId} groupId={state.groupId} items={items}/>
                        </Flex>
                    }
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);

const renderLoader = (usersIdsCount: number | undefined) => {
    const potentialTableHeight = !usersIdsCount ? 400 : (usersIdsCount * 50 + 50);
    return (
        <Flex fill vAlign={"center"} hAlign={"center"} styles={{height: `${potentialTableHeight}px`}}>
            <Loader/>
        </Flex>
    )
}