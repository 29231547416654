import moment, {Moment} from "moment";
import {WeekDay} from "interfaces/WeekDay";
import {translations} from "../translations";
import {TimeModule} from "./Time.module";
import {Time} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/TimePicker/TimePicker";

const getWeekDaysOrderPerCulture = () => {
    const currentLocaleData = moment.localeData();
    return moment.weekdays(true).map(d => currentLocaleData.weekdaysParse(d, "dddd", false) as WeekDay);
}

const defaultBusinessDays = new Array<WeekDay>(
    WeekDay.Monday, WeekDay.Tuesday, WeekDay.Wednesday, WeekDay.Thursday, WeekDay.Friday
);

const getDateOfDayXWeeksAfter = (weeksToAdd: number, date?: string) => {
    const momDate = !date ? moment() : moment(date);
    momDate.add(weeksToAdd, "weeks").startOf("day");
    return momDate.toISOString(false);
}

const getFirstDayOfWeek = (): WeekDay => {
    return getWeekDaysOrderPerCulture()[0]!;
}

const getRangeDays = (startDate: string | undefined, endDate: string | undefined): Array<Moment> => {
    if (!startDate || !endDate) return [];
    const start = moment(startDate).startOf("day");
    const end = moment(endDate).startOf("day");
    const days: Array<Moment> = [];
    for (let m = start.clone(); m.diff(end, "days") <= 0; m.add(1, "days")) days.push(m.clone());
    return days;
}

const getWeekDay = (date: string) => {
    return moment(date).day() as WeekDay;
}

const isDateWeekEnd = (date: string) => {
    return [WeekDay.Sunday, WeekDay.Saturday].includes(WeekDaysModule.getWeekDay(date));
}

const isToday = (date: string) => {
    return moment(date).isSame(moment(), "day");
}

const getDayLabel = (day: WeekDay) => {
    return translations.get(WeekDay[day]);
}

const getPeriodLabel = (params: {
    startDate: string,
    endDate: string,
    startTime?: Time | undefined,
    endTime?: Time | undefined,
    isAllDay: boolean,
}) => {
    const isSingleDay = moment(params.startDate).isSame(params.endDate, "day");
    const showStartTime = !params.isAllDay && params.startTime;
    const showEndTime = !params.isAllDay && params.endTime;
    const showEndDate = !isSingleDay;
    const showArrow = !isSingleDay || (isSingleDay && !params.isAllDay);
    const startDate = moment(params.startDate).format("L");
    const endDate = moment(params.endDate).format("L");
    const startTime = TimeModule.getTimeLabel(params.startTime);
    const endTime = TimeModule.getTimeLabel(params.endTime);
    return [
        startDate,
        showStartTime && startTime,
        showArrow && (isSingleDay ? "-" : "→"),
        showEndDate && endDate,
        showEndTime && endTime
    ].filter(Boolean).join(" ");
}

export const WeekDaysModule = {
    getWeekDaysOrderPerCulture,
    defaultBusinessDays,
    getDateOfDayXWeeksAfter,
    getFirstDayOfWeek,
    getRangeDays,
    getWeekDay,
    isDateWeekEnd,
    getDayLabel,
    isToday,
    getPeriodLabel,
}