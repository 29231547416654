import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./StaffPart.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./StaffPart.reducer";
import {CompareModule} from "modules/Compare.module";
import "./StaffPart.styles.scss";
import {PeopleIcon} from "assets/icons";
import {translations} from "translations";
import {Accordion} from "components/others/Accordion/Accordion";
import {PeopleButton} from "components/buttons/PeopleButton/PeopleButton";
import {AddIcon, Button, Flex, SearchIcon} from "@fluentui/react-northstar";
import {useForm} from "@witivio_teamspro/northstar-form";
import {useUsersCache} from "hooks/cache/useUsersCache";
import {useGroupCache} from "../../../../../hooks/cache/groups/useGroupCache";
import {useDialogContext} from "../../../../../services/DialogContext/DialogContext";

export const StaffPart = memo((props: Props): ReactElement | null => {
    const {selectPeopleDialog} = useDialogContext();
    const {group, addStaffs, deleteStaff} = useGroupCache(props.groupId);
    const {users} = useUsersCache(group?.usersIds);
    const [state, dispatch] = useMagicReducer(reducer({addStaffs, deleteStaff, selectPeopleDialog}), initialState);

    const searchBar = useForm({
        items: {
            searchbar: {
                type: "input",
                inputMode: "text",
                placeholder: translations.get("SearchStaff"),
                inputIcon: <SearchIcon styles={{color: "darkgray"}} outline/>,
            }
        }
    });

    const filteredStaffIds = useMemo(() => {
        const filter = ((searchBar.state.data.searchbar ?? "") + "")?.toLowerCase();
        return users?.filter(user => group?.usersIds.includes(user.id) && user.displayName.toLowerCase().includes(filter))
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
            .map(user => user.id)
    }, [users, group?.usersIds, searchBar.state.data.searchbar])

    const content = (
        <Flex fill column gap={"gap.smaller"} className={"overflow-hidden"}>
            <Flex>
                {searchBar.renderForm()}
            </Flex>
            <Button
                className={"no-shrink"} fluid tinted icon={<AddIcon/>}
                content={translations.get("AddStaff")}
                onClick={dispatch("addStaff")}
            />
            <Flex column fill gap={"gap.smaller"} className={"overflow-scroll"}>
                <Flex column gap={"gap.smaller"}>
                    {filteredStaffIds?.map(id => (
                        <PeopleButton
                            fluid key={id} userId={id}
                            onDelete={dispatch("deleteStaff", id)}
                        />
                    ))}
                </Flex>
            </Flex>
        </Flex>
    )

    return (
        <Accordion
            card
            icon={PeopleIcon}
            expandable={false}
            title={translations.get("Staff")}
            content={content}
        />
    )
}, CompareModule.areObjectsEqual);