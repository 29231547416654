import {WeekDay} from "./WeekDay";
import {HEX} from "./Color";

export type TimeRange = {
    start: {
        hour: number,
        minutes: number,
    },
    end: {
        hour: number,
        minutes: number,
    },
}

export type ShopDayOpeningHours = TimeRange & {
    day: WeekDay,
};

export enum ShopShiftCategoryType {
    Shift, Absence
}

export type ShopShiftCategory = TimeRange & {
    key: string,
    shopId: string,
    name: string,
    type: ShopShiftCategoryType,
    badge: boolean,
    showInList: boolean,
    color: HEX,
}

export type ShopShiftActivity = {
    key: string,
    name: string,
}

export type ShopOpeningHours = Array<ShopDayOpeningHours>;

export enum ShopType {
    BigStore, OwnStore, RotatingStaff
}

export type ShopData = {
    id: string,
    name: string,
    type: ShopType,
    postalAddress: string,
    publicHolidaysId: string,
    managersIds: Array<string>,
    openingHours: ShopOpeningHours,
    categories: Array<ShopShiftCategory>,
    activities: Array<ShopShiftActivity>,
    linkedTeamId: string | undefined,
    totalStaffCount: number,
}