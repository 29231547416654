import {State} from "./UserShiftsSideView.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {SideView} from "components/dialogs/SideView/SideView";
import moment from "moment";

export const initialState: State = {
    userId: "",
    startDate: moment().startOf("day").toISOString(false),
    endDate: moment().startOf("day").add(30, "day").toISOString(false),
}

export const reducer = (config: {
    sideViewRef: MagicReducerRef<typeof SideView>,
}) => ({
    open: ({setState}, _, userId: string) => {
        setState({userId});
        config.sideViewRef.dispatch("open")();
    },
}) satisfies MagicReducerObject<State>;