import React, {memo, ReactElement} from "react";
import {Props} from "./RequestCard.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./RequestCard.reducer";
import {CompareModule} from "modules/Compare.module";
import "./RequestCard.styles.scss";
import {Button, Flex, Skeleton, Text} from "@fluentui/react-northstar";
import {UserThumbnail} from "../UserThumbnail/UserThumbnail";
import {useUserCache} from "../../../hooks/cache/useUsersCache";
import {RequestModule} from "../../../modules/Request.module";

export const RequestCard = memo((props: Props): ReactElement | null => {
    const [] = useMagicReducer(reducer, initialState);
    const {user} = useUserCache(props.request?.userId);

    if (!props.request) return (
        <Skeleton animation={"pulse"}>
            <Skeleton.Shape className={"request-card-skeleton"}/>
        </Skeleton>
    )

    const title = RequestModule.generateRequestTitle(props.request, user?.displayName);

    return (
        <Button
            primary={props.primary ?? false}
            fluid
            className={"request-card-btn"}
            {...(props.onClick && {onClick: props.onClick})}>
            <Flex fill vAlign={"center"} gap={"gap.small"} className={"request-card-content"}>
                <UserThumbnail userId={props.request?.userId} size={"small"}/>
                {!props.request ?
                    <Skeleton animation={"pulse"} className={"text-skeleton"}>
                        <Skeleton.Text styles={{minWidth: "100px", width: "100px", height: "20px"}}/>
                    </Skeleton>
                    :
                    <Flex fill column styles={{gap: "3px"}}>
                        <Text title={title} className={"no-shrink"} content={title} weight={"semibold"}/>
                        <Text content={RequestModule.generateRequestSubTitle(props.request)}/>
                    </Flex>
                }
            </Flex>
        </Button>
    )
}, CompareModule.areObjectsEqual);