import React, {memo, ReactElement} from "react";
import {Props} from "./BadgeErrorDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./BadgeErrorDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {AcceptIcon, Button, CloseIcon, Flex, Text} from "@fluentui/react-northstar";
import "./BadgeErrorDialog.styles.scss";
import {translations} from "../../../translations";
import {ClockingPlayIcon, ClockingStopIcon, InvalidQrCodeIcon} from "../../../assets/icons";
import {useBadgeErrorForm} from "../../../hooks/forms/useBadgeErrorForm";

export const BadgeErrorDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({props, dialogRef}), initialState, props.externalRef);

    const form = useBadgeErrorForm(state.isSubmitting);

    const mainColor = state.isStart ? "#5B5FC7" : "#C3314A";

    const clockingLabel = translations.get(state.isStart ? "ClockIn" : "ClockOut");
    const ClockingIcon = state.isStart ? ClockingPlayIcon : ClockingStopIcon;

    return (
        <Dialog
            className={"badge-error-dialog"}
            externalRef={dialogRef}
            width={500}
            onClose={dispatch("close", form)}
            popup
            content={
                <Flex fill column hAlign={"center"} gap={"gap.medium"}>
                    <Flex gap={"gap.smaller"} vAlign={"center"}>
                        <Flex className={"badge-error-clocking-icon"} style={{backgroundColor: mainColor}}>
                            <ClockingIcon color={"white"}/>
                        </Flex>
                        <Text weight={"semibold"} content={clockingLabel}/>
                    </Flex>
                    <Flex column hAlign={"center"} style={{gap: "3px"}}>
                        <InvalidQrCodeIcon color={mainColor} height={60} width={60}/>
                        <Text weight={"semibold"} size={"large"} content={translations.get("InvalidQrCode")}/>
                        <Text
                            content={translations.get(!state.showForm ? "DoYouWantToForceClocking" : "PleaseExplainTheReason")}/>
                    </Flex>
                    {state.showForm &&
                        <Flex className={"w-100"} style={{marginBottom: "25px"}}>
                            {form.renderForm()}
                        </Flex>
                    }
                    <Flex column className={"w-100"} gap={"gap.medium"}>
                        <Button
                            primary fluid icon={!state.showForm ? <AcceptIcon/> : <ClockingIcon/>}
                            content={!state.showForm ? translations.get("Continue") : clockingLabel}
                            onClick={!state.showForm ? dispatch("showForm") : dispatch("forceClocking", form)}
                            disabled={state.showForm && (!form.isValid || state.isSubmitting)}
                            loading={state.isSubmitting}
                        />
                        {!state.showForm &&
                            <Button
                                fluid icon={<CloseIcon/>} content={translations.get("Cancel")}
                                onClick={dialogRef.dispatch("close")}
                            />
                        }
                    </Flex>
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);