import {TokenResult} from "interfaces/TokenResult";
import {PublicClientApplication} from "@azure/msal-browser";

export type AuthReducerState = {
    inIframe: boolean,
    accessToken: TokenResult | undefined,
    isTeamsApp: boolean,
    msalInstance: PublicClientApplication | undefined,
    accessTokenInterval: NodeJS.Timeout | undefined,
    clientId: string | undefined,
}

export const AUTH_INIT = "auth/init";
export const AUTH_LOGIN = "auth/login";
export const AUTH_SIGN_OUT = "auth/signout";