import {translations} from "translations";
import {useForm} from "@witivio_teamspro/northstar-form";
import {AddIcon, Button, Flex, SaveIcon} from "@fluentui/react-northstar";
import React, {useLayoutEffect, useMemo, useState} from "react";
import {useShopCache} from "../cache/useShopsCache";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {ShopShiftActivity} from "../../interfaces/ShopData";

export const useShopActivityForm = (shopId: string | undefined, activity: ShopShiftActivity | undefined) => {
    const {shop, upsertActivity} = useShopCache(shopId);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useLayoutEffect(function onActivityChange() {
        form.setFieldsInitialValues({
            name: activity?.name,
        });
        form.reset();
        setIsLoading(false);
    }, [activity]);

    const activitiesNames = useMemo(() => shop?.activities.map(a => a.name.toLowerCase()) ?? [], [shop?.activities]);

    const form = useForm({
        disabled: isLoading,
        items: {
            name: {
                type: "input",
                inputMode: "text",
                label: translations.get("Name"),
                required: true,
                placeholder: translations.get("EnterName"),
                clearable: false,
                validate: name => !activitiesNames.includes((name + "").trim().toLowerCase()),
                errorMessage: translations.get("ActivityAlreadyExists"),
                autoFocus: true,
            },
        },
    });

    const {name} = form.formItems;

    const renderForm = () => (
        <Flex column fill gap={"gap.small"}>
            {name}
        </Flex>
    );

    const renderDoneButton = (onClick: (activity: Immutable<ShopShiftActivity> | undefined) => void) => (
        <Button
            fluid
            primary
            disabled={!form.isValid || !form.state.hasChanged || isLoading}
            loading={isLoading}
            content={translations.get(activity ? "Save" : "Add")}
            icon={activity ? <SaveIcon size={"large"} outline/> : <AddIcon outline/>}
            onClick={handleUpdateActivity({
                activityId: activity?.key,
                formState: form.state,
                callback: onClick,
                upsertActivity,
                setIsLoading,
            })}
        />
    );

    return {
        ...form,
        renderForm,
        renderDoneButton,
        isLoading,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleUpdateActivity = (config: {
    activityId: string | undefined,
    callback?: ((category: Immutable<ShopShiftActivity> | undefined) => void) | undefined,
    upsertActivity: ReturnType<typeof useShopCache>["upsertActivity"],
    formState: ReturnType<typeof useShopActivityForm>["state"],
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
}) => async () => {
    const {activityId, upsertActivity, formState, callback, setIsLoading} = config;
    const newActivity = generateShopActivity(activityId, formState);
    if (!newActivity) return;
    setIsLoading(true);
    await upsertActivity(newActivity);
    callback?.(newActivity);
    setIsLoading(false);
}

const generateShopActivity = (
    activityId: string | undefined,
    formState: ReturnType<typeof useShopActivityForm>["state"]
): Immutable<ShopShiftActivity> | undefined => {
    const {name} = formState.data;
    if (!name) return;
    return {key: activityId ?? "", name: name as string};
}