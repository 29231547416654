import {State} from "./RequestPreviewSideView.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {SideView} from "../../../components/dialogs/SideView/SideView";

export const initialState: State = {
    requestId: "",
}

export const reducer = (config: {
    sideViewRef: MagicReducerRef<typeof SideView>,
}) => ({
    open: ({setState}, _, requestId: string) => {
        setState({requestId});
        config.sideViewRef.dispatch("open")();
    },
}) satisfies MagicReducerObject<State>;