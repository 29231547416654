import {State} from "./ConfirmDeleteDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";

export const initialState: State = {
    title: "",
    subtitle: "",
    callback: undefined,
    isDeleting: false,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, {title, subtitle, callback}: Required<Omit<State, "isDeleting">>) => {
        setState({title, subtitle, callback, isDeleting: false});
        config.dialogRef.dispatch("open")();
    },
    confirm: async ({state, setState}) => {
        const result = state.callback?.();
        if (result instanceof Promise) {
            setState({isDeleting: true});
            await result;
        }
        config.dialogRef.dispatch("close")();
    },
}) satisfies MagicReducerObject<State>;