import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {TokenResult} from "../../interfaces/TokenResult";

const instance = axios.create();

const customRetryCountHeader = "axios-retry-count";

const setAxiosInstanceApiBaseUrl = (baseUrl: string) => {
    instance.defaults.baseURL = baseUrl;
}

const setAxiosRequestMiddleware = (accessToken: string, refreshToken: () => Promise<TokenResult | undefined>) => {
    instance.interceptors.request.use(async function (config) {
        if (!config.headers) config.headers = {};
        config.headers["Content-Type"] = "application/json";
        config.headers["Authorization"] = `Bearer ${accessToken}`;
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
    instance.interceptors.response.use(
        (response: AxiosResponse): AxiosResponse => response,
        async (error: AxiosError): Promise<AxiosResponse | void> => {
            const originalRequest: AxiosRequestConfig = {...error.config};
            const retryCount = Number(originalRequest.headers?.[customRetryCountHeader] ?? 0);
            // Retry 3 times after fail
            if (error.response?.status === 401 && retryCount < 2) {
                try {
                    await refreshToken();
                    if (!originalRequest.headers) originalRequest.headers = {[customRetryCountHeader]: retryCount};
                    originalRequest.headers[customRetryCountHeader] = retryCount + 1;
                    return instance(originalRequest);
                } catch (refreshError) {
                    return Promise.reject(refreshError);
                }
            }
            return Promise.reject(error);
        }
    );
}

export const AxiosConfig = {
    instance,
    setAxiosInstanceApiBaseUrl,
    setAxiosRequestMiddleware,
}