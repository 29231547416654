import {QueryClient, QueryKey, QueryObserverOptions, useQueries, useQuery, useQueryClient} from "react-query";
import {Immutable} from "@witivio_teamspro/use-reducer";
import moment from "moment";
import {WeekDaysModule} from "../../modules/WeekDays.module";
import {ObjectModule} from "../../modules/Object.module";
import {GuidModule} from "../../modules/Guid.module";
import {DateRange} from "../../components/others/DateRangeSelector/DateRangeSelector.interfaces";
import {PlanningApi} from "../../apis/Planning/PlanningApi";
import {ShiftApi} from "../../apis/Shift/ShiftApi";
import {QueryModule} from "../../modules/Query.module";
import {Shift} from "../../classes/Shift";
import {CopyPlanningRequest} from "../../interfaces/CopyPlanningRequest";
import {ShiftType} from "../../interfaces/ShiftData";
import {BusinessRuleException} from "../../interfaces/BusinessRuleException";

const staleTime = 600000; // 10 minutes

export const shiftsCacheKey = "shifts";

export const useUserShiftsCache = (userId: string | undefined, startDate: string | undefined, endDate: string | undefined, enabled: boolean = true) => {
    const queryClient = useQueryClient();

    const {data: shifts, isLoading, isFetching} = useQuery(getUserShiftsQueryArgs(userId, startDate, endDate, enabled));

    return {
        shifts,
        isLoading: isLoading || isFetching,
        getTotalShiftsTime: getTotalShiftsTime(queryClient, userId, startDate, endDate),
    };
}

export const useGroupShiftsCountCache = (groupId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    const {data: shifts, isLoading, isFetching} = useQuery(
        [shiftsCacheKey, "group", groupId, startDate, endDate],
        () => PlanningApi.getGroupUsersShiftsCountRange(groupId, startDate, endDate), {
            staleTime,
            enabled: !!groupId && !!startDate && !!endDate
        }
    );

    return {
        shifts,
        isLoading: isLoading || isFetching,
    };
}

export const useShiftsIdsCache = (shiftIds: Array<string>) => {
    const results = useQueries(shiftIds.map(shiftId => ({
        queryKey: [shiftsCacheKey, "shift", shiftId],
        queryFn: () => ShiftApi.getById(shiftId),
        staleTime,
        enabled: !!shiftId
    })));

    const shifts = results.map(r => r.data).filter(Boolean) as Immutable<Shift>[];

    const isLoading = results.some(r => r.isLoading || r.isFetching);

    return {
        shifts,
        isLoading,
    }
}

export const useUserGroupsShiftsCache = (startDate: string, endDate: string, enabled: boolean = true) => {
    const {data: shifts, isLoading, isFetching} = useQuery(
        [shiftsCacheKey, "user-groups", startDate, endDate],
        () => ShiftApi.getByUserGroup(startDate, endDate), {
            staleTime,
            enabled: enabled && !!startDate && !!endDate
        }
    );

    return {
        shifts,
        isLoading: isLoading || isFetching,
    };
}

export const useShiftsCache = () => {
    const queryClient = useQueryClient();

    return {
        replaceUserShifts: replaceUserShifts(queryClient),
        upsertShift: upsertShift(queryClient),
        deleteShift: deleteShift(queryClient),
        notifyShift: notifyShift(queryClient),
        notifyShiftsRange: notifyShiftsRange(queryClient),
        copyShiftsRange: copyShiftsRange(queryClient),
        updateClocking: updateClocking(queryClient),
        deleteShifts: deleteShifts(queryClient),
        getLocalUserShift: getLocalUserShift(queryClient),
        refetchUserShifts: refetchUserShifts(queryClient),
        refetchAllUserShifts: refetchAllUsersShifts(queryClient),
    }
}

export const useShopShiftsCache = (shopId: string | undefined, startDate: string | undefined, endDate: string | undefined, enabled: boolean = true) => {
    const {data: shifts, isLoading, isFetching} = useQuery(getShopShiftsQueryArgs(shopId, startDate, endDate, enabled));

    return {
        shifts,
        isLoading: isLoading || isFetching,
    };
}

export const useRotatingStaffsShiftsCache = (currentShopId: string | undefined, startDate: string | undefined, endDate: string | undefined, enabled: boolean = true) => {
    const {data: shifts, isLoading, isFetching} = useQuery({
        ...getShopShiftsQueryArgs(currentShopId, startDate, endDate, enabled),
        queryFn: () => PlanningApi.getRotatingStaffsShiftsRange(currentShopId, startDate, endDate),
    });

    return {
        shifts: !enabled ? undefined : shifts,
        isLoading: isLoading || isFetching,
    };
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

export const getUserShiftsQueryArgs = (userId: string | undefined, startDate: string | undefined, endDate: string | undefined, enabled: boolean = true): QueryObserverOptions<Array<Shift> | undefined> => ({
    queryKey: getUserShiftsCacheKey(userId, startDate, endDate),
    queryFn: () => PlanningApi.getUserShiftsRange(userId, startDate, endDate),
    staleTime,
    enabled: enabled && !!userId && !!startDate && !!endDate
});

export const getShopShiftsQueryArgs = (shopId: string | undefined, startDate: string | undefined, endDate: string | undefined, enabled: boolean = true): QueryObserverOptions<Array<Shift> | undefined> => ({
    queryKey: getShopShiftsCacheKey(shopId, startDate, endDate),
    queryFn: () => PlanningApi.getShopShiftsRange(shopId, startDate, endDate),
    staleTime,
    enabled: enabled && !!shopId && !!startDate && !!endDate
});

export const getUserShiftsCacheKey = (userId: string | undefined, startDate: string | undefined, endDate: string | undefined): QueryKey => {
    return [shiftsCacheKey, "user", userId, startDate, endDate];
}

export const getShopShiftsCacheKey = (shopId: string | undefined, startDate: string | undefined, endDate: string | undefined): QueryKey => {
    return [shiftsCacheKey, "shop", shopId, startDate, endDate];
}

const getLocalUserShifts = (queryClient: QueryClient, userId: string | undefined): [QueryKey, Immutable<Shift>[]][] => {
    if (!userId) return [];
    const cachePrefix: QueryKey = [shiftsCacheKey, "user", userId];
    return queryClient.getQueriesData<Immutable<Shift>[]>(cachePrefix);
};

const getLocalUserShiftsByShift = (queryClient: QueryClient, userId: string | undefined, shiftId: string | undefined) => {
    const userShifts = getLocalUserShifts(queryClient, userId);
    return userShifts.find(([, shifts]) => shifts.some(s => s.getId() === shiftId));
}

const getLocalUserShiftsByDate = (queryClient: QueryClient, userId: string | undefined, date: string | undefined) => {
    const userShifts = getLocalUserShifts(queryClient, userId);
    const momentDate = moment(date);
    return userShifts.find(([key]) => {
        const startDate = moment(key[3] as string);
        const endDate = moment(key[4] as string);
        return startDate <= momentDate && endDate >= momentDate;
    });
}

const getLocalUserShiftsByRange = (queryClient: QueryClient, userId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    return queryClient.getQueryData<Immutable<Array<Shift>>>([shiftsCacheKey, "user", userId, startDate, endDate]);
}

const getLocalUserShift = (queryClient: QueryClient) => (shiftId: string | undefined) => {
    if (!shiftId) return;
    const allShifts = queryClient.getQueriesData<Immutable<Shift>[] | undefined>([shiftsCacheKey, "user"]);
    return allShifts.flatMap(([, shifts]) => shifts).find(s => s?.getId() === shiftId);
}

const deleteLocalShifts = (queryClient: QueryClient, shiftsIds: Array<string>) => {
    const allShopsShifts = queryClient.getQueriesData<Immutable<Shift>[] | undefined>([shiftsCacheKey, "shop"]);
    const allUsersShifts = queryClient.getQueriesData<Immutable<Shift>[] | undefined>([shiftsCacheKey, "user"]);
    [...allUsersShifts, ...allShopsShifts].forEach(([key, shifts]) => {
        const prevLength = shifts?.length ?? 0;
        const newShifts = (shifts ?? []).filter(s => !shiftsIds.includes(s.getId()));
        if (prevLength === newShifts.length) return;
        queryClient.setQueryData(key, newShifts);
    });
}

const updateLocalUserShift = (queryClient: QueryClient, shift: Immutable<Shift>) => {
    const allShopsShifts = queryClient.getQueriesData<Immutable<Shift>[] | undefined>([shiftsCacheKey, "shop"]);
    const allUsersShifts = queryClient.getQueriesData<Immutable<Shift>[] | undefined>([shiftsCacheKey, "user"]);
    const shiftUserId = shift.getUserId();
    const shiftStartDate = moment(shift.getDate());
    [...allUsersShifts, ...allShopsShifts].forEach(([key, shifts]) => {
        const userId = key[2] as string;
        const startDate = moment(key[3] as string);
        const endDate = moment(key[4] as string);
        const shiftIndex = shifts?.findIndex(s => s.getId() === shift.getId()) ?? -1;
        const isShiftExisting = shiftIndex !== -1;
        const isUserAssignedToShift = userId === shiftUserId;
        const isShiftInDateRange = startDate <= shiftStartDate && endDate >= shiftStartDate;
        if (!isShiftExisting && !isUserAssignedToShift) return;
        let newShifts = [...(shifts ?? [])];
        if (!isShiftExisting && isUserAssignedToShift && isShiftInDateRange) {
            newShifts.push(shift.clone());
        } else if (isShiftExisting && (!isUserAssignedToShift || !isShiftInDateRange)) {
            newShifts = newShifts.filter(s => s.getId() !== shift.getId());
        } else if (isShiftExisting && isUserAssignedToShift && isShiftInDateRange) {
            newShifts[shiftIndex] = shift.clone();
        }
        queryClient.setQueryData(key, newShifts);
    });
}

const getTotalShiftsTime = (queryClient: QueryClient, userId: string | undefined, startDate: string | undefined, endDate: string | undefined) => (): number => {
    const shifts = getLocalUserShiftsByRange(queryClient, userId, startDate, endDate);
    const count = shifts?.reduce((totalTime, shift) => {
        if (shift.getType() === ShiftType.Absence) return totalTime;
        const start = shift.getStart();
        const end = shift.getEnd();
        if (!start || !end) return totalTime;
        const startTime = moment().startOf("day").add(start.hour, "hours").add(start.minutes, "minutes");
        const endTime = moment().startOf("day").add(end.hour, "hours").add(end.minutes, "minutes");
        let timeDiff = endTime.diff(startTime, "hours", true);
        if (timeDiff < 0) timeDiff = 0;
        return totalTime + timeDiff;
    }, 0) ?? 0;
    return Math.round(count * 10) / 10;
}

const replaceUserShifts = (queryClient: QueryClient) => async (config: {
    userId: string,
    dateRange: Immutable<DateRange>,
    shifts: Immutable<Array<Shift | undefined>>,
    beginReplaceDate: string,
}): Promise<Array<BusinessRuleException>> => {
    const {userId, dateRange, shifts, beginReplaceDate} = config;
    const userShifts = getLocalUserShiftsByRange(queryClient, userId, dateRange.startDate, dateRange.endDate);
    if (!userShifts) return [];
    let newUserShifts = [...userShifts];
    const rangeDays = WeekDaysModule.getRangeDays(beginReplaceDate, dateRange.endDate);
    const deletePromises: Record<string, ReturnType<typeof ShiftApi.deleteShift>> = {};
    const createPromises: Record<string, ReturnType<typeof ShiftApi.create>> = {};
    rangeDays.forEach((day, dayIndex) => {
        if (!shifts[dayIndex]) return;
        const replacedShift = new Shift({id: "", preShift: {...shifts[dayIndex]!.flatten(), clocking: undefined}});
        replacedShift.setField("date", day.toISOString(false));
        replacedShift.setField("userId", userId);
        replacedShift.setField("recurrence", undefined);
        replacedShift.setField("id", "replaced-shift-" + GuidModule.generateGUID());
        const existingShifts = newUserShifts.filter(s => moment(s.getDate()).isSame(day, "day"));
        if (existingShifts.length > 0) {
            existingShifts.forEach(shift => {
                deletePromises[shift.getId()] = ShiftApi.deleteShift(shift.getShopId(), shift.getId(), false);
            });
            const existingShiftsIds = existingShifts.map(s => s.getId());
            newUserShifts = newUserShifts.filter(s => !existingShiftsIds.includes(s.getId()));
        }
        createPromises[replacedShift.getId()] = ShiftApi.create(replacedShift);
        newUserShifts.push(replacedShift);
    });
    const userShiftsCacheKey = getUserShiftsCacheKey(userId, dateRange.startDate, dateRange.endDate);
    queryClient.setQueryData(userShiftsCacheKey, newUserShifts, QueryModule.ignoredQueryEventOptions);
    let isValid = true;
    const exceptions = new Array<BusinessRuleException>();
    const deleteResults = await Promise.all(Object.values(deletePromises));
    await new Promise(resolve => setTimeout(resolve, 100));
    isValid = deleteResults.every(Boolean);
    if (isValid) {
        const createResults = await Promise.all(Object.values(createPromises));
        Object.keys(createPromises).forEach((shiftId, index) => {
            const result = createResults[index];
            if (!result) isValid = false;
            if (typeof result !== "object") return;
            if (result.shiftsIds.length === 0 && result.exceptions.length > 0) {
                isValid = false;
                exceptions.push(...result.exceptions);
                return;
            }
            const shiftIndex = newUserShifts.findIndex(s => s.getId() === shiftId);
            if (shiftIndex === -1) return;
            newUserShifts[shiftIndex]!.setField("id", result.shiftsIds[0]!);
        });
    }
    queryClient.setQueryData(userShiftsCacheKey, !isValid ? [...userShifts] : [...newUserShifts]);
    return exceptions;
}

const upsertShift = (queryClient: QueryClient) => async (config: {
    prevShift: Immutable<Shift> | undefined,
    newShift: Immutable<Shift>,
    isSeriesSelected: boolean,
}): Promise<Array<BusinessRuleException>> => {
    const {newShift, prevShift, isSeriesSelected} = config;
    if (prevShift) {
        const updatedFields = ObjectModule.findUpdatedFields(prevShift.flatten(), newShift.flatten());
        const result = await ShiftApi.update(newShift.getShopId(), newShift.getId(), updatedFields, isSeriesSelected);
        if (!result) return [];
        if (result?.exceptions.length > 0) return result?.exceptions ?? [];
        if (isSeriesSelected) await refetchUserShifts(queryClient)({
            userId: newShift.getUserId(),
            date: newShift.getDate()
        });
        else updateLocalUserShift(queryClient, newShift);
    } else {
        const result = await ShiftApi.create(newShift);
        if (result?.shiftsIds.length === 0) return result?.exceptions ?? [];
        if (!newShift.getRecurrence() && result?.shiftsIds.length === 1) {
            const newShiftWithId = new Shift({...newShift.get(), id: result?.shiftsIds[0]!});
            newShiftWithId.setField("id", result?.shiftsIds[0]!);
            updateLocalUserShift(queryClient, newShiftWithId);
        } else {
            await refetchUserShifts(queryClient)({
                userId: newShift.getUserId(),
                date: newShift.getDate()
            });
        }
    }
    return [];
}

const refetchUserShifts = (queryClient: QueryClient) => async (config: {
    userId: string | undefined,
    date: string | undefined
}) => {
    const {userId, date} = config;
    if (!userId || !date) return;
    let allRanges = getLocalUserShifts(queryClient, userId);
    const activeRange = getLocalUserShiftsByDate(queryClient, userId, date);
    if (activeRange) {
        allRanges = allRanges.filter(([key]) => key !== activeRange[0]);
        await queryClient.invalidateQueries(activeRange[0], {exact: true});
    }
    allRanges.forEach(([key]) => queryClient.removeQueries(key, {exact: true}));
}

const refetchAllUsersShifts = (queryClient: QueryClient) => async () => {
    await queryClient.invalidateQueries([shiftsCacheKey, "user"]);
}

const updateClocking = (queryClient: QueryClient) => async (config: {
    shift: Immutable<Shift>,
}) => {
    const {shift} = config;
    const result = await ShiftApi.updateClocking(shift.getShopId(), shift.getId(), shift.getClocking());
    if (!result) return;
    updateLocalUserShift(queryClient, shift);
}

const deleteShift = (queryClient: QueryClient) => async (config: {
    shiftId: string,
    deleteSeries: boolean,
}) => {
    const {shiftId, deleteSeries} = config;
    const shift = getLocalUserShift(queryClient)(shiftId);
    if (!shift) return;
    const result = await ShiftApi.deleteShift(shift.getShopId(), shiftId, deleteSeries);
    if (!result) return;
    if (deleteSeries) {
        await refetchUserShifts(queryClient)({userId: shift.getUserId(), date: shift.getDate()});
    } else {
        deleteLocalShifts(queryClient, [shiftId]);
    }
}

const deleteShifts = (queryClient: QueryClient) => async (config: {
    shiftsIds: string[],
}) => {
    const shifts = config.shiftsIds.map(shiftId => getLocalUserShift(queryClient)(shiftId)).filter(Boolean) as Array<Shift>;
    const tasks = shifts.map(shift => ShiftApi.deleteShift(shift.getShopId(), shift.getId(), false));
    const results = await Promise.all(tasks);
    const deletedShiftsIds = results.map((isDeleted, index) => isDeleted ? config.shiftsIds[index] : undefined).filter(Boolean) as Array<string>;
    deleteLocalShifts(queryClient, deletedShiftsIds);
}

const notifyShift = (queryClient: QueryClient) => async (config: {
    shopId: string | undefined,
    shift: Immutable<Shift>,
}) => {
    const {shift, shopId} = config;
    if (!shopId) return;
    const result = await ShiftApi.notifyShift(shopId, shift.getId());
    if (!result) return;
    updateLocalUserShift(queryClient, shift.clone().applyUpdates())
}

const notifyShiftsRange = (queryClient: QueryClient) => async (config: {
    shopId: string,
    startDate: string,
    endDate: string,
    notifyConcernedMembers: boolean,
}) => {
    const cachePrefix: QueryKey = [shiftsCacheKey, "user"];
    const usersShifts = queryClient.getQueriesData<Immutable<Shift>[] | undefined>(cachePrefix);
    if (usersShifts.length === 0) return;
    const {shopId, startDate, endDate, notifyConcernedMembers} = config;
    const result = await PlanningApi.sharePlanning(shopId, {startDate, endDate, notifyConcernedMembers});
    if (!result) return;
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);
    usersShifts.filter(i => !!i[1]).forEach(([key, shifts]) => {
        const updatedShifts = shifts!.filter(s => {
            const date = moment(s.getDate());
            return !s.isSynchronized() && momentStartDate <= date && momentEndDate >= date;
        }).map(s => s.clone().applyUpdates());
        if (updatedShifts.length === 0) return;
        const newShifts = shifts!.map(s => updatedShifts.find(us => us.getId() === s.getId()) ?? s);
        queryClient.setQueryData(key, newShifts);
    });
}

const copyShiftsRange = (queryClient: QueryClient) => async (config: {
    shopId: string | undefined,
    copyRequest: CopyPlanningRequest,
}) => {
    const {shopId, copyRequest} = config;
    const shifts = await PlanningApi.copyPlanning(shopId, copyRequest);
    if (!shifts) return;
    shifts.forEach(shift => updateLocalUserShift(queryClient, shift));
}

const removeAllGroupShifts = (queryClient: QueryClient) => async () => {
    queryClient.removeQueries([shiftsCacheKey, "group"]);
}

const clearShopShiftsCache = (queryClient: QueryClient) => (shopId: string) => {
    queryClient.removeQueries([shiftsCacheKey, "shop", shopId]);
}

export const ShiftsCache = {
    refetchUserShifts,
    removeAllGroupShifts,
    clearShopShiftsCache,
}