import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./UserShiftsSideView.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./UserShiftsSideView.reducer";
import {CompareModule} from "modules/Compare.module";
import {Flex} from "@fluentui/react-northstar";
import "./UserShiftsSideView.styles.scss";
import {SideView} from "components/dialogs/SideView/SideView";
import {translations} from "../../../../translations";
import {useUserShiftsCache} from "../../../../hooks/cache/useShiftsCache";
import {useMsTeamsSelector} from "../../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {useUserCache} from "../../../../hooks/cache/useUsersCache";
import {UserThumbnail} from "../../../../components/others/UserThumbnail/UserThumbnail";
import {ShiftsList} from "../ShiftsList/ShiftsList";
import {Shift} from "../../../../classes/Shift";

export const UserShiftsSideView = memo((props: Props): ReactElement | null => {
    const {userId} = useMsTeamsSelector("userId");
    const sideViewRef = useMagicReducerRef(SideView);
    const [state, dispatch] = useMagicReducer(reducer({sideViewRef}), initialState, props.externalRef);
    const {shifts} = useUserShiftsCache(state.userId, state.startDate, state.endDate);
    const {user} = useUserCache(state.userId);

    const isCurrentUserShifts = state.userId === userId;
    const title = isCurrentUserShifts ? translations.get("AllMyShifts") : `${user?.displayName ?? ""} - ${translations.get("Shifts")}`;
    const userIcon = isCurrentUserShifts ? undefined : (
        <Flex styles={{marginTop: "2px"}}>
            <UserThumbnail userId={state.userId} size={"smaller"}/>
        </Flex>
    );

    const shiftsWithoutPreshifts = useMemo(() => {
        return shifts?.map(s => new Shift({...s.get(), preShift: undefined}));
    }, [shifts]);

    return (
        <SideView externalRef={sideViewRef} title={title} icon={userIcon} style={{zIndex: 10}}>
            <ShiftsList shiftDetailsSideViewRef={props.shiftDetailsSideViewRef} shifts={shiftsWithoutPreshifts}/>
        </SideView>
    )
}, CompareModule.areObjectsEqual);