import {MagicReducerExternalRef} from "@witivio_teamspro/use-reducer";
import {reducer} from "./CreateRequestDialog.reducer";
import {DialogProps} from "../Dialog/Dialog.interfaces";

export enum CreateRequestDialogTab {
    TimeOff = 0,
    Swap = 1,
    UpdateClocking = 2,
}

export type State = {
    activeTab: CreateRequestDialogTab,
    onBeforeClose: DialogProps["onBeforeClose"],
}

export type Props = {
    externalRef: MagicReducerExternalRef<typeof reducer>,
}