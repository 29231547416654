import React, {ReactElement} from "react";
import {Props} from "./LineChartKpi.interfaces";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import {
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Filler,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import {Line} from "react-chartjs-2";
import {translations} from "../../../../translations";
import {useMsTeamsSelector} from "../../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import variables from "variables.module.scss";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
);

export const LineChartKpi = (props: Props): ReactElement | null => {
    const {isLightTheme} = useMsTeamsSelector("isLightTheme");
    return (
        <Flex column fill className={"overflow-hidden"}>
            {!props.title ? null : (
                <Flex>
                    <Text weight={"semibold"} size={"large"} content={props.title}/>
                </Flex>
            )}
            {props.showSkeletons ?
                <Loader styles={{height: "100%"}}/>
                :
                <Flex fill className={"pos-relative"} styles={{marginTop: "20px"}}>
                    <Flex className={"fill-absolute"} vAlign={"center"} hAlign={"center"}>
                        {renderLineChart(props, isLightTheme)}
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderLineChart = (props: Props, isLightTheme: boolean) => {
    const noDataAvailable = props.values.length === 0;
    if (props.showSkeletons) return null;
    if (noDataAvailable) return (
        <Text size={"large"} content={translations.get("NoDataAvailable")}/>
    )
    return renderLineChartContent({
        xLabels: props.labels,
        data: props.values,
        isLightTheme,
    });
}

const renderLineChartContent = (data: { xLabels: Array<string>, data: Array<number>, isLightTheme: boolean }) => {
    const chartTextColor = data.isLightTheme ? "#252423" : "white";
    const chartGridColor = data.isLightTheme ? "rgb(237, 235, 233)" : "rgb(91, 91, 91)";
    const chartLabels = data.xLabels;
    const chartOptions: ChartOptions<"line"> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    color: chartGridColor,
                },
                ticks: {
                    maxTicksLimit: 6,
                    color: chartTextColor,
                    font: {
                        family: 'Segoe UI',
                        size: 16,
                    },
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: chartGridColor,
                },
                ticks: {
                    maxTicksLimit: 5,
                    stepSize: 1,
                    color: chartTextColor,
                    font: {
                        family: 'Segoe UI',
                        size: 16,
                    },
                },
            },
        }
    };
    const chartData: ChartData<"line"> = {
        labels: chartLabels,
        datasets: [{
            data: data.data,
            borderColor: data.isLightTheme ? variables.blueColorLight : variables.blueColorDark,
            backgroundColor: data.isLightTheme ? variables.blueColorLight : variables.blueColorDark,
            pointRadius: 5,
        }]
    };
    return <Line options={chartOptions} data={chartData}/>;
}