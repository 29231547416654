import {useQuery, useQueryClient} from "react-query";
import {PlanningApi} from "../../apis/Planning/PlanningApi";
import {useEffect} from "react";
import {QueryModule} from "../../modules/Query.module";

export const staffsCountCacheKey = "staffsCount";

export const usePlanningStaffInfoCache = (shopId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    const queryClient = useQueryClient();

    const cacheKey = [staffsCountCacheKey, "planning", shopId, startDate, endDate];

    const {data: staffInfo, isLoading} = useQuery(
        cacheKey, () => PlanningApi.getPlanningStaffInfo(shopId, startDate, endDate), {
            staleTime: Infinity,
            enabled: !!shopId && !!startDate && !!endDate
        }
    );

    useEffect(function listenQueriesUpdates() {
        return queryClient.getQueryCache().subscribe(async (event) => {
            if (!event || QueryModule.isQueryEventIgnored(event)) return;
            if (!QueryModule.isUserShiftsQueryUpdate(event)) return;
            await queryClient.invalidateQueries(cacheKey);
        });
    }, [queryClient, cacheKey]);

    return {
        staffInfo,
        isLoading,
    };
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////