import {PlanningConfigurationDialogTab, State} from "./PlanningConfigurationDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import React from "react";
import {MenuItemProps} from "@fluentui/react-northstar";
import {DialogProps} from "../Dialog/Dialog.interfaces";

export const initialState: State = {
    shopId: "",
    activeTab: PlanningConfigurationDialogTab.Shop,
    onBeforeClose: undefined,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, {shopId, tab}: { shopId: string, tab?: PlanningConfigurationDialogTab }) => {
        setState({...initialState, shopId, activeTab: tab ?? PlanningConfigurationDialogTab.Shop});
        config.dialogRef.dispatch("open")();
    },
    changeTab: ({setState}, [, data]: [React.SyntheticEvent, MenuItemProps | undefined]) => {
        const index = data?.index;
        if (index === undefined) return;
        setState({activeTab: index});
    },
    setBeforeClose: ({setState}, [onBeforeClose]: [DialogProps["onBeforeClose"]]) => {
        setState({onBeforeClose});
    }
}) satisfies MagicReducerObject<State>;