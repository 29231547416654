import {useQuery} from "react-query";
import {ReportApi} from "../../apis/Report/ReportApi";

export const groupReportCacheKey = "groupReport";

export const useGroupReportCache = (groupId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    const {
        data: groupReport,
        isLoading
    } = useQuery([groupReportCacheKey, groupId, startDate, endDate], () => ReportApi.getGroupReport(groupId, startDate, endDate), {
        staleTime: Infinity,
        enabled: !!groupId && !!startDate && !!endDate
    });

    return {
        groupReport,
        isLoading,
    }
}