import {State} from "./ShopActivityDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {ConfirmCancelChangesDialog} from "../ConfirmCancelChangesDialog/ConfirmCancelChangesDialog";
import {useShopActivityForm} from "../../../hooks/forms/useShopActivityForm";

export const initialState: State = {
    shopId: undefined,
    activity: undefined,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
    confirmCancelChangesDialog: MagicReducerRef<typeof ConfirmCancelChangesDialog>,
}) => ({
    open: ({setState}, _, {shopId, activity}: State) => {
        setState({shopId, activity});
        config.dialogRef.dispatch("open")();
    },
    reset: ({setState}, _, form: ReturnType<typeof useShopActivityForm>) => {
        setState({...initialState});
        form.reset();
    },
    beforeClose: ({}, [callback]: [(close: boolean) => void], hasChanged: boolean) => {
        if (!hasChanged) return callback(true);
        config.confirmCancelChangesDialog.dispatch("open", callback)();
    }
}) satisfies MagicReducerObject<State>;