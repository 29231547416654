import React, {ReactElement, useEffect} from "react";
import {MagicDispatch, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Reports.reducer";
import "./Reports.styles.scss";
import {Button, Flex} from "@fluentui/react-northstar";
import {Toolbar} from "./Toolbar/Toolbar";
import {useShopsCache} from "../../hooks/cache/useShopsCache";
import {ShopAccordion} from "../../components/others/ShopAccordion/ShopAccordion";
import {ArrowTrendingLinesIcon} from "../../assets/icons";
import {ShopGroupsReports} from "./ShopGroupsReports/ShopGroupsReports";
import {useDialogContext} from "../../services/DialogContext/DialogContext";
import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {ReportsMobile} from "./ReportsMobile/ReportsMobile";
import {useUserRolesCache} from "../../hooks/cache/useUserRoleCache";
import {LoadingPage} from "../LoadingPage/LoadingPage";
import {UserRole} from "../../interfaces/UserRole";
import {NoShopPage} from "../NoShopPage/NoShopPage";

export const Reports = (): ReactElement => {
    const {isOnMobile, userId} = useMsTeamsSelector("isOnMobile", "userId");
    const {shopReportDialog, userReportDialog} = useDialogContext();
    const toolbarRef = useMagicReducerRef(Toolbar);
    const {isOnlyRole, isLoading} = useUserRolesCache();
    const [state, dispatch, render] = useMagicReducer(reducer({shopReportDialog}), initialState);
    const {shops, isLoading: areShopsLoading} = useShopsCache();

    const isOnlyStaff = isOnlyRole(UserRole.Staff);

    const hasNoShops = !areShopsLoading && !shops?.length;

    useEffect(() => {
        if (isLoading || !isOnlyStaff) return;
        userReportDialog.dispatch("open", userId)();
    }, [isLoading]);

    if (isOnMobile) return <ReportsMobile/>;

    if (isLoading) return <LoadingPage/>;

    if (hasNoShops) return <NoShopPage/>;

    const shopsItems = shops?.map((s) => isOnlyStaff ? null : (
        <ShopAccordion
            key={s.id} shopId={s.id} defaultOpen
            headerContent={renderShopAccordionHeaderContent(dispatch, s.id)}>
            <ShopGroupsReports shopId={s.id}/>
        </ShopAccordion>
    ));

    return (
        <Flex fill column className={"fade"}>
            {!isOnlyStaff &&
                <Toolbar externalRef={toolbarRef}/>
            }
            {(isOnlyStaff || !shopsItems) ? <LoadingPage/> :
                <Flex fill column className={"overflow-scroll"}>
                    {shopsItems}
                </Flex>
            }
        </Flex>
    )
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderShopAccordionHeaderContent = (dispatch: MagicDispatch<typeof reducer>, shopId: string) => {
    return (
        <Button iconOnly text icon={<ArrowTrendingLinesIcon/>} onClick={dispatch("openShopReport", shopId)}/>
    )
}