import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./Toolbar.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Toolbar.reducer";
import {CompareModule} from "modules/Compare.module";
import "./Toolbar.styles.scss";
import {AddIcon, Button, Flex,} from "@fluentui/react-northstar";
import {translations} from "translations";
import {useDialogContext} from "services/DialogContext/DialogContext";
import {SettingsIcon} from "../../../assets/icons";

export const Toolbar = memo((props: Props): ReactElement | null => {
    const dialogContext = useDialogContext();
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    const addShopButton = useMemo(() => (
        <Button
            className={"no-shrink"} primary icon={<AddIcon/>}
            content={translations.get("AddShop")}
            onClick={dialogContext.shopDialog.dispatch("open")}
        />
    ), []);

    const settingsButton = useMemo(() => (
        <Button
            className={"no-shrink"} text icon={<SettingsIcon/>}
            content={translations.get("Settings")}
            onClick={dialogContext.settingsDialog.dispatch("open")}
        />
    ), []);

    return (
        <Flex className={"toolbar"}>
            {addShopButton}
            <Flex fill/>
            {settingsButton}
        </Flex>
    )
}, CompareModule.areObjectsEqual);