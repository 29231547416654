import {State} from "./AddRequestSideView.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {SideView} from "../../../components/dialogs/SideView/SideView";
import React from "react";
import {MenuItemProps} from "@fluentui/react-northstar";
import {CreateRequestDialogTab} from "../../../components/dialogs/CreateRequestDialog/CreateRequestDialog.interfaces";

export const initialState: State = {
    activeTab: CreateRequestDialogTab.Swap
}

export const reducer = (config: {
    sideViewRef: MagicReducerRef<typeof SideView>,
}) => ({
    open: ({setState}) => {
        setState({...initialState});
        config.sideViewRef.dispatch("open")();
    },
    changeTab: ({setState}, [, data]: [React.SyntheticEvent, MenuItemProps | undefined]) => {
        const tabId = (data as any)?.id;
        if (tabId === undefined) return;
        setState({activeTab: tabId});
    },
}) satisfies MagicReducerObject<State>;