import React, {memo, ReactElement} from "react";
import {Props} from "./ShopGroupsReports.interfaces";
import {Immutable, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShopGroupsReports.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ShopGroupsReports.styles.scss";
import {Flex, Skeleton, Text} from "@fluentui/react-northstar";
import {useShopGroupsCache} from "../../../hooks/cache/groups/useShopGroupsCache";
import {ShopGroupReport} from "./ShopGroupReport/ShopGroupReport";
import {translations} from "../../../translations";
import {GroupData} from "../../../interfaces/GroupData";

export const ShopGroupsReports = memo((props: Props): ReactElement | null => {
    const [] = useMagicReducer(reducer, initialState);
    const {groups, isLoading} = useShopGroupsCache(props.shopId);

    return (
        <Flex fill className={"shops-groups-reports flex-grid"}>
            {isLoading ? renderSkeletons() : renderGroups(props.shopId, groups)}
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderSkeletons = () => {
    return Array.from({length: 3}).map((_, i) => (
        <Skeleton key={"skeleton" + i} animation={"pulse"}>
            <Skeleton.Shape style={{borderRadius: "6px"}} height={"200px"} width={"100%"}/>
        </Skeleton>
    ));
}

const renderEmptyContent = () => {
    return <Text style={{color: "darkgray"}} content={translations.get("NoDataAvailable")}/>
}

const renderGroups = (shopId: string | undefined, groups: Immutable<GroupData>[] | undefined) => {
    const filteredGroups = groups?.filter(g => g.usersIds.length > 0);
    if (!filteredGroups || filteredGroups.length === 0) return renderEmptyContent();
    return filteredGroups.map((g) => (
        <ShopGroupReport key={g.id} shopId={shopId} groupId={g.id}/>
    ));
}