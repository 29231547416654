import {State} from "./SwapPart.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {ConfirmCancelChangesDialog} from "../../../ConfirmCancelChangesDialog/ConfirmCancelChangesDialog";
import {useSwapRequestForm} from "../../../../../hooks/forms/useSwapRequestForm";

export const initialState: State = {}

export const reducer = (config: {
    confirmCancelChangesDialog: MagicReducerRef<typeof ConfirmCancelChangesDialog>,
    form: ReturnType<typeof useSwapRequestForm>,
}) => ({
    beforeClose: ({}, [callback]: [(close: boolean) => void]) => {
        const hasChanged = config.form.state.hasChanged;
        if (!hasChanged) return callback(true);
        config.confirmCancelChangesDialog.dispatch("open", callback)();
    }
}) satisfies MagicReducerObject<State>;