import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./PeopleButton.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./PeopleButton.reducer";
import {CompareModule} from "modules/Compare.module";
import "./PeopleButton.styles.scss";
import {Button, CallIcon, ChatIcon, Flex, Skeleton, Text, TrashCanIcon} from "@fluentui/react-northstar";
import {useUserCache} from "hooks/cache/useUsersCache";
import {UserThumbnail} from "components/others/UserThumbnail/UserThumbnail";
import {PopupMenuButton} from "components/buttons/PopupMenuButton/PopupMenuButton";
import {translations} from "translations";
import {UserModule} from "modules/User.module";

export const PeopleButton = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState);
    const {user, isLoading} = useUserCache(props.userId);

    const className = [
        "people-button",
        props.fluid && "people-button-fluid",
        props.danger && "people-button-danger",
    ].filter(Boolean).join(" ");

    const trigger = (
        <Button
            ref={dispatch("buttonRef")}
            key={props.userId}
            fluid={props.fluid ?? false}
            styles={{height: "fit-content", minHeight: "2rem"}}
            className={className}
            {...(props.onClick && {onClick: props.onClick})}>
            <Flex fill vAlign={"center"} gap={"gap.small"} styles={props.styles ?? {}}>
                <UserThumbnail userId={props.userId} size={props.pictureSize ?? "smallest"}/>
                {isLoading ?
                    <Skeleton animation={"pulse"} className={"text-skeleton"}>
                        <Skeleton.Text styles={{minWidth: "100px", width: "100px", height: "20px"}}/>
                    </Skeleton>
                    :
                    <Flex fill column styles={{gap: "3px"}} vAlign={"center"}>
                        <Text align={"start"} content={user?.displayName} weight={"semibold"}/>
                        {props.subContent}
                    </Flex>
                }
            </Flex>
        </Button>
    );

    const menu = useMemo(() => props.onClick ? [] : [
        {
            key: "chat",
            icon: <ChatIcon outline/>,
            content: translations.get("Chat"),
            onClick: () => UserModule.startChat(user?.userPrincipalName)
        },
        {
            key: "call",
            icon: <CallIcon outline/>,
            content: translations.get("Call"),
            onClick: () => UserModule.startCall(user?.userPrincipalName)
        },
        ...(!props.onDelete ? [] : [
            {
                key: "divider",
                kind: "divider"
            },
            {
                key: "delete",
                icon: <TrashCanIcon styles={{color: "red !important"}} outline/>,
                content: translations.get("Delete"),
                onClick: props.onDelete,
                styles: {color: "red !important"}
            }
        ])
    ], [user, props.onClick, props.onDelete]);

    if (props.onClick) return trigger;

    return (
        <PopupMenuButton
            trigger={trigger}
            position={"below"}
            offset={[0, -2]}
            menu={{
                items: menu,
                styles: {width: state.buttonWidth + "px"}
            }}
        />
    )
}, CompareModule.areObjectsEqual);