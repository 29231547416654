import {State} from "./SelectStep.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";

export const initialState: State = {}

export const reducer = {
    downloadTemplate: () => {
        const link = document.createElement("a");
        link.href = "/assets/files/planning_template.xlsx";
        link.download = "planning_template.xlsx";
        link.click();
        link.remove();
    },
} satisfies MagicReducerObject<State>;