import React, {memo, ReactElement, useCallback, useMemo} from "react";
import {Props} from "./CategoriesTable.interfaces";
import {Immutable, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./CategoriesTable.reducer";
import {CompareModule} from "modules/Compare.module";
import "./CategoriesTable.styles.scss";
import {useCategoriesTableColumns} from "./hooks/useCategoriesTableColumns";
import {EditIcon, Flex, LockIcon, MoreIcon, TrashCanIcon} from "@fluentui/react-northstar";
import {Table} from "../Table/Table";
import {ShopShiftCategory} from "interfaces/ShopData";
import {useShopCache} from "hooks/cache/useShopsCache";
import {TableProps} from "../Table/Table.interfaces";
import {translations} from "../../../translations";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";

export const CategoriesTable = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState);
    const {deleteCategory} = useShopCache(props.shopId);
    const {shopShiftCategoryDialog, confirmDeleteDialog} = useDialogContext();

    const handleEditCategory = useCallback((category: Immutable<ShopShiftCategory> | null) => {
        if (!category || category.shopId !== props.shopId) return;
        shopShiftCategoryDialog.dispatch("open", {
            shopId: props.shopId,
            type: props.type,
            category,
        })();
    }, [props.shopId, props.type]);

    const columns = useCategoriesTableColumns(props.shopId, handleEditCategory, props.type);

    const items = (props.items.filter(c => c.type === props.type) ?? []) as Array<Immutable<ShopShiftCategory>>;

    const actions: TableProps<ShopShiftCategory>["actions"] = useMemo(() => ({
        items: [
            {
                label: translations.get("Edit"),
                icon: <EditIcon outline/>,
                onClick: handleEditCategory,
            },
            {
                label: translations.get("Delete"),
                icon: <TrashCanIcon outline/>,
                onClick: c => confirmDeleteDialog.dispatch("open", {
                    title: translations.get("DeleteCategory"),
                    subtitle: translations.get("AreYouSureToDeleteThisCategory", {name: c?.name ?? ""}),
                    callback: () => deleteCategory(c?.key),
                })(),
            }
        ],
        icon: renderActionsIcon(props.shopId),
        disabled: i => i?.shopId !== props.shopId,
    }), [handleEditCategory, props.shopId]);

    if (items.length === 0) return null;

    return (
        <Flex fill className={"shop-categories-table"}>
            <Table<ShopShiftCategory>
                items={items}
                columns={columns}
                actions={actions}
                sortLocally
            />
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderActionsIcon = (shopId: string) => (items: Immutable<ShopShiftCategory> | null) => {
    if (shopId !== items?.shopId) return <LockIcon/>;
    return <MoreIcon/>;
}