import {State} from "./LegalRulesPart.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {DialogContextValue} from "../../../../../services/DialogContext/DialogContext.interfaces";
import {IGraphUserData} from "@witivio_teamspro/northstar-form/dist/cjs/interfaces/IGraphUserData";
import {translations} from "../../../../../translations";

export const initialState: State = {}

export const reducer = (config: {
    groupId: string,
    personLegalRulesDialog: DialogContextValue["personLegalRulesDialog"],
    selectPeopleDialog: DialogContextValue["selectPeopleDialog"],
}) => ({
    addPersonRules: async ({}, _, groupUsers: Immutable<Array<IGraphUserData>> | undefined) => {
        config.selectPeopleDialog.dispatch("open", {
            title: translations.get("AddPersonRules"),
            multiple: false,
            callback: usersIds => {
                const userId = usersIds[0];
                if (!userId) return;
                config.personLegalRulesDialog.dispatch("open", {groupId: config.groupId, userId})();
            },
            fetchUsers: async (search) => {
                if (!search) return Promise.resolve(groupUsers?.slice(0, 5) ?? []);
                return groupUsers?.filter(u =>
                    [u.displayName, u.userPrincipalName].some(s => s.toLowerCase().includes(search.trim()))
                ) ?? [];
            }
        })();
    },
}) satisfies MagicReducerObject<State>;