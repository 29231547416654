import {State} from "./ShiftDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "../Dialog/Dialog";
import {ConfirmCancelChangesDialog} from "../ConfirmCancelChangesDialog/ConfirmCancelChangesDialog";

export const initialState: State = {
    currentShopId: "",
    shift: undefined,
    userId: undefined,
    date: undefined,
    readOnly: undefined,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
    confirmCancelChangesDialog: MagicReducerRef<typeof ConfirmCancelChangesDialog>,
}) => ({
    open: ({setState}, _, state: Partial<State>) => {
        if (!state.currentShopId) return;
        setState({...initialState, ...state});
        config.dialogRef.dispatch("open")();
    },
    reset: ({setState}) => {
        setState({...initialState});
    },
    beforeClose: ({}, [callback]: [(close: boolean) => void], hasChanged: boolean) => {
        if (!hasChanged) return callback(true);
        config.confirmCancelChangesDialog.dispatch("open", callback)();
    }
}) satisfies MagicReducerObject<State>;