import React from "react";
import {ComponentSlotStyle, Flex, Loader, Text} from "@fluentui/react-northstar";
import {Props} from "./NumberKpi.interfaces";

const NumberKpi = (props: Props) => {

    const textStyles: ComponentSlotStyle = {
        color: props.color,
    }

    return (
        <Flex column fill className={"overflow-hidden"}>
            {!props.title ? null : (
                <Flex>
                    <Text weight={"semibold"} size={"large"} content={props.title}/>
                </Flex>
            )}
            {props.showSkeletons ?
                <Loader styles={{height: "100%"}}/>
                :
                <Flex column fill vAlign={"center"} hAlign={"center"}>
                    <Text
                        styles={{...textStyles, fontSize: "3rem", marginTop: "-10px"}}
                        weight={"semibold"}
                        content={props.value ?? "0"}
                    />
                    <Text size={"large"} content={props.subtitle} styles={textStyles}/>
                </Flex>
            }
            {!props.showSkeletons && props.content}
        </Flex>
    );
}

export default NumberKpi;