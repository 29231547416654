import {Props, State} from "./ActivityItem.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import React from "react";

export const initialState: State = {}

export const reducer = (props: Props) => ({
    delete: ({}, [e]: [React.SyntheticEvent]) => {
        e.stopPropagation();
        props.onDelete?.(props.activity.key);
    }
}) satisfies MagicReducerObject<State>;