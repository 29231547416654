import React, {ReactElement, useEffect} from "react";
import {Props} from "./Badge.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Badge.reducer";
import "./Badge.styles.scss";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import {QRCodeSVG} from 'qrcode.react';
import {useUserShopCache} from "../../hooks/cache/useShopsCache";
import {translations} from "../../translations";

export const Badge = (props: Props): ReactElement => {
    const {shop} = useUserShopCache();
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    useEffect(function onUserShop() {
        if (!shop) return;
        dispatch("generateValue", shop.id)();
    }, [shop]);

    return !state.value ? renderLoader() : renderContent(state.value, shop?.name);
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderLoader = () => {
    return (
        <Flex fill vAlign={"center"} hAlign={"center"}>
            <Loader/>
        </Flex>
    )
}

const renderContent = (value: string, shopName: string | undefined) => {
    return (
        <Flex fill column vAlign={"center"} hAlign={"center"} gap={"gap.large"} className={"badge-view"}>
            <Flex column hAlign={"center"} style={{gap: "3px"}}>
                <Text weight={"semibold"} size={"largest"} content={translations.get("Clocking")}/>
                <Text size={"large"} content={translations.get("Shop") + " " + shopName}/>
            </Flex>
            {renderQrCode(value)}
        </Flex>
    )
}

const renderQrCode = (value: string) => {
    return (
        <QRCodeSVG
            className={"badge-qr-code"}
            value={value}
            level={"H"}
            includeMargin={false}
            imageSettings={{
                src: "/assets/images/CartierShift.svg",
                height: 30,
                width: 30,
                excavate: true,
            }}
        />
    )
}