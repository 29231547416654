import React, {memo, ReactElement, useEffect} from "react";
import {Props} from "./TimeOffPart.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./TimeOffPart.reducer";
import {CompareModule} from "modules/Compare.module";
import "./TimeOffPart.styles.scss";
import {useTimeOffRequestForm} from "../../../../../hooks/forms/useTimeOffRequestForm";
import {Flex} from "@fluentui/react-northstar";
import {useDialogContext} from "../../../../../services/DialogContext/DialogContext";

export const TimeOffPart = memo((props: Props): ReactElement | null => {
    const {confirmCancelChangesDialog} = useDialogContext();
    const form = useTimeOffRequestForm();
    const [state, dispatch] = useMagicReducer(reducer({confirmCancelChangesDialog, form}), initialState);

    useEffect(function onFormStateChanged() {
        props.setBeforeClose(dispatch("beforeClose"));
        return () => props.setBeforeClose(undefined);
    }, [form.state.hasChanged]);

    return (
        <Flex fill column gap={"gap.medium"}>
            {form.renderForm()}
            <Flex hAlign={"end"} className={"no-shrink"}>
                {form.renderSendButton(props.onClose)}
            </Flex>
        </Flex>
    )
}, CompareModule.areObjectsEqual);