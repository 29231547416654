import {createContext, ReactElement, useContext, useMemo} from "react";
import {useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {CopyPlanningDialog} from "components/dialogs/CopyPlanningDialog/CopyPlanningDialog";
import {ExportPlanningDialog} from "components/dialogs/ExportPlanningDialog/ExportPlanningDialog";
import {GroupConfigurationDialog} from "components/dialogs/GroupConfigurationDialog/GroupConfigurationDialog";
import {PeopleShiftsDialog} from "components/dialogs/PeopleShiftsDialog/PeopleShiftsDialog";
import {PlanningConfigurationDialog} from "components/dialogs/PlanningConfigurationDialog/PlanningConfigurationDialog";
import {SharePlanningDialog} from "components/dialogs/SharePlanningDialog/SharePlanningDialog";
import {ShiftDialog} from "components/dialogs/ShiftDialog/ShiftDialog";
import {DialogContextValue} from "./DialogContext.interfaces";
import {CustomShiftThresholdDialog} from "components/dialogs/CustomShiftThresholdDialog/CustomShiftThresholdDialog";
import {PersonLegalRulesDialog} from "components/dialogs/PersonLegalRulesDialog/PersonLegalRulesDialog";
import {GroupDialog} from "components/dialogs/GroupDialog/GroupDialog";
import {UpdateClockingDialog} from "components/dialogs/UpdateClockingDialog/UpdateClockingDialog";
import {ShopDialog} from "components/dialogs/ShopDialog/ShopDialog";
import {ShiftActivityDialog} from "components/dialogs/ShiftActivityDialog/ShiftActivityDialog";
import {ImportPlanningDialog} from "../../components/dialogs/ImportPlanningDialog/ImportPlanningDialog";
import {ShopShiftCategoryDialog} from "../../components/dialogs/ShopShiftCategoryDialog/ShopShiftCategoryDialog";
import {ConfirmDeleteDialog} from "../../components/dialogs/ConfirmDeleteDialog/ConfirmDeleteDialog";
import {ShopActivityDialog} from "../../components/dialogs/ShopActivityDialog/ShopActivityDialog";
import {OrderGroupsDialog} from "../../components/dialogs/OrderGroupsDialog/OrderGroupsDialog";
import {DayNotesDialog} from "../../components/dialogs/DayNotesDialog/DayNotesDialog";
import {UserReportDialog} from "../../components/dialogs/UserReportDialog/UserReportDialog";
import {SettingsDialog} from "../../components/dialogs/SettingsDialog/SettingsDialog";
import {CreateRequestDialog} from "../../components/dialogs/CreateRequestDialog/CreateRequestDialog";
import {ShopReportDialog} from "../../components/dialogs/ShopReportDialog/ShopReportDialog";
import {BadgeErrorDialog} from "../../components/dialogs/BadgeErrorDialog/BadgeErrorDialog";
import {RenameGroupDialog} from "../../components/dialogs/RenameGroupDialog/RenameGroupDialog";
import {SelectPeopleDialog} from "../../components/dialogs/SelectPeopleDialog/SelectPeopleDialog";
import {GroupReportDialog} from "../../components/dialogs/GroupReportDialog/GroupReportDialog";
import {
    ConfirmCancelChangesDialog
} from "../../components/dialogs/ConfirmCancelChangesDialog/ConfirmCancelChangesDialog";
import {ConfirmDialog} from "../../components/dialogs/ConfirmDialog/ConfirmDialog";
import {UserWeekSummaryDialog} from "../../components/dialogs/UserWeekSummaryDialog/UserWeekSummaryDialog";

export const useDialogContextValue = () => {
    const copyPlanningDialog = useMagicReducerRef(CopyPlanningDialog);
    const exportPlanningDialog = useMagicReducerRef(ExportPlanningDialog);
    const groupConfigurationDialog = useMagicReducerRef(GroupConfigurationDialog);
    const peopleShiftsDialog = useMagicReducerRef(PeopleShiftsDialog);
    const planningConfigurationDialog = useMagicReducerRef(PlanningConfigurationDialog);
    const sharePlanningDialog = useMagicReducerRef(SharePlanningDialog);
    const shiftDialog = useMagicReducerRef(ShiftDialog);
    const customShiftThresholdDialog = useMagicReducerRef(CustomShiftThresholdDialog);
    const personLegalRulesDialog = useMagicReducerRef(PersonLegalRulesDialog);
    const groupDialog = useMagicReducerRef(GroupDialog);
    const updateClockingDialog = useMagicReducerRef(UpdateClockingDialog);
    const shopDialog = useMagicReducerRef(ShopDialog);
    const shiftActivityDialog = useMagicReducerRef(ShiftActivityDialog);
    const importPlanningDialog = useMagicReducerRef(ImportPlanningDialog);
    const shopShiftCategoryDialog = useMagicReducerRef(ShopShiftCategoryDialog);
    const confirmDeleteDialog = useMagicReducerRef(ConfirmDeleteDialog);
    const shopActivityDialog = useMagicReducerRef(ShopActivityDialog);
    const orderGroupsDialog = useMagicReducerRef(OrderGroupsDialog);
    const dayNotesDialog = useMagicReducerRef(DayNotesDialog);
    const userReportDialog = useMagicReducerRef(UserReportDialog);
    const settingsDialog = useMagicReducerRef(SettingsDialog);
    const createRequestDialog = useMagicReducerRef(CreateRequestDialog);
    const shopReportDialog = useMagicReducerRef(ShopReportDialog);
    const badgeErrorDialog = useMagicReducerRef(BadgeErrorDialog);
    const renameGroupDialog = useMagicReducerRef(RenameGroupDialog);
    const selectPeopleDialog = useMagicReducerRef(SelectPeopleDialog);
    const groupReportDialog = useMagicReducerRef(GroupReportDialog);
    const confirmCancelChangesDialog = useMagicReducerRef(ConfirmCancelChangesDialog);
    const confirmDialog = useMagicReducerRef(ConfirmDialog);
    const userWeekSummaryDialog = useMagicReducerRef(UserWeekSummaryDialog);

    return useMemo(() => ({
        copyPlanningDialog,
        exportPlanningDialog,
        groupConfigurationDialog,
        peopleShiftsDialog,
        planningConfigurationDialog,
        sharePlanningDialog,
        shiftDialog,
        customShiftThresholdDialog,
        personLegalRulesDialog,
        groupDialog,
        updateClockingDialog,
        shopDialog,
        shiftActivityDialog,
        importPlanningDialog,
        shopShiftCategoryDialog,
        confirmDeleteDialog,
        shopActivityDialog,
        orderGroupsDialog,
        dayNotesDialog,
        userReportDialog,
        settingsDialog,
        createRequestDialog,
        shopReportDialog,
        badgeErrorDialog,
        renameGroupDialog,
        selectPeopleDialog,
        groupReportDialog,
        confirmCancelChangesDialog,
        confirmDialog,
        userWeekSummaryDialog,
    }), []);
}

const DialogContext = createContext<DialogContextValue | undefined>(undefined);

export const DialogProvider = (props: { children: ReactElement }) => {
    const value = useDialogContextValue();
    return (
        <DialogContext.Provider value={value}>
            {props.children}
            <SettingsDialog externalRef={value.settingsDialog}/>
            <PlanningConfigurationDialog externalRef={value.planningConfigurationDialog}/>
            <GroupConfigurationDialog externalRef={value.groupConfigurationDialog}/>
            <GroupDialog externalRef={value.groupDialog}/>
            <PeopleShiftsDialog externalRef={value.peopleShiftsDialog}/>
            <CopyPlanningDialog externalRef={value.copyPlanningDialog}/>
            <ExportPlanningDialog externalRef={value.exportPlanningDialog}/>
            <SharePlanningDialog externalRef={value.sharePlanningDialog}/>
            <ShiftDialog externalRef={value.shiftDialog}/>
            <CustomShiftThresholdDialog externalRef={value.customShiftThresholdDialog}/>
            <PersonLegalRulesDialog externalRef={value.personLegalRulesDialog}/>
            <UpdateClockingDialog externalRef={value.updateClockingDialog}/>
            <ShopDialog externalRef={value.shopDialog}/>
            <ShiftActivityDialog externalRef={value.shiftActivityDialog}/>
            <ImportPlanningDialog externalRef={value.importPlanningDialog}/>
            <ShopShiftCategoryDialog externalRef={value.shopShiftCategoryDialog}/>
            <ShopActivityDialog externalRef={value.shopActivityDialog}/>
            <ConfirmDeleteDialog externalRef={value.confirmDeleteDialog}/>
            <OrderGroupsDialog externalRef={value.orderGroupsDialog}/>
            <DayNotesDialog externalRef={value.dayNotesDialog}/>
            <UserReportDialog externalRef={value.userReportDialog}/>
            <CreateRequestDialog externalRef={value.createRequestDialog}/>
            <ShopReportDialog externalRef={value.shopReportDialog}/>
            <GroupReportDialog externalRef={value.groupReportDialog}/>
            <UserWeekSummaryDialog externalRef={value.userWeekSummaryDialog}/>
            <BadgeErrorDialog externalRef={value.badgeErrorDialog}/>
            <RenameGroupDialog externalRef={value.renameGroupDialog}/>
            <SelectPeopleDialog externalRef={value.selectPeopleDialog}/>
            <ConfirmCancelChangesDialog externalRef={value.confirmCancelChangesDialog}/>
            <ConfirmDialog externalRef={value.confirmDialog}/>
        </DialogContext.Provider>
    );
};

export const useDialogContext = () => {
    const context = useContext(DialogContext);
    if (!context) throw new Error('useDialogContext must be used within a DialogContextProvider');
    return context;
};