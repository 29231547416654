import {memo, ReactElement} from "react";
import {ImportPlanningStep, Props} from "./ImportPlanningDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ImportPlanningDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import "./ImportPlanningDialog.styles.scss";
import {translations} from "../../../translations";
import {SelectStep} from "./steps/SelectStep/SelectStep";
import {ImportStep} from "./steps/ImportStep/ImportStep";
import {ResultStep} from "./steps/ResultStep/ResultStep";
import {ImportIcon} from "../../../assets/icons";
import {usePlanningDateRangeCache} from "../../../hooks/cache/usePlanningDateRangeCache";
import {useShiftsCache} from "../../../hooks/cache/useShiftsCache";

export const ImportPlanningDialog = memo((props: Props): ReactElement | null => {
    const {refetchAllUserShifts} = useShiftsCache();
    const {planningDateRange: {selectedDate}} = usePlanningDateRangeCache();
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({
        dialogRef,
        selectedDate,
        refetchAllUserShifts
    }), initialState, props.externalRef);

    const renderContent = () => {
        switch (state.step) {
            case ImportPlanningStep.Select:
                return <SelectStep onFileSelected={dispatch("fileSelected")}/>
            case ImportPlanningStep.Import:
                return <ImportStep/>
            case ImportPlanningStep.Result:
                return <ResultStep isSuccess={state.isSuccess}/>
            default:
                return null;
        }
    }

    return (
        <Dialog
            icon={<ImportIcon/>}
            externalRef={dialogRef}
            width={500}
            height={500}
            title={translations.get("ImportPlanning")}
            content={renderContent()}
        />
    )
}, CompareModule.areObjectsEqual);