import {Slice} from "@reduxjs/toolkit";
import {ReduxStoreState} from "./store";
import {useSelector} from "react-redux";
import {CompareModule} from "modules/Compare.module";

type SliceName<SLICE extends Slice> = SLICE extends Slice<any, any, infer NAME> ?
    NAME extends keyof ReduxStoreState ? NAME : never : never;

type SliceState<SLICE extends Slice> = SLICE extends Slice<infer STATE, any, any> ? STATE : never;

type SelectorObject<SLICE extends Slice, ARG extends StringSelectorArgument<SLICE>[]> =
    { [key in ARG[number]]: SliceState<SLICE>[key] };

type SelectorReturnType<SLICE extends Slice, ARG extends SelectorArgument<SLICE>> =
    ARG extends [FunctionSelectorArgument<SLICE, infer RETURN_TYPE>] ? RETURN_TYPE :
        ARG extends StringSelectorArgument<SLICE>[] ? SelectorObject<SLICE, ARG> : never;

type FunctionSelectorArgument<SLICE extends Slice, RETURN_TYPE extends any> = ((state: SliceState<SLICE>) => RETURN_TYPE);

type StringSelectorArgument<SLICE extends Slice> = keyof SliceState<SLICE>;

type SelectorArgument<SLICE extends Slice> = [FunctionSelectorArgument<SLICE, any>] | StringSelectorArgument<SLICE>[];

export const getSliceSelector = <SLICE extends Slice>(slice: SLICE) => {
    return <ARG extends SelectorArgument<SLICE>>(...arg: ARG): SelectorReturnType<SLICE, ARG> => {
        if (arg.length === 1 && typeof arg[0] === "function") return useSelector(arg[0]);
        return useSelector((state: ReduxStoreState) => {
            const object = {} as any;
            // @ts-ignore
            arg.forEach(i => object[i] = state[slice.name][i as any]);
            return object;
        }, CompareModule.areObjectsEqual);
    }
}