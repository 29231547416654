import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./ShopGroupReport.interfaces";
import {MagicDispatch, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShopGroupReport.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ShopGroupReport.styles.scss";
import {useGroupCache} from "../../../../hooks/cache/groups/useGroupCache";
import {Button, Flex, Skeleton} from "@fluentui/react-northstar";
import {Accordion} from "../../../../components/others/Accordion/Accordion";
import {ArrowTrendingLinesIcon, PeopleIcon} from "../../../../assets/icons";
import {PeopleButton} from "../../../../components/buttons/PeopleButton/PeopleButton";
import {useDialogContext} from "../../../../services/DialogContext/DialogContext";
import {TimeReport} from "../../../../components/others/TimeReport/TimeReport";
import {useGroupUsersReportsCache} from "../../../../hooks/cache/groups/useGroupUsersReportsCache";
import {usePlanningDateRangeCache} from "../../../../hooks/cache/usePlanningDateRangeCache";

export const ShopGroupReport = memo((props: Props): ReactElement | null => {
    const {userReportDialog, groupReportDialog} = useDialogContext();
    const [state, dispatch] = useMagicReducer(reducer({groupReportDialog}), initialState);
    const {planningDateRange: {selectedRange}} = usePlanningDateRangeCache();
    const {group, isLoading: isGroupLoading} = useGroupCache(props.groupId);
    const {
        groupUsersReports,
        isLoading: areReportsLoading
    } = useGroupUsersReportsCache(props.groupId, selectedRange.startDate, selectedRange.endDate);

    const isLoading = isGroupLoading || areReportsLoading;

    const totalHours = useMemo(() => groupUsersReports?.reduce((acc, cur) => acc + cur.hours, 0) ?? 0, [groupUsersReports]);
    const totalOvertime = useMemo(() => groupUsersReports?.reduce((acc, cur) => acc + cur.overtime, 0) ?? 0, [groupUsersReports]);
    const totalAbsences = useMemo(() => groupUsersReports?.reduce((acc, cur) => acc + cur.absences, 0) ?? 0, [groupUsersReports]);
    const totalUnClocked = useMemo(() => groupUsersReports?.reduce((acc, cur) => acc + cur.unClocked, 0) ?? 0, [groupUsersReports]);

    if (isGroupLoading) return renderSkeleton();

    const content = (
        <Flex fill className={"shop-group-report"}>
            <Flex className={"users-grid"}>
                {group?.usersIds.map(uid => {
                    const userReport = groupUsersReports?.find(ur => ur.userId === uid);
                    return (
                        <PeopleButton
                            fluid userId={uid} key={uid}
                            onClick={userReportDialog.dispatch("open", uid)}
                            pictureSize={"small"}
                            styles={{padding: "6px 0"}}
                            subContent={
                                <TimeReport
                                    hours={(userReport?.hours ?? 0) + (userReport?.usedRetrieveHours ?? 0)}
                                    overtimeHours={userReport?.overtime}
                                    absences={userReport?.absences}
                                    unClocked={userReport?.unClocked}
                                    showSkeleton={isLoading}
                                />
                            }
                        />
                    )
                })}
            </Flex>
        </Flex>
    );

    return (
        <Accordion
            card
            icon={PeopleIcon}
            expandable={false}
            title={group?.name ?? ""}
            content={content}
            headerContent={
                <Flex fill vAlign={"center"} space={"between"}>
                    {renderGroupAccordionHeaderContent(dispatch, props.groupId)}
                    <TimeReport
                        hours={totalHours}
                        overtimeHours={totalOvertime}
                        absences={totalAbsences}
                        unClocked={totalUnClocked}
                        showSkeleton={isLoading}
                    />
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderSkeleton = () => {
    return (
        <Skeleton animation={"pulse"}>
            <Skeleton.Shape style={{borderRadius: "6px"}} height={"200px"} width={"100%"}/>
        </Skeleton>
    )
}

const renderGroupAccordionHeaderContent = (dispatch: MagicDispatch<typeof reducer>, groupId: string | undefined) => {
    return (
        <Button iconOnly text icon={<ArrowTrendingLinesIcon/>} onClick={dispatch("openShopGroupReport", groupId)}/>
    )
}