import React, {memo, ReactElement, useCallback, useEffect, useMemo} from "react";
import {IFilterItem, Props} from "./Filter.interfaces";
import {Immutable, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {hasFiltersChanged, initialState, reducer} from "./Filter.reducer";
import "./Filter.styles.scss";
import {AcceptIcon, Button, Checkbox, Flex, Input, MenuButton, MenuItemProps, Text} from "@fluentui/react-northstar";
import {ShorthandCollection, ShorthandValue} from "@fluentui/react-northstar/dist/es/types";
import {useMsTeamsSelector} from "redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {ScreenModule} from "modules/Screen.module";
import {translations} from "translations";
import {FilterMobileDialog} from "./MobileDialog/FilterMobileDialog";

export const Filter = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer(props), initialState(props), props.externalRef);
    const {isTouchScreen} = useMsTeamsSelector("isTouchScreen");

    useEffect(function onItemsChange() {
        dispatch("setDefaultItems")(props.items);
        dispatch("reset", true)();
        if (state.data) props.onItemChecked(state.data);
    }, [props.items]);

    useEffect(function handleCloseOnOutsideClick() {
        if (!state.open) return;
        window.document.body.addEventListener("click", dispatch("close"));
        return () => window.document.body.removeEventListener("click", dispatch("close"));
    }, [state.open]);

    const isSmallScreen = ScreenModule.isSmallScreen();

    const formatItemWithMenu = useCallback((item: Immutable<IFilterItem>, isTouchScreen: boolean) => {
        if (!item.menu) throw new Error("Item don't have a menu");
        const formattedItem: ShorthandValue<MenuItemProps> = {key: item.key};
        formattedItem.content = item.label;
        formattedItem.icon = item.icon;
        formattedItem.onClick = (e) => e.stopPropagation();
        if (!isTouchScreen) formattedItem.on = "hover";
        formattedItem.menu = {
            items: item.menu.items.map(subItem => formatItem(subItem, isTouchScreen, item)),
            popper: {position: "after", offset: [-13, 0]},
            ...(isSmallScreen && {
                popper: {align: "end", position: "below"}
            }),
            onClick: (e: React.SyntheticEvent) => e.stopPropagation(),
        };
        return formattedItem;
    }, []);

    const formatSingleItem = useCallback((item: Immutable<IFilterItem>, parentItem?: Immutable<IFilterItem>) => {
        const formattedItem: ShorthandValue<MenuItemProps> = {key: item.key};
        const unitWidth = !!item.input?.unit ? item.input?.unit.width : 0;
        const inputMaxWidth = unitWidth + 80;
        const isParentUniqueMenu = parentItem?.menu?.unique;
        formattedItem.onClick = dispatch("handleToggleItem", [item.key, parentItem?.key]);
        formattedItem.children = (
            <Flex fill gap={"gap.small"} space={"between"} vAlign={"center"}>
                <Flex gap={"gap.small"} vAlign={"center"}>
                    {item.icon}
                    <Text content={item.label}/>
                </Flex>
                {!item.input ? null :
                    <Flex
                        fill className={"filter-input-container"}
                        styles={{maxWidth: inputMaxWidth + "px"}}
                        onClick={(e: React.SyntheticEvent<HTMLElement, Event>) => e.stopPropagation()}>
                        <Input
                            fluid
                            value={item.input.value}
                            type={item.input.type ?? "text"}
                            min={item.input.min}
                            max={item.input.max}
                            input={{styles: {paddingRight: unitWidth + "px"}}}
                            className={"filter-input"}
                            icon={<Text styles={{color: "grey"}} content={item.input.unit.label}/>}
                            onChange={dispatch("handleFilterItemInputValueChange", item.key)}
                        />
                    </Flex>
                }
                {item.input?.hideCheckbox ? null :
                    isParentUniqueMenu ?
                        <AcceptIcon className={"unique-menu-item-check " + (item.isChecked ? "checked" : "")}/>
                        :
                        <Checkbox labelPosition={"start"} checked={item.isChecked ?? false}/>
                }
            </Flex>
        )
        return formattedItem;
    }, []);

    const formatItem = useCallback((item: Immutable<IFilterItem>, isTouchScreen: boolean, parentItem?: Immutable<IFilterItem>) => {
        if (!!item.menu) return formatItemWithMenu(item, isTouchScreen);
        return formatSingleItem(item, parentItem);
    }, []);

    const formattedItems = useMemo((): ShorthandCollection<MenuItemProps> => {
        if (state.items.length === 0) return [];
        const items: ShorthandCollection<MenuItemProps> = [
            {
                key: "header",
                disabled: true,
                className: "filter-header",
                children: (
                    <Flex fill gap={"gap.small"} space={"between"} vAlign={"center"}>
                        <Text className={"header-title"} weight={"semibold"} content={translations.get("Filters")}/>
                        <Button styles={{minWidth: "0px"}} primary text content={translations.get("Reset")}
                                onClick={dispatch("handleResetFilters")}/>
                    </Flex>
                ),
            },
            {
                key: "divider-1",
                kind: "divider",
                styles: {margin: "5px 0"}
            },
        ];
        items.push(...state.items.map(item => formatItem(item, isTouchScreen)));
        return items;
    }, [state.items, isTouchScreen]);

    const isButtonPrimary = hasFiltersChanged(state.defaultItems, state.items);

    const trigger = (
        <Flex onClick={dispatch("toggleOpen")}
              {...(!isTouchScreen && props.on === "hover" && {onMouseEnter: dispatch("open")})}>
            {props.renderTrigger(isButtonPrimary)}
        </Flex>
    )

    const desktopContent = (
        <MenuButton
            onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
            className={"no-select"}
            open={state.open}
            {...(!isTouchScreen && {
                mouseLeaveDelay: 500,
                onOpenChange: dispatch("handlePopupHoverOpenChange")
            })}
            trigger={trigger}
            on={isTouchScreen ? "click" : "hover"}
            align={"start"}
            menu={formattedItems}
        />
    )

    const mobileContent = (
        <Flex>
            {trigger}
            <FilterMobileDialog
                menuItems={formattedItems}
                items={state.items}
                show={state.open}
                onHide={dispatch("close")}
                filterDispatch={dispatch}
            />
        </Flex>
    )

    return (
        <Flex className={"filter"}>
            {isSmallScreen ? mobileContent : desktopContent}
        </Flex>
    )
}, (prevProps, nextProps) => (
    prevProps.items === nextProps.items &&
    prevProps.onItemChecked === nextProps.onItemChecked &&
    prevProps.disabled === nextProps.disabled
));