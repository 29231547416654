import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./Toolbar.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Toolbar.reducer";
import {CompareModule} from "modules/Compare.module";
import "./Toolbar.styles.scss";
import {
    AcceptIcon,
    AddIcon,
    Button,
    CalendarIcon,
    ChevronEndIcon,
    ChevronStartIcon,
    Divider,
    EyeIcon,
    FilterIcon,
    Flex,
    MoreIcon,
    Text,
} from "@fluentui/react-northstar";
import {translations} from "translations";
import {CopyIcon, ExportIcon, ImportIcon, SettingsIcon, ShareIcon} from "assets/icons";
import {DateRangeSelector} from "components/others/DateRangeSelector/DateRangeSelector";
import {Filter} from "components/others/Filter/Filter";
import {PopupMenuButton} from "components/buttons/PopupMenuButton/PopupMenuButton";
import {useDialogContext} from "services/DialogContext/DialogContext";
import {useShopsCache} from "hooks/cache/useShopsCache";
import {DateRangeType} from "components/others/DateRangeSelector/DateRangeSelector.interfaces";
import {useToolbarFilterItems} from "./hooks/useToolbarFilterItems";
import {PlanningView} from "../Planning/Planning.interfaces";
import {useShopGroupsCache} from "../../../hooks/cache/groups/useShopGroupsCache";
import {ShopPopup} from "../../../components/others/ShopPopup/ShopPopup";
import {usePlanningDateRangeCache} from "../../../hooks/cache/usePlanningDateRangeCache";
import {useUserRolesCache} from "../../../hooks/cache/useUserRoleCache";

export const Toolbar = memo((props: Props): ReactElement | null => {
    const filterRef = useMagicReducerRef(Filter);
    const dateRangeRef = useMagicReducerRef(DateRangeSelector);
    const shopPopupRef = useMagicReducerRef(ShopPopup);
    const dialogContext = useDialogContext();
    const {shops, isLoading: areShopsLoading} = useShopsCache();
    const {canUpdateShop, isLoading: isUserRoleLoading} = useUserRolesCache();
    const {setPlanningDateRange} = usePlanningDateRangeCache();
    const [state, dispatch] = useMagicReducer(reducer({
        props, setPlanningDateRange
    }), initialState, props.externalRef);
    const filterItems = useToolbarFilterItems(state.selectedShopId);
    const {groups} = useShopGroupsCache(state.selectedShopId);

    const selectedShop = shops?.find(shop => shop.id === state.selectedShopId);

    const disablePlanningButtons = !selectedShop || !groups?.length;

    const readOnly = !canUpdateShop(state.selectedShopId) || isUserRoleLoading;

    const noShopAvailable = !areShopsLoading && !shops?.length;

    const addShiftButton = useMemo(() => readOnly ? null : (
        <Button
            className={"no-shrink"} primary icon={<AddIcon/>}
            content={translations.get("NewShift")}
            onClick={dialogContext.shiftDialog.dispatch("open", {
                currentShopId: state.selectedShopId,
                shift: undefined,
                userId: undefined,
                date: undefined,
            })}
            disabled={disablePlanningButtons}
        />
    ), [disablePlanningButtons, state.selectedShopId, readOnly]);

    const shopButton = useMemo(() => (
        <ShopPopup
            externalRef={shopPopupRef}
            onShopSelected={dispatch("switchShop")}
        />
    ), []);

    const dateRange = useMemo(() => !groups?.length ? null : (
        <DateRangeSelector
            externalRef={dateRangeRef}
            className={"planning-date-range"}
            onDateRangeChange={dispatch("dateRangeChange")}
        />
    ), [groups?.length]);

    const prevRangeButton = useMemo(() => (
        <Button
            iconOnly text
            icon={<ChevronStartIcon/>}
            disabled={disablePlanningButtons || dateRangeRef.state?.selectionMode === DateRangeType.Custom}
            onClick={dateRangeRef.dispatch("prevRange")}
        />
    ), [disablePlanningButtons, dateRangeRef.state?.selectionMode]);

    const nextRangeButton = useMemo(() => (
        <Button
            iconOnly text
            icon={<ChevronEndIcon/>}
            disabled={disablePlanningButtons || dateRangeRef.state?.selectionMode === DateRangeType.Custom}
            onClick={dateRangeRef.dispatch("nextRange")}
        />
    ), [disablePlanningButtons, dateRangeRef.state?.selectionMode]);

    const todayButton = useMemo(() => (
        <Button
            className={"no-shrink"} text icon={<CalendarIcon/>}
            content={translations.get("Today")}
            onClick={dateRangeRef.dispatch("goToToday")}
            disabled={disablePlanningButtons || (dateRangeRef.state?.isTodaySelected ?? true)}
        />
    ), [disablePlanningButtons, dateRangeRef.state?.isTodaySelected]);

    const filter = useMemo(() => (
        <Filter
            id={"schedules-filter"}
            externalRef={filterRef}
            items={filterItems}
            onItemChecked={dispatch("filtersChange")}
            renderTrigger={(primary) => (
                <Button
                    text
                    primary={primary}
                    className={"no-shrink"} icon={<FilterIcon/>}
                    content={translations.get("Filter")}
                    disabled={disablePlanningButtons}
                />
            )}
        />
    ), [disablePlanningButtons, filterItems]);

    const viewButton = useMemo(() => (
        <PopupMenuButton
            trigger={
                <Button
                    className={"no-shrink"} text icon={<EyeIcon/>}
                    content={translations.get("View")}
                    disabled={disablePlanningButtons}
                />
            }
            menu={{
                className: "planning-view-menu-button",
                items: [
                    {
                        key: "group-people",
                        content: (
                            <Flex fill vAlign={"center"} space={"between"} gap={"gap.medium"}>
                                <Text content={translations.get("Group") + " > " + translations.get("People")}/>
                                {renderViewMenuButtonCheck(state.view === PlanningView.People)}
                            </Flex>
                        ),
                        onClick: dispatch("changeView", PlanningView.People),
                    },
                    {
                        key: "group-shift",
                        content: (
                            <Flex fill vAlign={"center"} space={"between"} gap={"gap.medium"}>
                                <Text content={translations.get("Group") + " > " + translations.get("Shift")}/>
                                {renderViewMenuButtonCheck(state.view === PlanningView.Shifts)}
                            </Flex>
                        ),
                        onClick: dispatch("changeView", PlanningView.Shifts),
                    }
                ]
            }}
        />
    ), [state.view, disablePlanningButtons]);

    const moreButton = useMemo(() => readOnly ? null : (
        <PopupMenuButton
            mouseLeaveDelay={500}
            trigger={<Button iconOnly text icon={<MoreIcon/>} disabled={disablePlanningButtons}/>}
            menu={[
                {
                    key: "share",
                    content: translations.get("SharePlanning"),
                    icon: <ShareIcon/>,
                    onClick: dialogContext.sharePlanningDialog.dispatch("open", {
                        shopId: state.selectedShopId,
                        startDate: dateRangeRef.state?.selectedRange.startDate,
                        endDate: dateRangeRef.state?.selectedRange.endDate
                    })
                },
                {
                    key: "copy",
                    content: translations.get("CopyPlanning"),
                    icon: <CopyIcon/>,
                    onClick: dialogContext.copyPlanningDialog.dispatch("open", {
                        shopId: state.selectedShopId,
                    })
                },
                {
                    key: 'divider-1',
                    kind: 'divider',
                },
                {
                    key: "import",
                    content: translations.get("ImportPlanning"),
                    icon: <ImportIcon/>,
                    onClick: dialogContext.importPlanningDialog.dispatch("open", state.selectedShopId)
                },
                {
                    key: "export",
                    content: translations.get("ExportPlanning"),
                    icon: <ExportIcon/>,
                    onClick: dialogContext.exportPlanningDialog.dispatch("open", {shopId: state.selectedShopId})
                },
                {
                    key: 'divider-2',
                    kind: 'divider',
                },
                {
                    key: "configuration",
                    content: translations.get("Configuration"),
                    icon: <SettingsIcon/>,
                    onClick: dialogContext.planningConfigurationDialog.dispatch("open", {shopId: state.selectedShopId})
                },
            ]}
        />
    ), [state.selectedShopId, dateRangeRef.state?.selectedRange, disablePlanningButtons, readOnly]);

    if (noShopAvailable) return null;

    return (
        <Flex className={"toolbar"}>
            {addShiftButton}
            {shopButton}
            <Flex fill/>
            {dateRange}
            {prevRangeButton}
            {nextRangeButton}
            {todayButton}
            {!groups?.length ? null : <Divider vertical/>}
            {filter}
            {viewButton}
            {moreButton}
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderViewMenuButtonCheck = (isChecked: boolean) => (
    isChecked ? <AcceptIcon className={"planning-view-button-check"}/> : <div style={{width: "16px"}}/>
)