import {State} from "./GroupsPart.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {useShopGroupsCache} from "../../../../../hooks/cache/groups/useShopGroupsCache";

export const initialState: State = {}

export const reducer = (config: {
    deleteStaff: ReturnType<typeof useShopGroupsCache>["deleteStaff"],
}) => ({
    deleteStaff: ({}, _, {groupId, staffId}: { groupId: string, staffId: string }) => {
        config.deleteStaff(groupId, staffId);
    }
}) satisfies MagicReducerObject<State>;