import {State} from "./ConfirmCancelChangesDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";

export const initialState: State = {
    callback: Promise.resolve,
    isLoading: false,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, callback: State["callback"]) => {
        setState({...initialState, callback});
        config.dialogRef.dispatch("open")();
    },
    confirm: async (reducerData) => {
        await reducer(config).callback(reducerData, undefined, true);
    },
    cancel: async (reducerData) => {
        await reducer(config).callback(reducerData, undefined, false);
    },
    callback: async ({state, setState}, _, confirm: boolean) => {
        const result = state.callback?.(confirm);
        if (confirm && result instanceof Promise) {
            setState({isLoading: true});
            await result;
        }
        config.dialogRef.dispatch("close")();
    }
}) satisfies MagicReducerObject<State>;