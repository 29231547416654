import {State} from "./ShopGroupReport.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {DialogContextValue} from "../../../../services/DialogContext/DialogContext.interfaces";
import React from "react";

export const initialState: State = {}

export const reducer = (config: {
    groupReportDialog: DialogContextValue["groupReportDialog"],
}) => ({
    openShopGroupReport: ({}, [e]: [React.SyntheticEvent], groupId: string | undefined) => {
        e.stopPropagation();
        if (!groupId) return;
        config.groupReportDialog.dispatch("open", groupId)();
    }
}) satisfies MagicReducerObject<State>;