import {FilterItemsData} from "components/others/Filter/Filter.interfaces";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {Shift} from "../../../classes/Shift";

export enum PlanningView {
    People, Shifts
}

export type SelectedCellData = {
    userId: string,
    starDate: string,
    endDate: string,
    shifts: Array<Immutable<Shift> | undefined>,
}

export type State = {
    scrollbarWidth: number,
    planningGroupsContainer: HTMLDivElement | null,
    selectedCells: Record<string, SelectedCellData>,
    clipboardCells: Record<string, SelectedCellData> | undefined,
    orderedStaffsIds: string[],
    rightClickPosition: { x: number, y: number } | undefined,
}

export type Props = {
    shopId: string | undefined,
    filters: FilterItemsData | undefined,
    view: PlanningView,
}