import React, {memo, ReactElement} from "react";
import {Props} from "./AddShiftSideView.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./AddShiftSideView.reducer";
import {CompareModule} from "modules/Compare.module";
import "./AddShiftSideView.styles.scss";
import {SideView} from "components/dialogs/SideView/SideView";
import {translations} from "../../../../translations";
import {useShiftForm} from "../../../../hooks/forms/useShiftForm";
import {Flex} from "@fluentui/react-northstar";

export const AddShiftSideView = memo((props: Props): ReactElement | null => {
    const sideViewRef = useMagicReducerRef(SideView);
    const [state, dispatch] = useMagicReducer(reducer({sideViewRef}), initialState, props.externalRef);

    const form = useShiftForm({
        currentShopId: props.currentShopId,
        shift: undefined,
        userId: undefined,
        initialDate: undefined,
        readOnly: undefined
    });

    return (
        <SideView externalRef={sideViewRef} title={translations.get("NewShift")} style={{zIndex: 10}}>
            <Flex fill column className={"overflow-hidden"}>
                <Flex fill column className={"overflow-scroll"}>
                    <Flex fill column gap={"gap.small"} className={"add-shift-side-view"}>
                        {form.renderForm()}
                    </Flex>
                </Flex>
                <Flex className={"no-shrink"} style={{padding: "15px"}}>
                    {form.renderSaveButton(sideViewRef.dispatch("close"), true)}
                </Flex>
            </Flex>
        </SideView>
    )
}, CompareModule.areObjectsEqual);