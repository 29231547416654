import {translations} from "translations";
import {useForm} from "@witivio_teamspro/northstar-form";
import {AddIcon, ArrowRightIcon, Button, Flex, SaveIcon} from "@fluentui/react-northstar";
import React, {useLayoutEffect} from "react";
import {useShopCache} from "../cache/useShopsCache";
import {ShiftActivity} from "interfaces/ShiftData";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {FormModule} from "../../modules/Form.module";

export const useShiftActivityForm = (shopId: string, activity: ShiftActivity | undefined) => {
    const {shop} = useShopCache(shopId);

    useLayoutEffect(function onActivityChange() {
        form.setFieldsInitialValues({
            type: activity?.key,
            startTime: activity?.start,
            endTime: activity?.end,
        });
        form.reset();
    }, [activity]);

    const activities = shop?.activities ?? [];

    const form = useForm({
        items: {
            type: {
                type: "dropdown",
                label: translations.get("Type"),
                required: true,
                items: activities.map(a => a.key),
                renderItem: (id) => activities.find(a => a.key === id)?.name ?? "",
                renderSelectedItem: (id) => activities.find(a => a.key === id)?.name ?? "",
                placeholder: translations.get("SelectAType"),
                clearable: false,
            },
            ...FormModule.generateTimeRangeItems({required: true, showLabel: true}),
        },
    });

    const {type, startTime, endTime} = form.formItems;

    const renderForm = () => (
        <Flex column fill gap={"gap.small"}>
            {type}
            <Flex vAlign={"end"} gap={"gap.small"}>
                {startTime}
                <ArrowRightIcon style={{color: "darkgray", marginBottom: "8px"}}/>
                {endTime}
            </Flex>
        </Flex>
    );

    const renderDoneButton = (onClick: (activity: Immutable<ShiftActivity> | undefined) => void) => (
        <Button
            fluid
            primary
            disabled={!form.isValid}
            content={translations.get(activity ? "Save" : "Add")}
            icon={activity ? <SaveIcon size={"large"} outline/> : <AddIcon outline/>}
            onClick={() => onClick(generateShiftActivity(form.state))}
        />
    );

    return {
        ...form,
        renderForm,
        renderDoneButton,
    }
}

const generateShiftActivity = (
    formState: ReturnType<typeof useShiftActivityForm>["state"]
): Immutable<ShiftActivity> | undefined => {
    const {type, startTime, endTime} = formState.data;
    if (!type || !startTime || !endTime) return;
    return {key: type as string, start: startTime, end: endTime};
}