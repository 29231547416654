import {useMsTeamsSelector} from "redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {useCallback, useEffect, useState} from "react";
import {DeeplinkContext} from "interfaces/DeeplinkContext";

export const useDeeplinkContext = () => {
    const {subEntityId} = useMsTeamsSelector("subEntityId");
    const [deeplinkContext, setDeeplinkContext] = useState<DeeplinkContext | undefined>(undefined);

    useEffect(() => {
        if (!subEntityId) return undefined;
        if (typeof subEntityId === "object") setDeeplinkContext(subEntityId as DeeplinkContext);
        else if (subEntityId.startsWith("{") && subEntityId.endsWith("}")) setDeeplinkContext(JSON.parse(subEntityId) as DeeplinkContext);
    }, [subEntityId]);

    const clearDeeplinkContext = useCallback(() => setDeeplinkContext(undefined), []);

    return {
        deeplinkContext,
        clearDeeplinkContext
    }
}