import {cloneElement, isValidElement} from "react";
import {Immutable, Mutable} from "@witivio_teamspro/use-reducer";
import {UpdatedField} from "../interfaces/UpdatedField";
import {CompareModule} from "./Compare.module";

const deepClone = <T>(source: Immutable<T>): Mutable<T> => {
    if (source === null || typeof source !== 'object') return source as any;
    if (isValidElement(source)) return cloneElement(source) as any;
    if (Array.isArray(source)) return source.map((item) => deepClone(item)) as any;
    const clonedObj = {} as any;
    for (const key in source) {
        // @ts-ignore
        if (source.hasOwnProperty(key)) clonedObj[key] = deepClone(source[key]);
    }
    return clonedObj as any;
}

const flattenUpdatedField = (obj: any, prefix: string = ''): UpdatedField[] => {
    return Object.entries(obj).reduce((acc: UpdatedField[], [key, value]) => {
        const newKey = prefix ? `${prefix}_${key}` : key;
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
            acc.push(...flattenUpdatedField(value, newKey));
        } else {
            acc.push({field: newKey, value: JSON.stringify(value)});
        }
        return acc;
    }, []);
};

const mapUpdatedFields = <T extends object>(fields: Partial<T>, eligibleFields?: Array<keyof T>): UpdatedField[] => {
    return Object.entries(fields)
        .filter(([field]) => !eligibleFields || eligibleFields.includes(field as keyof T))
        .flatMap(([field, value]) => {
            return [{field: field.toLowerCase(), value: JSON.stringify(value ?? null)}];
        })
        .filter(({field, value}) => !!field && !!value)
};

const findUpdatedFields = <T extends object>(prevItem: T | undefined, newItem: T | undefined) => {
    const updatedFields: Partial<T> = {};
    if (!prevItem || !newItem) return updatedFields;
    Object.entries(newItem).forEach(([key, value]) => {
        const property = key as keyof T;
        const areEquals = CompareModule.areValuesEqual(prevItem[property], newItem[property], true);
        if (!areEquals) updatedFields[property] = value as never;
    });
    return updatedFields;
}

const clearNullValues = <T extends object>(object: T) => {
    for (const [key, value] of Object.entries(object)) {
        const property = key as keyof T;
        if (value === null) delete object[property];
    }
    return object;
}

export const ObjectModule = {
    deepClone,
    mapUpdatedFields,
    findUpdatedFields,
    clearNullValues
}