import React, {memo, ReactElement} from "react";
import {Props} from "./ShiftDialog.interfaces";
import {Immutable, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShiftDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ShiftDialog.styles.scss";
import {Dialog} from "../Dialog/Dialog";
import {translations} from "translations";
import {useShiftForm} from "hooks/forms/useShiftForm";
import {AddIcon, EditIcon, Flex} from "@fluentui/react-northstar";
import {ShiftType} from "../../../interfaces/ShiftData";
import {Shift} from "../../../classes/Shift";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";
import {ShiftsIcon} from "../../../assets/icons";
import {useUserRolesCache} from "../../../hooks/cache/useUserRoleCache";

export const ShiftDialog = memo((props: Props): ReactElement | null => {
    const {confirmCancelChangesDialog} = useDialogContext();
    const {canUpdateShop} = useUserRolesCache();
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({
        dialogRef,
        confirmCancelChangesDialog
    }), initialState, props.externalRef);

    const shiftForm = useShiftForm({
        currentShopId: state.currentShopId,
        shift: state.shift,
        userId: state.userId,
        initialDate: state.date,
        readOnly: state.readOnly,
    });

    const readOnly = !canUpdateShop(state.currentShopId) || (state.readOnly ?? false);
    const isShift = shiftForm.state.data.type === ShiftType.Shift;

    const icon = generateIcon(state.shift, readOnly);
    const title = generateTitle(state.shift, isShift, readOnly, shiftForm.isSeriesSelected);

    return (
        <Dialog
            width={550}
            onClose={dispatch("reset")}
            onBeforeClose={dispatch("beforeClose", !shiftForm.isSaving && shiftForm.hasChanged)}
            icon={icon}
            externalRef={dialogRef}
            title={title}
            content={shiftForm.renderForm()}
            footer={readOnly ? null :
                <Flex fill space={"between"}>
                    {shiftForm.renderDeleteButton(dialogRef.dispatch("close"))}
                    <Flex fill hAlign={"end"}>
                        {shiftForm.renderSaveButton(dialogRef.dispatch("close"))}
                    </Flex>
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const generateIcon = (shift: Immutable<Shift> | undefined, readOnly: boolean) => {
    if (readOnly) return <ShiftsIcon/>;
    if (!shift) return <AddIcon outline/>;
    return <EditIcon outline/>;
}

const generateTitle = (
    shift: Immutable<Shift> | undefined,
    isShift: boolean,
    readOnly: boolean,
    isSeriesSelected: boolean
) => {
    if (readOnly) return translations.get(isShift ? "ShiftDetails" : "AbsenceDetails");
    if (!shift) return translations.get(isShift ? "NewShift" : "NewAbsence");
    if (isSeriesSelected) return translations.get("EditSeries");
    return translations.get(isShift ? "EditShift" : "EditAbsence");
}