import {useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "translations";
import {Button, Flex} from "@fluentui/react-northstar";
import {ExportIcon} from "../../assets/icons";
import React, {useCallback} from "react";
import {PlanningApi} from "../../apis/Planning/PlanningApi";
import {useUserRolesCache} from "../cache/useUserRoleCache";
import {UserRole} from "../../interfaces/UserRole";
import moment from "moment";
import {FileModule} from "../../modules/File.module";
import {useShopCache} from "../cache/useShopsCache";
import {MagicReducerRef, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {DateRangeSelector} from "../../components/others/DateRangeSelector/DateRangeSelector";
import {DateRangeType} from "../../components/others/DateRangeSelector/DateRangeSelector.interfaces";
import {usePlanningDateRangeCache} from "../cache/usePlanningDateRangeCache";

export const useExportPlanningForm = (shopId: string | undefined) => {
    const {isOnlyRole, userRoles} = useUserRolesCache();
    const [isExporting, setIsExporting] = React.useState(false);
    const {shop} = useShopCache(shopId);
    const dateRangeSelectorRef = useMagicReducerRef(DateRangeSelector);
    const {planningDateRange: {selectedRange}} = usePlanningDateRangeCache();

    const isStaff = isOnlyRole(UserRole.Staff);
    const canExportEveryShop = userRoles.superManager || userRoles.administrator;

    const form = useForm({
        disabled: isExporting,
        items: {
            onlyMyShop: {
                type: "checkbox",
                toggle: true,
                label: translations.get("OnlyMyShop"),
                initialValue: true,
                disabled: !canExportEveryShop,
            }
        }
    });

    const {onlyMyShop} = form.state.data;

    const renderExportButton = (callback: () => void) => isStaff ? null : (
        <Button
            primary icon={<ExportIcon/>}
            content={translations.get("Export")}
            disabled={isExporting || !form.isValid}
            loading={isExporting}
            onClick={handleExportPlanning({
                formData: form.state.data,
                callback,
                shopId,
                shopName: onlyMyShop ? shop?.name : undefined,
                setIsExporting,
                dateRangeSelectorRef
            })}
        />
    )

    const renderForm = () => isStaff ? null : (
        <Flex column fill gap={"gap.small"}>
            <Flex fill vAlign={"end"} gap={"gap.small"}>
                <DateRangeSelector
                    displayAsInput
                    initialDateRange={selectedRange}
                    availableTypes={[DateRangeType.Day, DateRangeType.Week, DateRangeType.Month]}
                    externalRef={dateRangeSelectorRef}
                    disabled={isExporting}
                />
            </Flex>
        </Flex>
    )

    const reset = useCallback(() => {
        form.reset();
        setIsExporting(false);
    }, []);

    return {
        ...form,
        reset,
        renderForm,
        renderExportButton
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleExportPlanning = (props: {
    shopId: string | undefined,
    shopName: string | undefined,
    formData: ReturnType<typeof useExportPlanningForm>["state"]["data"],
    callback: () => void,
    setIsExporting: React.Dispatch<React.SetStateAction<boolean>>,
    dateRangeSelectorRef: MagicReducerRef<typeof DateRangeSelector>,
}) => async () => {
    if (!props.shopId) return;
    const datesRange = props.dateRangeSelectorRef.state?.selectedRange;
    const startDate = datesRange?.startDate as string;
    const endDate = datesRange?.endDate as string;
    const onlyMyShop = props.formData.onlyMyShop as boolean;
    props.setIsExporting(true);
    const file = await PlanningApi.exportPlanning(startDate, endDate, onlyMyShop ? props.shopId : undefined);
    if (!file) return props.setIsExporting(false);
    FileModule.downloadFile(file, generateFileName(file, props.shopName, startDate, endDate));
    props.callback();
    props.setIsExporting(false);
}

const generateFileName = (file: Blob, shopName: string | undefined, startDate: string, endDate: string) => {
    if (file.type === "application/zip") return `planning.zip`;
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
    if (startDate === endDate) return `planning_${formattedStartDate}.xlsx`;
    const formattedShopName = shopName?.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_]/g, '');
    return `planning${formattedShopName ? "_" + formattedShopName : ""}_${formattedStartDate}_${formattedEndDate}.xlsx`;
}