import {QueryClient, useQuery, useQueryClient} from "react-query";
import {SettingsApi} from "../../apis/Settings/SettingsApi";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {SettingsData} from "../../interfaces/SettingsData";
import {ShopApi} from "../../apis/Shop/ShopApi";

export const settingsCacheKey = "settings";

export const useSettingsCache = () => {
    const queryClient = useQueryClient();

    const {
        data,
        isLoading
    } = useQuery([settingsCacheKey], getSettingsWithAssociatedShop, {staleTime: Infinity});

    return {
        settings: data?.settings,
        categories: data?.categories,
        activities: data?.activities,
        isLoading,
        updateSettings: updateSettings(queryClient),
    };
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getSettingsWithAssociatedShop = async () => {
    const settings = await SettingsApi.get();
    if (!settings) return;
    const settingsShop = await ShopApi.getById("settings");
    if (!settingsShop) return;
    return {
        settings,
        categories: settingsShop.categories,
        activities: settingsShop.activities
    };
}

const updateSettings = (queryClient: QueryClient) => async (settings: Immutable<SettingsData>) => {
    const prevSettings = queryClient.getQueryData([settingsCacheKey]);
    if (!prevSettings) return;
    const result = await SettingsApi.update(settings);
    if (!result) return;
    queryClient.setQueryData([settingsCacheKey], {...prevSettings, settings});
}