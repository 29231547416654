import {useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "../../translations";
import {Time} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/TimePicker/TimePicker";

export const useBadgeErrorForm = (disabled: boolean) => {
    const form = useForm({
        disabled,
        items: {
            justification: {
                type: "textArea",
                required: true,
                label: translations.get("Justification"),
                placeholder: translations.get("EnterJustification"),
                minLength: 10,
                maxLength: 300,
                minHeight: "50px",
                maxHeight: "150px",
                clearable: false,
            },
            time: {
                type: "timePicker",
                required: true,
                label: translations.get("RealClockingTime"),
                initialValue: getCurrentTime(),
                clearable: false,
            }
        }
    });

    return {
        ...form,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getCurrentTime = (): Time => {
    const date = new Date();
    return {
        hour: date.getHours(),
        minutes: date.getMinutes()
    }
}