import {Immutable} from "@witivio_teamspro/use-reducer";
import {Recurrence} from "../components/others/ShiftRecurrence/ShiftRecurrence.interfaces";
import moment from "moment/moment";
import {PreShiftData, ShiftActivity, ShiftData, ShiftType} from "../interfaces/ShiftData";
import {Time} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/TimePicker/TimePicker";
import {ObjectModule} from "../modules/Object.module";

export class Shift {
    private shiftData: Immutable<ShiftData>;

    constructor(shiftData: Immutable<ShiftData>) {
        if (shiftData.id === undefined) throw new Error("Shift id is required");
        this.shiftData = ObjectModule.deepClone(shiftData);
        this.initializePreShift();
    }

    getId = (): string => this.getField("id");
    getType = (): ShiftType | undefined => this.getField("type");
    getShopId = (): string | undefined => this.getField("shopId");
    getUserId = (): string | undefined => this.getField("userId");
    getCategoryId = (): string | undefined => this.getField("categoryId");
    getCustomWording = (): string | undefined => this.getField("customWording");
    getDate = (): string | undefined => this.getField("date");
    getStart = (): Time | undefined => this.getField("start");
    getEnd = (): Time | undefined => this.getField("end");
    getClocking = (): { start: Time, end: Time } | undefined => this.getField("clocking");
    getRecurrence = (): Recurrence | undefined => this.getField("recurrence");
    getNotes = (): string | undefined => this.getField("notes");
    getActivities = (): Immutable<Array<ShiftActivity>> | undefined => this.getField("activities");
    get = (): Immutable<ShiftData> => this.shiftData;
    getPreShift = (): Immutable<PreShiftData> | undefined => this.shiftData.preShift;

    isSynchronized = (): boolean => {
        if (!this.shiftData.preShift || Object.keys(this.shiftData.preShift).length === 0) return true;
        return Object.keys(this.shiftData.preShift).length === 1 && "id" in this.shiftData.preShift;
    }

    isRecurring = (): boolean => !!this.getRecurrence();

    isDone = (): boolean => {
        if (this.isClocked()) return true;
        return !!this.shiftData.date && moment(this.shiftData.date).isBefore(moment(), "day");
    }

    getDateWithTime = () => {
        const endTime = this.getClocking()?.end ?? this.getEnd();
        const date = moment(this.getDate()).add(endTime?.hour ?? 0, "hours").add(endTime?.minutes ?? 0, "minutes");
        return date.toISOString(false);
    }

    getDurationInHours = () => {
        const startTime = this.getClocking()?.start ?? this.getStart();
        const endTime = this.getClocking()?.end ?? this.getEnd();
        const start = moment(((startTime?.hour ?? 0) + "").padStart(2, "0") + ":" + ((startTime?.minutes ?? 0) + "").padStart(2, "0"), "HH:mm");
        const end = moment(((endTime?.hour ?? 0) + "").padStart(2, "0") + ":" + ((endTime?.minutes ?? 0) + "").padStart(2, "0"), "HH:mm");
        const diff = moment.duration(end.diff(start));
        return diff.hours();
    }

    getField = <K extends keyof PreShiftData>(field: K): PreShiftData[K] => {
        return (this.shiftData.preShift?.[field] ?? this.shiftData[field]) as PreShiftData[K];
    }

    setField = <K extends keyof PreShiftData>(field: K, value: Immutable<PreShiftData[K]>): Shift => {
        this.initializePreShift();
        this.shiftData = {
            ...this.shiftData,
            id: field == "id" ? (value as string) : this.shiftData.id,
            preShift: {...this.shiftData.preShift!, [field]: value},
        }
        return this;
    }

    updateClocking = (clocking: { start: Time, end: Time }): Shift => {
        this.shiftData = {...this.shiftData, clocking};
        return this;
    }

    setFields = (fields: Partial<Immutable<PreShiftData>>): Shift => {
        this.initializePreShift();
        this.shiftData = {
            ...this.shiftData,
            id: !fields.id ? this.shiftData.id : fields.id,
            preShift: {...this.shiftData.preShift!, ...fields},
        }
        return this;
    }

    applyUpdates = (): Shift => {
        if (!this.shiftData.preShift) return this;
        this.shiftData = {...this.flatten(), preShift: undefined};
        this.initializePreShift();
        return this;
    }

    clone = (): Shift => {
        return new Shift(this.shiftData);
    }

    isToday = (): boolean => {
        const date = this.getDate();
        const now = moment().startOf("day");
        return !date ? false : moment(date).isSame(now, "day");
    }

    isClocked = (): boolean => {
        const clocking = this.getClocking();
        return !!clocking && !!clocking.start && !!clocking.end;
    }

    flatten = (): Immutable<PreShiftData> => ({
        id: this.getId(),
        type: this.getType(),
        shopId: this.getShopId(),
        userId: this.getUserId(),
        categoryId: this.getCategoryId(),
        customWording: this.getCustomWording(),
        date: this.getDate(),
        start: this.getStart(),
        end: this.getEnd(),
        clocking: this.getClocking(),
        recurrence: this.getRecurrence(),
        notes: this.getNotes(),
        activities: this.getActivities(),
    });

    private initializePreShift = () => {
        if (!this.shiftData.preShift) this.shiftData = {...this.shiftData, preShift: {id: this.shiftData.id}};
    }
}