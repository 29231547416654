import React, {memo, ReactElement} from "react";
import {Props} from "./Cell.interfaces";
import {CompareModule} from "modules/Compare.module";
import "./Cell.styles.scss";
import {Flex} from "@fluentui/react-northstar";

export const Cell = memo((props: Props): ReactElement | null => {
    const className = [
        "cell",
        props.primary && "primary",
        props.inline && "inline",
        props.noPadding && "no-padding",
        props.noBorder && "no-border",
        props.selected && "selected",
        props.className,
    ].filter(Boolean).join(" ");

    return (
        <Flex fill={props.fluid ?? false} className={className} onClick={props.onClick}>
            {props.children}
        </Flex>
    );
}, CompareModule.areObjectsEqual);