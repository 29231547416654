import {useQuery} from "react-query";
import {ReportApi} from "../../apis/Report/ReportApi";

export const shopReportCacheKey = "shopReport";

export const useShopReportCache = (shopId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    const {
        data: shopReport,
        isLoading
    } = useQuery([shopReportCacheKey, shopId, startDate, endDate], () => ReportApi.getShopReport(shopId, startDate, endDate), {
        staleTime: Infinity,
        enabled: !!shopId && !!startDate && !!endDate
    });

    return {
        shopReport,
        isLoading,
    }
}