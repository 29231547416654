import React, {memo, ReactElement, useEffect, useMemo} from "react";
import {Props, State} from "./ShiftPicker.interfaces";
import {
    Immutable,
    MagicDispatch,
    MagicReducerRef,
    useMagicReducer,
    useMagicReducerRef
} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShiftPicker.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ShiftPicker.styles.scss";
import {
    DatepickerCalendar,
    DateRangeType,
    Divider,
    Dropdown,
    Flex,
    FormLabel,
    PopperRefHandle,
    Popup,
    Text
} from "@fluentui/react-northstar";
import {translations} from "../../../translations";
import moment from "moment";
import {useMsTeamsSelector} from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {DateRangeSelectorHelpers} from "../DateRangeSelector/DateRangeSelector.reducer";
import {WeekDaysModule} from "../../../modules/WeekDays.module";
import {useUserGroupsShiftsCache, useUserShiftsCache} from "../../../hooks/cache/useShiftsCache";
import {ShiftCard} from "../ShiftCard/ShiftCard";
import {Shift} from "../../../classes/Shift";
import {SideView} from "../../dialogs/SideView/SideView";

export const ShiftPicker = memo((props: Props): ReactElement | null => {
    const {fullLocale, userId, isOnMobile} = useMsTeamsSelector("fullLocale", "userId", "isOnMobile");
    const popperRef = React.useRef<PopperRefHandle>(null);
    const sideViewRef = useMagicReducerRef(SideView);
    const [state, dispatch] = useMagicReducer(reducer({
        props,
        sideViewRef,
        isOnMobile
    }), initialState(props), props.externalRef);
    const {shifts: userGroupsShifts} = useUserGroupsShiftsCache(state.selectedDate, state.selectedDate, !props.onlyMyShifts);
    const {shifts: userShifts} = useUserShiftsCache(userId, state.selectedDate, state.selectedDate, props.onlyMyShifts);

    useEffect(function onDefaultSelectedShiftChange() {
        if (!props.defaultSelectedShift) return;
        dispatch("handleSelectShift", props.defaultSelectedShift)();
    }, [props.defaultSelectedShift]);

    useEffect(function onSelectedShiftChange() {
        if (!state.selectedShift) return;
        popperRef.current?.updatePosition();
        props.onShiftSelected?.(state.selectedShift);
    }, [state.selectedShift]);

    const shortDaysNames = useMemo(() => DateRangeSelectorHelpers.getShortDaysNames(fullLocale), []);
    const monthsNames = useMemo(() => DateRangeSelectorHelpers.getMonthsNames(fullLocale), []);
    const firstDayOfWeek = useMemo(() => WeekDaysModule.getFirstDayOfWeek(), []);

    const shifts = useMemo(() => {
        let items = props.onlyMyShifts ? userShifts : userGroupsShifts;
        items = items?.map(s => new Shift({...s.get(), preShift: undefined}));
        items = items?.filter(s => !!s.getDate() && !!s.getShopId() && !!s.getUserId());
        return items;
    }, [props.onlyMyShifts, userShifts, userGroupsShifts]);

    const dropdown = useMemo(() => (
        <Dropdown
            fluid
            ref={dispatch("handleDropdownRef")}
            className={"shift-picker-dropdown"}
            value={!state.selectedShift ? undefined : 0}
            items={[0]}
            multiple
            {...(isOnMobile && {onClick: sideViewRef.dispatch("open")})}
            placeholder={state.selectedShift ? "" : translations.get("SelectAShift")}
            renderSelectedItem={() => !state.selectedShift ? null : (
                <Flex fill key={state.selectedShift?.getId()} style={{paddingLeft: "10px"}}>
                    <ShiftCard small readonly showDate showAvatar shift={state.selectedShift}/>
                </Flex>
            )}
        />
    ), [state.selectedShift, props.onlyMyShifts]);

    const datePicker = useMemo(() => (
        <DatepickerCalendar
            className={"no-select overflow-hidden"}
            style={{marginTop: "-10px", minHeight: "245px", maxHeight: "245px"}}
            shortDays={shortDaysNames}
            months={monthsNames}
            formatMonthDayYear={date => moment(date).locale(fullLocale).format("L")}
            firstDayOfWeek={firstDayOfWeek as number}
            onDateChange={dispatch("handleChangeDate")}
            dateRangeType={DateRangeType.Day}
            selectedDate={moment(state.selectedDate).toDate()}
            {...(props.onlyDoneShifts ? {
                maxDate: moment().startOf("day").add(-1, "day").toDate(),
            } : {
                minDate: moment().startOf("day").toDate(),
            })}
        />
    ), [state.selectedDate, props.onlyDoneShifts]);

    const shiftsList = (
        <Flex fill column gap={"gap.small"} style={isOnMobile ? {} : {height: "245px"}} className={"overflow-hidden"}>
            <Flex space={"between"}>
                <Text weight={"bold"} content={translations.get("AvailableShifts")}/>
                <Text style={{color: "darkgray"}} content={moment(state.selectedDate).format("L")}/>
            </Flex>
            <Flex fill column gap={"gap.small"} className={"overflow-scroll"}>
                {renderShiftsList(dispatch, shifts, props.onlyMyShifts)}
            </Flex>
        </Flex>
    );

    const className = [
        "shift-picker",
        "no-select",
        isOnMobile && "shift-picker-mobile"
    ].filter(Boolean).join(" ");

    return (
        <Flex column className={className}>
            <FormLabel>
                <Text content={props.label}/>
                <Text style={{color: "rgb(196, 49, 75)"}}> *</Text>
            </FormLabel>
            {!isOnMobile ?
                renderPopup({state, dropdown, popperRef, datePicker, shiftsList})
                :
                <>
                    {dropdown}
                    {renderDialog({sideViewRef, datePicker, shiftsList})}
                </>
            }
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderShiftsList = (
    dispatch: MagicDispatch<typeof reducer>,
    shifts: Immutable<Array<Shift | undefined>> | undefined,
    onlyMyShifts: boolean = false,
) => {
    if (shifts?.length === 0) return (
        <Flex fill hAlign={"center"} vAlign={"center"}>
            <Text content={translations.get("NoShiftAvailable")}/>
        </Flex>
    );
    let finalShifts = shifts;
    if (!shifts) finalShifts = Array.from({length: 3}).map(() => undefined);
    return finalShifts?.map((s, i) => (
        <Flex key={s?.getId() ?? i} className={"cursor-pointer"}>
            <ShiftCard
                small
                readonly
                showAvatar={!onlyMyShifts}
                shift={s}
                onClick={dispatch("handleSelectShift", s)}
            />
        </Flex>
    ));
}

const renderPopup = (config: {
    state: Immutable<State>,
    dropdown: ReactElement,
    popperRef: React.RefObject<PopperRefHandle>,
    datePicker: ReactElement,
    shiftsList: ReactElement,
}) => {
    const {state, dropdown, datePicker, shiftsList, popperRef} = config;
    return (
        <Popup
            key={state.popupKey}
            position={"below"}
            trigger={dropdown}
            popperRef={popperRef}
            content={{
                styles: {width: state.dropdownWidth + "px"},
                content: (
                    <Flex fill gap={"gap.small"} className={"no-select overflow-hidden"}>
                        <Flex className={"no-shrink"} gap={"gap.small"}>
                            {datePicker}
                            <Flex>
                                <Divider vertical style={{marginLeft: "-5px"}}/>
                            </Flex>
                        </Flex>
                        {shiftsList}
                    </Flex>
                )
            }}
        />
    )
}

const renderDialog = (config: {
    sideViewRef: MagicReducerRef<typeof SideView>,
    datePicker: ReactElement,
    shiftsList: ReactElement,
}) => {
    const {sideViewRef, shiftsList, datePicker} = config;
    return (
        <SideView externalRef={sideViewRef} title={translations.get("SelectAShift")} style={{zIndex: 9999}}>
            <Flex fill column gap={"gap.small"} className={"no-select overflow-hidden"}
                  style={{padding: "15px"}}>
                <Flex column className={"no-shrink overflow-hidden"} gap={"gap.small"}>
                    {datePicker}
                    <Divider style={{marginLeft: "-5px"}}/>
                </Flex>
                <Flex fill className={"overflow-hidden"}>
                    {shiftsList}
                </Flex>
            </Flex>
        </SideView>
    )
}