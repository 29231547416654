import {Props, State} from "./ShiftPicker.interfaces";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import moment from "moment";
import React from "react";
import {DatepickerCalendarProps} from "@fluentui/react-northstar";
import {IDay} from "@fluentui/react-northstar/dist/es/utils/date-time-utilities";
import {Shift} from "../../../classes/Shift";
import {SideView} from "../../dialogs/SideView/SideView";

export const initialState = (props: Props): State => ({
    popupKey: "popup",
    selectedShift: undefined,
    selectedDate: moment().startOf("day").add(props.onlyDoneShifts ? -1 : 0, "day").toISOString(false),
    dropdownWidth: 500,
})

export const reducer = (config: {
    props: Props,
    isOnMobile: boolean,
    sideViewRef: MagicReducerRef<typeof SideView>,
}) => ({
    handleChangeDate: ({setState}, [, datePickerData]: [React.SyntheticEvent, DatepickerCalendarProps & {
        value: IDay;
    } | undefined]) => {
        const value = datePickerData?.value.originalDate.toISOString();
        if (!value) return;
        setState({selectedDate: value});
    },
    handleSelectShift: ({setState}, _, shift: Immutable<Shift> | undefined) => {
        if (!shift) return;
        setState({selectedShift: shift, popupKey: Math.random() + ""});
        if (config.isOnMobile) config.sideViewRef.dispatch("close")();
    },
    handleDropdownRef: ({state, setState}, [element]: [HTMLDivElement | null]) => {
        const width = element?.clientWidth;
        if (!width || state.dropdownWidth === width) return;
        setState({dropdownWidth: width});
    },
    clear: ({setState}) => {
        setState({...initialState(config.props)});
    }
}) satisfies MagicReducerObject<State>;