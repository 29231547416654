import React, {memo, ReactElement, useEffect, useLayoutEffect, useMemo} from "react";
import {Props} from "./ShiftStaffs.interfaces";
import {Immutable, ImmutableObject, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShiftStaffs.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ShiftStaffs.styles.scss";
import {WeekDaysModule} from "../../../modules/WeekDays.module";
import {ComponentSlotStyle, Flex, Skeleton, Text} from "@fluentui/react-northstar";
import {Cell} from "../Cell/Cell";
import {ShiftType} from "../../../interfaces/ShiftData";
import {ShiftCard} from "../ShiftCard/ShiftCard";
import {useShopCache} from "../../../hooks/cache/useShopsCache";
import {translations} from "../../../translations";
import {useGroupCache} from "../../../hooks/cache/groups/useGroupCache";
import {GroupModule} from "../../../modules/Group.module";
import {usePlanningDateRangeCache} from "../../../hooks/cache/usePlanningDateRangeCache";
import {ShopShiftCategory} from "../../../interfaces/ShopData";
import {GroupData} from "../../../interfaces/GroupData";
import {useGroupShiftsCache} from "../../../hooks/cache/groups/useGroupShiftsCache";
import moment, {Moment} from "moment";
import {Shift} from "../../../classes/Shift";

export const ShiftStaffs = memo((props: Props): ReactElement | null => {
    const {planningDateRange} = usePlanningDateRangeCache();
    const {selectedRange} = planningDateRange
    const rangeDays = useMemo(() => WeekDaysModule.getRangeDays(selectedRange.startDate, selectedRange.endDate), [selectedRange.startDate, selectedRange.endDate]);
    const daysCount = rangeDays.length;
    const [state, dispatch] = useMagicReducer(reducer({
        daysCount,
        isVisible: props.isVisible
    }), initialState(daysCount));
    const {shifts} = useGroupShiftsCache(props.groupId, selectedRange);
    const {group} = useGroupCache(props.groupId);
    const {shop} = useShopCache(group?.shopId);

    const shiftCategory = shop?.categories.find(c => c.key === props.shiftCategoryId);

    const shift: Shift | undefined = useMemo(() => generateShift(group?.shopId, shiftCategory), [shiftCategory, group?.shopId]);

    useEffect(function onResize() {
        const handler = dispatch("updateCardsFormat");
        window.addEventListener("resize", handler);
        return () => window.removeEventListener("resize", handler);
    }, []);

    useLayoutEffect(function onDaysCountChange() {
        dispatch("updateCardsFormat")();
    }, [daysCount]);

    const shiftCell = useMemo(() => {
        return (
            <Flex fill className={"shift-cell"}>
                <Cell primary fluid noPadding noBorder>
                    <Flex fill className={"overflow-hidden"} vAlign={"center"} gap={"gap.small"}>
                        <ShiftCard readonly square currentShopId={group?.shopId} shift={shift} userId={undefined}
                                   onClick={() => 0}/>
                    </Flex>
                </Cell>
            </Flex>
        )
    }, [shift]);

    const showSkeletons = state.showSkeletons || !group || !shifts || !state.visible;

    const staffsCells = useMemo(() => rangeDays.map(day => {
        const staffCount = getStaffsCountPerDate({
            date: day,
            shifts,
            group,
            shiftCategoryId: props.shiftCategoryId,
        });
        const textStyles: ComponentSlotStyle = staffCount.isInvalid ? {color: "red"} : {};
        return (
            <Flex key={"day" + day} fill className={"overflow-hidden"}>
                <Cell fluid inline
                      className={WeekDaysModule.isDateWeekEnd(day.toISOString(false)) ? "planning-weekend-cell" : ""}>
                    <Flex fill column gap={"gap.smaller"} styles={{minHeight: "60px", padding: "0 8px"}}>
                        <Flex fill column vAlign={"center"}>
                            {showSkeletons ?
                                <Skeleton animation={"pulse"}>
                                    <Skeleton.Text size={"large"} width={"100%"}/>
                                    <Skeleton.Text width={"100%"}/>
                                </Skeleton>
                                :
                                <>
                                    <Text styles={textStyles} size={"large"} weight={"bold"}
                                          content={`${staffCount.count} / ${staffCount.maximum}`}/>
                                    <Text styles={textStyles} content={translations.get("Attributed").toLowerCase()}/>
                                </>
                            }
                        </Flex>
                    </Flex>
                </Cell>
            </Flex>
        )
    }), [group, rangeDays, shifts, showSkeletons, props.shiftCategoryId, props.visibleCategoriesIds]);

    return (
        <Flex className={"shift-staffs"} ref={dispatch("containerRef")}>
            {shiftCell}
            {staffsCells}
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const generateShift = (shopId: string | undefined, shiftCategory: ImmutableObject<ShopShiftCategory> | undefined) => {
    if (!shiftCategory || !shopId) return undefined;
    return new Shift({
        id: "",
        type: ShiftType.Shift,
        shopId: shopId,
        categoryId: shiftCategory?.key,
        start: shiftCategory?.start,
        end: shiftCategory?.end,
    });
}

const getStaffsCountPerDate = (params: {
    group: Immutable<GroupData> | undefined,
    shifts: Immutable<Array<Shift>> | undefined,
    shiftCategoryId: string,
    date: Moment,
}) => {
    const {shifts, shiftCategoryId, group, date} = params;
    if (!group || !shifts) return {count: 0, maximum: 0, isInvalid: false};
    const count = shifts.filter(s => s.getCategoryId() === shiftCategoryId && moment(s.getDate()).isSame(date, "day")).length;
    const maximum = GroupModule.getShiftCategoryThresholdByDate(group, shiftCategoryId, date.toISOString(false));
    return {
        count,
        maximum,
        isInvalid: count < maximum,
    };
}