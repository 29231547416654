import {QueryClient, useQuery, useQueryClient} from "react-query";
import {ShiftApi} from "../../apis/Shift/ShiftApi";

export const userLastShiftCacheKey = "userLastShift";

export const useUserLastShiftCache = () => {
    const queryClient = useQueryClient();

    const {data: lastUserShift, isLoading} = useQuery([userLastShiftCacheKey], ShiftApi.getNextUserShift, {
        staleTime: Infinity,
    });

    return {
        lastUserShift,
        isLoading,
        invalidateUserLastShift: invalidateUserLastShift(queryClient),
    };
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const invalidateUserLastShift = (queryClient: QueryClient) => async () => {
    await queryClient.invalidateQueries([userLastShiftCacheKey]);
}