import React, {ReactElement} from "react";
import {useMsTeamsSelector} from "../../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import {
    ActiveElement,
    ArcElement,
    Chart,
    Chart as ChartJS,
    ChartData,
    ChartEvent,
    ChartOptions,
    Legend,
    LegendItem,
    Tooltip,
} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import {translations} from "../../../../translations";
import {Props} from "./PieChartKpi.interfaces";
import {ColorModule} from "../../../../modules/Color.module";
import {LayoutPosition} from "chart.js/dist/types/layout";
import {TRBL} from "chart.js/dist/types/geometric";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export const PieChartKpi = (props: Props): ReactElement | null => {
    const {isLightTheme} = useMsTeamsSelector("isLightTheme");

    return (
        <Flex column fill className={"overflow-hidden"}>
            {!props.title ? null : (
                <Flex>
                    <Text weight={"semibold"} size={"large"} content={props.title}/>
                </Flex>
            )}
            {props.showSkeletons ?
                <Loader styles={{height: "100%"}}/>
                :
                <Flex fill className={"pos-relative"} styles={{marginTop: "10px"}}>
                    <Flex className={"fill-absolute"} vAlign={"center"} hAlign={"center"}>
                        {renderPieChart(props, isLightTheme)}
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handlePieClick = (onClick: Props["onClick"]) => (_: ChartEvent, elements: ActiveElement[]) => {
    if (!onClick || elements.length < 0) return;
    const clickedItem = elements[0];
    const index = clickedItem?.index;
    if (index !== undefined) onClick(index);
};

const renderPieChart = (
    props: Props,
    isLightTheme: boolean,
) => {
    const noDataAvailable = props.values.length === 0;

    if (props.showSkeletons) return null;
    if (noDataAvailable) return (
        <Text content={translations.get("NoDataAvailable")}/>
    )
    return renderPieChartContent({
        xLabels: props.labels,
        data: props.values,
        colors: props.colors,
        isLightTheme: isLightTheme,
        onClick: handlePieClick(props.onClick),
        width: props.width,
        position: props.labelPosition,
        padding: props.padding,
        labelFormatter: props.formatLabel,
    });
}

const renderPieChartContent = (data: {
    xLabels: Array<string>,
    data: Array<number>,
    colors: Array<string> | undefined,
    isLightTheme: boolean,
    onClick: (event: ChartEvent, elements: ActiveElement[]) => void,
    width: number | undefined,
    position: LayoutPosition | undefined,
    padding: TRBL | undefined,
    labelFormatter: ((value: number) => string) | undefined,
}) => {
    const chartTextColor = data.isLightTheme ? "#252423" : "white";
    const chartLabels = data.xLabels;
    const position = data.position ?? ((data.width ?? 1) === 1 ? "bottom" : "left");
    const isBottom = position === "bottom";
    const fontSize = getFontSize(chartLabels.length);
    const chartOptions: ChartOptions<"pie"> = {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: data.padding ?? {
                top: isBottom ? 10 : 0,
                left: isBottom ? 0 : -5,
                right: isBottom ? 0 : -999
            },
        },
        plugins: {
            datalabels: {
                color: '#FFF',
                font: {
                    size: 25,
                },
                formatter: data.labelFormatter ?? (v => v),
            },
            legend: {
                position,
                labels: {
                    padding: 15,
                    font: {
                        family: "Segoe UI",
                        size: fontSize,
                    },
                    boxWidth: 15,
                    boxHeight: 15,
                    generateLabels: (chart: Chart): LegendItem[] => {
                        const {data: chartData} = chart;
                        const labels = chartData.labels as string[];
                        return labels.map((label, i) => ({
                            text: label.length > 25 ? label.substring(0, 22) + '...' : label,
                            fillStyle: data.colors?.[i] ?? ColorModule.getColorFromIndex(i),
                            borderRadius: 2,
                            strokeStyle: "transparent",
                            fontColor: chartTextColor,
                        }));
                    }
                },
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const value = context.parsed;
                        const total = (context.chart.getDatasetMeta(context.datasetIndex) as any)?.total ?? 0;
                        const percentage = ((value / total) * 100).toFixed(0);
                        return ` ${percentage}% (${value})`;
                    }
                }
            },
        },
        onClick: data.onClick,
    };
    const colors = data.colors ?? data.xLabels.map((_, i) => ColorModule.getColorFromIndex(i));
    const chartData: ChartData<"pie"> = {
        labels: chartLabels,
        datasets: [{
            data: data.data,
            borderColor: "white",
            borderWidth: 1,
            backgroundColor: colors,
        }]
    };
    return <Pie options={chartOptions} data={chartData}/>;
}

const getFontSize = (itemsCount: number) => {
    if (itemsCount < 6) return 18;
    if (itemsCount <= 12) return 16;
    if (itemsCount <= 21) return 14;
    return 12;
}