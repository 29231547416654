import React, {memo, ReactElement, useEffect, useLayoutEffect} from "react";
import {Props} from "./Clocking.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Clocking.reducer";
import {CompareModule} from "modules/Compare.module";
import "./Clocking.styles.scss";
import {Button, Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../../translations";
import {ClockingPlayIcon, ClockingStopIcon, TimerIcon} from "../../../assets/icons";
import {TimeModule} from "../../../modules/Time.module";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";
import {useUserLastShiftCache} from "../../../hooks/cache/useUserLastShiftCache";
import {useShopCache} from "../../../hooks/cache/useShopsCache";
import {ShopShiftCategoryType} from "../../../interfaces/ShopData";
import {useUserBadgeCache} from "../../../hooks/cache/useUserBadgeCache";

export const Clocking = memo((props: Props): ReactElement | null => {
    const {badgeErrorDialog} = useDialogContext();
    const {lastUserShift, isLoading: isUserLastShiftLoading, invalidateUserLastShift} = useUserLastShiftCache();
    const {shop, isLoading: isShopLoading} = useShopCache(lastUserShift?.getShopId());
    const {canBadge, isLoading: canUserBadgeLoading} = useUserBadgeCache();
    const [state, dispatch] = useMagicReducer(reducer({badgeErrorDialog, invalidateUserLastShift}), initialState);

    const isLoading = isUserLastShiftLoading || isShopLoading || canUserBadgeLoading;

    const shiftCategory = shop?.categories.find(category => category.key === lastUserShift?.getCategoryId());

    useLayoutEffect(function onLastUserShiftLoaded() {
        if (!lastUserShift || isLoading) return;
        dispatch("shiftLoaded", lastUserShift)();
    }, [lastUserShift]);

    useEffect(function onPlayStateChange() {
        if (!state.isPlaying) return;
        const interval = setInterval(dispatch("incrementPlaySeconds"), 1000);
        return () => clearInterval(interval);
    }, [state.isPlaying]);

    const isAbsence = shiftCategory?.type === ShopShiftCategoryType.Absence;
    const isNotBadgeable = !canBadge || isAbsence || (!!shiftCategory && !shiftCategory?.badge);
    const areButtonsDisabled = isLoading || state.isBadging || !lastUserShift?.isToday() || !shiftCategory || isNotBadgeable || isAbsence;
    const isPlayButtonDisabled = areButtonsDisabled || state.isPlaying;
    const isStopButtonDisabled = areButtonsDisabled || !state.isPlaying;

    return (
        <Flex column hAlign={"center"} gap={"gap.small"} className={"clocking"}>
            <Flex column hAlign={"center"}>
                <Text size={"large"} weight={"semibold"} content={translations.get("Clocking")}/>
                {renderTimer(state.playSeconds)}
            </Flex>
            <Flex gap={"gap.large"}>
                <Button
                    key={"play" + state.isPlaying}
                    disabled={isPlayButtonDisabled}
                    disabledFocusable={isPlayButtonDisabled}
                    primary className={"play-button"}
                    icon={<ClockingPlayIcon/>} iconOnly circular
                    onClick={dispatch("play")}
                />
                <Button
                    key={"stop" + state.isPlaying}
                    primary
                    disabled={isStopButtonDisabled}
                    disabledFocusable={isStopButtonDisabled}
                    className={"stop-button"}
                    icon={<ClockingStopIcon/>} iconOnly circular
                    onClick={dispatch("stop")}
                />
            </Flex>
            {isNotBadgeable && renderNotBadgeableLabel()}
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderTimer = (playSeconds: number) => {
    const {seconds, minutes, hour} = TimeModule.getTimeFromSeconds(playSeconds);
    return (
        <Flex style={{gap: "3px"}} vAlign={"center"}>
            <TimerIcon height={"16px"} width={"16px"} style={{marginTop: "2px"}}/>
            <Text content={`${hour}:${minutes}:${seconds}`}/>
        </Flex>
    )
}

const renderNotBadgeableLabel = () => {
    return <Text styles={{color: "darkgray"}} content={translations.get("ShiftInProgressNotBadgeable")}/>;
}