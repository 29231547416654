import React, {memo, ReactElement} from "react";
import {Props} from "./CustomShiftThresholdButton.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./CustomShiftThresholdButton.reducer";
import {CompareModule} from "modules/Compare.module";
import "./CustomShiftThresholdButton.styles.scss";
import {Button, Flex, Text} from "@fluentui/react-northstar";
import moment from "moment";
import {useDialogContext} from "services/DialogContext/DialogContext";
import {useGroupCache} from "../../../hooks/cache/groups/useGroupCache";

export const CustomShiftThresholdButton = memo((props: Props): ReactElement | null => {
    const dialogContext = useDialogContext();
    const [state, dispatch] = useMagicReducer(reducer, initialState);
    const {group} = useGroupCache(props.groupId);

    const threshold = group?.thresholds.custom.find(t => t.id === props.customThresholdId);

    if (!threshold) return null;
    return (
        <Button key={threshold.id} fluid className={"custom-shift-threshold-button"}
                onClick={dialogContext.customShiftThresholdDialog.dispatch("open", props)}>
            <Flex fill vAlign={"center"} space={"between"} gap={"gap.small"}>
                <Text weight={"semibold"} truncated content={threshold.name}/>
                <Text
                    className={"no-shrink"}
                    styles={{color: "darkgray"}}
                    size={"small"}
                    content={renderDateRangeLabel(threshold.startDate, threshold.endDate)}
                />
            </Flex>
        </Button>
    )
}, CompareModule.areObjectsEqual);

const renderDateRangeLabel = (startDate: string, endDate: string) => {
    return `${moment(startDate).format("L").slice(0, 5)} - ${moment(endDate).format("L").slice(0, 5)}`;
}