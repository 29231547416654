import {InitArgs} from "./ScopesService.interfaces";
import {useCallback} from "react";
import {ErrorModule} from "components/others/ErrorBoundary/ErrorBoundary";
import {scopesServiceButtonAction} from "redux/reducers/ScopesReducer/ScopesReducer";

export const useScopesService = () => {
    const bindConsentButton = useCallback(async (ref: HTMLButtonElement | null) => {
        if (!ref) return;
        if (!scopesServiceButtonAction) return ErrorModule.showErrorAlert("Consent button action is undefined");
        await handleConsentButtonClick(ref, scopesServiceButtonAction)
    }, []);

    return {
        bindConsentButton,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

export const checkConfig = (config: InitArgs) => {
    if (!config.scopes || config.scopes.length === 0)
        config.scopes = [".default"];
    if (!config.clientId) return ErrorModule.showErrorAlert("TeamsFx client id is invalid");
    if (!config.apiBaseUrl) return ErrorModule.showErrorAlert("TeamsFx api base url is invalid");
    if (config.apiBaseUrl.lastIndexOf('/') === config.apiBaseUrl.length - 1)
        config.apiBaseUrl = config.apiBaseUrl.substring(0, config.apiBaseUrl.length - 1);
};

const handleConsentButtonClick = (button: HTMLButtonElement | null, action: () => Promise<void>): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        if (!button) return reject(ErrorModule.showErrorAlert("Consent button ref is undefined"));
        const onClickLogin = async () => {
            await action();
            button?.removeEventListener("click", onClickLogin);
            resolve();
        };
        button?.addEventListener("click", onClickLogin);
    });
};