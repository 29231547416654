import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./GroupConfigurationDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./GroupConfigurationDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Button, Flex, MoreIcon, TrashCanIcon} from "@fluentui/react-northstar";
import "./GroupConfigurationDialog.styles.scss";
import {translations} from "translations";
import {ShiftThresholdsPart} from "./parts/ShiftsThresholdsPart/ShiftThresholdsPart";
import {LegalRulesPart} from "./parts/LegalRulesPart/LegalRulesPart";
import {StaffPart} from "./parts/StaffPart/StaffPart";
import {EditIcon, PeopleSettingsIcon} from "assets/icons";
import {useGroupCache} from "../../../hooks/cache/groups/useGroupCache";
import {PopupMenuButton} from "../../buttons/PopupMenuButton/PopupMenuButton";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";

export const GroupConfigurationDialog = memo((props: Props): ReactElement | null => {
    const {renameGroupDialog, confirmDeleteDialog} = useDialogContext();
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);
    const {group, remove} = useGroupCache(state.groupId);

    const moreButton = useMemo(() => (
        <PopupMenuButton
            position={"below"}
            align={"end"}
            trigger={<Button iconOnly text icon={<MoreIcon/>}/>}
            menu={[
                {
                    key: "rename",
                    content: translations.get("Rename"),
                    icon: <EditIcon/>,
                    onClick: renameGroupDialog.dispatch("open", state.groupId),
                },
                {
                    key: "delete",
                    content: translations.get("Delete"),
                    icon: <TrashCanIcon outline/>,
                    onClick: confirmDeleteDialog.dispatch("open", {
                        title: translations.get("DeleteGroup"),
                        subtitle: translations.get("AreYouSureToDeleteThisGroup", {name: group?.name ?? ""}),
                        callback: async () => {
                            await remove();
                            dialogRef.dispatch("close")();
                        }
                    })
                }
            ]}
        />
    ), [state.groupId]);

    return (
        <Dialog
            externalRef={dialogRef}
            width={1400}
            height={800}
            icon={PeopleSettingsIcon}
            title={translations.get("GroupConfiguration", {group: group?.name ?? ""})}
            headerElement={
                <Flex fill vAlign={"center"} hAlign={"end"}>
                    {moreButton}
                </Flex>
            }
            content={
                <Flex fill styles={{paddingTop: "5px"}} gap={"gap.large"}>
                    <ShiftThresholdsPart dialogRef={dialogRef} groupId={state.groupId}/>
                    <LegalRulesPart groupId={state.groupId}/>
                    <StaffPart groupId={state.groupId}/>
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);