import {Immutable} from "@witivio_teamspro/use-reducer";
import {startTransition, useEffect, useState} from "react";

export type Viewport = {
    top: number;
    left: number;
    width: number;
    height: number;
    scrollHeight: number;
    scrollWidth: number;
}

export const useViewport = (element: Immutable<HTMLElement> | null, pixelsMargin: number = 0) => {
    const [viewport, setViewport] = useState<Viewport>(getDefaultViewport());

    useEffect(() => {
        if (!element) return;
        const handleEvent = () => {
            startTransition(() => setViewport(prevViewport => updateViewport(prevViewport, element, pixelsMargin)));
        };
        element?.addEventListener('scroll', handleEvent);
        const mutationObserver = new MutationObserver(handleEvent);
        const config = {childList: true, subtree: true};
        mutationObserver.observe(element as HTMLDivElement, config);
        handleEvent();
        return () => {
            if (!element) return;
            element?.removeEventListener('scroll', handleEvent);
            mutationObserver.disconnect();
        }
    }, [element, pixelsMargin]);

    return viewport;
};

const getDefaultViewport = (): Viewport => ({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    scrollHeight: 0,
    scrollWidth: 0
})

const getViewport = (element: Immutable<HTMLElement> | null): Viewport => {
    if (!element) return getDefaultViewport();
    const {scrollTop, scrollLeft, clientHeight, clientWidth, scrollHeight, scrollWidth} = element;
    return {
        top: Math.round(scrollTop),
        left: Math.round(scrollLeft),
        width: Math.round(clientWidth),
        height: Math.round(clientHeight),
        scrollHeight: Math.round(scrollHeight),
        scrollWidth: Math.round(scrollWidth)
    };
}

const updateViewport = (prevViewport: Viewport, element: Immutable<HTMLElement> | null, pixelsMargin: number) => {
    const newViewport = getViewport(element);
    return needViewportUpdate(prevViewport, newViewport, pixelsMargin) ? newViewport : prevViewport;
}

const needViewportUpdate = (prevViewport: Viewport, newViewport: Viewport, pixelsMargin: number) => {
    const comparedProperties: Array<keyof Viewport> = ["top", "left", "width", "height", "scrollHeight", "scrollWidth"];
    return comparedProperties.some(prop => !isSameProperty(prevViewport[prop], newViewport[prop], pixelsMargin));
}

const isSameProperty = (prev: number, next: number, gap: number) => Math.abs(prev - next) <= gap;