import {QueryObserverOptions, useQueries, useQuery} from "react-query";
import {GraphService} from "services/GraphService/GraphService";
import {GraphUserData} from "services/GraphService/GraphService.interfaces";
import {Immutable} from "@witivio_teamspro/use-reducer";

export const usersCacheKey = "users";

const getUserQueryArgs = (id: string | undefined): QueryObserverOptions<Immutable<GraphUserData> | undefined> => ({
    queryKey: [usersCacheKey, id],
    queryFn: () => GraphService.getUserAsync(id),
    staleTime: Infinity,
    enabled: !!id,
});

export const useUsersCache = (ids: Immutable<Array<string>> | undefined) => {
    const results = useQueries(ids?.map(getUserQueryArgs) ?? []);

    const isLoading = results.some(r => r.isLoading || r.isFetching);

    const users = (isLoading || results.length === 0) ? undefined :
        results.map(r => r.data).filter(Boolean) as Immutable<GraphUserData>[];

    return {
        users,
        isLoading
    };
};

export const useUserCache = (id: string | undefined) => {
    const {data: user, isLoading} = useQuery(getUserQueryArgs(id));

    return {user, isLoading};
}