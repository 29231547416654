import {State} from "./ShopPart.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {ConfirmCancelChangesDialog} from "../../../ConfirmCancelChangesDialog/ConfirmCancelChangesDialog";

export const initialState: State = {}

export const reducer = (config: {
    confirmCancelChangesDialog: MagicReducerRef<typeof ConfirmCancelChangesDialog>,
}) => ({
    beforeClose: ({}, [callback]: [(close: boolean) => void], hasChanged: boolean) => {
        if (!hasChanged) return callback(true);
        config.confirmCancelChangesDialog.dispatch("open", callback)();
    }
}) satisfies MagicReducerObject<State>;