import {CreateRequestDialogTab, State} from "./CreateRequestDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import React from "react";
import {MenuItemProps} from "@fluentui/react-northstar";
import {DialogProps} from "../Dialog/Dialog.interfaces";

export const initialState: State = {
    activeTab: CreateRequestDialogTab.Swap,
    onBeforeClose: undefined,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}) => {
        setState({...initialState});
        config.dialogRef.dispatch("open")();
    },
    changeTab: ({setState}, [, data]: [React.SyntheticEvent, MenuItemProps | undefined]) => {
        const tabId = (data as any)?.id;
        if (tabId === undefined) return;
        setState({activeTab: tabId});
    },
    setBeforeClose: ({setState}, [onBeforeClose]: [DialogProps["onBeforeClose"]]) => {
        setState({onBeforeClose});
    }
}) satisfies MagicReducerObject<State>;