import React, {memo, ReactElement, useEffect, useMemo} from "react";
import {Props, RecurrenceType, State} from "./ShiftRecurrence.interfaces";
import {Immutable, MagicDispatch, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {
    generateIntervalsMenuItems,
    generateRecurrenceTypesMenuItems,
    initialState,
    reducer
} from "./ShiftRecurrence.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ShiftRecurrence.styles.scss";
import {ArrowSyncIcon, Button, ChevronDownIcon, Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../../translations";
import {PopupMenuButton} from "../../buttons/PopupMenuButton/PopupMenuButton";
import {WeekDaysModule} from "../../../modules/WeekDays.module";
import {useForm} from "@witivio_teamspro/northstar-form";
import moment from "moment";

export const ShiftRecurrence = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer(props), initialState(props), props.externalRef);

    const disabled = props.disabled ?? false;

    const form = useForm({
        disabled,
        items: {
            endDate: {
                type: "datePicker",
                required: true,
                minDate: moment().startOf("day").add(1, "day").toDate(),
                initialValue: state.endDate,
            }
        }
    });

    const selectedEndDate = form.state.data.endDate;

    useEffect(function onEndDateChange() {
        if (!selectedEndDate) return;
        dispatch("setEndDate", selectedEndDate)();
    }, [selectedEndDate]);

    const recurrenceTypesMenuItems = useMemo(() => generateRecurrenceTypesMenuItems(dispatch), []);

    const intervalsMenuItems = useMemo(() => generateIntervalsMenuItems(dispatch), []);

    return (
        <Flex column className={"shift-recurrence"}>
            <Flex gap={"gap.medium"} vAlign={"center"}>
                <Flex gap={"gap.smaller"} vAlign={"center"}>
                    <ArrowSyncIcon outline/>
                    <Text content={translations.get("RepeatEach")}/>
                </Flex>
                <PopupMenuButton
                    mouseLeaveDelay={500}
                    trigger={
                        <Button
                            className={"shift-recurrence-menu-button"} text content={state.interval}
                            disabled={disabled}
                            icon={<ChevronDownIcon/>}
                        />
                    }
                    menu={intervalsMenuItems}
                    position={"below"}
                    align={"start"}
                    className={"shift-recurrence-menu-item"}
                />
                <PopupMenuButton
                    mouseLeaveDelay={500}
                    trigger={
                        <Button
                            className={"shift-recurrence-menu-button"} text
                            content={translations.get(RecurrenceType[state.type])}
                            icon={<ChevronDownIcon/>}
                            disabled={disabled}
                        />
                    }
                    menu={recurrenceTypesMenuItems}
                    position={"below"}
                    align={"start"}
                    className={"shift-recurrence-menu-item"}
                />
            </Flex>
            {renderWeekDaysButtons(state, dispatch, disabled)}
            <Flex vAlign={"center"} gap={"gap.small"}>
                <Text content={translations.get("Until")}/>
                {form.renderForm()}
            </Flex>
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderWeekDaysButtons = (state: Immutable<State>, dispatch: MagicDispatch<typeof reducer>, disabled: boolean) => {
    const isValidType = state.type === RecurrenceType.Day || state.type === RecurrenceType.Week;
    if (!isValidType) return null;
    const isIntervalValid = state.interval === 1 && state.type === RecurrenceType.Day || state.type === RecurrenceType.Week;
    if (!isIntervalValid) return null;
    const days = WeekDaysModule.getWeekDaysOrderPerCulture();
    return (
        <Flex wrap styles={{gap: "10px", marginBottom: "5px"}}>
            {days.map((day, index) => (
                <Button
                    key={index}
                    styles={{width: "40px"}}
                    content={WeekDaysModule.getDayLabel(day).slice(0, 3)}
                    circular
                    onClick={dispatch("toggleSelectedDay", day)}
                    primary={state.days.includes(day)}
                    disabled={disabled}
                />
            ))}
        </Flex>
    )
}