import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./ShopShiftsSideView.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShopShiftsSideView.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ShopShiftsSideView.styles.scss";
import {SideView} from "../../../../components/dialogs/SideView/SideView";
import {useShopCache} from "../../../../hooks/cache/useShopsCache";
import {Flex, Text} from "@fluentui/react-northstar";
import {DateRangeSelector} from "../../../../components/others/DateRangeSelector/DateRangeSelector";
import {GroupAccordion} from "../../../../components/others/GroupAccordion/GroupAccordion";
import {useShopGroupsCache} from "../../../../hooks/cache/groups/useShopGroupsCache";
import {useShopShiftsCache} from "../../../../hooks/cache/useShiftsCache";
import {ShiftsList} from "../ShiftsList/ShiftsList";
import {Shift} from "../../../../classes/Shift";
import {translations} from "../../../../translations";
import {DateRangeType} from "../../../../components/others/DateRangeSelector/DateRangeSelector.interfaces";

export const ShopShiftsSideView = memo((props: Props): ReactElement | null => {
    const sideViewRef = useMagicReducerRef(SideView);
    const dateRangeRef = useMagicReducerRef(DateRangeSelector);
    const [state, dispatch, render] = useMagicReducer(reducer({sideViewRef}), initialState, props.externalRef);
    const {shop} = useShopCache(state.shopId);
    const {groups} = useShopGroupsCache(state.shopId);
    const startDate = dateRangeRef.state?.selectedRange.startDate;
    const endDate = dateRangeRef.state?.selectedRange.endDate;
    const {shifts} = useShopShiftsCache(state.shopId, startDate, endDate);

    const shiftsWithoutPreshifts = useMemo(() => {
        return shifts?.map(s => new Shift({...s.get(), preShift: undefined}));
    }, [shifts]);

    const shiftsPerGroup = useMemo(() => {
        if (!groups) return [];
        if (!shiftsWithoutPreshifts && groups) return groups.map(g => ({groupId: g.id, shifts: undefined}));
        const groupedShifts = shiftsWithoutPreshifts!.reduce((acc, shift) => {
            const userId = shift?.getUserId() ?? "";
            const groupId = groups!.find(g => g.usersIds.includes(userId))?.id;
            if (groupId) {
                if (!acc[groupId]) acc[groupId] = [];
                if (shift) acc[groupId]!.push(shift);
            }
            return acc;
        }, {} as Record<string, Array<Shift>>);
        const items = Object.entries(groupedShifts).map(([groupId, shifts]) => ({groupId, shifts}));
        return items.sort((a, b) => {
            const aGroup = groups!.find(g => g.id === a.groupId);
            const bGroup = groups!.find(g => g.id === b.groupId);
            if (!aGroup || !bGroup) return 0;
            return aGroup.index - bGroup.index;
        });
    }, [shiftsWithoutPreshifts, groups]);

    const groupsItems = shiftsPerGroup?.map(({groupId, shifts}) => (
        <GroupAccordion
            key={"group" + groupId}
            groupId={groupId}
            shopId={state.shopId}
            defaultOpen hideActions>
            <ShiftsList shiftDetailsSideViewRef={props.shiftDetailsSideViewRef} shifts={shifts} small showAvatar/>
        </GroupAccordion>
    ));

    const hasNoShift = shiftsWithoutPreshifts?.length === 0;

    const dateRangeSelector = (
        <DateRangeSelector
            availableTypes={[DateRangeType.Day, DateRangeType.Week]}
            externalRef={dateRangeRef}
            onDateRangeChange={render}
        />
    )

    return (
        <SideView externalRef={sideViewRef} title={shop?.name ?? "?"} headerElement={dateRangeSelector}
                  style={{zIndex: 10}}>
            <Flex fill column className={"overflow-scroll"}>
                {hasNoShift && (
                    <Flex fill hAlign={"center"} vAlign={"center"}>
                        <Text size={"large"} content={translations.get("NoShiftAvailable")}
                              style={{color: "darkgray"}}/>
                    </Flex>
                )}
                {groupsItems}
            </Flex>
        </SideView>
    )
}, CompareModule.areObjectsEqual);