import React, {CSSProperties, memo, ReactElement} from "react";
import {Props, ShiftCardFormat, ShiftEditType, ShowDateOptions} from "./ShiftCard.interfaces";
import {Immutable, MagicDispatch, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShiftCard.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ShiftCard.styles.scss";
import {
    AddIcon,
    ArrowSyncIcon,
    Button,
    ComponentSlotStyle,
    Flex,
    MoreIcon,
    Text,
    TrashCanIcon
} from "@fluentui/react-northstar";
import {ColorModule} from "modules/Color.module";
import {useShopCache} from "hooks/cache/useShopsCache";
import {translations} from "translations";
import {BellIcon, CopyIcon, EditIcon, PasteIcon, ShiftsIcon, ShopIcon, TimerIcon} from "assets/icons";
import {ShopShiftActivity, ShopType} from "interfaces/ShopData";
import {PopupMenuButton} from "components/buttons/PopupMenuButton/PopupMenuButton";
import {useDialogContext} from "services/DialogContext/DialogContext";
import {UserThumbnail} from "../UserThumbnail/UserThumbnail";
import {TimeModule} from "modules/Time.module";
import {Shift} from "../../../classes/Shift";
import {useShiftsCache} from "../../../hooks/cache/useShiftsCache";
import moment from "moment";
import {useUserCache} from "../../../hooks/cache/useUsersCache";
import {useSettingsCache} from "../../../hooks/cache/useSettingsCache";

const cardClassNames = [
    "w-100",
    "shift-card",
].join(" ");

export const ShiftCard = memo((props: Props): ReactElement | null => {
    const {updateClockingDialog, shiftDialog, confirmDeleteDialog} = useDialogContext();
    const {notifyShift, deleteShift} = useShiftsCache();
    const {shop: currentShop, isLoading: isCurrentShopLoading} = useShopCache(props.currentShopId);
    const {shop: shiftShop, isLoading: isShopLoading} = useShopCache(props.shift?.getShopId());
    const {categories: settingsCategories, isLoading: areSettingsLoading} = useSettingsCache();

    const shiftCategory = shiftShop?.categories?.find(s => s.key === props.shift?.getCategoryId())
        ?? settingsCategories?.find(s => s.key === props.shift?.getCategoryId());

    const {user} = useUserCache(props.shift?.getUserId());

    const [, dispatch] = useMagicReducer(reducer({
        props,
        updateClockingDialog,
        shiftDialog,
        notifyShift,
        deleteShift,
        confirmDeleteDialog,
        shiftCategoryName: shiftCategory?.name ?? "",
    }), initialState);

    if (props.addCard) return renderAddButton(dispatch("click"));
    if (!props.shift || isShopLoading || isCurrentShopLoading || areSettingsLoading) return renderCardSkeleton();

    const isCurrentShopRotatingStaff = currentShop?.type === ShopType.RotatingStaff;
    const isWorkingInOtherShop = !isCurrentShopRotatingStaff && !!props.currentShopId &&
        props.currentShopId !== props.shift?.getShopId();
    const shiftColor = isWorkingInOtherShop ? "#FFFFFF" : shiftCategory?.color ?? "#FF0000";
    const textColor = ColorModule.getContrastingTextColor(shiftColor);
    const rgbColor = ColorModule.hexToRgb(shiftColor);
    const borderColor = `rgb(${rgbColor?.r}, ${rgbColor?.g}, ${rgbColor?.b})`;
    const backgroundColor = `rgba(${rgbColor?.r}, ${rgbColor?.g}, ${rgbColor?.b}, 0.25)`;
    const format = props.format ?? ShiftCardFormat.Classic;
    const isSynchronized = props.shift.isSynchronized();
    const isPartOfRecurrence = !!props.shift.getRecurrence();

    const cardStyles: ComponentSlotStyle = {backgroundColor: `${backgroundColor} !important`, borderColor};

    const shiftTitle = getShiftTitle(props.shift?.getCustomWording(), shiftCategory?.name, format, isWorkingInOtherShop);

    const activities = shiftShop?.activities.filter(a => props.shift?.getActivities()?.find(sa => sa.key === a.key));

    const containerClassNames = [
        "w-100",
        "no-select",
        "shift-card-container",
        "cursor-pointer",
        !props.transparent ? "fade" : "fade-transparent",
        props.square && "square",
        props.small && "small-shift-card-container"
    ].filter(Boolean).join(" ");

    const cardBorderStyles = generateCardBorderStyles(backgroundColor, borderColor, isSynchronized, isCurrentShopRotatingStaff);

    const card = (
        <Flex key={props.shift.getId()} className={containerClassNames} onClick={dispatch("click")}>
            <Flex className={cardClassNames} styles={cardStyles}>
                <Flex fill className={"overflow-hidden"} vAlign={"center"}>
                    {!isCurrentShopRotatingStaff &&
                        <div
                            className={"shift-card-border"}
                            style={cardBorderStyles}
                        />
                    }
                    <Flex fill column className={"overflow-hidden"} vAlign={"center"}>
                        {isCurrentShopRotatingStaff &&
                            <Flex className={"shift-card-shop"} vAlign={"center"} style={{
                                ...cardBorderStyles, color: isSynchronized ? textColor : "black",
                            }}>
                                <ShopIcon width={14} height={14}/>
                                <Text size={"small"} weight={"semibold"} content={shiftShop?.name}/>
                            </Flex>
                        }
                        <Flex fill className={"overflow-hidden"} vAlign={"center"}>
                            {props.showAvatar &&
                                <Flex className={"shift-card-avatar"}>
                                    <UserThumbnail userId={props.shift.getUserId()} size={"small"}/>
                                </Flex>
                            }
                            <Flex fill column className={"shift-card-content"}>
                                <Flex vAlign={"start"} space={"between"}>
                                    <Flex vAlign={"center"} styles={{gap: "5px"}}>
                                        {isPartOfRecurrence && <ArrowSyncIcon size={"small"} outline/>}
                                        <Text
                                            className={"no-shrink"}
                                            content={props.small ? user?.displayName : shiftTitle}
                                            weight={"semibold"}
                                        />
                                    </Flex>
                                    {props.small && renderDuration(props.shift, shiftCategory?.badge)}
                                </Flex>
                                <Flex className={"overflow-hidden"}>
                                    {format !== ShiftCardFormat.Mini &&
                                        <Flex fill column className={"overflow-hidden"}>
                                            {isWorkingInOtherShop ?
                                                renderWorkingOutside(shiftShop?.name)
                                                :
                                                props.small ?
                                                    <Text size={"small"} content={shiftTitle}/>
                                                    :
                                                    renderDuration(props.shift, shiftCategory?.badge)
                                            }
                                            {props.small !== true && renderActivities(activities, shiftColor, isWorkingInOtherShop)}
                                        </Flex>
                                    }
                                    {!props.readonly && renderMoreButton(dispatch, isSynchronized, shiftColor, format, props.shift, shiftCategory?.badge)}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )

    if (!props.showDate) return card;

    return (
        <Flex fill gap={"gap.small"}>
            {renderDate(props.shift.getDate(), props.showDate)}
            <Flex key={props.shift.getId()} className={containerClassNames}>
                {card}
            </Flex>
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getShiftTitle = (customWording: string | undefined, name: string | undefined, format: ShiftCardFormat, isWorkingInOtherShop: boolean) => {
    let label = customWording ? customWording : name ?? "";
    if (isWorkingInOtherShop) label = translations.get("WorkingOutside");
    switch (format) {
        case ShiftCardFormat.Small:
            return label.substring(0, 20);
        case ShiftCardFormat.Mini:
            return label.substring(0, 3);
    }
    return label;
}

const renderDuration = (shift: Immutable<Shift>, canBadge: boolean | undefined) => {
    let shiftDuration = "";
    if (canBadge !== undefined) {
        const start = canBadge && shift.isDone() ? shift.getClocking()?.start : shift.getStart();
        const end = canBadge && shift.isDone() ? shift.getClocking()?.end : shift.getEnd();
        shiftDuration = TimeModule.getTimeRangeLabel(start, end);
    }
    return (
        <Text
            title={shiftDuration}
            content={shiftDuration}
            size={"small"}
            truncated
        />
    )
}

const renderActivities = (activities: Immutable<ShopShiftActivity>[] | undefined, color: string, isWorkingInOtherShop: boolean) => {
    if (!activities || activities.length === 0 || isWorkingInOtherShop) return null;
    const stringActivities = activities?.map(a => a.name).join(", ");
    return (
        <Text
            content={stringActivities}
            styles={{color, opacity: 0.7}}
            size={"smaller"}
            className={"shift-notes"}
            truncated
        />
    )
}

const renderWorkingOutside = (shopName: string | undefined) => {
    return !shopName ? null : (
        <Flex vAlign={"center"} styles={{gap: "3px"}}>
            <ShopIcon className={"no-shrink"} width={"14px"} height={"14px"}/>
            <Text content={shopName} size={"small"} truncated/>
        </Flex>
    )
}

const renderCardSkeleton = () => {
    return <Flex fill className={"shift-card-skeleton"}/>
}

const renderAddButton = (onClick: () => void) => {
    return (
        <Flex fill vAlign={"center"} hAlign={"center"} className={"shift-card-add-button"} onClick={onClick}>
            <AddIcon outline styles={{color: "darkgray"}}/>
        </Flex>
    )
}

const renderMoreButton = (
    dispatch: MagicDispatch<typeof reducer>,
    isSynchronized: boolean,
    shiftColor: string,
    format: ShiftCardFormat,
    shift: Immutable<Shift>,
    canBadge: boolean | undefined,
) => {
    const isDoneShift = shift.isDone();
    const isRecurringShift = shift.isRecurring();
    return (
        <PopupMenuButton
            position={"below"}
            align={"start"}
            offset={[-20, 0]}
            trigger={
                <Button
                    text
                    iconOnly
                    icon={<MoreIcon/>}
                    className={"hover-shift-card-item"}
                    styles={{
                        paddingTop: "10px",
                        color: shiftColor,
                        marginLeft: format === ShiftCardFormat.Mini ? "-8px" : "-5px"
                    }}
                    onMouseDown={stopPropagation}
                />
            }
            menu={[
                {
                    key: "edit",
                    content: translations.get("EditShift"),
                    icon: <EditIcon/>,
                    onClick: dispatch("editShift"),
                    onMouseDown: stopPropagation,
                },
                ...((!isDoneShift || !canBadge) ? [] : [
                    {
                        key: "updateClocking",
                        content: translations.get("UpdateClocking"),
                        icon: <TimerIcon/>,
                        onClick: dispatch("updateClocking"),
                        onMouseDown: stopPropagation,
                    },
                ]),
                {
                    key: "divider-1",
                    kind: "divider",
                    styles: {margin: "5px 0"},
                    onMouseDown: stopPropagation,
                },
                {
                    key: "copy",
                    content: translations.get("Copy"),
                    icon: <CopyIcon/>,
                    onClick: dispatch("copy"),
                    onMouseDown: stopPropagation,
                },
                ...(isDoneShift ? [] : [
                    {
                        key: "paste",
                        content: translations.get("Paste"),
                        icon: <PasteIcon/>,
                        onClick: dispatch("paste"),
                        onMouseDown: stopPropagation,
                    },
                ]),
                ...(isSynchronized ? [] : [
                    {
                        key: "divider-2",
                        kind: "divider",
                        styles: {margin: "5px 0"},
                        onMouseDown: stopPropagation,
                    }, {
                        key: "notify",
                        content: translations.get("Notify"),
                        icon: <BellIcon/>,
                        onClick: dispatch("notify"),
                        onMouseDown: stopPropagation,
                    }
                ]),
                {
                    key: "divider",
                    kind: "divider"
                },
                ...(isRecurringShift ?
                    [{
                        key: "delete",
                        icon: <TrashCanIcon styles={{color: "red !important"}} outline/>,
                        content: translations.get("Delete"),
                        styles: {color: "red !important"},
                        onMouseDown: stopPropagation,
                        on: "hover",
                        menu: {
                            popper: {position: "after", offset: [-7, 0]},
                            items: [
                                {
                                    key: "delete-occurence",
                                    content: translations.get("Occurence"),
                                    icon: <ShiftsIcon/>,
                                    onClick: dispatch("delete", ShiftEditType.Occurence),
                                    onMouseDown: stopPropagation,
                                },
                                {
                                    key: "delete-series",
                                    content: translations.get("Series"),
                                    icon: <ArrowSyncIcon outline/>,
                                    onClick: dispatch("delete", ShiftEditType.Series),
                                    onMouseDown: stopPropagation,
                                }
                            ]
                        }
                    }] :
                    [{
                        key: "delete",
                        icon: <TrashCanIcon styles={{color: "red !important"}} outline/>,
                        content: translations.get("Delete"),
                        onClick: dispatch("delete"),
                        styles: {color: "red !important"}
                    }]),
            ]}
        />
    )
}

const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();

const generateCardBorderStyles = (backgroundColor: string, borderColor: string, isSynchronized: boolean, isCurrentShopRotatingStaff: boolean): CSSProperties => {
    if (isSynchronized) return {backgroundColor: borderColor};
    if (isCurrentShopRotatingStaff) {
        return {
            background: `repeating-linear-gradient(-45deg, ${backgroundColor}, ${backgroundColor} 6px, ${borderColor} 6px, ${borderColor} 13px)`,
            animation: "shift-border-animation-top 1.5s linear infinite",
        }
    }
    return {
        background: `repeating-linear-gradient(-45deg, ${backgroundColor}, ${backgroundColor} 4px, ${borderColor} 4px, ${borderColor} 8px)`,
        animation: "shift-border-animation 2s linear infinite",
    }
}

const renderDate = (date: string | undefined, format: ShowDateOptions | boolean | undefined) => {
    if (!date) return null;
    const showMonth = typeof format === "boolean" ? true : format?.showMonth;
    const dayLabel = moment(date).format("ddd");
    const dateLabel = moment(date).toDate().toLocaleDateString(moment.locale(), {
        ...(showMonth && {month: 'numeric'}),
        day: 'numeric',
    });
    if (showMonth) return (
        <Flex column vAlign={"center"} hAlign={"center"} className={"no-shrink"}>
            <Text content={dayLabel} size={"small"} truncated/>
            <Text content={dateLabel} weight={"semibold"} truncated/>
        </Flex>
    )
    return (
        <Flex column vAlign={"center"} hAlign={"center"} className={"no-shrink"} style={{marginRight: "12px"}}>
            <Text content={dateLabel} weight={"semibold"} size={"large"} truncated/>
            <Text content={dayLabel} align={"center"} size={"small"} truncated
                  style={{marginTop: "-3px", minWidth: "25px"}}/>
        </Flex>
    )
}