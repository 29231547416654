import React, {CSSProperties, memo, ReactElement} from "react";
import {Props} from "./WeekSummaryRequestPreview.types";
import {Immutable, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./WeekSummaryRequestPreview.reducer";
import {CompareModule} from "modules/Compare.module";
import "./WeekSummaryRequestPreview.styles.scss";
import {useRequestsCache} from "../../../../hooks/cache/useRequestsCache";
import {WeekSummaryRequestData, WeekSummaryUserData} from "../../../../interfaces/RequestData";
import {ExclamationTriangleIcon, Flex, Text} from "@fluentui/react-northstar";
import {RequestPreviewItem} from "../RequestPreviewItem/RequestPreviewItem";
import moment from "moment/moment";
import {translations} from "../../../../translations";
import {PeopleButton} from "../../../buttons/PeopleButton/PeopleButton";
import {TimeModule} from "../../../../modules/Time.module";
import {useDialogContext} from "../../../../services/DialogContext/DialogContext";
import {DialogContextValue} from "../../../../services/DialogContext/DialogContext.interfaces";

export const WeekSummaryRequestPreview = memo((props: Props): ReactElement | null => {
    const {userWeekSummaryDialog} = useDialogContext();
    const {requests} = useRequestsCache();
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    const request = requests?.find(r => r.id === props.requestId) as WeekSummaryRequestData | undefined;

    if (!request) return null;

    return (
        <Flex fill column hAlign={"center"} className={"week-summary-request-preview"}>
            <RequestPreviewItem
                width={"1000px"}
                title={translations.get("WeekSummaryRequestTitle", {date: moment(request.weekDate).format("L")})}
                userId={request.userId}
                date={moment(request.date).toISOString(false)}
                content={
                    <Flex fill className={"content-grid"}>
                        {request.usersData?.map(u => renderPeopleButton(props.requestId, userWeekSummaryDialog, u))}
                    </Flex>
                }
            />
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderPeopleButton = (
    requestId: string | undefined,
    userWeekSummaryDialog: DialogContextValue["userWeekSummaryDialog"],
    data: Immutable<WeekSummaryUserData>
) => {
    const totalWorkedHours = data.totalWorkedHours + data.usedRetrieveHours;
    const areWorkingHoursValid = totalWorkedHours >= data.totalLegalHours;
    const textStyles: CSSProperties = {color: areWorkingHoursValid ? "darkgray" : "red"};

    return (
        <PeopleButton
            fluid userId={data.userId} key={data.userId}
            pictureSize={"small"}
            styles={{padding: "6px 0"}}
            onClick={userWeekSummaryDialog.dispatch("open", {userId: data.userId, requestId})}
            subContent={
                <Flex styles={{gap: "5px"}} className={"overflow-hidden"} vAlign={"center"}>
                    <Text truncated
                          style={textStyles}
                          title={translations.get("WorkingHoursSingularPlural")}
                          content={`${TimeModule.getLabelFromDecimalHours(totalWorkedHours)}`}/>
                    <Text truncated style={textStyles}
                          title={translations.get("LegalWorkingHours")}
                          content={`/ ${TimeModule.getLabelFromDecimalHours(data.totalLegalHours)}`}/>
                    {!areWorkingHoursValid &&
                        <ExclamationTriangleIcon size={"small"} styles={{color: "red", marginTop: "2px"}}/>
                    }
                </Flex>
            }
        />
    )
}