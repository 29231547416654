import {Props, State} from "./Accordion.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";

export const initialState = (props: Props): State => {
    const open = props.expandable === false || (props.defaultOpen ?? false);
    return {
        open,
        contentHeight: 0,
        renderContent: open,
        transitionTimeout: undefined,
        accordionContentContainer: null,
        accordionHeader: null,
    }
}

export const reducer = {
    accordionContentContainerRef: ({setState}, [ref]: [HTMLDivElement | null]) => {
        setState({accordionContentContainer: ref}, false);
    },
    accordionHeaderRef: ({setState}, [ref]: [HTMLDivElement | null]) => {
        setState({accordionHeader: ref}, false);
    },
    toggleOpen: ({state, setState}) => {
        const open = !state.open;
        if (state.transitionTimeout) clearTimeout(state.transitionTimeout);
        if (open) {
            setState({open, renderContent: true});
            setTimeout(() => {
                state.accordionHeader?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "start"
                });
            }, 200);
        } else {
            const transitionTimeout = setTimeout(() => setState({renderContent: false}), 300);
            setState({open, transitionTimeout});
        }
    },
    setContentHeight: ({state, setState}, [contentHeight]: [number]) => {
        if (state.contentHeight === contentHeight) return;
        setState({contentHeight});
    }
} satisfies MagicReducerObject<State>;