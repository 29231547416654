import {Flex} from "@fluentui/react-northstar";
import React from "react";
import "./LoadingPage.styles.scss";
import {CartierShiftIcon} from "../../assets/icons";

type Props = {
    label?: string;
}

export const LoadingPage = (props: Props) => {
    return (
        <Flex fill className={"loading-page"} vAlign={"center"} hAlign={"center"}>
            <CartierShiftIcon height={150} width={150}/>
        </Flex>
    )
}