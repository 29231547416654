import React, {memo, ReactElement, useLayoutEffect} from "react";
import {Props} from "./GroupDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./GroupDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import "./GroupDialog.styles.scss";
import {translations} from "translations";
import {useForm} from "@witivio_teamspro/northstar-form";
import {AddIcon, Button, Flex} from "@fluentui/react-northstar";
import {useShopCache} from "hooks/cache/useShopsCache";
import {useShopGroupsCache} from "hooks/cache/groups/useShopGroupsCache";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";

export const GroupDialog = memo((props: Props): ReactElement | null => {
    const {confirmCancelChangesDialog} = useDialogContext();
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({
        dialogRef,
        confirmCancelChangesDialog
    }), initialState, props.externalRef);
    const {addGroupToShop} = useShopCache(state.shopId);
    const {groups} = useShopGroupsCache(state.shopId);

    useLayoutEffect(function onShopIdChange() {
        if (!state.shopId) groupForm.reset();
    }, [state.shopId]);

    const forbiddenNames = groups?.map(group => group.name);

    const groupForm = useForm({
        disabled: state.isLoading,
        items: {
            name: {
                type: "input",
                inputMode: "text",
                label: translations.get("Name"),
                required: true,
                minLength: 3,
                maxLength: 50,
                validate: (value) => {
                    const name = ((value ?? "") + "").trim();
                    if (name.length < 3) return false;
                    return !forbiddenNames?.includes(name as string);
                },
                errorMessage: translations.get("NameAlreadyExists"),
                autoFocus: true,
                placeholder: translations.get("EnterName")
            }
        }
    });

    const name = groupForm.state.data.name as string;

    const addButton = (
        <Button
            icon={<AddIcon/>}
            content={translations.get("Add")}
            primary
            disabled={!groupForm.isValid || state.isLoading}
            loading={state.isLoading}
            onClick={dispatch("addGroup", {name, index: (groups?.length ?? 0) + 1, addGroupToShop})}
        />
    )

    return (
        <Dialog
            externalRef={dialogRef}
            width={400}
            icon={<AddIcon outline/>}
            title={translations.get("NewGroup")}
            onClose={dispatch("close")}
            onBeforeClose={dispatch("beforeClose", groupForm.state.hasChanged)}
            content={groupForm.renderForm()}
            footer={
                <Flex fill hAlign={"end"}>
                    {addButton}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);