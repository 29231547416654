import * as React from "react";
import {memo, ReactElement, useEffect} from "react";
import {Props} from "./PopupMenuButton.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer, stopPropagation} from "./PopupMenuButton.reducer";
import "./PopupMenuButton.styles.scss";
import {Flex, MenuButton, MenuButtonProps, Popup} from "@fluentui/react-northstar";
import {useMsTeamsSelector} from "redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {MenuProps} from "@fluentui/react-northstar/dist/es/components/Menu/Menu";

export const PopupMenuButton = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer({props}), initialState, props.externalRef);
    const {isTouchScreen} = useMsTeamsSelector("isTouchScreen");

    useEffect(function handleCloseOnOutsideClick() {
        if (!state.open) return;
        window.document.body.addEventListener("click", dispatch("close"));
        window.document.body.addEventListener("touchmove", dispatch("close"));
        return () => {
            window.document.body.removeEventListener("click", dispatch("close"));
            window.document.body.removeEventListener("touchmove", dispatch("close"));
        }
    }, [state.open]);

    const fillTrigger = !!props.trigger && "fill" in props.trigger && props.trigger.fill;

    const trigger = !props.trigger ? undefined :
        (!!props.trigger && "fill" in props.trigger) ? props.trigger.trigger : props.trigger;

    const triggerElement = !trigger ? null : (
        <Flex fill={fillTrigger} onClick={dispatch("toggleOpen")} ref={dispatch("triggerRef")}
              {...(!isTouchScreen && props.on === "hover" && {onMouseEnter: dispatch("open")})}
              onMouseLeave={dispatch("handleLeaveTrigger")}>
            {trigger}
        </Flex>
    )

    const closeOnClick = props.closeOnClick !== false;

    const menuButton = (
        <MenuButton
            ref={dispatch("contentRef")}
            open={state.open}
            on={isTouchScreen ? "click" : "hover"}
            onClick={closeOnClick ? dispatch("close") : stopPropagation}
            className={"no-select " + (props.className ?? "")}
            menu={props.menu}
            {...(!isTouchScreen && {
                mouseLeaveDelay: props.mouseLeaveDelay ?? 300,
                onOpenChange: dispatch("handlePopupHoverOpenChange"),
            })}
        />
    )

    if (!hasMenuItems(props.menu)) return null;

    return (
        <Popup
            open={state.open}
            closeOnScroll
            {...(props.offset && {offset: props.offset})}
            {...(props.position && {position: props.position})}
            {...(props.align && {align: props.align})}
            {...(triggerElement && {trigger: triggerElement})}
            {...(props.target && {target: props.target})}
            content={{
                className: "popup-menu-button",
                styles: {width: state.contentWidth + "px"},
                content: menuButton,
                onClick: stopPropagation,
            }}
        />
    )
}, (prevProps, nextProps) => (
    prevProps.menu === nextProps.menu &&
    prevProps.trigger === nextProps.trigger
));

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const hasMenuItems = (menu: MenuButtonProps["menu"]): boolean => {
    if (Array.isArray(menu)) return menu.length > 0;
    const items = (menu as MenuProps | undefined)?.items as MenuProps["items"] ?? [];
    return items.length > 0;
}