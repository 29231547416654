import {QueriesObserver, QueryObserverOptions, QueryObserverResult, useQueryClient} from "react-query";
import {useCallback, useEffect, useState} from "react";
import {useGroupCache} from "./useGroupCache";
import {DateRange} from "../../../components/others/DateRangeSelector/DateRangeSelector.interfaces";
import {getUserShiftsQueryArgs} from "../useShiftsCache";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {Shift} from "../../../classes/Shift";

export const useGroupShiftsCache = (id: string | undefined, dateRange: DateRange, enabled: boolean = true) => {
    const queryClient = useQueryClient();
    const {group} = useGroupCache(id);
    const [shifts, setShifts] = useState<Immutable<Array<Shift>>>();

    useEffect(function listenUsersShifts() {
        const observer = new QueriesObserver(queryClient, group?.usersIds
            ?.map(uid => ({
                ...getUserShiftsQueryArgs(uid, dateRange.startDate, dateRange.endDate),
                enabled,
            })) as QueryObserverOptions[]);
        observer.subscribe(handleObserverResult);
        handleObserverResult(observer.getCurrentResult());
        return () => observer.destroy();
    }, [group?.usersIds, queryClient, dateRange, enabled]);

    const handleObserverResult = useCallback((result: QueryObserverResult[]) => {
        const isLoading = result.some(({isLoading}) => isLoading);
        if (isLoading) return setShifts(undefined);
        const shifts = result
            .flatMap((response) => response.data as Immutable<Array<Shift>>)
            .filter(Boolean);
        setShifts(shifts);
    }, []);

    return {
        shifts,
        getTotalShiftsHours: getTotalShiftsHours(shifts),
    };
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getTotalShiftsHours = (shifts: Immutable<Array<Shift>> | undefined) => (): number | undefined => {
    return shifts?.reduce((acc, shift) => acc + shift.getDurationInHours(), 0);
}