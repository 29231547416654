import {QueryClient, useQuery, useQueryClient} from "react-query";
import {DateRange} from "../../components/others/DateRangeSelector/DateRangeSelector.interfaces";
import moment from "moment";

export type PlanningDateRangeCache = {
    selectedRange: DateRange,
    selectedDate: string,
}

export const planningDateRangeCacheKey = "planning-date-range";

export const usePlanningDateRangeCache = () => {
    const queryClient = useQueryClient();

    const {data: planningDateRange} = useQuery([planningDateRangeCacheKey], getDefaultData, {
        staleTime: Infinity,
        enabled: true
    });

    return {
        planningDateRange: planningDateRange ?? getDefaultData(),
        setPlanningDateRange: setPlanningDateRange(queryClient),
    };
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getDefaultData = (): PlanningDateRangeCache => ({
    selectedRange: {
        startDate: moment().startOf("week").toISOString(false),
        endDate: moment().endOf("week").toISOString(false),
    },
    selectedDate: moment().startOf("day").toISOString(false),
});

const setPlanningDateRange = (queryClient: QueryClient) => (data: PlanningDateRangeCache) => {
    queryClient.setQueryData([planningDateRangeCacheKey], data);
}