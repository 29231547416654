import {State} from "./UserWeekSummaryDialog.types";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {useRequestsCache} from "../../../hooks/cache/useRequestsCache";

export const initialState: State = {
    requestId: "",
    userId: "",
    isSaving: false,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
    applyRetrieveHours: ReturnType<typeof useRequestsCache>["applyRetrieveHours"],
}) => ({
    open: ({setState}, _, {requestId, userId}: { requestId: string | undefined, userId: string | undefined }) => {
        if (!requestId || !userId) return;
        setState({...initialState, requestId, userId});
        config.dialogRef.dispatch("open")();
    },
    confirm: async ({state, setState}, _, usedHours: number) => {
        setState({isSaving: true});
        await config.applyRetrieveHours(state.requestId, state.userId, usedHours);
        config.dialogRef.dispatch("close")();
        setState({isSaving: false});
    },
}) satisfies MagicReducerObject<State>;