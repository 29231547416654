import React, {memo, ReactElement} from "react";
import {Props} from "./ShopDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShopDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import "./ShopDialog.styles.scss";
import {translations} from "translations";
import {useShopForm} from "hooks/forms/useShopForm";
import {AddIcon, EditIcon, Flex} from "@fluentui/react-northstar";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";

export const ShopDialog = memo((props: Props): ReactElement | null => {
    const {confirmCancelChangesDialog} = useDialogContext();
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({
        dialogRef,
        confirmCancelChangesDialog
    }), initialState, props.externalRef);

    const shopForm = useShopForm(state.shopId);

    return (
        <Dialog
            externalRef={dialogRef}
            width={600}
            onClose={dispatch("reset", shopForm)}
            onBeforeClose={dispatch("beforeClose", !shopForm.isSaving && shopForm.state.hasChanged)}
            icon={!state.shopId ? <AddIcon outline/> : <EditIcon outline/>}
            title={translations.get(!state.shopId ? "AddShop" : "EditShop")}
            content={shopForm.renderForm()}
            footer={
                shopForm.isLoading ? null :
                    <Flex gap={"gap.small"} hAlign={"end"}>
                        {shopForm.renderDeleteButton(dialogRef.dispatch("close"))}
                        <Flex fill gap={"gap.small"} hAlign={"end"}>
                            {shopForm.renderSaveButton(dialogRef.dispatch("close"))}
                        </Flex>
                    </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);