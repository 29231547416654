import {useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "translations";
import {ArrowRightIcon, Button, Flex, SaveIcon, TrashCanIcon} from "@fluentui/react-northstar";
import {useShiftThresholdsForm} from "./useShiftThresholdsForm";
import React, {useLayoutEffect} from "react";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {CustomShiftThresholdsData, ThresholdDataItem} from "../../interfaces/GroupData";
import {useDialogContext} from "../../services/DialogContext/DialogContext";
import {DialogContextValue} from "../../services/DialogContext/DialogContext.interfaces";
import {useGroupCache} from "../cache/groups/useGroupCache";
import {FormModule} from "../../modules/Form.module";

export const useCustomShiftThresholdForm = (groupId: string, categoryId: string, customThresholdId: string) => {
    const {confirmDeleteDialog} = useDialogContext();
    const {group, upsertCustomThreshold, deleteCustomThreshold} = useGroupCache(groupId);
    const [isSaving, setIsSaving] = React.useState(false);

    const threshold = group?.thresholds.custom.find(threshold => threshold.id === customThresholdId);

    useLayoutEffect(function onThresholdChange() {
        form.setFieldsInitialValues({
            name: threshold?.name,
            startDate: threshold?.startDate,
            endDate: threshold?.endDate,
        });
        form.reset();
    }, [groupId, customThresholdId]);

    const form = useForm({
        disabled: isSaving,
        items: {
            name: {
                type: "input",
                inputMode: "text",
                required: true,
                label: translations.get("Name"),
                placeholder: translations.get("EnterName"),
                minLength: 3,
                maxLength: 50,
                autoFocus: !threshold
            },
            ...FormModule.generateDateRangeItems({showLabel: true, required: true}),
        }
    });

    const weekThresholdsForm = useShiftThresholdsForm({
        groupId,
        categoryId,
        customId: customThresholdId,
        disabled: isSaving,
    });

    const {name, startDate, endDate} = form.formItems;

    const renderForm = () => (
        <Flex column fill gap={"gap.medium"}>
            {name}
            <Flex vAlign={"end"} gap={"gap.small"}>
                {startDate}
                <ArrowRightIcon styles={{color: "darkgray", marginBottom: "8px"}}/>
                {endDate}
            </Flex>
            {weekThresholdsForm.renderForm()}
        </Flex>
    )

    const hasChanged = form.state.hasChanged || weekThresholdsForm.hasChanged;

    const renderSaveButton = (callback: (item: Immutable<CustomShiftThresholdsData> | undefined) => void) => (
        <Button
            primary
            disabled={!form.isValid || !hasChanged || isSaving}
            loading={isSaving}
            content={translations.get("Save")}
            icon={<SaveIcon size={"large"} outline/>}
            onClick={handleUpdateCustomShiftThreshold({
                groupId,
                categoryId,
                customThresholdId,
                callback,
                upsertCustomThreshold,
                formState: form.state,
                thresholds: weekThresholdsForm.thresholdsData,
                setIsSaving,
            })}
        />
    );

    const renderDeleteButton = (callback: () => void) => !customThresholdId ? null : (
        <Button
            className={isSaving ? "" : "delete-btn"}
            text
            disabled={isSaving}
            content={translations.get("Delete")}
            icon={<TrashCanIcon outline/>}
            onClick={handleDeleteCustomShiftThreshold({
                customThreshold: threshold,
                callback,
                deleteCustomThreshold,
                confirmDeleteDialog,
            })}
        />
    );

    return {
        ...form,
        renderForm,
        renderSaveButton,
        renderDeleteButton,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleUpdateCustomShiftThreshold = (config: {
    groupId: string,
    categoryId: string,
    customThresholdId: string | undefined,
    callback?: ((item: Immutable<CustomShiftThresholdsData> | undefined) => void) | undefined,
    upsertCustomThreshold: ReturnType<typeof useGroupCache>["upsertCustomThreshold"],
    formState: ReturnType<typeof useCustomShiftThresholdForm>["state"],
    thresholds: Immutable<Array<ThresholdDataItem>>,
    setIsSaving: React.Dispatch<React.SetStateAction<boolean>>,
}) => async () => {
    const {
        customThresholdId,
        callback,
        upsertCustomThreshold,
        formState,
        thresholds,
        categoryId,
        groupId,
        setIsSaving
    } = config;
    const newCustomThreshold = generateCustomShiftThreshold(groupId, categoryId, customThresholdId, formState, thresholds);
    setIsSaving(true);
    await upsertCustomThreshold(newCustomThreshold);
    callback?.(newCustomThreshold);
    setIsSaving(false);
}

const handleDeleteCustomShiftThreshold = (config: {
    customThreshold: Immutable<CustomShiftThresholdsData> | undefined,
    callback?: (() => void) | undefined,
    deleteCustomThreshold: ReturnType<typeof useGroupCache>["deleteCustomThreshold"],
    confirmDeleteDialog: DialogContextValue["confirmDeleteDialog"],
}) => () => {
    const {deleteCustomThreshold, customThreshold, callback, confirmDeleteDialog} = config;
    if (!customThreshold) return;
    confirmDeleteDialog.dispatch("open", {
        title: translations.get("DeleteCustomThreshold"),
        subtitle: translations.get("AreYouSureToDeleteThisCustomThreshold", {name: customThreshold?.name}),
        callback: async () => {
            await deleteCustomThreshold(customThreshold?.id);
            callback?.();
        }
    })();
}

const generateCustomShiftThreshold = (
    groupId: string,
    categoryId: string,
    customThresholdId: string | undefined,
    formState: ReturnType<typeof useCustomShiftThresholdForm>["state"],
    thresholds: Immutable<Array<ThresholdDataItem>>,
): Immutable<CustomShiftThresholdsData> | undefined => {
    const {name, startDate, endDate} = formState.data;
    if (!name || !startDate || !endDate) return;
    return {
        id: customThresholdId ?? "",
        groupId,
        categoryId,
        name: name as string,
        startDate: startDate as string,
        endDate: endDate as string,
        thresholds,
    };
}