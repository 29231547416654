import React, {memo, ReactElement, useEffect, useMemo} from "react";
import {Props} from "./ShopPopup.interfaces";
import {Immutable, MagicDispatch, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShopPopup.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ShopPopup.styles.scss";
import {
    AcceptIcon,
    Button,
    ChevronDownIcon,
    Divider,
    Flex,
    Menu,
    MenuItemProps,
    Popup,
    SearchIcon,
    Text
} from "@fluentui/react-northstar";
import {ShopIcon} from "../../../assets/icons";
import {useShopsCache, useUserShopCache} from "../../../hooks/cache/useShopsCache";
import {useForm} from "@witivio_teamspro/northstar-form";
import {useMsTeamsSelector} from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {translations} from "../../../translations";
import {ShorthandCollection, ShorthandValue} from "@fluentui/react-northstar/dist/es/types";
import {ShopData} from "../../../interfaces/ShopData";
import {useUserRolesCache} from "../../../hooks/cache/useUserRoleCache";

export const ShopPopup = memo((props: Props): ReactElement | null => {
    const {userRoles} = useUserRolesCache();
    const {shop: userShop, isLoading: isUserShopLoading} = useUserShopCache();
    const {shops, isLoading: areShopsLoading} = useShopsCache();
    const {userId} = useMsTeamsSelector("locale", "userId");
    const [state, dispatch] = useMagicReducer(reducer(props), initialState, props.externalRef);

    useEffect(function onMount() {
        if (areShopsLoading || isUserShopLoading) return;
        if (shops?.length === 0 && !userShop) return;
        const shopId = userShop?.id ?? shops?.[0]?.id;
        if (!shopId) return;
        dispatch("selectShop", shopId)();
    }, [areShopsLoading, isUserShopLoading]);

    const form = useForm({
        items: {
            search: {
                type: "input",
                inputMode: "text",
                placeholder: translations.get("Search"),
                inputIcon: <SearchIcon outline styles={{color: "darkgray"}}/>
            }
        }
    });

    const {search} = form.state.data;

    const filteredShops = useMemo(() => {
        const filter = ((search ?? "") + "")?.toLowerCase();
        const items = shops?.filter(shop => shop.name.toLowerCase().includes(filter)) ?? [];
        if (userRoles.administrator || userRoles.superManager || userRoles.rotatingStaffManager) return items;
        if (userRoles.manager) return items.filter(shop => shop.managersIds.includes(userId));
        if (userRoles.staff) return items.filter(shop => shop.id === userShop?.id);
        return items;
    }, [search, shops, userRoles]);

    const selectedShop = shops?.find(shop => shop.id === state.selectedShopId);

    const menuItems = generateMenuItems({
        shops: filteredShops,
        selectedShopId: state.selectedShopId,
        dispatch,
    });

    if (areShopsLoading || isUserShopLoading) return null;

    return (
        <Popup
            open={state.open}
            closeOnScroll
            trigger={
                <Button
                    className={"no-shrink"}
                    text icon={<ShopIcon/>}
                    content={
                        <Flex gap={"gap.smaller"} vAlign={"center"}>
                            <Text content={selectedShop?.name}/>
                            <ChevronDownIcon outline/>
                        </Flex>
                    }
                    disabled={!state.selectedShopId}
                    onClick={dispatch("setOpen", true)}
                />
            }
            content={{
                onMouseEnter: dispatch("mouseEnter"),
                onMouseLeave: dispatch("mouseLeave"),
                className: "shop-popup",
                content: (
                    <Flex fill column gap={"gap.smaller"}>
                        <Flex column gap={"gap.smaller"}>
                            {form.formItems.search}
                            <Divider/>
                        </Flex>
                        <Menu
                            fluid
                            items={menuItems}
                            vertical
                            pointing
                        />
                    </Flex>
                )
            }}
        />
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const formatMenuShopItem = (config: {
    shop: Immutable<ShopData>,
    selectedShopId: string | undefined,
    dispatch: MagicDispatch<typeof reducer>
}) => {
    const {shop, selectedShopId, dispatch} = config;
    const formattedItem: ShorthandValue<MenuItemProps> = {key: shop.id};
    formattedItem.onClick = dispatch("selectShop", shop.id);
    formattedItem.children = (
        <Flex fill gap={"gap.small"} space={"between"} vAlign={"center"}>
            <Flex gap={"gap.small"} vAlign={"center"}>
                <Text content={shop.name}/>
            </Flex>
            <AcceptIcon className={"unique-menu-item-check " + (shop.id === selectedShopId ? "checked" : "")}/>
        </Flex>
    )
    return formattedItem;
}

const generateMenuItems = (config: {
    shops: Immutable<Array<ShopData>>,
    selectedShopId: string | undefined,
    dispatch: MagicDispatch<typeof reducer>
}): ShorthandCollection<MenuItemProps> => {
    const {shops, selectedShopId, dispatch} = config;
    if (shops.length === 0) return [];
    return shops.map(shop => formatMenuShopItem({
        shop,
        selectedShopId,
        dispatch
    }));
};