import {State} from "./Clocking.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import * as microsoftTeams from "@microsoft/teams-js";
import {ErrorCode} from "@microsoft/teams-js";
import {CryptoModule} from "../../../modules/Crypto.module";
import moment from "moment/moment";
import {GuidModule} from "../../../modules/Guid.module";
import {BadgeApi} from "../../../apis/Badge/BadgeApi";
import {DialogContextValue} from "../../../services/DialogContext/DialogContext.interfaces";
import {MagicReducerData} from "@witivio_teamspro/use-reducer/dist/cjs/useMagicReducer";
import {Shift} from "../../../classes/Shift";
import {Time} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/TimePicker/TimePicker";
import {useUserLastShiftCache} from "../../../hooks/cache/useUserLastShiftCache";
import {ShiftClocking} from "../../../interfaces/ShiftData";

export const initialState: State = {
    shiftId: "",
    isBadging: false,
    isPlaying: false,
    playSeconds: 0,
}

export const reducer = (config: {
    badgeErrorDialog: DialogContextValue["badgeErrorDialog"],
    invalidateUserLastShift: ReturnType<typeof useUserLastShiftCache>["invalidateUserLastShift"],
}) => ({
    incrementPlaySeconds: ({state, setState}) => {
        setState({playSeconds: state.playSeconds + 1});
    },
    play: async (reducerData) => {
        reducerData.setState({isBadging: true});
        await updateClockingState(reducerData, config.badgeErrorDialog, true);
        reducerData.setState({isBadging: false});
    },
    stop: async (reducerData) => {
        reducerData.setState({isBadging: true});
        await updateClockingState(reducerData, config.badgeErrorDialog, false);
        await config.invalidateUserLastShift();
        reducerData.setState({isBadging: false});
    },
    shiftLoaded: ({setState}, _, shift: Shift) => {
        setState({shiftId: shift.getId()}, false);
        const clocking = shift.getClocking();
        if (!clocking?.start || clocking?.end) return;
        setState({
            isPlaying: true,
            playSeconds: getPlaySecondsFromStartTime(clocking.start),
        });
    }
}) satisfies MagicReducerObject<State>;

const badge = async (shiftId: string, isStart: boolean): Promise<ShiftClocking | undefined> => {
    const scanResult = await scanQRCode();
    if (!isScanResultValid(scanResult)) return;
    return await BadgeApi.badge({qrCodeValue: scanResult, isStart, shiftId});
}

const scanQRCode = () => new Promise<string>(async (resolve) => {
    microsoftTeams.media.scanBarCode(async (error: microsoftTeams.SdkError | undefined, decodedText: string) => {
        if (!error) return resolve(decodedText);
        switch (error.errorCode) {
            case ErrorCode.PERMISSION_DENIED:
                const hasPermission = await checkScanPermission();
                if (!hasPermission) return resolve("");
                const result = await scanQRCode();
                resolve(result);
                break;
            default:
                resolve("");
                break;
        }
    }, {timeOutIntervalInSec: 30});
});

const checkScanPermission = (): Promise<boolean> => new Promise<boolean>(async resolve => {
    const result = await navigator.permissions.query({name: 'camera' as PermissionName})
    if (result.state === 'granted') return resolve(true);
    navigator.mediaDevices.getUserMedia({video: true})
        .then(async () => resolve(await checkScanPermission()))
        .catch(() => resolve(false));
});

const isScanResultValid = (result: string) => {
    if (!result) return false;
    const iv = result.split("_")[0] ?? "";
    if (!iv) return false;
    const encryptedValue = result.split("_")[1] ?? "";
    if (!encryptedValue) return false;
    const decryptedValue = CryptoModule.decryptValue(encryptedValue, process.env.REACT_APP_BADGE_SECRET_KEY!, iv);
    const today = moment().startOf("day").toISOString(false).split("T")[0];
    const date = decryptedValue.split(".")[0];
    if (today !== date) return false;
    const shopId = decryptedValue.split(".")[1] ?? "";
    if (!GuidModule.isValidGuid(shopId)) return false;
    return true;
}

const updateClockingState = async (
    {state, setState}: MagicReducerData<State>,
    badgeErrorDialog: DialogContextValue["badgeErrorDialog"],
    isStart: boolean,
) => {
    const result = await badge(state.shiftId, isStart);
    if (!result) {
        const callback = (clocking: ShiftClocking | undefined) => {
            if (!clocking) setState({isBadging: false});
            else {
                const playSeconds = isStart ? getPlaySecondsFromStartTime(clocking.start) : 0;
                setState({isBadging: false, isPlaying: isStart, playSeconds});
            }
        }
        badgeErrorDialog.dispatch("open", {shiftId: state.shiftId, isStart, callback})();
        return;
    }
    setState({isPlaying: isStart, playSeconds: 0});
}

const getPlaySecondsFromStartTime = (startTime: Time) => {
    const start = moment().startOf("day").add(startTime.hour, "hour").add(startTime.minutes, "minutes");
    const now = moment();
    return now.diff(start, "seconds");
}