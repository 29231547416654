import React, {memo, ReactElement, useMemo} from "react";
import {Props, State} from "./SwapRequestPreview.interfaces";
import {Immutable, MagicDispatch, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./SwapRequestPreview.reducer";
import {CompareModule} from "modules/Compare.module";
import "./SwapRequestPreview.styles.scss";
import {AcceptIcon, ArrowSyncIcon, Button, CloseIcon, Divider, Flex, Loader, Text} from "@fluentui/react-northstar";
import {RequestPreviewItem} from "../RequestPreviewItem/RequestPreviewItem";
import moment from "moment";
import {ManagerResponseItem} from "../ManagerResponseItem/ManagerResponseItem";
import {ShiftCard} from "../../ShiftCard/ShiftCard";
import {useUserCache} from "../../../../hooks/cache/useUsersCache";
import {translations} from "../../../../translations";
import {SwapRequestData, SwapRequestShiftData} from "../../../../interfaces/RequestData";
import {useRequestsCache} from "../../../../hooks/cache/useRequestsCache";
import {Shift} from "../../../../classes/Shift";
import {GraphUserData} from "../../../../services/GraphService/GraphService.interfaces";
import {ClockIcon} from "../../../../assets/icons";
import {useMsTeamsSelector} from "../../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {useUserRolesCache} from "../../../../hooks/cache/useUserRoleCache";
import {UserRole} from "../../../../interfaces/UserRole";

export const SwapRequestPreview = memo((props: Props): ReactElement | null => {
    const {userId} = useMsTeamsSelector("userId");
    const {requests, approveSwapRequest} = useRequestsCache();
    const [state, dispatch] = useMagicReducer(reducer({props, approveSwapRequest}), initialState);
    const {isOnlyRole, isLoading} = useUserRolesCache();

    const canAnswerRequest = !isOnlyRole(UserRole.Staff);

    const request = requests?.find(r => r.id === props.requestId) as SwapRequestData | undefined;

    const {user: toUser, isLoading: isUserLoading} = useUserCache(request?.toShift?.userId);

    const fromShift = useMemo(() => generateShiftFromSwapRequestShiftData(request?.fromShift), [request?.fromShift]);
    const toShift = useMemo(() => generateShiftFromSwapRequestShiftData(request?.toShift), [request?.toShift]);

    if (!request || isLoading) return null;

    const hideUserApprobation = (canAnswerRequest && userId === request.toShift.userId) || request.response?.managerId === request.toShift.userId;

    const showManagerResponse = hideUserApprobation || request.toUserResponse === true;

    return (
        <Flex fill column hAlign={"center"}>
            {renderRequestPreviewItem({request, fromShift, toShift, isUserLoading, toUser})}
            {!hideUserApprobation && renderUserApprobationPreviewItem({dispatch, request, state, userId})}
            {showManagerResponse &&
                <ManagerResponseItem requestId={props.requestId} disabled={isUserLoading}/>
            }
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderLoader = () => (
    <Flex fill column hAlign={"center"} vAlign={"center"} gap={"gap.small"} style={{padding: "10px"}}>
        <Loader size={"medium"}/>
    </Flex>
)

const generateShiftFromSwapRequestShiftData = (shiftData: Immutable<SwapRequestShiftData> | undefined): Shift | undefined => {
    if (!shiftData) return;
    return new Shift({
        type: shiftData.type,
        id: shiftData.id,
        shopId: shiftData.shopId,
        categoryId: shiftData.categoryId,
        userId: shiftData.userId,
        date: shiftData.date,
        start: shiftData.start,
        end: shiftData.end,
        customWording: shiftData.customWording,
    });
}

const renderRequestPreviewItem = (config: {
    request: SwapRequestData,
    fromShift: Shift | undefined,
    toShift: Shift | undefined,
    isUserLoading: boolean,
    toUser: Immutable<GraphUserData> | undefined,
}) => {
    const {request, fromShift, toShift, isUserLoading, toUser} = config;
    return (
        <RequestPreviewItem
            userId={request.userId}
            date={moment(request.date).toISOString(false)}
            content={
                isUserLoading ? renderLoader() :
                    <Flex fill column gap={"gap.small"}>
                        <ShiftCard
                            small
                            shift={fromShift}
                            currentShopId={undefined}
                            userId={undefined}
                            readonly
                            showAvatar
                            showDate
                        />
                        <Flex gap={"gap.smaller"} vAlign={"center"} hAlign={"center"}>
                            <ArrowSyncIcon outline/>
                            <Flex styles={{gap: "4px"}}>
                                <Text content={translations.get("SwapWith")}/>
                                <Text weight={"semibold"} content={toUser?.displayName}/>
                            </Flex>
                        </Flex>
                        <ShiftCard
                            small
                            shift={toShift}
                            currentShopId={undefined}
                            userId={undefined}
                            readonly
                            showAvatar
                            showDate
                        />
                        {request.note &&
                            <>
                                <Divider/>
                                <Flex column style={{marginTop: "-5px"}}>
                                    <Text style={{color: "darkgray"}} content={translations.get("Notes")}/>
                                    <Text content={request.note}/>
                                </Flex>
                            </>
                        }
                    </Flex>
            }
        />
    )
}

const renderUserApprobationPreviewItem = (config: {
    state: Immutable<State>,
    dispatch: MagicDispatch<typeof reducer>,
    request: SwapRequestData,
    userId: string,
}) => {
    const {dispatch, request, state, userId} = config;
    const showResult = request.toUserResponse !== undefined;
    const showButtons = userId === request.toShift.userId;
    const buttonsDisabled = state.isDenying || state.isApproving;
    return (
        <RequestPreviewItem
            userId={request.toShift.userId}
            content={
                showResult ?
                    renderUserApprobationResponse(request.toUserResponse!)
                    :
                    <Flex fill column gap={"gap.small"}>
                        <Flex styles={{gap: "6px"}} vAlign={"center"}>
                            <ClockIcon height={16} width={16}/>
                            <Text
                                content={translations.get(showButtons ? "AwaitingYourApproval" : "AwaitingApproval")}
                            />
                        </Flex>
                        {showButtons &&
                            <Flex gap={"gap.smaller"}>
                                <Button
                                    fluid content={translations.get("Deny")}
                                    icon={<CloseIcon outline/>}
                                    onClick={dispatch("approveRequest", false)}
                                    disabled={buttonsDisabled}
                                    loading={state.isDenying}
                                />
                                <Button
                                    primary fluid
                                    content={translations.get("Approve")}
                                    icon={<AcceptIcon outline/>}
                                    onClick={dispatch("approveRequest", true)}
                                    disabled={buttonsDisabled}
                                    loading={state.isApproving}
                                />
                            </Flex>
                        }
                    </Flex>
            }
        />
    )
}

const renderUserApprobationResponse = (approved: boolean) => {
    const Icon = approved ? AcceptIcon : CloseIcon;
    const title = approved ? translations.get("RequestApproved") : translations.get("RequestDenied");
    const color = approved ? "#56B74E" : "#E00B1C";
    return (
        <Flex fill column gap={"gap.smaller"}>
            <Flex column vAlign={"center"} hAlign={"center"} style={{gap: "6px", color}}>
                <Icon size={"larger"}/>
                <Text weight={"semibold"} content={title}/>
            </Flex>
        </Flex>
    )
}