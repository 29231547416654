import React, {memo, ReactElement} from "react";
import {Props} from "./ImportStep.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ImportStep.reducer";
import {CompareModule} from "modules/Compare.module";
import {ReactComponent as ImportPlanningImage} from "assets/images/ImportPlanning.svg";
import "./ImportStep.styles.scss";
import {useMsTeamsSelector} from "../../../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import {translations} from "../../../../../translations";

export const ImportStep = memo((props: Props): ReactElement | null => {
    const {isLightTheme} = useMsTeamsSelector("isLightTheme");
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    return (
        <Flex fill column vAlign={"center"} hAlign={"center"} gap={"gap.medium"}>
            <ImportPlanningImage
                height={250} width={250}
                color={isLightTheme ? "#F5F5F5" : "#565656"}
                style={{marginTop: "-10px"}}
            />
            <Flex column hAlign={"center"} styles={{gap: "5px", marginTop: "-10px"}}>
                <Text size={"large"} weight={"bold"} content={translations.get("ImportationOfPlanningInProgress")}/>
                <Text content={translations.get("PleaseWaitAMoment")}/>
            </Flex>
            <Loader/>
        </Flex>
    )
}, CompareModule.areObjectsEqual);