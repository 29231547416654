import {State} from "./RenameGroupDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {useGroupCache} from "../../../hooks/cache/groups/useGroupCache";

export const initialState: State = {
    groupId: "",
    isRenaming: false,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, groupId: string) => {
        setState({...initialState, groupId});
        config.dialogRef.dispatch("open")();
    },
    reset: ({setState}) => {
        setState({...initialState});
    },
    rename: async ({setState}, _, {name, renameGroup}: {
        name: string,
        renameGroup: ReturnType<typeof useGroupCache>["rename"],
    }) => {
        setState({isRenaming: true});
        await renameGroup(name);
        config.dialogRef.dispatch("close")();
    }
}) satisfies MagicReducerObject<State>;