import {ShopShiftCategory, ShopShiftCategoryType} from "../../interfaces/ShopData";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {useShopCache} from "../cache/useShopsCache";
import React, {useCallback, useLayoutEffect, useMemo, useRef} from "react";
import {useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "../../translations";
import {FormModule} from "../../modules/Form.module";
import {AddIcon, ArrowRightIcon, Button, Flex, SaveIcon} from "@fluentui/react-northstar";
import {HEX} from "../../interfaces/Color";

export const useShopCategoryForm = (shopId: string | undefined, type: ShopShiftCategoryType, category: Immutable<ShopShiftCategory> | undefined) => {
    const {shop, upsertCategory} = useShopCache(shopId);
    const colorInputRef = useRef<HTMLInputElement | null>(null);
    const [isSaving, setIsSaving] = React.useState<boolean>(false);
    const [isSameColor, setIsSameColor] = React.useState<boolean>(true);

    useLayoutEffect(() => reset(category), [category]);

    const categoriesNames = useMemo(() => {
        return shop?.categories?.filter(c => c.key !== category?.key && c.type === type).map(({name}) => name.toLowerCase()) ?? [];
    }, [shop?.categories]);

    const isShiftCategory = type === ShopShiftCategoryType.Shift;

    const form = useForm({
        disabled: isSaving,
        items: {
            name: {
                type: "input",
                inputMode: "text",
                label: translations.get("Name"),
                required: true,
                placeholder: translations.get("EnterName"),
                clearable: false,
                validate: name => !categoriesNames.includes((name + "").trim().toLowerCase()),
                errorMessage: translations.get("CategoryAlreadyExists"),
                autoFocus: !category
            },
            ...FormModule.generateTimeRangeItems({required: true, showLabel: true}),
            badge: {
                type: "checkbox",
                toggle: true,
                label: translations.get("Badge"),
                initialValue: true,
            },
            showInList: {
                type: "checkbox",
                toggle: true,
                label: translations.get(isShiftCategory ? "DisplayDistribution" : "InReport"),
                initialValue: true,
            }
        },
    });

    const reset = useCallback((category?: Immutable<ShopShiftCategory> | undefined) => {
        resetColorInputValue(colorInputRef, category?.color)(colorInputRef.current);
        form.setFieldsInitialValues({
            name: category?.name,
            startTime: category?.start,
            endTime: category?.end,
            badge: category?.badge,
            showInList: category?.showInList,
        });
        form.reset();
        setIsSaving(false);
        setIsSameColor(true);
    }, []);

    const handleColorChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const color = e.target.value;
        const categoryColor = category?.color;
        setIsSameColor(categoryColor === color);
    }, [category]);

    const colorPicker = useMemo(() => (
        <Flex styles={{marginTop: "20px"}}>
            <input onChange={handleColorChange} ref={resetColorInputValue(colorInputRef)} type={"color"}
                   className={"color-picker"}/>
        </Flex>
    ), [handleColorChange]);

    const {name, startTime, endTime, badge, showInList} = form.formItems;

    const renderForm = () => (
        <Flex column fill gap={"gap.small"}>
            <Flex gap={"gap.small"}>
                {colorPicker}
                {name}
            </Flex>
            <Flex vAlign={"end"} gap={"gap.small"}>
                {startTime}
                <ArrowRightIcon style={{color: "darkgray", marginBottom: "8px"}}/>
                {endTime}
            </Flex>
            {isShiftCategory && badge}
            {showInList}
        </Flex>
    );

    const hasChanged = form.state.hasChanged || !isSameColor;

    const renderSaveButton = (callback: (category: Immutable<ShopShiftCategory> | undefined) => void) => (
        <Button
            fluid
            primary
            disabled={!form.isValid || !hasChanged || isSaving}
            loading={isSaving}
            content={translations.get(category ? "Save" : "Add")}
            icon={category ? <SaveIcon size={"large"} outline/> : <AddIcon outline/>}
            onClick={handleSaveCategory({
                shopId,
                type,
                upsertCategory,
                colorInputRef,
                callback,
                categoryId: category?.key,
                formState: form.state,
                setIsSaving,
            })}
        />
    );

    return {
        ...form,
        renderForm,
        renderSaveButton,
        reset,
        isSaving,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleSaveCategory = (config: {
    shopId: string | undefined,
    categoryId: string | undefined,
    type: ShopShiftCategoryType,
    callback?: ((category: Immutable<ShopShiftCategory> | undefined) => void) | undefined,
    colorInputRef: React.RefObject<HTMLInputElement | null>,
    upsertCategory: ReturnType<typeof useShopCache>["upsertCategory"],
    formState: ReturnType<typeof useShopCategoryForm>["state"],
    setIsSaving: React.Dispatch<React.SetStateAction<boolean>>,
}) => async () => {
    const {categoryId, type, callback, colorInputRef, upsertCategory, formState, setIsSaving, shopId} = config;
    const newCategory = generateShopCategory(shopId, categoryId, type, formState, colorInputRef);
    setIsSaving(true);
    await upsertCategory(newCategory);
    callback?.(newCategory);
    setIsSaving(false);
}

const resetColorInputValue = (
    colorInputRef: React.MutableRefObject<HTMLInputElement | null>,
    color: string = "#FF0000"
) => (ref: HTMLInputElement | null) => {
    colorInputRef.current = ref;
    if (colorInputRef.current) colorInputRef.current.value = color;
}

const generateShopCategory = (
    shopId: string | undefined,
    categoryId: string | undefined,
    type: ShopShiftCategoryType,
    formState: ReturnType<typeof useShopCategoryForm>["state"],
    colorInputRef: React.RefObject<HTMLInputElement | null>
): Immutable<ShopShiftCategory> | undefined => {
    const {name, startTime, endTime, badge, showInList} = formState.data;
    if (!name || !startTime || !endTime) return;
    return {
        key: categoryId ?? "",
        shopId: shopId ?? "",
        name: name as string,
        type,
        start: startTime,
        end: endTime,
        color: (colorInputRef.current?.value ?? "#FF0000") as HEX,
        badge: type === ShopShiftCategoryType.Absence ? false : (badge ?? true),
        showInList: showInList ?? true,
    };
}