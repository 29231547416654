import React, {memo, ReactElement} from "react";
import {Props} from "./TimeOffRequestPreview.interfaces";
import {Immutable, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./TimeOffRequestPreview.reducer";
import {CompareModule} from "modules/Compare.module";
import "./TimeOffRequestPreview.styles.scss";
import {RequestPreviewItem} from "../RequestPreviewItem/RequestPreviewItem";
import moment from "moment/moment";
import {Flex, Text} from "@fluentui/react-northstar";
import {ManagerResponseItem} from "../ManagerResponseItem/ManagerResponseItem";
import {translations} from "../../../../translations";
import {useRequestsCache} from "../../../../hooks/cache/useRequestsCache";
import {TimeOffRequestData} from "../../../../interfaces/RequestData";
import {WeekDaysModule} from "../../../../modules/WeekDays.module";
import {useUserShiftsCache} from "../../../../hooks/cache/useShiftsCache";
import {ShiftCard} from "../../ShiftCard/ShiftCard";
import {Shift} from "../../../../classes/Shift";

export const TimeOffRequestPreview = memo((props: Props): ReactElement | null => {
    const {requests} = useRequestsCache();
    const [state, dispatch] = useMagicReducer(reducer, initialState);
    const request = requests?.find(r => r.id === props.requestId) as TimeOffRequestData | undefined;
    const {shifts} = useUserShiftsCache(request?.userId, request?.startDate, request?.endDate);

    if (!request) return null;

    const {userId, endDate, startDate, date, justification} = request;

    const isSingleDay = moment(startDate).isSame(endDate, "day");

    return (
        <Flex fill column hAlign={"center"}>
            <RequestPreviewItem
                userId={userId}
                date={moment(date).toISOString(false)}
                content={
                    <Flex fill column gap={"gap.small"}>
                        {renderLinkedShiftsList(shifts)}
                        <Flex column>
                            <Text style={{color: "darkgray"}}
                                  content={translations.get(isSingleDay ? "Date" : "Period")}/>
                            <Flex gap={"gap.small"} vAlign={"center"}>
                                {WeekDaysModule.getPeriodLabel(request)}
                            </Flex>
                        </Flex>
                        <Flex column>
                            <Text style={{color: "darkgray"}} content={translations.get("Justification")}/>
                            <Text content={justification}/>
                        </Flex>
                    </Flex>
                }
            />
            <ManagerResponseItem
                requestId={props.requestId}
                onlyApprove
                approveText={translations.get("RequestProcessed")}
                successText={translations.get("Processed")}
                pendingText={translations.get("ProcessingInProgress")}
            />
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderLinkedShiftsList = (shifts: Immutable<Array<Shift>> | undefined) => {
    let content: ReactElement | ReactElement[];
    if (shifts?.length === 0) content = <Text content={translations.get("NoLinkedShift")}/>;
    else content = (
        shifts ?? [undefined]).map((s, i) => (
        <ShiftCard shift={s} key={s?.getId() ?? ("skeleton" + i)} readonly small/>
        )
    )
    return (
        <Flex column gap={"gap.small"}>
            <Text style={{color: "darkgray", marginBottom: "5px"}}
                  content={translations.get("LinkedShiftSingularPlural")}/>
            {content}
        </Flex>
    )
}