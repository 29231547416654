import {translations} from "../../../../translations";
import {ExclamationTriangleIcon, Flex, Skeleton, Text, Tooltip} from "@fluentui/react-northstar";
import {Cell} from "../../Cell/Cell";
import {UserThumbnail} from "../../UserThumbnail/UserThumbnail";
import React, {memo} from "react";
import {Props} from "./StaffCell.interfaces";
import {CompareModule} from "../../../../modules/Compare.module";

export const StaffCell = memo((props: Props) => {
    const {totalShiftsTime, maximumWorkingHours, rangeDays, user} = props;
    const isExceedingWorkingHours = totalShiftsTime > maximumWorkingHours;
    const exceedingLabel = translations.get(rangeDays.length === 1 ?
        "WorksAMaximumOfXHoursPerDay" : "WorksAMaximumOfXHoursPerWeek", {hours: maximumWorkingHours});
    let timeLabel: string;
    if (rangeDays.length === 7) timeLabel = `${totalShiftsTime} / ${maximumWorkingHours} h`;
    else timeLabel = `${totalShiftsTime}h`;

    return (
        <Flex fill className={"staff-cell"}>
            <Cell primary fluid>
                <Flex fill className={"overflow-hidden"} vAlign={"center"} gap={"gap.small"}>
                    <UserThumbnail userId={user?.id} size={"large"}/>
                    {!user ?
                        <Skeleton animation={"pulse"}>
                            <Skeleton.Text width={"100%"}/>
                        </Skeleton>
                        :
                        <Flex fill column vAlign={"center"} className={"overflow-hidden"}>
                            <Text content={user?.displayName} truncated/>
                            <Flex vAlign={"center"} className={"overflow-hidden"} styles={{gap: "5px"}}>
                                <Text
                                    size={"small"}
                                    styles={{color: isExceedingWorkingHours ? "red" : "darkgray"}}
                                    content={timeLabel} truncated
                                />
                                {isExceedingWorkingHours &&
                                    <Tooltip
                                        content={{
                                            content: exceedingLabel,
                                            className: "staff-shift-warning-tooltip"
                                        }}
                                        trigger={<ExclamationTriangleIcon size={"small"} styles={{color: "red"}}/>}
                                        position={"after"}
                                        offset={[0, 8]}
                                    />
                                }
                            </Flex>
                        </Flex>
                    }
                </Flex>
            </Cell>
        </Flex>
    )
}, CompareModule.areObjectsEqual);