import React, {memo, ReactElement, useMemo} from "react";
import {CreateRequestDialogTab, Props, State} from "./CreateRequestDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./CreateRequestDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {AddIcon, Flex, Menu} from "@fluentui/react-northstar";
import "./CreateRequestDialog.styles.scss";
import {translations} from "../../../translations";
import {TimeOffPart} from "./parts/TimeOffPart/TimeOffPart";
import {SwapPart} from "./parts/SwapPart/SwapPart";
import {UpdateClockingPart} from "./parts/UpdateClockingPart/UpdateClockingPart";
import {DialogProps} from "../Dialog/Dialog.interfaces";

export const CreateRequestDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    const menuItems = useMemo(() => generateMenuItems(), []);

    const activeIndex = menuItems.findIndex(item => item.id === state.activeTab);

    const menu = (
        <Menu
            className={"create-request-dialog-menu"}
            activeIndex={activeIndex}
            items={menuItems}
            underlined
            primary
            onItemClick={dispatch("changeTab")}
        />
    )

    return (
        <Dialog
            externalRef={dialogRef}
            width={600}
            height={600}
            noPadding
            onBeforeClose={state.onBeforeClose}
            title={translations.get("NewRequest")}
            icon={<AddIcon outline/>}
            className={"create-request-dialog"}
            content={
                <Flex fill column>
                    {menu}
                    {renderDialogContent(dispatch("setBeforeClose"), state.activeTab, dialogRef.dispatch("close"))}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderDialogContent = (
    setBeforeClose: (onBeforeClose: DialogProps["onBeforeClose"]) => void,
    activeTab: State["activeTab"],
    onClose: () => void,
) => {
    let content: ReactElement | null = null;

    switch (activeTab) {
        case CreateRequestDialogTab.TimeOff:
            content = <TimeOffPart onClose={onClose} setBeforeClose={setBeforeClose}/>;
            break;
        case CreateRequestDialogTab.Swap:
            content = <SwapPart onClose={onClose} setBeforeClose={setBeforeClose}/>;
            break;
        case CreateRequestDialogTab.UpdateClocking:
            content = <UpdateClockingPart onClose={onClose} setBeforeClose={setBeforeClose}/>;
            break;
        default:
            return null;
    }

    return (
        <Flex fill column className={"create-request-dialog-content"}>
            {content}
        </Flex>
    )
}

const generateMenuItems = () => {
    return [
        {
            key: "swap",
            id: CreateRequestDialogTab.Swap,
            content: translations.get("Swap")
        },
        {
            key: "update-clocking",
            id: CreateRequestDialogTab.UpdateClocking,
            content: translations.get("UpdateClocking")
        },
        {
            key: "time-off",
            id: CreateRequestDialogTab.TimeOff,
            content: translations.get("Absence")
        },
    ]
}

export const CreateRequestDialogModule = {
    renderDialogContent,
    generateMenuItems,
}