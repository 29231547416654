import {AddIcon, Button} from "@fluentui/react-northstar";
import React from "react";
import {Props} from "./MobileAddButton.interfaces";
import "./MobileAddButton.styles.scss";

export const MobileAddButton = (props: Props) => {

    return (
        <Button
            primary className={"mobile-add-button"}
            icon={<AddIcon outline/>} iconOnly circular
            onClick={props.onClick}
        />
    )
}