import React, {memo, ReactElement} from "react";
import {Props} from "./CategoriesPart.interfaces";
import {Immutable, MagicDispatch, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./CategoriesPart.reducer";
import {CompareModule} from "modules/Compare.module";
import "./CategoriesPart.styles.scss";
import {AddIcon, Button, Flex, Loader} from "@fluentui/react-northstar";
import {Accordion} from "components/others/Accordion/Accordion";
import {translations} from "translations";
import {CategoriesTable} from "components/tables/CategoriesTable/CategoriesTable";
import {useDialogContext} from "../../../../../services/DialogContext/DialogContext";
import {ShopShiftCategory, ShopShiftCategoryType} from "../../../../../interfaces/ShopData";
import {useShopCache} from "../../../../../hooks/cache/useShopsCache";
import {useSettingsCache} from "../../../../../hooks/cache/useSettingsCache";

export const CategoriesPart = memo((props: Props): ReactElement | null => {
    const {shopShiftCategoryDialog} = useDialogContext();
    const [state, dispatch] = useMagicReducer(reducer({
        shopId: props.shopId,
        shopShiftCategoryDialog,
    }), initialState);
    const {shop} = useShopCache(props.shopId);
    const {categories} = useSettingsCache();

    const settingAbsencesCategories = categories?.filter(category => category.type === ShopShiftCategoryType.Absence);

    const isSettingsShop = props.shopId === "settings";

    const items = new Array<Immutable<ShopShiftCategory>>;
    // Push only absences from settings if shop is not settings because it's static and can't be modified
    if (!isSettingsShop) items.push(...(settingAbsencesCategories ?? []));
    items.push(...(shop?.categories ?? []));

    if (!shop || !categories) return (
        <Flex fill vAlign={"center"} hAlign={"center"}>
            <Loader/>
        </Flex>
    )

    return (
        <Flex fill column gap={"gap.medium"} styles={{height: "fit-content", padding: "0 25px"}}>
            {renderCategoryList(dispatch, props.shopId, items, ShopShiftCategoryType.Shift)}
            {renderCategoryList(dispatch, props.shopId, items, ShopShiftCategoryType.Absence)}
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderCategoryList = (
    dispatch: MagicDispatch<typeof reducer>,
    shopId: string,
    items: Immutable<Array<ShopShiftCategory>>,
    type: ShopShiftCategoryType,
) => {
    return (
        <Accordion
            card
            defaultOpen
            title={translations.get(type === ShopShiftCategoryType.Shift ? "Shifts" : "Absences")}
            content={
                <Flex fill column gap={"gap.smaller"}>
                    <CategoriesTable items={items} shopId={shopId} type={type}/>
                    <Button
                        fluid
                        tinted
                        className={"no-shrink"}
                        icon={<AddIcon/>}
                        content={translations.get("AddCategory")}
                        onClick={dispatch("addCategory", type)}
                    />
                </Flex>
            }
        />
    );
}