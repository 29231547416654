import React, {memo, ReactElement} from "react";
import {Props} from "./UserReportDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./UserReportDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Flex} from "@fluentui/react-northstar";
import "./UserReportDialog.styles.scss";
import {useUserCache} from "../../../hooks/cache/useUsersCache";
import {UserThumbnail} from "../../others/UserThumbnail/UserThumbnail";
import {translations} from "../../../translations";
import {useUserReport} from "../../../hooks/reports/useUserReport";
import {Kpi} from "components/others/Kpi/Kpi";
import {DateRangeSelector} from "../../others/DateRangeSelector/DateRangeSelector";
import {usePlanningDateRangeCache} from "../../../hooks/cache/usePlanningDateRangeCache";
import {useUserRolesCache} from "../../../hooks/cache/useUserRoleCache";
import {UserRole} from "../../../interfaces/UserRole";
import {useMsTeamsSelector} from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";

export const UserReportDialog = memo((props: Props): ReactElement | null => {
    const {userId} = useMsTeamsSelector("userId");
    const dialogRef = useMagicReducerRef(Dialog);
    const dateRangeRef = useMagicReducerRef(DateRangeSelector);
    const {isOnlyRole} = useUserRolesCache();
    const {planningDateRange} = usePlanningDateRangeCache();
    const [state, dispatch, render] = useMagicReducer(reducer({
        dialogRef, planningDateRange, dateRangeRef
    }), initialState, props.externalRef);
    const {user} = useUserCache(state.userId);
    const startDate = dateRangeRef.state?.selectedRange.startDate;
    const endDate = dateRangeRef.state?.selectedRange.endDate;
    const {userReportItems, isLoading} = useUserReport(state.userId, startDate, endDate);

    const kpis: Array<ReactElement> = Object.entries(userReportItems).map(([key, value]) => (
        <Kpi key={key} {...value} showSkeletons={isLoading}/>
    ));

    const isOnlyStaff = isOnlyRole(UserRole.Staff);

    const cantCloseDialog = state.userId === userId && isOnlyStaff;

    return (
        <Dialog
            externalRef={dialogRef}
            width={900}
            noCloseButton={cantCloseDialog}
            closeOnOutsideClick={!cantCloseDialog}
            headerStyles={{paddingBottom: "10px"}}
            onOpen={dispatch("setDateRangeDate")}
            headerElement={
                <DateRangeSelector
                    externalRef={dateRangeRef}
                    className={"planning-date-range"}
                    onDateRangeChange={render}
                />
            }
            icon={
                <Flex styles={{marginTop: "2px"}}>
                    <UserThumbnail userId={state.userId} size={"smaller"}/>
                </Flex>
            }
            title={user?.displayName + " - " + translations.get("Report")}
            content={
                <Flex fill column styles={{gap: "15px"}}>
                    <Flex fill className={"kpis-grid"}>
                        {kpis.slice(0, 2)}
                    </Flex>
                    <Flex fill className={"small-kpis-grid"}>
                        {kpis.slice(2)}
                    </Flex>
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);