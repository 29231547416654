import {State} from "./SharePlanningDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "../Dialog/Dialog";
import {useSharePlanningForm} from "../../../hooks/forms/useSharePlanningForm";

export const initialState: State = {
    shopId: "",
    isLoading: false,
    startDate: undefined,
    endDate: undefined,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, data: {
        shopId: string,
        startDate: string | undefined,
        endDate: string | undefined
    }) => {
        const {dialogRef} = config;
        const {shopId, startDate, endDate} = data;
        setState({shopId, startDate, endDate, isLoading: false});
        dialogRef.dispatch("open")();
    },
    reset: ({setState}, _, form: ReturnType<typeof useSharePlanningForm>) => {
        setState({...initialState});
        form.reset();
    }
}) satisfies MagicReducerObject<State>;