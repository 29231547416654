import {TeamsFxConfig} from "services/ScopesService/ScopesService.interfaces";

export const SCOPE_INIT = "scope/init";
export const SCOPE_GET_GRAPHCLIENT = "scope/get/graphClient";
export const SCOPE_INIT_GRAPHCLIENT = "scope/init/graphClient";

export type ScopesReducerState = {
    loaded: boolean,
    needToConsent: boolean,
    isConsenting: boolean,
    teamsFxConfig: TeamsFxConfig | undefined,
}