import React, {memo, ReactElement, useEffect, useLayoutEffect, useMemo, useRef} from "react";
import {PlanningView, Props, State} from "./Planning.interfaces";
import {
    Immutable,
    MagicDispatch,
    MagicReducerRef,
    useMagicReducer,
    useMagicReducerRef
} from "@witivio_teamspro/use-reducer";
import {initialState, isStaffVisible, reducer} from "./Planning.reducer";
import {CompareModule} from "modules/Compare.module";
import "./Planning.styles.scss";
import {AddIcon, ArrowSyncIcon, Button, Flex, Loader, Text, Tooltip, TrashCanIcon} from "@fluentui/react-northstar";
import {Header} from "./Header/Header";
import {GroupAccordion} from "components/others/GroupAccordion/GroupAccordion";
import {StaffShifts} from "components/others/StaffShifts/StaffShifts";
import {useShopCache, useShopsCache} from "hooks/cache/useShopsCache";
import {FilterItemData} from "components/others/Filter/Filter.interfaces";
import {useViewport, Viewport} from "hooks/useViewport";
import {GroupData} from "interfaces/GroupData";
import {ShopData, ShopShiftCategoryType, ShopType} from "interfaces/ShopData";
import {ShiftStaffs} from "components/others/ShiftStaffs/ShiftStaffs";
import {SelectionArea} from "components/others/SelectionArea/SelectionArea";
import {ShiftsCache, useRotatingStaffsShiftsCache, useShiftsCache} from "../../../hooks/cache/useShiftsCache";
import {useShopGroupsCache} from "../../../hooks/cache/groups/useShopGroupsCache";
import {GroupModule} from "../../../modules/Group.module";
import {translations} from "../../../translations";
import {CopyIcon, PasteIcon, ShopIcon} from "../../../assets/icons";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";
import {
    PlanningConfigurationDialogTab
} from "../../../components/dialogs/PlanningConfigurationDialog/PlanningConfigurationDialog.interfaces";
import {DialogContextValue} from "../../../services/DialogContext/DialogContext.interfaces";
import {usePlanningDateRangeCache} from "../../../hooks/cache/usePlanningDateRangeCache";
import {PopupMenuButton} from "../../../components/buttons/PopupMenuButton/PopupMenuButton";
import {useUserRolesCache} from "../../../hooks/cache/useUserRoleCache";
import {
    RotatingStaffGroupsCache,
    useRotatingStaffGroupsCache
} from "../../../hooks/cache/groups/useRotatingStaffGroupsCache";
import {useDeeplinkContext} from "../../../hooks/useDeeplinkContext";
import {DeeplinkContextType} from "../../../interfaces/DeeplinkContext";
import {ShiftApi} from "../../../apis/Shift/ShiftApi";
import {NoShopPage} from "../../NoShopPage/NoShopPage";
import {useQueryClient} from "react-query";

export const Planning = memo((props: Props): ReactElement | null => {
    const {deeplinkContext, clearDeeplinkContext} = useDeeplinkContext();
    const {
        planningConfigurationDialog,
        confirmDeleteDialog,
        confirmDialog,
        shiftDialog,
        updateClockingDialog
    } = useDialogContext();
    const {planningDateRange} = usePlanningDateRangeCache();
    const {replaceUserShifts, deleteShifts} = useShiftsCache();
    const {shops, isLoading: areShopsLoading} = useShopsCache();
    const {canUpdateShop} = useUserRolesCache();
    const {shop} = useShopCache(props.shopId);
    const {groups, isLoading: areGroupsLoading} = useShopGroupsCache(props.shopId);
    const {startDate, endDate} = planningDateRange.selectedRange;
    const selectionAreaRef = useMagicReducerRef(SelectionArea);
    const contextMenuPopupRef = useMagicReducerRef(PopupMenuButton);
    const contextDivRef = useRef<HTMLDivElement | null>(null);
    const queryClient = useQueryClient();
    const [state, dispatch] = useMagicReducer(reducer({
        replaceUserShifts,
        props,
        planningDateRange,
        contextMenuPopupRef,
        confirmDeleteDialog,
        deleteShifts,
        confirmDialog,
        shop
    }), initialState);

    useEffect(function checkDeeplink() {
        if (!deeplinkContext || !deeplinkContext.id || !props.shopId) return;
        const validContextTypes = [DeeplinkContextType.Shift, DeeplinkContextType.UpdateClocking];
        if (!validContextTypes.includes(deeplinkContext.type)) return;
        ShiftApi.getById(deeplinkContext.id).then(shift => {
            if (!shift) return;
            switch (deeplinkContext.type) {
                case DeeplinkContextType.Shift:
                    shiftDialog.dispatch("open", {currentShopId: props.shopId!, shift})();
                    break;
                case DeeplinkContextType.UpdateClocking:
                    updateClockingDialog.dispatch("open", {currentShopId: props.shopId!, shift})();
                    break;
                default:
                    break;
            }
            clearDeeplinkContext();
        })
    }, [deeplinkContext, props.shopId]);

    const isShopHeadOffice = shop?.type === ShopType.RotatingStaff;
    const areRotatingStaffsShiftsEnabled = !isShopHeadOffice && !!shop;
    const {shifts: rotatingStaffsShifts} = useRotatingStaffsShiftsCache(props.shopId, startDate, endDate, areRotatingStaffsShiftsEnabled);

    const areHeadOfficeGroupsEnabled = !!shop && !isShopHeadOffice && ((rotatingStaffsShifts?.length ?? 0) > 0);
    const {groups: headOfficeGroups} = useRotatingStaffGroupsCache(areHeadOfficeGroupsEnabled);

    const readOnly = !canUpdateShop(props.shopId);

    const viewport = useViewport(state.planningGroupsContainer, 20);

    const noShopAvailable = !areShopsLoading && !shops?.length;

    useLayoutEffect(function onShopChange() {
        if (!props.shopId) return;
        RotatingStaffGroupsCache.clearRotatingStaffGroupsCache(queryClient);
        ShiftsCache.clearShopShiftsCache(queryClient)(props.shopId);
    }, [props.shopId]);

    useEffect(function addResizeObserver() {
        if (!state.planningGroupsContainer) return;
        const resizeObserver = new ResizeObserver(() => {
            const clientWidth = state.planningGroupsContainer?.clientWidth ?? 0;
            const offsetWidth = state.planningGroupsContainer?.offsetWidth ?? 0;
            const scrollbarWidth = offsetWidth - clientWidth;
            dispatch("setScrollbarWidth")(scrollbarWidth);
        });
        resizeObserver.observe(state.planningGroupsContainer as HTMLDivElement);
        return () => {
            if (state.planningGroupsContainer) resizeObserver.unobserve(state.planningGroupsContainer as HTMLDivElement);
        }
    }, [state.planningGroupsContainer]);

    useEffect(function addKeyEventListener() {
        const handler = dispatch("keyDown");
        window.addEventListener("keydown", handler);
        return () => window.removeEventListener("keydown", handler);
    }, []);

    const filteredUsers = useMemo(() => {
        const staffFilter = (props.filters?.["staff"] ?? {}) as Record<string, FilterItemData>;
        if (Object.values(staffFilter).every(f => !f.isChecked)) return undefined;
        const usersIds = new Array<string>();
        for (const [key, value] of Object.entries(staffFilter))
            if (value.isChecked) usersIds.push(key);
        return usersIds;
    }, [props.filters]);

    const filteredHeadOfficeUsers = useMemo(() => {
        const usersIds = rotatingStaffsShifts?.filter(s => s.getShopId() === props.shopId).map(s => s.getUserId());
        return headOfficeGroups?.flatMap(g => g.usersIds.filter(id => usersIds?.includes(id)));
    }, [headOfficeGroups, rotatingStaffsShifts, props.shopId]);

    const filteredHeadOfficeGroups = useMemo(() => {
        return headOfficeGroups?.filter(g => {
            return g.usersIds.some(userId => filteredHeadOfficeUsers?.includes(userId));
        });
    }, [headOfficeGroups, filteredHeadOfficeUsers]);

    const checkedFilteredGroupsIds = useMemo(() => {
        const groupFilter = (props.filters?.["group"] ?? {}) as Record<string, FilterItemData>;
        return Object.entries(groupFilter).filter(([, value]) => value.isChecked).map(([key]) => key);
    }, [props.filters]);

    const filteredGroups = useMemo(() => {
        let items: Array<Immutable<GroupData>> | undefined = [...(groups ?? [])];
        if (filteredUsers) items = items.filter(g => g.usersIds.some(id => filteredUsers.includes(id)));
        if (checkedFilteredGroupsIds.length > 0) items = items.filter(g => checkedFilteredGroupsIds.includes(g.id));
        items = GroupModule.sortGroupsByOrder(items);
        dispatch("updateStaffsOrder", items)();
        return items;
    }, [groups, checkedFilteredGroupsIds, filteredUsers, filteredHeadOfficeGroups, props.view]);

    const filteredCategoriesIds = useMemo(() => {
        const categoryFilter = (props.filters?.["category"] ?? {}) as Record<string, FilterItemData>;
        if (Object.values(categoryFilter).every(f => !f.isChecked)) return undefined;
        const categoriesIds = new Array<string>();
        for (const [key, value] of Object.entries(categoryFilter))
            if (value.isChecked) categoriesIds.push(key);
        return categoriesIds;
    }, [props.filters]);

    const showOnlyUnClockedShifts = props.filters?.["unclockedShifts"]?.isChecked as boolean ?? false;

    const showRotatingStaffGroups = !filteredUsers && checkedFilteredGroupsIds.length === 0 && props.view === PlanningView.People;

    const clipboardShiftsCount = useMemo(() => {
        return Object.values(state.clipboardCells ?? {}).flatMap(cellData => cellData.shifts.filter(Boolean)).length;
    }, [state.clipboardCells]);

    const selectedShiftsCount = useMemo(() => {
        return Object.values(state.selectedCells).flatMap(cellData => cellData.shifts.filter(Boolean)).length;
    }, [state.selectedCells]);

    if (areShopsLoading) return renderLoader();
    if (noShopAvailable) return <NoShopPage/>;

    if (areGroupsLoading) return renderLoader();
    if (!groups?.length) return renderNewGroupPage(planningConfigurationDialog, props.shopId);

    const allGroups = showRotatingStaffGroups ? [...(filteredGroups ?? []), ...(filteredHeadOfficeGroups ?? [])] : filteredGroups;

    const groupsItems = allGroups?.map((g) => {
        const isRotatingStaffGroup = headOfficeGroups?.some(hg => hg.id === g.id) ?? false;
        return (
            <GroupAccordion
                key={"shop" + props.shopId + "group" + g.id}
                {...isRotatingStaffGroup && {renderTitle: renderRotatingStaffGroupTitle}}
                shopId={props.shopId} groupId={g.id} defaultOpen
                hideActions={isRotatingStaffGroup}>
                <Flex fill column>
                    {props.view === PlanningView.People ?
                        renderPeopleItems({
                            currentShopId: props.shopId,
                            dispatch,
                            filteredUsers,
                            filteredHeadOfficeUsers,
                            filteredCategoriesIds,
                            viewport,
                            group: g,
                            selectionAreaRef,
                            selectedCells: state.selectedCells,
                            showOnlyUnClockedShifts,
                            isRotatingStaffGroup,
                        })
                        :
                        renderShiftsItems({
                            shop,
                            group: g,
                            filteredCategoriesIds,
                        })
                    }
                </Flex>
            </GroupAccordion>
        )
    });

    return (
        <Flex fill column className={"planning"}>
            <Header
                shopId={props.shopId}
                scrollbarWidth={state.scrollbarWidth}
                groups={filteredGroups}
                categoriesIds={filteredCategoriesIds}
            />
            <Flex fill column className={"planning-groups-container"}
                  onContextMenu={dispatch("rightClick")}
                  ref={dispatch("planningGroupsContainerRef")}>
                <div className={"planning-groups-container-shadow"}/>
                {renderContextPopup(dispatch, contextMenuPopupRef, state.rightClickPosition, contextDivRef, selectedShiftsCount, clipboardShiftsCount)}
                {groupsItems}
            </Flex>
            {!readOnly && <SelectionArea externalRef={selectionAreaRef} element={state.planningGroupsContainer}/>}
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderPeopleItems = (config: {
    currentShopId: string | undefined,
    dispatch: MagicDispatch<typeof reducer>,
    group: Immutable<GroupData>,
    filteredUsers: string[] | undefined,
    filteredHeadOfficeUsers: string[] | undefined,
    filteredCategoriesIds: string[] | undefined,
    viewport: Viewport,
    selectionAreaRef: MagicReducerRef<typeof SelectionArea>,
    selectedCells: Immutable<State["selectedCells"]>,
    showOnlyUnClockedShifts: boolean,
    isRotatingStaffGroup: boolean,
}): Array<ReactElement | null> => {
    const {
        currentShopId,
        group,
        filteredUsers,
        filteredHeadOfficeUsers,
        filteredCategoriesIds,
        viewport,
        selectionAreaRef,
        selectedCells,
        dispatch,
        isRotatingStaffGroup,
    } = config;
    if (!currentShopId) return [null];
    let filteredUsersIds = group.usersIds.filter(id => !filteredUsers || filteredUsers.includes(id));
    if (isRotatingStaffGroup) filteredUsersIds = filteredUsersIds.filter(id => filteredHeadOfficeUsers?.includes(id));
    return filteredUsersIds.map((id) => (
        <StaffShifts
            key={"staff" + group.id + id}
            currentShopId={currentShopId}
            userId={id}
            groupId={group.id}
            visibleCategoriesIds={filteredCategoriesIds}
            isVisible={isStaffVisible(viewport)}
            selectedCellData={selectedCells[group.id + id]}
            selectionAreaRef={selectionAreaRef}
            onSelectCells={dispatch("handleSelectCells", group.id + id)}
            onCopyShift={dispatch("copySingleShift", group.id + id)}
            onPasteShift={dispatch("pasteFromShift", group.id + id)}
            showOnlyUnClockedShifts={config.showOnlyUnClockedShifts}
            readOnly={isRotatingStaffGroup}
        />
    ))
}

const renderShiftsItems = (config: {
    shop: Immutable<ShopData> | undefined,
    group: Immutable<GroupData>,
    filteredCategoriesIds: string[] | undefined,
}): Array<ReactElement> => {
    const {shop, group, filteredCategoriesIds} = config;
    if (!shop) return [];
    const categories = shop.categories
        .filter(c => c.type === ShopShiftCategoryType.Shift && (!filteredCategoriesIds || filteredCategoriesIds.includes(c.key)));
    return categories.map((category) =>
        <ShiftStaffs
            key={group.id + category.key}
            groupId={group.id}
            shiftCategoryId={category.key}
        />
    )
}

const renderNewGroupPage = (
    planningConfigurationDialog: DialogContextValue["planningConfigurationDialog"],
    shopId: string | undefined
) => {
    return (
        <Flex fill column vAlign={"center"} hAlign={"center"} gap={"gap.smaller"}>
            <ShopIcon height={100} width={100}/>
            <Text size={"larger"} weight={"semibold"} content={translations.get("WelcomeInYourShop")}/>
            <Text size={"large"} content={translations.get("CreateYourFirstGroup")}/>
            <Button
                styles={{marginTop: "15px"}} primary
                content={translations.get("AddGroup")}
                icon={<AddIcon/>}
                onClick={planningConfigurationDialog.dispatch("open", {
                    shopId: shopId!,
                    tab: PlanningConfigurationDialogTab.Groups,
                })}
            />
        </Flex>
    )
}

const renderLoader = () => {
    return (
        <Flex fill vAlign={"center"} hAlign={"center"}>
            <Loader/>
        </Flex>
    )
}

const renderContextPopup = (
    dispatch: MagicDispatch<typeof reducer>,
    contextMenuPopupRef: MagicReducerRef<typeof PopupMenuButton>,
    rightClickPosition: Immutable<State["rightClickPosition"]>,
    contextDivRef: React.RefObject<HTMLDivElement>,
    selectedShiftsCount: number,
    clipboardShiftsCount: number,
) => {
    return <>
        <div
            ref={contextDivRef}
            style={{position: "absolute", left: rightClickPosition?.x, top: rightClickPosition?.y}}
        />
        <PopupMenuButton
            position={"below"}
            align={"start"}
            externalRef={contextMenuPopupRef}
            target={contextDivRef.current}
            menu={{
                onMouseDown: stopPropagation,
                items: [
                    ...(selectedShiftsCount > 0 ? [{
                        key: "copy",
                        content: translations.get("Copy"),
                        icon: <CopyIcon/>,
                        onClick: dispatch("copySelectedCells"),
                    }] : []),
                    ...(clipboardShiftsCount > 0 ? [{
                        key: "paste",
                        content: translations.get("Paste"),
                        icon: <PasteIcon/>,
                        onClick: dispatch("pasteClipboardShifts"),
                    }] : []),
                    ...(selectedShiftsCount > 0 ? [
                        {
                            key: "divider",
                            kind: "divider"
                        },
                        {
                            key: "delete",
                            icon: <TrashCanIcon styles={{color: "red !important"}} outline/>,
                            content: translations.get("Delete"),
                            onClick: dispatch("deleteSelectedCells"),
                            styles: {color: "red !important"}
                        }
                    ] : [])
                ]
            }}
        />
    </>
}

const renderRotatingStaffGroupTitle = (title: string): ReactElement => {
    return (
        <Tooltip
            position={"above"}
            align={"center"}
            offset={[-10, 0]}
            pointing
            content={translations.get("RotatingStaff")}
            trigger={
                <Flex vAlign={"center"} gap={"gap.smaller"}>
                    <ArrowSyncIcon styles={{color: "darkgray"}}/>
                    <Text content={title} size={"large"} weight={"bold"}/>
                </Flex>
            }
        />
    )
}

const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();