import {State} from "./AddShiftSideView.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {SideView} from "components/dialogs/SideView/SideView";

export const initialState: State = {}

export const reducer = (config: {
    sideViewRef: MagicReducerRef<typeof SideView>,
}) => ({
    open: config.sideViewRef.dispatch("open"),
}) satisfies MagicReducerObject<State>;