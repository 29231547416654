import React, {memo, ReactElement, useEffect} from "react";
import {Props} from "./ShopPart.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShopPart.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ShopPart.styles.scss";
import {useShopForm} from "hooks/forms/useShopForm";
import {Flex, Loader} from "@fluentui/react-northstar";
import {useDialogContext} from "../../../../../services/DialogContext/DialogContext";

export const ShopPart = memo((props: Props): ReactElement | null => {
    const {confirmCancelChangesDialog} = useDialogContext();
    const [, dispatch] = useMagicReducer(reducer({confirmCancelChangesDialog}), initialState);
    const shopForm = useShopForm(props.shopId);

    useEffect(function onMount() {
        props.setBeforeClose(dispatch("beforeClose", shopForm.state.hasChanged));
        return () => props.setBeforeClose(undefined);
    }, [shopForm.state.hasChanged]);

    return (
        <Flex fill column gap={"gap.small"} className={"planning-shop-part"}>
            {shopForm.isLoading ?
                <Flex fill className={"planning-shop-part-loader"}>
                    <Loader/>
                </Flex>
                :
                <Flex fill column styles={{gap: "20px"}} className={"overflow-hidden"}>
                    <Flex fill className={"overflow-scroll"} style={{padding: "0 25px"}}>
                        {shopForm.renderForm()}
                    </Flex>
                    <Flex gap={"gap.small"} hAlign={"end"} className={"no-shrink"} style={{padding: "0 25px"}}>
                        {shopForm.renderSaveButton()}
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}, CompareModule.areObjectsEqual);