import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";
import {AxiosResponse} from "axios";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {UserReportData} from "../../interfaces/UserReportData";
import {GroupUserReportData} from "../../interfaces/GroupUserReportData";
import {BasicReportData} from "../../interfaces/BasicReportData";

const getUserReport = async (userId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    if (!userId || !startDate || !endDate) return;
    try {
        const searchParams = new URLSearchParams({startDate, endDate}).toString();
        const response: AxiosResponse<Immutable<UserReportData>> = await AxiosConfig.instance.get(`/report/users/${userId}?${searchParams}`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get user report", error);
        return;
    }
}

const getShopReport = async (shopId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    if (!shopId || !startDate || !endDate) return;
    try {
        const searchParams = new URLSearchParams({startDate, endDate}).toString();
        const response: AxiosResponse<Immutable<BasicReportData>> = await AxiosConfig.instance.get(`/report/shops/${shopId}?${searchParams}`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get shop report", error);
        return;
    }
}

const getGroupReport = async (groupId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    if (!groupId || !startDate || !endDate) return;
    try {
        const searchParams = new URLSearchParams({startDate, endDate}).toString();
        const response: AxiosResponse<Immutable<BasicReportData>> = await AxiosConfig.instance.get(`/report/groups/${groupId}?${searchParams}`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get group report", error);
        return;
    }
}

const getGroupUsersReports = async (groupId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    if (!groupId || !startDate || !endDate) return;
    try {
        const searchParams = new URLSearchParams({startDate, endDate}).toString();
        const response: AxiosResponse<Immutable<Array<GroupUserReportData>>> = await AxiosConfig.instance.get(`/report/groups/${groupId}/users?${searchParams}`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get group users reports", error);
        return;
    }
}

const exportReport = async (startDate: string, endDate: string, shopId: string | undefined): Promise<Blob | undefined> => {
    try {
        const response = await AxiosConfig.instance.post(`/report/export`, {startDate, endDate, shopId});
        const base64File = `data:${response.headers["Content-Type"]};base64,${response.data}`;
        return await fetch(base64File).then(res => res.blob());
    } catch (error) {
        ErrorModule.showErrorAlert("Can't export report", error);
        return undefined;
    }
}

export const ReportApi = {
    getUserReport,
    getGroupUsersReports,
    getShopReport,
    getGroupReport,
    exportReport,
}