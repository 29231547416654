import {ITableColumn} from "../../Table/Table.interfaces";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {ShopData, ShopType} from "interfaces/ShopData";
import {Flex, Skeleton, Text} from "@fluentui/react-northstar";
import React, {useMemo} from "react";
import {translations} from "translations";
import {UserThumbnail} from "components/others/UserThumbnail/UserThumbnail";
import {PeopleIcon} from "assets/icons";
import {useShopsCache} from "hooks/cache/useShopsCache";
import {useUsersCache} from "hooks/cache/useUsersCache";
import {GraphUserData} from "services/GraphService/GraphService.interfaces";
import {useMsTeamsSelector} from "redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {useDialogContext} from "../../../../services/DialogContext/DialogContext";
import {DialogContextValue} from "../../../../services/DialogContext/DialogContext.interfaces";
import {ShopModule} from "../../../../modules/Shop.module";

export const useShopsTableColumns = (): Array<ITableColumn<Immutable<ShopData> | null>> => {
    const {shopDialog} = useDialogContext();
    const {isLightTheme} = useMsTeamsSelector("isLightTheme");
    const {shops} = useShopsCache();
    const managersIds = useMemo(() => shops?.flatMap(shop => shop.managersIds) ?? [], [shops]);
    const {users} = useUsersCache(managersIds);

    return [
        generateNameColumn(shopDialog),
        generateTypeColumn(),
        generatePostalAddressColumn(),
        generatePublicHolidaysColumn(),
        generateManagersColumn(users, isLightTheme),
        generateCollaboratorsColumn(),
    ];
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const generateNameColumn = (shopDialog: DialogContextValue["shopDialog"]): ITableColumn<Immutable<ShopData> | null> => ({
    field: "shop",
    label: translations.get("Shop"),
    render: item => item?.name ?? "",
    sort: (a, b) => {
        return (a?.name ?? "").localeCompare(b?.name ?? "") ?? 0;
    },
    minWidth: "100px",
    maxWidth: "100%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
    onClick: item => shopDialog.dispatch("open", item?.id)(),
});

const generateTypeColumn = (): ITableColumn<Immutable<ShopData> | null> => ({
    field: "type",
    label: translations.get("Type"),
    render: item => item?.type === undefined ? "" : translations.get(ShopType[item.type]),
    sort: (a, b) => (a?.type ?? 0) - (b?.type ?? 0),
    minWidth: "100px",
    maxWidth: "100%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
});

const generatePostalAddressColumn = (): ITableColumn<Immutable<ShopData> | null> => ({
    field: "postalAddress",
    label: translations.get("PostalAddress"),
    render: item => item?.postalAddress ?? "",
    sort: (a, b) => {
        return (a?.postalAddress ?? "").localeCompare(b?.postalAddress ?? "") ?? 0;
    },
    minWidth: "100px",
    maxWidth: "100%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
});

const generatePublicHolidaysColumn = (): ITableColumn<Immutable<ShopData> | null> => ({
    field: "publicHolidays",
    label: translations.get("PublicHolidays"),
    render: item => ShopModule.getHolidayIdLabel(item?.publicHolidaysId),
    sort: (a, b) => {
        return (a?.publicHolidaysId ?? "").localeCompare(b?.publicHolidaysId ?? "") ?? 0;
    },
    minWidth: "100px",
    maxWidth: "100%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
});

const generateManagersColumn = (
    users: Immutable<Array<GraphUserData>> | undefined,
    isLightTheme: boolean
): ITableColumn<Immutable<ShopData> | null> => ({
    field: "managers",
    label: translations.get("ManagerSingularPlural"),
    render: item => (
        <Flex fill gap={"gap.smaller"} vAlign={"center"}>
            <Flex>
                {item?.managersIds.slice(0, 3).map((id, i) => (
                    <Flex key={id} styles={{
                        marginLeft: i === 0 ? "0" : "-10px",
                        zIndex: 3 - i,
                        borderRadius: "100px",
                        border: "2px solid " + (isLightTheme ? "white" : "black"),
                    }}>
                        <UserThumbnail userId={id} size={"small"}/>
                    </Flex>
                ))}
            </Flex>
            <Text content={users?.find(u => u.id === item?.managersIds[0])?.displayName ?? ""}/>
            {(item?.managersIds.length ?? 0) > 1 &&
                <Text styles={{color: "darkgray"}} content={`+${(item?.managersIds.length ?? 0) - 1}`}/>
            }
        </Flex>
    ),
    minWidth: "100px",
    maxWidth: "100%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
});

const generateCollaboratorsColumn = (): ITableColumn<Immutable<ShopData> | null> => ({
    field: "collaborators",
    label: translations.get("Collaborators"),
    render: item => (
        <Flex fill gap={"gap.smaller"} vAlign={"center"}>
            <PeopleIcon/>
            <Text content={item?.totalStaffCount ?? 0}/>
        </Flex>
    ),
    minWidth: "100px",
    maxWidth: "100%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
});