import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./AddRequestSideView.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./AddRequestSideView.reducer";
import {CompareModule} from "modules/Compare.module";
import "./AddRequestSideView.styles.scss";
import {SideView} from "../../../components/dialogs/SideView/SideView";
import {translations} from "../../../translations";
import {Flex, Menu} from "@fluentui/react-northstar";
import {CreateRequestDialogModule} from "../../../components/dialogs/CreateRequestDialog/CreateRequestDialog";

export const AddRequestSideView = memo((props: Props): ReactElement | null => {
    const sideViewRef = useMagicReducerRef(SideView);
    const [state, dispatch] = useMagicReducer(reducer({sideViewRef}), initialState, props.externalRef);

    const menuItems = useMemo(() => CreateRequestDialogModule.generateMenuItems(), []);

    const activeIndex = menuItems.findIndex(item => item.id === state.activeTab);

    const menu = (
        <Menu
            styles={{paddingTop: "10px !important"}}
            className={"create-request-dialog-menu"}
            activeIndex={activeIndex}
            items={menuItems}
            underlined
            primary
            onItemClick={dispatch("changeTab")}
        />
    )

    return (
        <SideView externalRef={sideViewRef} title={translations.get("NewRequest")} style={{zIndex: 10}}>
            <Flex fill column className={"create-request-dialog"}>
                {menu}
                {CreateRequestDialogModule.renderDialogContent(() => 0, state.activeTab, sideViewRef.dispatch("close"))}
            </Flex>
        </SideView>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////