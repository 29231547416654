import {QueryClient, useQuery, useQueryClient} from "react-query";
import {RequestApi} from "../../apis/Request/RequestApi";
import {RequestResponse, RequestType} from "../../interfaces/RequestData";
import {RequestData} from "interfaces/RequestData";
import {Immutable} from "@witivio_teamspro/use-reducer";

export const requestsCacheKey = "requests";

export const useRequestsCache = () => {
    const queryClient = useQueryClient();

    const {data: requests, isLoading} = useQuery([requestsCacheKey], RequestApi.getRequests, {staleTime: Infinity});

    return {
        requests,
        isLoading,
        answerRequest: answerRequest(queryClient),
        createRequest: createRequest(queryClient),
        approveSwapRequest: approveSwapRequest(queryClient),
        applyRetrieveHours: applyRetrieveHours(queryClient),
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const createRequest = (queryClient: QueryClient) => async (requestData: Immutable<RequestData>) => {
    const result = await RequestApi.createRequest(requestData);
    if (!result) return;
    const requests = queryClient.getQueryData<Immutable<Array<RequestData>>>([requestsCacheKey]);
    if (!requests) return;
    queryClient.setQueryData([requestsCacheKey], [...requests, {...requestData, id: result}]);
}

const answerRequest = (queryClient: QueryClient) => async (requestId: string, response: RequestResponse) => {
    const requests = queryClient.getQueryData<Immutable<Array<RequestData>>>([requestsCacheKey]);
    if (!requests) return;
    const result = await RequestApi.answerRequest(requestId, response);
    if (!result) return;
    const updatedRequests = requests.map(r => r.id === requestId ? {...r, response} : r);
    queryClient.setQueryData([requestsCacheKey], updatedRequests);
}

const approveSwapRequest = (queryClient: QueryClient) => async (requestId: string, approbation: boolean) => {
    const requests = queryClient.getQueryData<Immutable<Array<RequestData>>>([requestsCacheKey]);
    if (!requests) return;
    const request = requests.find(r => r.id === requestId);
    if (!request || request.type !== RequestType.Swap) return;
    const newRequest = await RequestApi.approveSwapRequest(requestId, approbation);
    if (!newRequest) return;
    const updatedRequests = requests.map(r => r.id === requestId ? newRequest : r);
    queryClient.setQueryData([requestsCacheKey], updatedRequests);
}

const applyRetrieveHours = (queryClient: QueryClient) => async (requestId: string, userId: string, usedHours: number) => {
    const requests = queryClient.getQueryData<Immutable<Array<RequestData>>>([requestsCacheKey]);
    if (!requests) return;
    const request = requests.find(r => r.id === requestId);
    if (!request || request.type !== RequestType.WeekSummary) return;
    const newRequest = await RequestApi.applyRetrieveHours(requestId, userId, usedHours);
    if (!newRequest) return;
    const updatedRequests = requests.map(r => r.id === requestId ? newRequest : r);
    queryClient.setQueryData([requestsCacheKey], updatedRequests);
}