import {Props, Recurrence, RecurrenceType, State} from "./ShiftRecurrence.interfaces";
import {Immutable, MagicDispatch, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {WeekDaysModule} from "../../../modules/WeekDays.module";
import {MenuItemProps} from "@fluentui/react-northstar";
import {translations} from "../../../translations";
import moment from "moment";

export const initialState = (props: Props): State => ({
    id: props.initialRecurrence?.id ?? "",
    type: props.initialRecurrence?.type ?? RecurrenceType.Day,
    days: (props.initialRecurrence?.days as number[]) ?? WeekDaysModule.getWeekDaysOrderPerCulture(),
    interval: props.initialRecurrence?.interval ?? 1,
    endDate: props.initialRecurrence?.endDate ?? moment().startOf("day").add(1, "day").toISOString(false),
    hasChanged: false,
});

export const reducer = (props: Props) => ({
    changeType: ({state, setState, render}, _, type: RecurrenceType) => {
        if (type === RecurrenceType.Day) {
            setState({type, days: WeekDaysModule.getWeekDaysOrderPerCulture()});
        } else if (type === RecurrenceType.Week) {
            const selectedDay = WeekDaysModule.getWeekDay(props.shiftDate ?? moment().toISOString(false));
            setState({type, days: [selectedDay]});
        } else if (type === RecurrenceType.Month || type === RecurrenceType.Year) {
            setState({type, days: []});
        } else {
            setState({type});
        }
        reducer(props).updateHasChanged({state, setState, render});
    },
    changeInterval: ({state, setState, render}, _, interval: number) => {
        setState({interval});
        reducer(props).updateHasChanged({state, setState, render});
    },
    toggleSelectedDay: ({state, setState, render}, _, day: number) => {
        const days = state.days.includes(day) ? state.days.filter(d => d !== day) : [...state.days, day];
        if (state.type === RecurrenceType.Day && days.length < 7)
            return setState({days, type: RecurrenceType.Week});
        if (state.type === RecurrenceType.Week && days.length === 7)
            return setState({days, type: RecurrenceType.Day});
        setState({days});
        reducer(props).updateHasChanged({state, setState, render});
    },
    setEndDate: ({state, setState, render}, _, endDate: string) => {
        setState({endDate}, false);
        reducer(props).updateHasChanged({state, setState, render});
    },
    updateHasChanged: ({state, setState}) => {
        const prevChanged = state.hasChanged;
        setState({hasChanged: !areRecurrencesEquals(state, props.initialRecurrence)}, false);
        if (prevChanged === state.hasChanged) return;
        props.onChange?.(state.hasChanged);
    }
}) satisfies MagicReducerObject<State>;

export const generateRecurrenceTypesMenuItems = (dispatch: MagicDispatch<typeof reducer>): Array<MenuItemProps> => {
    const types = Object.keys(RecurrenceType).filter((key: string | number) => !isNaN(Number(key)))
        .map(key => Number(key) as RecurrenceType);
    return types.map(type => {
        return {
            key: type,
            content: translations.get(RecurrenceType[type as any] ?? ""),
            value: type,
            onClick: dispatch("changeType", type),
        }
    });
}

export const generateIntervalsMenuItems = (dispatch: MagicDispatch<typeof reducer>): Array<MenuItemProps> => {
    return Array.from({length: 99}, (_, i) => i + 1).map(i => {
        return {
            key: i,
            content: i,
            value: i,
            onClick: dispatch("changeInterval", i),
        }
    });
}

const areRecurrencesEquals = (a: Immutable<Recurrence> | undefined, b: Immutable<Recurrence> | undefined) => {
    if (!a || !b) return false;
    return (
        a.type === b.type &&
        a.interval === b.interval &&
        a.days.length === b.days.length &&
        a.days.every((day) => b.days.includes(day)) &&
        moment(a.endDate).isSame(moment(b.endDate), "day")
    )
}