import {useQuery} from "react-query";
import {GraphService} from "../../services/GraphService/GraphService";

export const graphTeamsCacheKey = "graphTeams";

export const useGraphTeamsCache = (enabled: boolean = true) => {
    const {data: graphTeams, isLoading} = useQuery([graphTeamsCacheKey], GraphService.getAllTeams, {
        staleTime: Infinity,
        enabled,
    });

    return {
        graphTeams,
        isLoading,
    };
}