import React, {ReactElement, useEffect} from "react";
import {Props} from "./Dialog.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Dialog.reducer";
import "./Dialog.styles.scss";
import {Button, CloseIcon, ComponentSlotStyle, Flex, Text} from "@fluentui/react-northstar";

const defaultStyles: ComponentSlotStyle = {
    borderRadius: "10px",
    minWidth: "200px",
    maxWidth: "calc(100% - 50px)",
    maxHeight: "calc(100% - 50px)",
}

const popupStyles: ComponentSlotStyle = {
    borderRadius: "10px",
    width: "100vw",
    maxHeight: "calc(100% - 50px)",
}

const fullScreenStyles: ComponentSlotStyle = {
    width: "100vw",
    height: "100vh",
}

export const Dialog = (props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer(props), initialState, props.externalRef);

    useEffect(function onBlurMaskVisibilityChange() {
        if (state.initialized) {
            if (state.hideBlurMask) props.onClose?.();
            else props.onOpen?.();
        }
        dispatch("initialize")();
    }, [state.hideBlurMask]);

    const renderDialog = state.isOpen || !state.hideBlurMask;
    if (!renderDialog) return null;

    const hideHeader = !props.title && !props.icon && !props.headerElement;

    const dialogStyles: ComponentSlotStyle = props.fullscreen ? fullScreenStyles : props.popup ? popupStyles : defaultStyles;
    if (!props.fullscreen) {
        dialogStyles.width = (props.width ?? 200) + "px";
        dialogStyles.height = !props.height ? "fit-content" : props.height + "px";
    }

    const dialogClassName = [
        "dialog",
        "no-select",
        !props.showOverflow && "overflow-hidden",
        props.className,
    ].filter(Boolean).join(" ");

    const dialogContentClassName = [
        "dialog-content",
        !props.showOverflow && "overflow-scroll",
        props.noPadding && "dialog-content-no-padding",
        !props.noPadding && hideHeader && "dialog-content-top-padding",
        !props.noPadding && !props.footer && "dialog-content-bottom-padding",
    ].filter(Boolean).join(" ");

    const dialogMaskClassName = [
        "dialog-mask",
        props.popup && "dialog-mask-popup",
        !state.isOpen && "dialog-mask-close"
    ].filter(Boolean).join(" ");

    const dialogBlurMaskClassName = [
        "dialog-blur-mask",
        !state.isOpen && "dialog-blur-mask-hide",
    ].filter(Boolean).join(" ");

    const dialogHeaderLeftPartClassName = [
        "overflow-hidden",
        "no-shrink",
        !props.headerElement && "w-100"
    ].filter(Boolean).join(" ");

    const icon = !props.icon ? null : typeof props.icon === "function" ?
        <props.icon width={24} height={24}/> : props.icon;

    const dialogHeader = hideHeader ? null : (
        <Flex vAlign={"center"} className={"dialog-header"} styles={props.headerStyles ?? {}}>
            <Flex fill gap={"gap.small"} vAlign={"center"} className={"overflow-hidden"}>
                <Flex gap={"gap.small"} vAlign={"center"} className={dialogHeaderLeftPartClassName}>
                    {icon}
                    <Text truncated className={"dialog-title"} size={"large"} weight={"semibold"}
                          content={props.title}/>
                </Flex>
                {props.headerElement}
            </Flex>
            {!props.noCloseButton &&
                <Button onClick={dispatch("close")} styles={{marginRight: "-5px"}} icon={<CloseIcon/>} iconOnly text/>
            }
        </Flex>
    )

    const dialog = (
        <Flex column styles={dialogStyles}
              className={dialogClassName} {...(props.closeOnClick && {onClick: dispatch("close")})}>
            <Flex column fill className={!props.showOverflow ? "overflow-hidden" : ""}>
                {dialogHeader}
                <Flex fill={(props.fullscreen || !!props.height) ?? false} className={dialogContentClassName}>
                    {props.content}
                </Flex>
                {!props.footer ? null : (
                    <Flex column className={"dialog-footer"}>
                        {props.footer}
                    </Flex>
                )}
            </Flex>
        </Flex>
    )

    return <>
        <div className={dialogBlurMaskClassName}/>
        <Flex className={dialogMaskClassName}
              {...(props.closeOnOutsideClick === false ? {} : {
                  onMouseDown: dispatch("maskMouseDown"),
                  onMouseUp: dispatch("maskMouseUp"),
              })}>
            {dialog}
        </Flex>
    </>
}