import {QueryClient, QueryObserverOptions} from "react-query";
import {ShopApi} from "apis/Shop/ShopApi";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {
    CustomShiftThresholdsData,
    CustomUserLegalRules,
    GroupData,
    GroupLegalRules,
    ShiftThresholdsData
} from "../../../interfaces/GroupData";
import {GroupApi} from "../../../apis/Group/GroupApi";
import {CompareModule} from "../../../modules/Compare.module";
import {ObjectModule} from "../../../modules/Object.module";
import {GuidModule} from "../../../modules/Guid.module";

const groupsCacheKey = "groups";

const getGroupCacheKey = (id: string | undefined) => [groupsCacheKey, id];

const getShopGroupsIdsKey = (shopId: string | undefined) => [groupsCacheKey, "shop", shopId];

const getShopGroupsIds = async (queryClient: QueryClient, shopId: string | undefined): Promise<Immutable<Array<string>>> => {
    if (!shopId) return [];
    const cacheKey = getShopGroupsIdsKey(shopId);
    const cachedIds = queryClient.getQueryData<Array<string>>(cacheKey);
    if (cachedIds) return cachedIds;
    const groups = await ShopApi.getGroupsById(shopId);
    groups?.forEach(g => queryClient.setQueryData(getGroupCacheKey(g.id), g));
    const ids = groups?.map(g => g.id) ?? [];
    queryClient.setQueryData(cacheKey, ids);
    return ids;
}

const getGroupQueryArgs = (id: string | undefined): QueryObserverOptions<Immutable<GroupData> | undefined> => ({
    queryKey: getGroupCacheKey(id),
    queryFn: () => GroupApi.getById(id),
    staleTime: Infinity,
    enabled: !!id,
});

const getUserMaximumWorkingHours = (queryClient: QueryClient, groupId: string | undefined) => (
    userId: string | undefined,
    daysRange: number,
) => {
    if (!groupId || !userId || !daysRange) return 999;
    const group = queryClient.getQueryData<Immutable<GroupData>>(getGroupCacheKey(groupId));
    if (!group) return 0;
    const customUserLegalRules = group.legalRules.customRules.find(r => r.userId === userId);
    const maximumDailyWorkingHours = customUserLegalRules?.maximumDailyWorkingHours || group.legalRules.maximumDailyWorkingHours || 0;
    const maximumWeeklyWorkingHours = customUserLegalRules?.maximumWeeklyWorkingHours || group.legalRules.maximumWeeklyWorkingHours || 0;
    if (daysRange === 1) return maximumDailyWorkingHours;
    if (daysRange === 7) return maximumWeeklyWorkingHours;
    if (daysRange % 7 === 0) return maximumWeeklyWorkingHours * Math.floor(daysRange / 7);
    return 999;
}

const upsertThreshold = (queryClient: QueryClient, groupId: string | undefined) => async (threshold: Immutable<ShiftThresholdsData> | undefined) => {
    if (!groupId || !threshold) return;
    const cacheKey = getGroupCacheKey(groupId);
    const group = queryClient.getQueryData<GroupData>(cacheKey);
    if (!group) return;
    const result = await GroupApi.upsertShiftThresholds(group.id, threshold);
    if (!result) return;
    const index = group.thresholds.shifts.findIndex(c => c.categoryId === threshold.categoryId);
    if (index === -1) group.thresholds.shifts.push(threshold as ShiftThresholdsData);
    else group.thresholds.shifts[index] = threshold as ShiftThresholdsData;
    queryClient.setQueryData(cacheKey, {...group});
}

const upsertCustomThreshold = (queryClient: QueryClient, groupId: string | undefined) => async (customThreshold: Immutable<CustomShiftThresholdsData> | undefined) => {
    if (!groupId || !customThreshold) return;
    const cacheKey = getGroupCacheKey(groupId);
    const group = queryClient.getQueryData<GroupData>(cacheKey);
    if (!group) return;
    const index = group.thresholds.custom.findIndex(c => c.id === customThreshold.id);
    if (index === -1) {
        const id = await GroupApi.createCustomThresholds(group.id, customThreshold);
        if (!id) return;
        group.thresholds.custom.push({...customThreshold, id} as CustomShiftThresholdsData);
    } else {
        const updatedFields = ObjectModule.findUpdatedFields(group.thresholds.custom[index], customThreshold);
        const result = await GroupApi.updateCustomThresholds(group.id, customThreshold.id, updatedFields);
        if (!result) return;
        group.thresholds.custom[index] = customThreshold as CustomShiftThresholdsData;
    }
    queryClient.setQueryData(cacheKey, {...group});
}

const deleteCustomThreshold = (queryClient: QueryClient, groupId: string | undefined) => async (customThresholdId: string | undefined) => {
    if (!groupId || !customThresholdId) return;
    const cacheKey = getGroupCacheKey(groupId);
    const group = queryClient.getQueryData<GroupData>(cacheKey);
    if (!group) return;
    const result = await GroupApi.deleteCustomThresholds(group.id, customThresholdId);
    if (!result) return;
    group.thresholds.custom = group.thresholds.custom.filter(c => c.id !== customThresholdId);
    queryClient.setQueryData(cacheKey, {...group});
}

const upsertCustomUserLegalRules = (queryClient: QueryClient, groupId: string | undefined) => async (customUserLegalRules: Immutable<CustomUserLegalRules> | undefined) => {
    if (!groupId || !customUserLegalRules) return;
    const cacheKey = getGroupCacheKey(groupId);
    const group = queryClient.getQueryData<GroupData>(cacheKey);
    if (!group) return;
    const newCustomRules = [...group.legalRules.customRules];
    const index = newCustomRules.findIndex(c => c.userId === customUserLegalRules.userId);
    if (index === -1) {
        const result = await GroupApi.createCustomLegalRules(group.id, customUserLegalRules);
        if (!result) return;
        newCustomRules.push(customUserLegalRules);
    } else {
        const updatedFields = ObjectModule.findUpdatedFields(newCustomRules[index], customUserLegalRules);
        const result = await GroupApi.updateCustomLegalRules(group.id, customUserLegalRules.userId, updatedFields);
        if (!result) return;
        newCustomRules[index] = customUserLegalRules;
    }
    queryClient.setQueryData(cacheKey, {...group, legalRules: {...group.legalRules, customRules: newCustomRules}});
}

const deleteCustomUserLegalRules = (queryClient: QueryClient, groupId: string | undefined) => async (userId: string | undefined) => {
    if (!groupId || !userId) return;
    const cacheKey = getGroupCacheKey(groupId);
    const group = queryClient.getQueryData<GroupData>(cacheKey);
    if (!group) return;
    const result = await GroupApi.deleteCustomLegalRules(group.id, userId);
    if (!result) return;
    group.legalRules.customRules = group.legalRules.customRules.filter(c => c.userId !== userId);
    queryClient.setQueryData(cacheKey, {...group});
}

const updateLegalRules = (queryClient: QueryClient, groupId: string | undefined) => async (legalRules: Immutable<GroupLegalRules>) => {
    if (!groupId) return;
    const cacheKey = getGroupCacheKey(groupId);
    const group = queryClient.getQueryData<GroupData>(cacheKey);
    if (!group) return;
    const updatedFields = findLegalRulesUpdatedFields(group.legalRules, legalRules);
    const updateResult = await GroupApi.updateLegalRules(group.id, updatedFields);
    if (!updateResult) return;
    group.legalRules = {...legalRules, customRules: group.legalRules.customRules};
    queryClient.setQueryData(cacheKey, {...group});
}

const findLegalRulesUpdatedFields = (oldRules: Immutable<GroupLegalRules>, newRules: Immutable<GroupLegalRules>) => {
    const updatedFields: Partial<GroupLegalRules> = {};
    Object.entries(newRules).forEach(([key, value]) => {
        const property = key as keyof GroupLegalRules;
        if (property === "customRules") return;
        const areEquals = CompareModule.areValuesEqual(oldRules[property], newRules[property], true);
        if (!areEquals) updatedFields[property] = value as never;
    });
    return updatedFields;
}

const addStaffs = (queryClient: QueryClient, groupId: string | undefined) => async (userIds: Immutable<string[]> | undefined) => {
    if (!groupId || !userIds || userIds.length === 0) return;
    const cacheKey = getGroupCacheKey(groupId);
    const group = queryClient.getQueryData<GroupData>(cacheKey);
    if (!group) return;
    const newUsersIds = userIds.filter(id => !group.usersIds.includes(id));
    const result = await GroupApi.assignStaffs(groupId, newUsersIds);
    if (!result) return;
    group.usersIds = [...group.usersIds, ...newUsersIds];
    queryClient.setQueryData(cacheKey, {...group});
    userIds.forEach(userId => checkDuplicatedUserInGroups(queryClient, userId, groupId));
}

const checkDuplicatedUserInGroups = (queryClient: QueryClient, userId: string | undefined, assignedGroupId: string | undefined) => {
    if (!userId || !assignedGroupId) return;
    const data = queryClient.getQueriesData<Immutable<GroupData> | Array<string>>([groupsCacheKey]);
    if (!data || data.length === 0) return;
    let groups = data.filter(i => GuidModule.isValidGuid(i[0][1] + "")).map(i => i[1]).filter(Boolean) as Array<Immutable<GroupData>>;
    const groupsToUpdate = groups.filter(g => g.id !== assignedGroupId && g.usersIds.includes(userId));
    if (groupsToUpdate.length === 0) return;
    groupsToUpdate.forEach(g => {
        queryClient.setQueryData(getGroupCacheKey(g.id), {
            ...g, usersIds: g.usersIds.filter(id => id !== userId),
        });
    });
}

const deleteStaff = (queryClient: QueryClient, groupId: string | undefined) => (userId: string | undefined) => {
    if (!groupId || !userId) return;
    const cacheKey = getGroupCacheKey(groupId);
    const group = queryClient.getQueryData<GroupData>(cacheKey);
    if (!group) return;
    const result = GroupApi.removeStaff(groupId, userId);
    if (!result) return;
    group.usersIds = group.usersIds.filter(id => id !== userId);
    queryClient.setQueryData(cacheKey, {...group});
}

const updateIndexes = (queryClient: QueryClient) => async (order: Immutable<Array<string>>) => {
    const groups = order
        .map(id => queryClient.getQueryData<GroupData>(getGroupCacheKey(id)))
        .filter(g => !!g) as Array<GroupData>;
    if (order.length !== groups.length || groups.some(g => !g)) return;
    const tasks = groups.map((g, index) => GroupApi.updateIndex(g.id, index));
    const results = await Promise.all(tasks);
    if (!results.every(r => r)) return;
    groups.forEach((g, index) => queryClient.setQueryData(getGroupCacheKey(g.id), {...g, index}));
}

const rename = (queryClient: QueryClient, groupId: string | undefined) => async (name: string) => {
    if (!groupId || !name) return;
    const cacheKey = getGroupCacheKey(groupId);
    const group = queryClient.getQueryData<GroupData>(cacheKey);
    if (!group) return;
    const result = await GroupApi.rename(group.id, name);
    if (!result) return;
    group.name = name;
    queryClient.setQueryData(cacheKey, {...group});
}

const remove = (queryClient: QueryClient, groupId: string | undefined) => async () => {
    if (!groupId) return;
    const cacheKey = getGroupCacheKey(groupId);
    const group = queryClient.getQueryData<GroupData>(cacheKey);
    if (!group) return;
    const result = await GroupApi.remove(group.id);
    if (!result) return;
    const shopGroupsIdsKey = getShopGroupsIdsKey(group.shopId);
    const shopGroupsIds = await getShopGroupsIds(queryClient, group.shopId);
    queryClient.setQueryData(shopGroupsIdsKey, shopGroupsIds.filter(id => id !== group.id));
    queryClient.setQueryData(cacheKey, undefined);
}

export const GroupsCache = {
    groupsCacheKey,
    getShopGroupsIds,
    getGroupQueryArgs,
    getUserMaximumWorkingHours,
    upsertCustomThreshold,
    deleteCustomThreshold,
    upsertCustomUserLegalRules,
    deleteCustomUserLegalRules,
    addStaffs,
    deleteStaff,
    updateLegalRules,
    getShopGroupsIdsKey,
    updateIndexes,
    upsertThreshold,
    rename,
    remove,
    getGroupCacheKey,
}