import React, {memo, ReactElement, useCallback, useMemo} from "react";
import {Props} from "./ShopsTable.interfaces";
import {Immutable, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShopsTable.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ShopsTable.styles.scss";
import {useShopsTableColumns} from "./hooks/useShopsTableColumns";
import {EditIcon, Flex, MoreIcon, TrashCanIcon} from "@fluentui/react-northstar";
import {Table} from "../Table/Table";
import {ShopData} from "interfaces/ShopData";
import {TableProps} from "../Table/Table.interfaces";
import {translations} from "../../../translations";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";
import {useShopsCache} from "../../../hooks/cache/useShopsCache";

export const ShopsTable = memo((props: Props): ReactElement | null => {
    const {shopDialog, confirmDeleteDialog} = useDialogContext();
    const [state, dispatch] = useMagicReducer(reducer, initialState);
    const {shops, deleteShop} = useShopsCache();

    const handleEditShop = useCallback((shop: Immutable<ShopData> | null) => {
        if (!shop) return;
        shopDialog.dispatch("open", shop.id)();
    }, []);

    const columns = useShopsTableColumns();

    const actions: TableProps<ShopData>["actions"] = useMemo(() => ({
        items: [
            {
                label: translations.get("Edit"),
                icon: <EditIcon outline/>,
                onClick: handleEditShop,
            },
            {
                label: translations.get("Delete"),
                icon: <TrashCanIcon outline/>,
                onClick: shop => confirmDeleteDialog.dispatch("open", {
                    title: translations.get("DeleteShop"),
                    subtitle: translations.get("AreYouSureToDeleteShop", {name: shop?.name ?? ""}),
                    callback: () => deleteShop(shop?.id),
                })(),
            }
        ],
        icon: <MoreIcon/>
    }), []);

    const items = shops ?? Array.from({length: 10}).map(() => null);

    return (
        <Flex fill className={"shop-categories-table"}>
            <Table<ShopData>
                items={items}
                columns={columns}
                actions={actions}
                sortLocally
            />
        </Flex>
    )
}, CompareModule.areObjectsEqual);