import {State} from "./ExportPlanningDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {useExportPlanningForm} from "../../../hooks/forms/useExportPlanningForm";

export const initialState: State = {
    shopId: "",
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, data: {
        shopId: string,
    }) => {
        const {dialogRef} = config;
        const {shopId} = data;
        setState({shopId});
        dialogRef.dispatch("open")();
    },
    initializeForm: ({}, _, exportPlanningForm: ReturnType<typeof useExportPlanningForm>) => {
        exportPlanningForm.reset();
    }
}) satisfies MagicReducerObject<State>;