import {State} from "./CopyPlanningDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "../Dialog/Dialog";
import {useCopyPlanningForm} from "../../../hooks/forms/useCopyPlanningForm";

export const initialState: State = {
    shopId: "",
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, data: { shopId: string }) => {
        const {dialogRef} = config;
        const {shopId} = data;
        setState({shopId});
        dialogRef.dispatch("open")();
    },
    reset: ({setState}, _, form: ReturnType<typeof useCopyPlanningForm>) => {
        setState({shopId: ""});
        form.reset();
    }
}) satisfies MagicReducerObject<State>;