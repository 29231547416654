import React, {memo, ReactElement} from "react";
import {Props} from "./ExportPlanningDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ExportPlanningDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import "./ExportPlanningDialog.styles.scss";
import {useExportPlanningForm} from "hooks/forms/useExportPlanningForm";
import {Flex} from "@fluentui/react-northstar";
import {translations} from "translations";
import {ExportIcon} from "assets/icons";

export const ExportPlanningDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    const exportPlanningForm = useExportPlanningForm(state.shopId);

    return (
        <Dialog
            externalRef={dialogRef}
            icon={<ExportIcon/>}
            onOpen={dispatch("initializeForm", exportPlanningForm)}
            width={500}
            title={translations.get("ExportPlanning")}
            content={exportPlanningForm.renderForm()}
            footer={
                <Flex fill hAlign={"end"} space={"between"} gap={"gap.small"}>
                    {exportPlanningForm.formItems.onlyMyShop}
                    {exportPlanningForm.renderExportButton(dialogRef.dispatch("close"))}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);