import React, {memo, ReactElement, useEffect} from "react";
import {Props} from "./LegalRulesPart.interfaces";
import {Immutable, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./LegalRulesPart.reducer";
import {CompareModule} from "modules/Compare.module";
import "./LegalRulesPart.styles.scss";
import {AddIcon, Button, Flex, Text} from "@fluentui/react-northstar";
import {Accordion} from "components/others/Accordion/Accordion";
import {ScalesIcon} from "assets/icons";
import {translations} from "translations";
import {useGroupLegalRulesForm} from "hooks/forms/useGroupLegalRulesForm";
import {PeopleButton} from "components/buttons/PeopleButton/PeopleButton";
import {useDialogContext} from "services/DialogContext/DialogContext";
import {useGroupCache} from "../../../../../hooks/cache/groups/useGroupCache";
import {GroupLegalRules} from "../../../../../interfaces/GroupData";
import {useUsersCache} from "../../../../../hooks/cache/useUsersCache";

export const LegalRulesPart = memo((props: Props): ReactElement | null => {
    const {personLegalRulesDialog, selectPeopleDialog} = useDialogContext();
    const [state, dispatch] = useMagicReducer(reducer({
        groupId: props.groupId,
        personLegalRulesDialog,
        selectPeopleDialog
    }), initialState);
    const {group, updateLegalRules} = useGroupCache(props.groupId);
    const {users} = useUsersCache(group?.usersIds);

    const groupLegalRulesForm = useGroupLegalRulesForm(props.groupId);

    useEffect(function onUnmount() {
        return () => {
            const {
                minimalBreakHoursBetweenShifts,
                maximumDailyWorkingHours,
                maximumWeeklyWorkingHours,
                maximumConsecutiveWorkingDays,
                badge
            } = groupLegalRulesForm.state.data;
            const legalRules: Immutable<GroupLegalRules> = {
                minimalBreakHoursBetweenShifts: Number(minimalBreakHoursBetweenShifts),
                maximumDailyWorkingHours: Number(maximumDailyWorkingHours),
                maximumWeeklyWorkingHours: Number(maximumWeeklyWorkingHours),
                maximumConsecutiveWorkingDays: Number(maximumConsecutiveWorkingDays),
                customRules: group?.legalRules.customRules ?? [],
                badge: Boolean(badge),
            };
            updateLegalRules(legalRules).then();
        }
    }, []);

    const customRules = (
        <Flex fill column gap={"gap.smaller"} styles={{minHeight: "250px"}} className={"overflow-hidden"}>
            <Text content={translations.get("CustomLegalRules")}/>
            <Button
                className={"no-shrink"} fluid tinted icon={<AddIcon/>}
                content={translations.get("AddPersonRules")}
                onClick={dispatch("addPersonRules", users)}
            />
            <Flex column fill gap={"gap.smaller"} className={"overflow-scroll"}>
                <Flex column gap={"gap.smaller"}>
                    {group?.legalRules.customRules.map((c) => (
                        <PeopleButton
                            fluid
                            key={c.userId}
                            userId={c.userId}
                            onClick={personLegalRulesDialog.dispatch("open", {
                                groupId: props.groupId,
                                userId: c.userId
                            })}
                        />
                    ))}
                </Flex>
            </Flex>
        </Flex>
    )

    const content = (
        <Flex fill column gap={"gap.medium"} className={"overflow-scroll"}>
            <Flex>
                {groupLegalRulesForm.renderForm()}
            </Flex>
            {customRules}
        </Flex>
    );

    return (
        <Accordion
            card
            icon={ScalesIcon}
            expandable={false}
            title={translations.get("LegalRules")}
            content={content}
        />
    )
}, CompareModule.areObjectsEqual);