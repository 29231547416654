import {Button, Flex, Text} from "@fluentui/react-northstar";
import {SettingsIcon, ShopIcon} from "../../assets/icons";
import {translations} from "../../translations";
import React from "react";
import * as microsoftTeams from "@microsoft/teams-js";

export const NoShopPage = () => {
    return (
        <Flex fill column vAlign={"center"} hAlign={"center"} gap={"gap.smaller"}>
            <ShopIcon height={100} width={100}/>
            <Text size={"larger"} weight={"semibold"} content={translations.get("NoShopFound")}/>
            <Text size={"large"} content={translations.get("CreateYourFirstShopInSettingsTab")}/>
            <Button
                icon={<SettingsIcon/>}
                style={{marginTop: "10px"}}
                primary
                content={translations.get("Settings")}
                onClick={navigateToSettings}
            />
        </Flex>
    )
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const navigateToSettings = async () => {
    const pageId = process.env.REACT_APP_SETTINGS_PAGE_ENTITY_ID;
    if (!pageId) return;
    await microsoftTeams.pages.currentApp.navigateTo({pageId});
}