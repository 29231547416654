import React, {memo, ReactElement} from "react";
import {Props} from "./CustomShiftThresholdDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./CustomShiftThresholdDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import "./CustomShiftThresholdDialog.styles.scss";
import {translations} from "translations";
import {useCustomShiftThresholdForm} from "hooks/forms/useCustomShiftThresholdForm";
import {AddIcon, EditIcon, Flex} from "@fluentui/react-northstar";

export const CustomShiftThresholdDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    const form = useCustomShiftThresholdForm(state.groupId, state.categoryId, state.customThresholdId);

    const isNewItem = !state.customThresholdId;

    return (
        <Dialog
            showOverflow
            onClose={dispatch("reset", form)}
            externalRef={dialogRef}
            icon={isNewItem ? <AddIcon outline/> : <EditIcon outline/>}
            width={600}
            title={translations.get("CustomShiftThreshold")}
            content={form.renderForm()}
            footer={
                <Flex hAlign={"end"} {...(!isNewItem && {space: "between"})} gap={"gap.small"}>
                    {form.renderDeleteButton(dialogRef.dispatch("close"))}
                    {form.renderSaveButton(dialogRef.dispatch("close"))}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);