import React, {memo, ReactElement} from "react";
import {Props, State} from "./ManagerResponseItem.interfaces";
import {Immutable, MagicDispatch, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ManagerResponseItem.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ManagerResponseItem.styles.scss";
import {AcceptIcon, Button, CloseIcon, Divider, Flex, Text} from "@fluentui/react-northstar";
import {RequestPreviewItem} from "../RequestPreviewItem/RequestPreviewItem";
import {translations} from "../../../../translations";
import {ClockIcon} from "../../../../assets/icons";
import {useMsTeamsSelector} from "../../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {useForm} from "@witivio_teamspro/northstar-form";
import {useRequestsCache} from "../../../../hooks/cache/useRequestsCache";
import {RequestResponse, TimeOffRequestData} from "../../../../interfaces/RequestData";
import {useUserCache} from "../../../../hooks/cache/useUsersCache";
import {useUserRolesCache} from "../../../../hooks/cache/useUserRoleCache";
import {UserRole} from "../../../../interfaces/UserRole";

export const ManagerResponseItem = memo((props: Props): ReactElement | null => {
    const {requests} = useRequestsCache();
    const {userId} = useMsTeamsSelector("userId");
    const {answerRequest} = useRequestsCache();
    const {isOnlyRole} = useUserRolesCache();
    const [state, dispatch] = useMagicReducer(reducer({props, answerRequest, currentUserId: userId}), initialState);

    const request = requests?.find(r => r.id === props.requestId) as TimeOffRequestData | undefined;

    const {user: manager} = useUserCache(request?.response?.managerId);

    const readOnly = isOnlyRole(UserRole.Staff);

    const form = useForm({
        disabled: readOnly || state.isApproving || state.isDenying || (props.disabled ?? false),
        items: {
            notes: {
                type: "textArea",
                placeholder: translations.get("AddANoteOptional"),
                maxLength: 300,
                maxHeight: "120px",
            }
        }
    });

    if (!request) return null;

    const hasAnswered = !!request?.response;

    const notes = form.state.data.notes;

    const disabled = props.disabled || state.isApproving || state.isDenying;

    return (
        <RequestPreviewItem
            userId={manager?.id}
            title={manager?.displayName ?? translations.get("ManagerResponse")}
            date={request.response?.date}
            content={!hasAnswered ?
                renderAnswerForm(props, state, dispatch, form.renderForm(), notes, disabled, readOnly) :
                renderResponse(props, request.response!)
            }
        />
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderAnswerForm = (
    props: Props,
    state: State,
    dispatch: MagicDispatch<typeof reducer>,
    form: ReactElement,
    notes: string | undefined,
    disabled: boolean = false,
    readOnly: boolean,
) => {
    return (
        <Flex fill column gap={"gap.small"}>
            <Flex styles={{gap: "6px"}} vAlign={"center"}>
                <ClockIcon height={16} width={16}/>
                <Text content={props.pendingText || translations.get("PendingFinalApproval")}/>
            </Flex>
            {!readOnly && <>
                <Flex styles={{marginTop: !props.onlyApprove ? "-5px" : "0"}}>
                    {form}
                </Flex>
                <Flex gap={"gap.smaller"}>
                    {!props.onlyApprove &&
                        <Button
                            fluid content={translations.get("Deny")}
                            icon={<CloseIcon outline/>}
                            onClick={dispatch("deny", notes)}
                            disabled={disabled}
                            loading={state.isDenying}
                        />
                    }
                    <Button
                        primary={!state.isApproving} fluid
                        content={props.approveText || translations.get("Approve")}
                        icon={<AcceptIcon outline/>}
                        onClick={dispatch("approve", notes)}
                        disabled={disabled}
                        loading={state.isApproving}
                    />
                </Flex>
            </>
            }
        </Flex>
    )
}

const renderResponse = (props: Props, response: Immutable<RequestResponse>) => {
    const Icon = response.accepted ? AcceptIcon : CloseIcon;
    const title = response.accepted ?
        (props.successText || translations.get("RequestApproved")) : translations.get("RequestDenied");
    const color = response.accepted ? "#56B74E" : "#E00B1C";
    return (
        <Flex fill column styles={{gap: "5px"}}>
            <Flex column vAlign={"center"} hAlign={"center"}
                  style={{gap: "6px", color, padding: response.note ? "0" : "5px"}}>
                <Icon size={"larger"}/>
                <Text weight={"semibold"} content={title}/>
            </Flex>
            {response.note &&
                <>
                    <Divider/>
                    <Flex column>
                        <Text style={{color: "darkgray"}} content={translations.get("Notes")}/>
                        <Text content={response.note}/>
                    </Flex>
                </>
            }
        </Flex>
    )
}