import axios from "axios";
import {Configuration} from "interfaces/Configuration";
import {ErrorModule} from "components/others/ErrorBoundary/ErrorBoundary";

const getConfiguration = () => new Promise<Configuration>(async (resolve, reject) => {
    try {
        const response = await axios.get("/api/settings");
        return resolve(response.data);
    } catch (error) {
        console.error(error);
        ErrorModule.showErrorAlert("Can't get client configuration", error as Error);
        reject("Can't get client configuration");
    }
});

export const ConfigurationApi = {
    getConfiguration,
}