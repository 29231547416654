import React, {ReactElement} from "react";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Schedules.reducer";
import "./Schedules.styles.scss";
import {Flex} from "@fluentui/react-northstar";
import {Toolbar} from "./Toolbar/Toolbar";
import {Planning} from "./Planning/Planning";
import {PlanningView} from "./Planning/Planning.interfaces";
import {useShopsCache} from "../../hooks/cache/useShopsCache";
import {LoadingPage} from "../LoadingPage/LoadingPage";
import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {SchedulesMobile} from "./SchedulesMobile/SchedulesMobile";

export const Schedules = (): ReactElement => {
    const {isOnMobile} = useMsTeamsSelector("isOnMobile");
    const toolbarRef = useMagicReducerRef(Toolbar);
    const [, , render] = useMagicReducer(reducer, initialState);
    const {shops} = useShopsCache();

    if (!shops) return <LoadingPage/>;

    if (isOnMobile) return <SchedulesMobile/>;

    const shopId = toolbarRef.state?.selectedShopId;
    const filters = toolbarRef.state?.filterData;
    const planningView = toolbarRef.state?.view ?? PlanningView.People;

    return (
        <Flex fill column className={"fade"}>
            <Toolbar
                externalRef={toolbarRef}
                onSwitchShop={render}
                onFiltersChange={render}
                onViewChange={render}
            />
            <Planning
                shopId={shopId}
                filters={filters}
                view={planningView}
            />
        </Flex>
    );
}