import {WeekDay} from "interfaces/WeekDay";
import React, {useCallback, useLayoutEffect, useMemo, useState} from "react";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {ArrowRightIcon, Dropdown, DropdownProps, Flex, Input, InputProps, Text} from "@fluentui/react-northstar";
import {translations} from "translations";
import {useShopCache} from "../cache/useShopsCache";
import {Time} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/TimePicker/TimePicker";
import {ShopOpeningHours} from "interfaces/ShopData";
import {TimeModule} from "modules/Time.module";
import {WeekDaysModule} from "../../modules/WeekDays.module";

type GroupedDaysOpeningHours = {
    days: Array<WeekDay>,
    hours: {
        start: Time,
        end: Time,
    },
}

export const useShopOpeningHoursForm = (shopId: string | undefined, isSaving: boolean) => {
    const {shop} = useShopCache(shopId);
    const [openingHours, setOpeningHours] = useState<Immutable<GroupedDaysOpeningHours[]>>(getInitialOpeningHours());

    useLayoutEffect(function onShopChange() {
        reset();
    }, [shopId]);

    const reset = useCallback(() => {
        const groupedDaysOpeningHours = !shop ? getInitialOpeningHours() : groupOpeningHoursByDays(shop.openingHours);
        setOpeningHours(groupedDaysOpeningHours);
    }, [shop]);

    const renderDayForm = useCallback((item: Immutable<GroupedDaysOpeningHours>, index: number) => {
        return (
            <Flex key={index} gap={"gap.small"} vAlign={"center"}>
                <Flex fill>
                    <Dropdown
                        multiple
                        className={"w-100"}
                        fluid
                        items={getDropdownDaysItems(item.days)}
                        value={getDropdownDaysValue(item.days)}
                        onChange={handleChangeThresholdDays(openingHours, setOpeningHours, index)}
                        disabled={isSaving}
                    />
                </Flex>
                <Flex fill gap={"gap.smaller"} vAlign={"end"} style={{width: "300px"}}>
                    <Input
                        fluid
                        type={"time"}
                        inputMode={"numeric"}
                        placeholder={"00:00"}
                        value={formatTimeValue(item.hours.start)}
                        min={0}
                        max={300}
                        onChange={handleChangeTime(openingHours, setOpeningHours, index, true)}
                        autoComplete={"off"}
                        disabled={isSaving}
                    />
                    <ArrowRightIcon styles={{color: "darkgray", marginBottom: "8px"}}/>
                    <Input
                        fluid
                        type={"time"}
                        inputMode={"numeric"}
                        placeholder={"00:00"}
                        value={formatTimeValue(item.hours.end)}
                        min={0}
                        max={300}
                        onChange={handleChangeTime(openingHours, setOpeningHours, index, false)}
                        autoComplete={"off"}
                        disabled={isSaving}
                    />
                </Flex>
            </Flex>
        )
    }, [openingHours, isSaving]);

    const renderForm = useCallback(() => (
        <Flex fill column styles={{gap: "3px"}}>
            <Text>
                {translations.get("OpeningHours")}
                <span style={{color: "red"}}> *</span>
            </Text>
            <Flex fill column gap={"gap.small"}>
                {openingHours.map(renderDayForm)}
            </Flex>
        </Flex>
    ), [openingHours, renderDayForm])

    const items = useMemo(() => flattenOpeningHours(openingHours), [openingHours]);

    const hasChanged = useMemo(() => doesOpeningHoursChanged(shop?.openingHours, items), [shop?.openingHours, items]);

    return {
        items,
        renderForm,
        hasChanged,
        reset,
    };
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getInitialHours = (): GroupedDaysOpeningHours["hours"] => ({
    start: {hour: 8, minutes: 0},
    end: {hour: 20, minutes: 0},
});

const getInitialOpeningHours = (): GroupedDaysOpeningHours[] => [{
    days: Object.values(WeekDay).filter(d => typeof d === "number") as WeekDay[],
    hours: getInitialHours(),
}];

const handleChangeThresholdDays = (
    thresholds: Immutable<GroupedDaysOpeningHours[]>,
    setThresholds: (thresholds: Immutable<GroupedDaysOpeningHours[]>) => void,
    thresholdIndex: number
) => (_: React.SyntheticEvent | null, data: DropdownProps) => {
    const previousThresholdDays = thresholds[thresholdIndex]?.days ?? [];
    const thresholdDays = (data.value as Array<{ value: WeekDay }>)?.flatMap(i => i.value);
    let concernedDay = getThresholdDaysUpdateConcernedDay(previousThresholdDays, thresholdDays);
    let newThresholds = new Array<Immutable<GroupedDaysOpeningHours>>(...thresholds);
    if (thresholdDays.length > 0) newThresholds[thresholdIndex] = {
        ...newThresholds[thresholdIndex]!,
        days: thresholdDays.sort((a, b) => a - b),
    };
    newThresholds = removeDayFromThresholds(newThresholds, concernedDay, thresholdIndex);
    const undefinedDays = getThresholdsUndefinedDays(newThresholds);
    if (undefinedDays.length > 0) newThresholds.push({days: undefinedDays, hours: getInitialHours()});
    setThresholds(newThresholds);
}

const handleChangeTime = (
    openingHours: Immutable<GroupedDaysOpeningHours[]>,
    setOpeningHours: (openingHours: Immutable<GroupedDaysOpeningHours[]>) => void,
    openingHoursIndex: number,
    isStart: boolean
) => (_: React.SyntheticEvent | null, data: InputProps & { value: string } | undefined) => {
    let value = !data?.value ? "00:00" : data?.value + "";
    const [hour, minutes] = value.split(":");
    const time: Time = {
        hour: Number(hour),
        minutes: Number(minutes),
    }
    const start: Time = isStart ? time : (openingHours[openingHoursIndex]?.hours.start ?? getInitialHours().start);
    const end: Time = !isStart ? time : (openingHours[openingHoursIndex]?.hours.end ?? getInitialHours().end);
    TimeModule.correctTimeRange({start, end, isStartUpdated: isStart});
    const prevValue = openingHours[openingHoursIndex]?.hours;
    if (isStart && prevValue?.start.hour === time.hour && prevValue?.start.minutes === time.minutes) return;
    else if (!isStart && prevValue?.end.hour === time.hour && prevValue?.end.minutes === time.minutes) return;
    const newOpeningHours: Immutable<GroupedDaysOpeningHours[]> = openingHours
        .map((t, i) => i === openingHoursIndex ? {
            ...t,
            hours: {start, end}
        } : t);
    setOpeningHours(newOpeningHours);
}

const getThresholdDaysUpdateConcernedDay = (prevDays: Immutable<Array<WeekDay>>, nextDays: Immutable<Array<WeekDay>>) => {
    let concernedDay: WeekDay;
    const isDayAdded = prevDays.length < nextDays.length;
    if (isDayAdded) concernedDay = nextDays.find(d => !prevDays.includes(d))!;
    else concernedDay = prevDays.find(d => !nextDays.includes(d))!;
    return concernedDay;
}

const removeDayFromThresholds = (thresholds: Immutable<GroupedDaysOpeningHours[]>, day: WeekDay, skipIndex: number) => {
    let newThresholds = thresholds.map((threshold, i) => {
        if (i === skipIndex) return threshold;
        if (threshold.days.includes(day)) {
            const days = threshold.days.filter(d => d !== day);
            if (days.length === threshold.days.length) return threshold;
            days.sort((a, b) => a - b);
            return {...threshold, days};
        }
        return threshold;
    });
    newThresholds = newThresholds.filter(t => t.days.length > 0);
    return newThresholds;
}

const getThresholdsUndefinedDays = (thresholds: Immutable<GroupedDaysOpeningHours[]>) => {
    const selectedDays = new Array<WeekDay>();
    thresholds.forEach((threshold) => selectedDays.push(...threshold.days));
    return Object.values(WeekDay).filter(d => typeof d === "number" && !selectedDays.includes(d)) as WeekDay[];
}

const getDropdownDaysItems = (selectedDays: Immutable<Array<WeekDay>>) => {
    return Object.values(WeekDay)
        .filter(d => typeof d === "number")
        .filter(d => !selectedDays.includes(d as WeekDay))
        .map(d => ({key: d + "", header: translations.get(WeekDay[d as WeekDay] + ""), value: d}));
}

const getDropdownDaysValue = (selectedDays: Immutable<Array<WeekDay>>) => {
    return selectedDays.map(i => ({
        key: i + "",
        header: translations.get(WeekDay[i] + "").slice(0, 3),
        value: i
    }));
}

const flattenOpeningHours = (openingHoursByDays: Immutable<GroupedDaysOpeningHours[]>) => {
    const defaultHours = getInitialHours();
    const weekDays = WeekDaysModule.getWeekDaysOrderPerCulture();
    const openingHours: ShopOpeningHours = weekDays.map(d => ({
        day: d,
        ...defaultHours,
    }));
    openingHoursByDays.forEach(g => g.days.forEach(d => {
        const day = openingHours.find(dd => dd.day === d);
        if (day) {
            day.start = g.hours.start;
            day.end = g.hours.end;
        }
    }));
    return openingHours;
}

const groupOpeningHoursByDays = (openingHours: Immutable<ShopOpeningHours>) => {
    const groupedDaysOpeningHours = new Array<GroupedDaysOpeningHours>();
    openingHours.forEach((item) => {
        const existingGroup = groupedDaysOpeningHours.find(g =>
            g.hours.start.hour === item.start.hour &&
            g.hours.start.minutes === item.start.minutes &&
            g.hours.end.hour === item.end.hour &&
            g.hours.end.minutes === item.end.minutes
        );
        if (existingGroup) existingGroup.days.push(item.day);
        else groupedDaysOpeningHours.push({days: [item.day], hours: {start: item.start, end: item.end}});
    });
    return groupedDaysOpeningHours;
}

const formatTimeValue = (time: Immutable<Time> | undefined) => {
    const hour = !time ? "00" : `${time.hour}`.padStart(2, "0");
    const minutes = !time ? "00" : `${time.minutes}`.padStart(2, "0");
    return `${hour}:${minutes}`;
}

const doesOpeningHoursChanged = (prev: Immutable<ShopOpeningHours> | undefined, next: ShopOpeningHours) => {
    if (!prev) return false;
    return prev.some(prevItem => {
        const newItem = next.find(n => n.day === prevItem.day);
        return prevItem.start.hour !== newItem?.start.hour
            || prevItem.start.minutes !== newItem?.start.minutes
            || prevItem.end.hour !== newItem?.end.hour
            || prevItem.end.minutes !== newItem?.end.minutes;
    });
}