import {State} from "./OrderGroupsDialog.interfaces";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Container} from "./Container/Container";
import {useShopGroupsCache} from "../../../hooks/cache/groups/useShopGroupsCache";
import {ConfirmCancelChangesDialog} from "../ConfirmCancelChangesDialog/ConfirmCancelChangesDialog";

export const initialState: State = {
    shopId: undefined,
    groupId: undefined,
    canSave: false,
    isSaving: false,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
    containerRef: MagicReducerRef<typeof Container>,
    confirmCancelChangesDialog: MagicReducerRef<typeof ConfirmCancelChangesDialog>,
}) => ({
    open: ({setState}, _, {shopId, groupId}: { shopId: string | undefined, groupId: string | undefined }) => {
        setState({shopId, groupId, canSave: false, isSaving: false});
        config.dialogRef.dispatch("open")();
    },
    orderChange: ({state, setState}, [newOrder]: [Immutable<Array<string>>], initialOrder: Array<string>) => {
        const canSave = !isSameOrder(newOrder, initialOrder);
        if (state.canSave === canSave) return;
        setState({canSave});
    },
    save: async ({setState}, _, updateIndexes: ReturnType<typeof useShopGroupsCache>["updateIndexes"]) => {
        const {dialogRef, containerRef} = config;
        const order = containerRef.state?.groupsOrder;
        if (!order) return;
        setState({isSaving: true});
        await updateIndexes(order);
        dialogRef.dispatch("close")();
    },
    beforeClose: ({}, [callback]: [(close: boolean) => void], hasChanged: boolean) => {
        if (!hasChanged) return callback(true);
        config.confirmCancelChangesDialog.dispatch("open", callback)();
    }
}) satisfies MagicReducerObject<State>;

const isSameOrder = (oldOrder: Immutable<Array<string>>, newOrder: Immutable<Array<string>>) => {
    for (let i = 0; i < oldOrder.length; i++)
        if (oldOrder[i] !== newOrder[i]) return false;
    return true;
}