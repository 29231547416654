import {Props, State} from "./Toolbar.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {DateRange} from "components/others/DateRangeSelector/DateRangeSelector.interfaces";
import {PlanningDateRangeCache, usePlanningDateRangeCache} from "../../../hooks/cache/usePlanningDateRangeCache";
import {ReportApi} from "../../../apis/Report/ReportApi";
import moment from "moment/moment";
import {FileModule} from "../../../modules/File.module";

export const initialState: State = {
    isExporting: false,
}

export const reducer = (config: {
    props: Props,
    setPlanningDateRange: ReturnType<typeof usePlanningDateRangeCache>["setPlanningDateRange"],
    planningDateRange: PlanningDateRangeCache,
}) => ({
    dateRangeChange: ({}, [selectedDate, selectedRange]: [string, Immutable<DateRange>]) => {
        config.setPlanningDateRange({selectedRange, selectedDate});
    },
    export: async ({setState}) => {
        setState({isExporting: true});
        const {selectedRange} = config.planningDateRange;
        const startDate = selectedRange.startDate;
        const endDate = selectedRange.endDate;
        const file = await ReportApi.exportReport(startDate, endDate, undefined);
        const fileName = generateFileName(startDate, endDate);
        FileModule.downloadFile(file, fileName);
        setState({isExporting: false});
    }
}) satisfies MagicReducerObject<State>;

const generateFileName = (startDate: string, endDate: string) => {
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
    if (startDate === endDate) return `reports_${formattedStartDate}.xlsx`;
    return `reports_${formattedStartDate}_${formattedEndDate}.xlsx`;
}