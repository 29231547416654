import {Config, State} from "./SelectPeopleDialog.interfaces";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";

export const initialState: State = {
    multiple: false,
    callback: Promise.resolve,
    isLoading: false,
    fetchInitialUsers: undefined,
    fetchUsers: undefined,
    title: undefined,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, options: Config) => {
        setState({...initialState, ...options});
        config.dialogRef.dispatch("open")();
    },
    confirm: async ({state, setState}, _, usersIds: Immutable<Array<string>>) => {
        if (usersIds.length === 0) return;
        const result = state.callback?.(usersIds);
        if (result instanceof Promise) {
            setState({isLoading: true});
            await result;
        }
        config.dialogRef.dispatch("close")();
    }
}) satisfies MagicReducerObject<State>;