import {Immutable} from "@witivio_teamspro/use-reducer";
import moment from "moment/moment";
import {TimeRange} from "../interfaces/ShopData";
import {Time} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/TimePicker/TimePicker";

const correctTimeRange = (config: {
    start: Time | undefined,
    end: Time | undefined,
    isStartUpdated: boolean,
    timeRange?: TimeRange | undefined,
}) => {
    const {start, end, isStartUpdated} = config;
    if (!start || !end) return;
    if (isStartUpdated) {
        roundTimeToQuarter(start);
        if (start.hour >= end.hour) end.hour = start.hour + 1;
        if (config.timeRange) {
            if (start.hour < config.timeRange.start.hour) {
                start.hour = config.timeRange.start.hour;
                start.minutes = config.timeRange.start.minutes;
            } else if (start.hour === config.timeRange.start.hour && start.minutes < config.timeRange.start.minutes) {
                start.minutes = config.timeRange.start.minutes;
            } else if (start.hour >= config.timeRange.end.hour) {
                start.hour = config.timeRange.end.hour - 1;
            }
        }
    } else {
        roundTimeToQuarter(end);
        if (end.hour <= start.hour && end.hour > 2) start.hour = end.hour - 1;
        if (config.timeRange) {
            if (end.hour > config.timeRange.end.hour) {
                end.hour = config.timeRange.end.hour;
                end.minutes = config.timeRange.end.minutes;
            } else if (end.hour === config.timeRange.end.hour && end.minutes > config.timeRange.end.minutes) {
                end.minutes = config.timeRange.end.minutes;
            } else if (end.hour <= config.timeRange.start.hour) {
                end.hour = config.timeRange.start.hour + 1;
            }
        }
    }
}

const roundTimeToQuarter = (time: Time) => {
    const isQuarter = time.minutes === 0 || time.minutes % 15 === 0;
    if (!isQuarter) {
        time.minutes = Math.ceil(time.minutes / 15) * 15;
        if (time.minutes > 59) time.minutes = 0;
    }
}

const getTimeLabel = (time: Immutable<Time> | undefined) => {
    if (!time) return "";
    const label = moment().startOf("day").add(time.hour, "hour").add(time.minutes, "minutes").format("LT");
    const isAmPmLabel = label.includes("AM") || label.includes("PM");
    if (isAmPmLabel) return label.replace(":00", "");
    return label;
}

const getTimeRangeLabel = (start: Immutable<Time> | undefined, end: Immutable<Time> | undefined) => {
    if (!start || !end) return "";
    const startTimeLabel = getTimeLabel(start);
    const endTimeLabel = getTimeLabel(end);
    return `${startTimeLabel} - ${endTimeLabel}`;
}

const isInferior = (time1: Time | undefined, time2: Time | undefined) => {
    if (!time1 || !time2) return false;
    return time1.hour < time2.hour || (time1.hour === time2.hour && time1.minutes < time2.minutes);
}

const isEqual = (time1: Time | undefined, time2: Time | undefined) => {
    if (!time1 && !time2) return true;
    if (!time1 || !time2) return false;
    return time1.hour === time2.hour && time1.minutes === time2.minutes;
}

const getTimeFromSeconds = (seconds: number) => {
    const hours = (Math.floor(seconds / 3600) + "").padStart(2, "0");
    const minutes = (Math.floor((seconds % 3600) / 60) + "").padStart(2, "0");
    const newSeconds = (seconds % 60 + "").padStart(2, "0");
    return {hour: hours, minutes, seconds: newSeconds};
}

const getLabelFromDecimalHours = (decimalHours: number | undefined) => {
    if (decimalHours === undefined) return "0h";
    let hours = (Math.floor(decimalHours) + "").padStart(1, "0");
    if (hours == "0") hours = "";
    let minutes = (Math.floor((decimalHours % 1) * 60) + "").padStart(2, "0");
    if (minutes == "00") minutes = "";
    if (!hours && !minutes) return "0h";
    return `${hours ? hours + "h" : ""}${minutes ? minutes : ""}`;
}

export const TimeModule = {
    correctTimeRange,
    getTimeLabel,
    getTimeRangeLabel,
    isInferior,
    isEqual,
    getTimeFromSeconds,
    getLabelFromDecimalHours,
}