import {Flex, ShiftActivityIcon, Skeleton, Text} from "@fluentui/react-northstar";
import React, {memo} from "react";
import {Props} from "./TimeReport.interfaces";
import {CompareModule} from "../../../modules/Compare.module";
import {TimerIcon} from "../../../assets/icons";
import {TimeModule} from "../../../modules/Time.module";
import {translations} from "../../../translations";

export const TimeReport = memo((props: Props) => {
    if (props.showSkeleton) return (
        <Flex fill hAlign={"end"} vAlign={"center"}>
            <Skeleton animation={"pulse"} styles={{maxWidth: "150px"}}>
                <Skeleton.Shape height={"14px"} width={"100%"}/>
            </Skeleton>
        </Flex>
    )
    return (
        <Flex styles={{gap: "10px"}} className={"overflow-hidden"}>
            <Flex styles={{gap: "3px"}} className={"overflow-hidden"}>
                {props.hours !== undefined &&
                    <Text truncated styles={{color: "darkgray"}} title={translations.get("WorkingHoursSingularPlural")}
                          content={`${TimeModule.getLabelFromDecimalHours(props.hours)}`}/>
                }
                {(props.overtimeHours ?? 0) > 0 &&
                    <Text truncated styles={{color: "#73c1fa"}} title={translations.get("OvertimeHoursSingularPlural")}
                          content={`+${TimeModule.getLabelFromDecimalHours(props.overtimeHours ?? 0)}`}/>
                }
            </Flex>
            {(props.absences ?? 0) > 0 &&
                <Flex vAlign={"center"} styles={{gap: "3px"}} title={translations.get("AbsencesSingularPlural")}
                      className={"overflow-hidden"}>
                    <Text truncated styles={{color: "orange"}} content={props.absences}/>
                    <ShiftActivityIcon styles={{color: "orange"}} size={"small"}/>
                </Flex>
            }
            {(props.unClocked ?? 0) > 0 &&
                <Flex vAlign={"center"} styles={{gap: "1px"}} title={translations.get("UnclockedShifts")}
                      className={"overflow-hidden"}>
                    <Text truncated styles={{color: "red"}} content={props.unClocked}/>
                    <TimerIcon className={"no-shrink"} color={"red"} width={16} height={16}/>
                </Flex>
            }
        </Flex>
    )
}, CompareModule.areObjectsEqual);