import {State} from "./Reports.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import React from "react";
import {DialogContextValue} from "../../services/DialogContext/DialogContext.interfaces";

export const initialState: State = {}

export const reducer = (config: {
    shopReportDialog: DialogContextValue["shopReportDialog"],
}) => ({
    openShopReport: ({}, [e]: [React.SyntheticEvent], shopId: string) => {
        e.stopPropagation();
        config.shopReportDialog.dispatch("open", shopId)();
    }
}) satisfies MagicReducerObject<State>;