import React, {CSSProperties, memo, ReactElement, useEffect} from "react";
import {Props} from "./UserWeekSummaryDialog.types";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./UserWeekSummaryDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {AcceptIcon, Button, Divider, ExclamationTriangleIcon, Flex, Text, Tooltip} from "@fluentui/react-northstar";
import "./UserWeekSummaryDialog.styles.scss";
import {UserThumbnail} from "../../others/UserThumbnail/UserThumbnail";
import {useUserCache} from "../../../hooks/cache/useUsersCache";
import {translations} from "../../../translations";
import {TimeModule} from "../../../modules/Time.module";
import moment from "moment";
import {useForm} from "@witivio_teamspro/northstar-form";
import {useRequestsCache} from "../../../hooks/cache/useRequestsCache";
import {WeekSummaryRequestData} from "../../../interfaces/RequestData";

export const UserWeekSummaryDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const {requests, applyRetrieveHours} = useRequestsCache();
    const [state, dispatch] = useMagicReducer(reducer({
        dialogRef,
        applyRetrieveHours
    }), initialState, props.externalRef);
    const {user} = useUserCache(state.userId);

    const request = requests?.find(r => r.id === state.requestId) as WeekSummaryRequestData | undefined;
    const userData = request?.usersData?.find(u => u.userId === state.userId);

    useEffect(() => {
        if (!userData) return;
        form.setFieldsInitialValues({retrieveHours: userData?.usedRetrieveHours ?? 0});
        form.reset();
    }, [userData]);

    const totalWorkedHours = (userData?.totalWorkedHours ?? 0);
    const totalLegalHours = userData?.totalLegalHours ?? 0;
    const retrieveHours = userData?.availableRetrieveHours ?? 0;
    const maximumUsableRetrieveHours = getMaximumUsableRetrieveHours(totalWorkedHours, totalLegalHours, retrieveHours);
    const hasEnoughRetrieveHours = retrieveHours >= totalLegalHours - totalWorkedHours;

    const form = useForm({
        items: {
            retrieveHours: {
                type: "input",
                inputMode: "numeric",
                min: 0,
                max: maximumUsableRetrieveHours,
                label: translations.get("UsedRetrievedHours"),
                placeholder: translations.get("EnterHours"),
                initialValue: 0,
                disabled: state.isSaving
            }
        }
    });

    const usedWorkingHours = Number(form.state.data.retrieveHours ?? 0)

    const totalWorkingHoursWithRetrieveHours = totalWorkedHours + usedWorkingHours;
    const availableRetrieveHours = retrieveHours - usedWorkingHours;

    const isInvalidWorkingHours = totalWorkedHours < totalLegalHours;
    const isInvalidWorkingHoursAfterFix = totalWorkingHoursWithRetrieveHours < totalLegalHours;
    const showFixForm = isInvalidWorkingHours && maximumUsableRetrieveHours > 0;

    const textStyles: CSSProperties = {
        color: isInvalidWorkingHoursAfterFix ? "red" : "black"
    }

    const disableApplyButton = usedWorkingHours === 0 || !form.state.hasChanged || !form.isValid || state.isSaving;

    return (
        <Dialog
            externalRef={dialogRef}
            width={500}
            title={user?.displayName ?? ""}
            onClose={form.reset}
            icon={<UserThumbnail userId={userData?.userId} size={"smallest"}/>}
            content={
                <Flex fill column styles={{gap: "5px"}}>
                    <Flex styles={{gap: "5px"}} vAlign={"center"}>
                        <Text content={translations.get("Week") + " :"} weight={"semibold"}/>
                        <Text content={moment(request?.weekDate).format("L")}/>
                    </Flex>
                    <Flex styles={{gap: "5px"}} vAlign={"center"}>
                        <Text content={translations.get("WorkingHoursSingularPlural") + " :"} weight={"semibold"}/>
                        <Text style={textStyles}
                              content={TimeModule.getLabelFromDecimalHours(totalWorkingHoursWithRetrieveHours)}/>
                        <Text style={textStyles} content={"/"}/>
                        <Text style={textStyles}
                              content={TimeModule.getLabelFromDecimalHours(totalLegalHours)}/>
                        {isInvalidWorkingHoursAfterFix &&
                            <Tooltip
                                content={{
                                    content: translations.get("WorkingHoursInferiorToLegalHours"),
                                    className: "staff-shift-warning-tooltip"
                                }}
                                trigger={
                                    <ExclamationTriangleIcon size={"small"} styles={{color: "red", marginTop: "2px"}}/>
                                }
                                position={"after"}
                                offset={[0, 8]}
                            />
                        }
                    </Flex>
                    <Flex styles={{gap: "5px"}} vAlign={"center"}>
                        <Text content={translations.get("AvailableHoursToRetrieve") + " :"} weight={"semibold"}/>
                        <Text content={TimeModule.getLabelFromDecimalHours(availableRetrieveHours)}/>
                    </Flex>
                    {isInvalidWorkingHours && (
                        <>
                            <Divider/>
                            {showFixForm && form.formItems.retrieveHours}
                            {!hasEnoughRetrieveHours &&
                                <Text content={translations.get("NotEnoughRetrieveHoursMessage")}
                                      styles={{color: "orange", marginTop: "5px"}}/>
                            }
                        </>
                    )}
                </Flex>
            }
            footer={showFixForm ?
                <Flex hAlign={"end"}>
                    <Button
                        primary icon={<AcceptIcon outline/>}
                        content={translations.get("Confirm")}
                        disabled={disableApplyButton}
                        onClick={dispatch("confirm", usedWorkingHours)}
                        loading={state.isSaving}
                    />
                </Flex>
                :
                null
            }
        />
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getMaximumUsableRetrieveHours = (
    totalWorkedHours: number,
    totalLegalHours: number,
    retrieveHours: number
) => {
    if (totalWorkedHours >= totalLegalHours) return 0;
    const maximumUsableRetrieveHours = Math.max(0, totalLegalHours - totalWorkedHours);
    return Math.min(maximumUsableRetrieveHours, retrieveHours);
}