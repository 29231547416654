import {
    DatePickerData,
    TimePickerData
} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/Form.interfaces";
import {translations} from "../translations";
import {TimeModule} from "./Time.module";
import {Time} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/TimePicker/TimePicker";
import {TimeRange} from "../interfaces/ShopData";
import moment from "moment";

const generateTimeRangeItems = (options: {
    required: boolean,
    showLabel: boolean,
    timeRange?: TimeRange | undefined,
}): {
    startTime: TimePickerData,
    endTime: TimePickerData
} => {
    return {
        startTime: {
            type: "timePicker",
            required: options.required,
            ...(options.showLabel ? {label: translations.get("StartTime")} : {}),
            listeners: [
                {
                    id: "startTime",
                    onChange: (value) => {
                        const currentValue: Time = {
                            hour: value.currentValue?.hour ?? 0,
                            minutes: value.currentValue?.minutes ?? 0
                        };
                        TimeModule.correctTimeRange({
                            start: currentValue,
                            end: {hour: 23, minutes: 59},
                            isStartUpdated: true,
                            timeRange: options.timeRange,
                        });
                        return currentValue;
                    }
                },
                {
                    id: "endTime",
                    onChange: (value) => {
                        const currentValue: Time = {
                            hour: value.currentValue?.hour ?? 0,
                            minutes: value.currentValue?.minutes ?? 0
                        };
                        const timeRange = {
                            start: currentValue,
                            end: {...value.listenedItemValue}
                        };
                        TimeModule.correctTimeRange({
                            start: timeRange.start,
                            end: timeRange.end,
                            isStartUpdated: false,
                            timeRange: options.timeRange,
                        });
                        return timeRange.start;
                    }
                }
            ],
            clearable: false,
            initialValue: {hour: 0, minutes: 0},
        },
        endTime: {
            type: "timePicker",
            required: options.required,
            ...(options.showLabel ? {label: translations.get("EndTime")} : {}),
            listeners: [
                {
                    id: "endTime",
                    onChange: (value) => {
                        const currentValue: Time = {
                            hour: value.currentValue?.hour ?? 0,
                            minutes: value.currentValue?.minutes ?? 0
                        };
                        TimeModule.correctTimeRange({
                            start: {hour: 0, minutes: 0},
                            end: currentValue,
                            isStartUpdated: false,
                            timeRange: options.timeRange,
                        });
                        return currentValue;
                    }
                },
                {
                    id: "startTime",
                    onChange: (value) => {
                        const currentValue: Time = {
                            hour: value.currentValue?.hour ?? 0,
                            minutes: value.currentValue?.minutes ?? 0
                        };
                        const timeRange = {
                            start: {...value.listenedItemValue},
                            end: currentValue,
                        };
                        TimeModule.correctTimeRange({
                            start: timeRange.start,
                            end: timeRange.end,
                            isStartUpdated: true,
                            timeRange: options.timeRange,
                        });
                        return timeRange.end;
                    }
                }
            ],
            clearable: false,
            initialValue: {hour: 0, minutes: 0},
        },
    }
}

const generateDateRangeItems = (options: {
    required: boolean,
    showLabel: boolean,
    minDate?: Date | undefined,
    maxDate?: Date | undefined,
}): {
    startDate: DatePickerData,
    endDate: DatePickerData
} => {
    return {
        startDate: {
            type: "datePicker",
            required: options.required,
            ...(options.showLabel ? {label: translations.get("StartDate")} : {}),
            listeners: [
                {
                    id: "endDate",
                    onChange: (value) => {
                        const startDate = moment(value.currentValue);
                        const endDate = moment(value.listenedItemValue);
                        if (startDate.isAfter(endDate)) return endDate.toISOString(false);
                        return startDate.toISOString(false);
                    }
                }
            ],
            clearable: false,
            placeholder: translations.get("SelectADate"),
            initialValue: moment().startOf("day").toISOString(false),
            ...(options.minDate && {minDate: options.minDate}),
            ...(options.maxDate && {maxDate: options.maxDate}),
        },
        endDate: {
            type: "datePicker",
            required: options.required,
            ...(options.showLabel ? {label: translations.get("EndDate")} : {}),
            listeners: [
                {
                    id: "startDate",
                    onChange: (value) => {
                        const startDate = moment(value.listenedItemValue);
                        const endDate = moment(value.currentValue);
                        if (endDate.isBefore(startDate)) return startDate.toISOString(false);
                        return endDate.toISOString(false);
                    }
                }
            ],
            clearable: false,
            placeholder: translations.get("SelectADate"),
            initialValue: moment().startOf("day").toISOString(false),
            ...(options.minDate && {minDate: options.minDate}),
            ...(options.maxDate && {maxDate: options.maxDate}),
        },
    }
}

export const FormModule = {
    generateTimeRangeItems,
    generateDateRangeItems
}