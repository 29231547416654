import React, {memo, ReactElement} from "react";
import {Props} from "./ShiftDetailsSideView.interfaces";
import {Immutable, MagicReducerRef, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShiftDetailsSideView.reducer";
import {CompareModule} from "modules/Compare.module";
import {ArrowSyncIcon, Button, Flex, FlexItem, Skeleton, Text} from "@fluentui/react-northstar";
import "./ShiftDetailsSideView.styles.scss";
import {SideView} from "../../../../components/dialogs/SideView/SideView";
import {translations} from "../../../../translations";
import {ShiftCard} from "../../../../components/others/ShiftCard/ShiftCard";
import {useDaysNotesRangeCache} from "../../../../hooks/cache/useDaysNotesCache";
import {Shift} from "../../../../classes/Shift";
import {ActivityItem} from "../../../../components/dialogs/ShiftDialog/ActivitiesList/ActivityItem/ActivityItem";
import {UserThumbnail} from "../../../../components/others/UserThumbnail/UserThumbnail";
import {useUserCache} from "../../../../hooks/cache/useUsersCache";
import {GraphUserData} from "../../../../services/GraphService/GraphService.interfaces";
import {SwapShiftSideView} from "./SwapShiftSideView/SwapShiftSideView";
import {ShiftType} from "../../../../interfaces/ShiftData";

export const ShiftDetailsSideView = memo((props: Props): ReactElement | null => {
    const sideViewRef = useMagicReducerRef(SideView);
    const swapShiftSideViewRef = useMagicReducerRef(SwapShiftSideView);
    const [state, dispatch] = useMagicReducer(reducer({sideViewRef}), initialState, props.externalRef);
    const {
        daysNotes,
        isLoading: areDaysNotesLoading,
    } = useDaysNotesRangeCache(state.shift?.getShopId(), state.shift?.getDate(), state.shift?.getDate());
    const {user} = useUserCache(state.shift?.getUserId());

    const dayNotes = daysNotes?.find(d => d.date === state.shift?.getDate())?.notes;

    const swapButton = renderSwapButton({shift: state.shift, swapShiftSideViewRef});

    return (
        <SideView externalRef={sideViewRef} title={translations.get("ShiftDetails")} style={{zIndex: 20}}>
            {!state.shift ? null : <>
                <Flex fill column gap={"gap.medium"} className={"overflow-scroll"} style={{padding: "15px"}}>
                    <Flex>
                        <ShiftCard shift={state.shift} showDate={{showMonth: false}}/>
                    </Flex>
                    {renderDayNotes({areDaysNotesLoading, dayNotes})}
                    {renderActivities({shift: state.shift})}
                    {renderOwner({shift: state.shift, user})}
                    {swapButton &&
                        <FlexItem push>
                            {swapButton}
                        </FlexItem>
                    }
                </Flex>
                <SwapShiftSideView externalRef={swapShiftSideViewRef}/>
            </>}
        </SideView>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderDayNotes = (config: {
    areDaysNotesLoading: boolean,
    dayNotes: string | undefined,
}) => {
    const {areDaysNotesLoading, dayNotes} = config;
    return (
        <Flex column style={{gap: "5px"}}>
            <Text size={"small"} style={{color: "darkgray"}} content={translations.get("TodayNotes")}/>
            {areDaysNotesLoading ?
                <Skeleton animation={"pulse"}>
                    <Skeleton.Shape width={"200px"} height={"20px"}/>
                </Skeleton>
                :
                <Text
                    style={!dayNotes ? {color: "darkgray"} : {}}
                    content={dayNotes || translations.get("NoNotes")}
                />
            }
        </Flex>
    )
}

const renderActivities = (config: {
    shift: Immutable<Shift> | undefined,
}) => {
    const activities = config.shift?.getActivities();
    const shopId = config.shift?.getShopId();
    const showList = !!shopId && !!activities && activities.length > 0;
    return (
        <Flex column>
            <Text size={"small"} style={{color: "darkgray"}} content={translations.get("Activities")}/>
            {showList ?
                <Flex column>
                    {activities?.map((activity, index) => (
                        <ActivityItem
                            key={activity.key}
                            readOnly
                            shopId={shopId}
                            activity={activity}
                            showDivider={index < activities.length - 1}
                        />
                    ))}
                </Flex>
                :
                <Text style={{color: "darkgray"}} content={translations.get("NoActivities")}/>
            }
        </Flex>
    )
}

const renderOwner = (config: {
    shift: Immutable<Shift> | undefined,
    user: Immutable<GraphUserData> | undefined,
}) => {
    const userId = config.shift?.getUserId();
    return (
        <Flex column style={{gap: "8px"}}>
            <Text size={"small"} style={{color: "darkgray"}} content={translations.get("Owner")}/>
            <Flex gap={"gap.small"} vAlign={"center"}>
                <UserThumbnail userId={userId} size={"large"}/>
                <Flex column vAlign={"center"}>
                    {!config.user ? <>
                            <Skeleton animation={"pulse"}>
                                <Flex column>
                                    <Skeleton.Shape width={"150px"} height={"20px"}/>
                                    <Skeleton.Shape width={"80px"} height={"16px"}/>
                                </Flex>
                            </Skeleton>
                        </>
                        :
                        <>
                            <Text content={config.user?.displayName}/>
                            {config.user?.jobTitle &&
                                <Text size={"small"} style={{color: "darkgray"}} content={config.user?.jobTitle}/>
                            }
                        </>
                    }
                </Flex>
            </Flex>
        </Flex>
    )
}

const renderSwapButton = (config: {
    shift: Immutable<Shift> | undefined,
    swapShiftSideViewRef: MagicReducerRef<typeof SwapShiftSideView>
}) => {
    if (!config.shift || config.shift.getType() === ShiftType.Absence) return null;
    return (
        <Button
            fluid primary
            className={"no-shrink"}
            content={translations.get("Swap")}
            icon={<ArrowSyncIcon/>}
            onClick={config.swapShiftSideViewRef.dispatch("open", config.shift)}
        />
    )
}