import {useForm} from "@witivio_teamspro/northstar-form";
import {Button, SaveIcon} from "@fluentui/react-northstar";
import {translations} from "../../translations";
import React, {useLayoutEffect} from "react";
import {useDaysNotesCache, useDaysNotesRangeCache} from "../cache/useDaysNotesCache";

export const useDayNotesForm = (shopId: string, date: string, notes: string) => {
    const [isSaving, setIsSaving] = React.useState(false);
    const {updateDayNotes} = useDaysNotesCache(shopId);

    useLayoutEffect(function onChange() {
        form.setFieldsInitialValues({notes});
        form.reset();
        setIsSaving(false);
    }, [shopId, date]);

    const form = useForm({
        disabled: isSaving,
        items: {
            notes: {
                type: "textArea",
                maxLength: 300,
                minHeight: "100px",
                autoFocus: true,
                placeholder: translations.get("DayNotes")
            }
        }
    });

    const renderSaveButton = (callback: () => void) => (
        <Button
            fluid
            primary icon={<SaveIcon outline size={"large"}/>}
            content={translations.get("Save")}
            disabled={!form.isValid || !form.state.hasChanged || isSaving}
            loading={isSaving}
            onClick={handleSaveDayNotes({
                date,
                callback,
                formState: form.state,
                setIsSaving,
                updateDayNotes
            })}
        />
    );

    return {
        ...form,
        renderSaveButton
    };
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleSaveDayNotes = (config: {
    date: string | undefined,
    formState: ReturnType<typeof useDayNotesForm>["state"],
    updateDayNotes: ReturnType<typeof useDaysNotesRangeCache>["updateDayNotes"],
    setIsSaving: (value: boolean) => void,
    callback: () => void,
}) => async () => {
    const {date, setIsSaving, callback, formState, updateDayNotes} = config;
    if (!date) return;
    setIsSaving(true);
    await updateDayNotes(date, formState.data.notes as string);
    setIsSaving(false);
    callback();
}