import React, {memo, ReactElement} from "react";
import {Props} from "./ReportsMobile.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ReportsMobile.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ReportsMobile.styles.scss";
import {Divider, Flex, Text} from "@fluentui/react-northstar";
import {useUserReport} from "../../../hooks/reports/useUserReport";
import {useMsTeamsSelector} from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {DateRangeSelector} from "../../../components/others/DateRangeSelector/DateRangeSelector";
import {DateRangeType} from "../../../components/others/DateRangeSelector/DateRangeSelector.interfaces";
import {Kpi} from "../../../components/others/Kpi/Kpi";
import {SideView} from "../../../components/dialogs/SideView/SideView";
import {translations} from "../../../translations";

export const ReportsMobile = memo((props: Props): ReactElement | null => {
    const {userId} = useMsTeamsSelector("userId");
    const dateRangeRef = useMagicReducerRef(DateRangeSelector);
    const sideViewRef = useMagicReducerRef(SideView);
    const [state, dispatch, render] = useMagicReducer(reducer, initialState);
    const startDate = dateRangeRef.state?.selectedRange.startDate;
    const endDate = dateRangeRef.state?.selectedRange.endDate;
    const {userReportItems, isLoading} = useUserReport(userId, startDate, endDate);

    const kpis: Array<ReactElement> = Object.entries(userReportItems).map(([key, value]) => (
        <Kpi key={key} {...value} showSkeletons={isLoading}/>
    ));

    const dateRangeSelector = (
        <DateRangeSelector
            availableTypes={[DateRangeType.Week, DateRangeType.Month]}
            externalRef={dateRangeRef}
            onDateRangeChange={render}
        />
    )

    return (
        <Flex fill column className={"side-view overflow-hidden"}>
            <Flex column className={"no-shrink side-view-header"} vAlign={"center"} style={{zIndex: 1}}>
                <Flex className={"overflow-hidden"} style={{minHeight: "32px", paddingLeft: "8px"}} vAlign={"center"}>
                    <Text size={"large"} weight={"semibold"} content={translations.get("MyReport")}/>
                </Flex>
                <Divider style={{margin: "3px -20px 3px -20px"}}/>
                {dateRangeSelector}
            </Flex>
            <Flex fill column className={"overflow-hidden"}>
                <Flex fill column className={"overflow-scroll"}
                      styles={{gap: "15px", padding: "15px"}}>
                    <Flex fill className={"kpis-grid"} style={{height: "fit-content"}}>
                        {kpis.slice(0, 2)}
                    </Flex>
                    <Flex fill className={"small-kpis-grid"} style={{height: "fit-content"}}>
                        {kpis.slice(2)}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}, CompareModule.areObjectsEqual);