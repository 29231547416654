import {AxiosResponse} from "axios";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {StaffInfoData} from "../../interfaces/StaffsCountData";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";
import {DayNoteData} from "../../interfaces/DayNoteData";
import {UserShiftsCountData} from "../../components/tables/GroupShiftsTable/GroupShiftsTable.interfaces";
import {Shift} from "../../classes/Shift";
import {ShiftData} from "../../interfaces/ShiftData";
import {ObjectModule} from "../../modules/Object.module";
import {CopyPlanningRequest} from "../../interfaces/CopyPlanningRequest";
import {CopyPlanningResponse} from "../../interfaces/CopyPlanningResponse";
import {IFilePickerFile} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/FilePicker/FilePicker";

const getPlanningStaffInfo = async (shopId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    if (!shopId || !startDate || !endDate) return;
    try {
        const searchParams = new URLSearchParams({startDate, endDate}).toString();
        const response: AxiosResponse<Array<StaffInfoData>> = await AxiosConfig.instance.get(`/planning/shops/${shopId}/staff-info?${searchParams}`);
        return response.data as Immutable<Array<StaffInfoData>>;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get planning staff info", error);
        return [];
    }
}

const getDaysNotes = async (shopId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    if (!shopId || !startDate || !endDate) return;
    try {
        const searchParams = new URLSearchParams({startDate, endDate}).toString();
        const response: AxiosResponse<Immutable<Array<DayNoteData>>> = await AxiosConfig.instance.get(`/planning/shops/${shopId}/days-notes?${searchParams}`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get days notes range", error);
        return [];
    }
}

const updateDayNotes = async (shopId: string | undefined, date: string | undefined, notes: string | undefined) => {
    if (!shopId || !date) return false;
    try {
        await AxiosConfig.instance.put(`/planning/shops/${shopId}/days-notes/${date}`, notes);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't update day notes", error);
        return false;
    }
}

const getGroupUsersShiftsCountRange = async (groupId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    if (!groupId || !startDate || !endDate) return;
    try {
        const searchParams = new URLSearchParams({startDate, endDate}).toString();
        const response: AxiosResponse<Immutable<Array<UserShiftsCountData>>> = await AxiosConfig.instance.get(`/planning/groups/${groupId}/users-shifts-count?${searchParams}`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get group users shifts count", error);
        return [];
    }
}

const getUserShiftsRange = async (userId: string | undefined, startDate: string | undefined, endDate: string | undefined): Promise<Array<Shift>> => {
    if (!userId || !startDate || !endDate) return [];
    try {
        const searchParams = new URLSearchParams({startDate, endDate}).toString();
        const response: AxiosResponse<Immutable<Array<ShiftData>>> = await AxiosConfig.instance.get(`/planning/users/${userId}/shifts?${searchParams}`);
        return response.data.map(s => new Shift(ObjectModule.clearNullValues(s)));
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get range of shifts of user " + userId, error);
        return [];
    }
}

const getShopShiftsRange = async (shopId: string | undefined, startDate: string | undefined, endDate: string | undefined): Promise<Array<Shift>> => {
    if (!shopId || !startDate || !endDate) return [];
    try {
        const searchParams = new URLSearchParams({startDate, endDate}).toString();
        const response: AxiosResponse<Immutable<Array<ShiftData>>> = await AxiosConfig.instance.get(`/planning/shops/${shopId}/shifts?${searchParams}`);
        return response.data.map(s => new Shift(ObjectModule.clearNullValues(s)));
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get range of shifts of shop " + shopId, error);
        return [];
    }
}

const getRotatingStaffsShiftsRange = async (currentShopId: string | undefined, startDate: string | undefined, endDate: string | undefined): Promise<Array<Shift>> => {
    if (!currentShopId || !startDate || !endDate) return [];
    try {
        const searchParams = new URLSearchParams({startDate, endDate}).toString();
        const response: AxiosResponse<Immutable<Array<ShiftData>>> = await AxiosConfig.instance.get(`/planning/shops/${currentShopId}/rotating-staffs/shifts?${searchParams}`);
        return response.data.map(s => new Shift(ObjectModule.clearNullValues(s)));
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get range of shifts of rotating staffs for shop " + currentShopId, error);
        return [];
    }
}

const sharePlanning = async (shopId: string | undefined, request: {
    startDate: string, endDate: string, notifyConcernedMembers: boolean,
}) => {
    if (!shopId) return false;
    try {
        await AxiosConfig.instance.post(`/planning/shops/${shopId}/share`, request);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't share planning of shop " + shopId, error);
        return false;
    }
}

const copyPlanning = async (shopId: string | undefined, request: CopyPlanningRequest): Promise<Array<Immutable<Shift>> | undefined> => {
    if (!shopId) return;
    try {
        const response = await AxiosConfig.instance.post(`/planning/shops/${shopId}/copy`, request);
        const data = response.data as CopyPlanningResponse;
        const shifts = data.copiedShifts.map(s => new Shift(ObjectModule.clearNullValues(s)));
        const preShifts = data.copiedPreshifts.map(s => new Shift(ObjectModule.clearNullValues(s)));
        preShifts.forEach(ps => {
            const linkedShift = shifts.find(s => s.getId() === ps.getId());
            if (linkedShift) linkedShift.setFields(ps.get());
        });
        return shifts;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't copy planning of shop " + shopId, error);
        return;
    }
}

const importPlanning = async (shopId: string, file: Immutable<IFilePickerFile>): Promise<boolean> => {
    try {
        await AxiosConfig.instance.post(`/planning/shops/${shopId}/import`, file.content);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't import planning for shop " + shopId, error);
        return false;
    }
}

const exportPlanning = async (startDate: string, endDate: string, shopId: string | undefined): Promise<Blob | undefined> => {
    try {
        const response = await AxiosConfig.instance.post(`/planning/export`, {startDate, endDate, shopId});
        const contentType = response.headers["content-type"] ?? "text/plain";
        const base64File = `data:${contentType};base64,${response.data}`;
        return await fetch(base64File).then(res => res.blob());
    } catch (error) {
        ErrorModule.showErrorAlert("Can't export planning", error);
        return undefined;
    }
}

const getPreshiftsCount = async (shopId: string | undefined, startDate: string | undefined, endDate: string | undefined): Promise<number> => {
    if (!shopId || !startDate || !endDate) return 0;
    try {
        const searchParams = new URLSearchParams({startDate, endDate}).toString();
        const response = await AxiosConfig.instance.get(`/planning/shops/${shopId}/preshifts-count?${searchParams}`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get preshifts count for planning of shop " + shopId, error);
        return 0;
    }
}

export const PlanningApi = {
    getDaysNotes,
    updateDayNotes,
    getPlanningStaffInfo,
    getGroupUsersShiftsCountRange,
    getUserShiftsRange,
    sharePlanning,
    copyPlanning,
    getShopShiftsRange,
    importPlanning,
    getRotatingStaffsShiftsRange,
    getPreshiftsCount,
    exportPlanning
}