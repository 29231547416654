import {useQuery} from "react-query";
import {ReportApi} from "../../../apis/Report/ReportApi";

export const groupUsersReportsCacheKey = "groupUsersReports";

export const useGroupUsersReportsCache = (groupId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    const {
        data: groupUsersReports,
        isLoading
    } = useQuery([groupUsersReportsCacheKey, groupId, startDate, endDate], () => ReportApi.getGroupUsersReports(groupId, startDate, endDate), {
        staleTime: Infinity,
        enabled: !!groupId && !!startDate && !!endDate
    });

    return {
        groupUsersReports,
        isLoading,
    }
}