import {AppViewData} from "interfaces/AppView/AppViewData";
import {AppView} from "interfaces/AppView/AppView";
import {Schedules} from "../views/Schedules/Schedules";
import {Requests} from "../views/Requests/Requests";
import {Reports} from "../views/Reports/Reports";
import {Settings} from "../views/Settings/Settings";
import {Badge} from "../views/Badge/Badge";

const SchedulesView: AppViewData = {
    id: AppView.Schedules,
    path: "/",
    element: Schedules,
}

const RequestsView: AppViewData = {
    id: AppView.Requests,
    path: "/requests",
    element: Requests,
}

const ReportsView: AppViewData = {
    id: AppView.Reports,
    path: "/reports",
    element: Reports,
}

const SettingsView: AppViewData = {
    id: AppView.Settings,
    path: "/settings",
    element: Settings,
}

const BadgeView: AppViewData = {
    id: AppView.Badge,
    path: "/badge",
    element: Badge,
}

export const AppViews: Record<AppView, AppViewData> = {
    [AppView.Schedules]: SchedulesView,
    [AppView.Requests]: RequestsView,
    [AppView.Reports]: ReportsView,
    [AppView.Settings]: SettingsView,
    [AppView.Badge]: BadgeView,
}