import React, {memo, ReactElement, useMemo} from "react";
import {PlanningConfigurationDialogTab, Props, State} from "./PlanningConfigurationDialog.interfaces";
import {MagicDispatch, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./PlanningConfigurationDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Flex, Menu} from "@fluentui/react-northstar";
import "./PlanningConfigurationDialog.styles.scss";
import {translations} from "translations";
import {SettingsIcon} from "assets/icons";
import {ShopPart} from "./parts/ShopPart/ShopPart";
import {CategoriesPart} from "./parts/CategoriesPart/CategoriesPart";
import {GroupsPart} from "./parts/GroupsPart/GroupsPart";
import {ActivitiesPart} from "./parts/ActivitiesPart/ActivitiesPart";

export const PlanningConfigurationDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    const menuItems = useMemo(() => [
        {key: "shop", id: PlanningConfigurationDialogTab.Shop, content: translations.get("Shop")},
        {key: "categories", id: PlanningConfigurationDialogTab.Categories, content: translations.get("Categories")},
        {key: "activities", id: PlanningConfigurationDialogTab.Activities, content: translations.get("Activities")},
        {key: "groups", id: PlanningConfigurationDialogTab.Groups, content: translations.get("Groups")},
    ], []);

    const activeIndex = menuItems.findIndex(item => item.id === state.activeTab);

    const menu = (
        <Menu
            className={"planning-configuration-dialog-menu"}
            activeIndex={activeIndex}
            items={menuItems}
            underlined
            primary
            onItemClick={dispatch("changeTab")}
        />
    )

    return (
        <Dialog
            externalRef={dialogRef}
            width={1000}
            height={700}
            noPadding
            onBeforeClose={state.onBeforeClose}
            icon={<SettingsIcon/>}
            title={translations.get("Configuration")}
            className={"planning-configuration-dialog"}
            content={
                <Flex fill column>
                    {menu}
                    {renderDialogContent(dispatch, state.activeTab, state.shopId)}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderDialogContent = (
    dispatch: MagicDispatch<typeof reducer>,
    activeTab: State["activeTab"],
    shopId: string
) => {
    let content: ReactElement | null = null;

    switch (activeTab) {
        case PlanningConfigurationDialogTab.Shop:
            content = <ShopPart shopId={shopId} setBeforeClose={dispatch("setBeforeClose")}/>;
            break;
        case PlanningConfigurationDialogTab.Categories:
            content = <CategoriesPart shopId={shopId}/>;
            break;
        case PlanningConfigurationDialogTab.Activities:
            content = <ActivitiesPart shopId={shopId}/>
            break;
        case PlanningConfigurationDialogTab.Groups:
            content = <GroupsPart shopId={shopId}/>;
            break;
        default:
            return null;
    }

    return (
        <Flex fill column className={"planning-configuration-dialog-content"}>
            {content}
        </Flex>
    )
}