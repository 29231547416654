import {translations} from "translations";
import {useForm} from "@witivio_teamspro/northstar-form";
import {useShopCache, useShopsCache} from "../cache/useShopsCache";
import React, {useCallback, useEffect, useLayoutEffect, useMemo} from "react";
import {useUsersCache} from "../cache/useUsersCache";
import {useShopOpeningHoursForm} from "./useShopOpeningHoursForm";
import {Button, Flex, Loader, SaveIcon, TrashCanIcon} from "@fluentui/react-northstar";
import {ShopData, ShopType} from "../../interfaces/ShopData";
import {DialogContextValue} from "../../services/DialogContext/DialogContext.interfaces";
import {useDialogContext} from "../../services/DialogContext/DialogContext";
import {ShopModule} from "../../modules/Shop.module";
import {useGraphTeamsCache} from "../cache/useGraphTeamsCache";
import {GraphService} from "../../services/GraphService/GraphService";
import {Immutable} from "@witivio_teamspro/use-reducer";

const shopTypes = Object.keys(ShopType)
    .filter((key: string | number) => !isNaN(Number(key)))
    .map(key => Number(key) as ShopType);

export const useShopForm = (shopId: string | undefined) => {
    const {confirmDeleteDialog} = useDialogContext();
    const {graphTeams} = useGraphTeamsCache();
    const {shops} = useShopsCache();
    const {shop, isLoading: isShopLoading, upsertShop, deleteShop} = useShopCache(shopId);
    const {users, isLoading: areUsersLoading} = useUsersCache(shop?.managersIds);
    const [isSaving, setIsSaving] = React.useState<boolean>(false);

    const openingHoursForm = useShopOpeningHoursForm(shopId, isSaving);

    useLayoutEffect(() => {
        form.setFieldsInitialValues({
            team: shop?.linkedTeamId,
            name: shop?.name,
            type: shop?.type,
            address: shop?.postalAddress,
            managers: (shop?.managersIds ?? []) as string[],
            publicHolidays: shop?.publicHolidaysId ?? "fr",
        });
        form.reset();
        setIsSaving(false);
    }, [shop]);

    const shopsNames = useMemo(() => {
        return shops?.filter(s => s.id !== shopId).map(s => s.name) ?? []
    }, [shops]);

    const form = useForm({
        disabled: isSaving,
        items: {
            team: {
                type: "dropdown",
                label: translations.get("LinkedTeam"),
                placeholder: translations.get("LinkTeamToThisShop"),
                items: graphTeams?.map(t => t.id) ?? [],
                renderItem: i => graphTeams?.find(t => t.id === i)?.displayName ?? "",
                renderSelectedItem: i => graphTeams?.find(t => t.id === i)?.displayName ?? "",
            },
            name: {
                type: "input",
                inputMode: "text",
                label: translations.get("Name"),
                required: true,
                placeholder: translations.get("EnterName"),
                autoFocus: !shopId,
                validate: name => !shopsNames.includes(name + ""),
                errorMessage: translations.get("NameAlreadyExists"),
            },
            type: {
                type: "dropdown",
                required: true,
                label: translations.get("Type"),
                placeholder: translations.get("SelectType"),
                items: shopTypes,
                renderItem: i => translations.get(ShopType[i as ShopType]),
                renderSelectedItem: i => translations.get(ShopType[i as ShopType]),
                clearable: false,
            },
            address: {
                type: "input",
                inputMode: "text",
                label: translations.get("PostalAddress"),
                required: true,
                placeholder: translations.get("EnterAddress"),
            },
            managers: {
                type: "peoplePicker",
                required: true,
                label: translations.get("ManagerSingularPlural"),
                multiple: true,
                openOrgWideSearchInChatOrChannel: true,
                placeholder: translations.get("SelectOneOrMoreManagers"),
                initialValue: users?.map(u => u.id),
                fetchInitialUsers: (ids) => GraphService.getUsersAsync(ids),
                fetchUsers: (search) => GraphService.searchForUsersAsync(search),
            },
            publicHolidays: {
                type: "dropdown",
                required: true,
                label: translations.get("PublicHolidays"),
                placeholder: translations.get("SelectPublicHolidays"),
                items: ["fr"],
                renderItem: i => ShopModule.getHolidayIdLabel(i),
                renderSelectedItem: i => ShopModule.getHolidayIdLabel(i),
                clearable: false,
                disabled: true,
            },
        }
    });

    const {team} = form.state.data;

    useEffect(() => {
        if (!team || !form.state.hasChanged) return;
        let teamName = graphTeams?.find(t => t.id === team)?.displayName ?? "";
        if (!teamName) return;
        if (shopsNames.includes(teamName)) teamName += "_copy";
        form.setFieldsValues({name: teamName});
    }, [team]);

    const renderForm = () => isLoading ? (
        <Flex fill vAlign={"center"} hAlign={"center"} styles={{height: "450px"}}>
            <Loader/>
        </Flex>
    ) : (
        <Flex fill column gap={"gap.medium"}>
            <Flex column className={"no-shrink"} gap={"gap.medium"}>
                {form.renderForm()}
            </Flex>
            <Flex className={"no-shrink"}>
                {openingHoursForm.renderForm()}
            </Flex>
        </Flex>
    )

    const reset = () => {
        form.reset();
        openingHoursForm.reset();
    }

    const isLoading = isShopLoading || areUsersLoading;
    const hasChanged = form.state.hasChanged || openingHoursForm.hasChanged;

    const renderSaveButton = (callback?: () => void) => (
        <Button
            primary content={translations.get("Save")}
            icon={<SaveIcon size={"large"} outline/>}
            disabled={!form.isValid || !hasChanged || isSaving}
            loading={isSaving}
            onClick={handleSaveShop(shop, form.state, openingHoursForm.items, upsertShop, setIsSaving, callback)}
        />
    );

    const renderDeleteButton = useCallback((callback?: () => void) => !shopId ? null : (
        <Button
            content={translations.get("Delete")}
            icon={<TrashCanIcon outline/>}
            text
            disabled={isSaving}
            className={isSaving ? "" : "delete-btn"}
            onClick={handleDeleteShop({
                shopId,
                confirmDeleteDialog,
                deleteShop,
                shopName: shop?.name ?? "",
                callback,
            })}
        />
    ), [shopId, isSaving]);

    return {
        ...form,
        renderForm,
        isLoading,
        hasChanged,
        reset,
        renderSaveButton,
        renderDeleteButton,
        isSaving,
    };
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleSaveShop = (
    oldShop: Immutable<ShopData> | undefined,
    formState: ReturnType<typeof useShopForm>["state"],
    openingHours: ReturnType<typeof useShopOpeningHoursForm>["items"],
    upsertShop: ReturnType<typeof useShopCache>["upsertShop"],
    setIsSaving: (value: boolean) => void,
    callback: (() => void) | undefined,
) => async () => {
    setIsSaving(true);
    const shop = generateShop(oldShop, formState, openingHours);
    await upsertShop(shop);
    callback?.();
    setIsSaving(false);
}

const handleDeleteShop = (config: {
    shopId: string | undefined,
    callback?: (() => void) | undefined,
    deleteShop: ReturnType<typeof useShopCache>["deleteShop"],
    confirmDeleteDialog: DialogContextValue["confirmDeleteDialog"],
    shopName: string,
}) => () => {
    const {shopId, shopName, deleteShop, confirmDeleteDialog, callback} = config;
    if (!shopId) return;
    confirmDeleteDialog.dispatch("open", {
        title: translations.get("DeleteShop"),
        subtitle: translations.get("AreYouSureToDeleteShop", {name: shopName}),
        callback: async () => {
            await deleteShop(shopId);
            callback?.();
        }
    })();
}

const generateShop = (
    oldShop: Immutable<ShopData> | undefined,
    formState: ReturnType<typeof useShopForm>["state"],
    openingHours: ReturnType<typeof useShopOpeningHoursForm>["items"],
): ShopData => {
    const {name, type, address, managers, publicHolidays, team} = formState.data;
    return {
        id: oldShop?.id ?? "",
        name: name as string,
        type: type as ShopType,
        managersIds: managers as string[],
        publicHolidaysId: publicHolidays as string,
        postalAddress: address as string,
        categories: [...(oldShop?.categories ?? [])],
        activities: [...(oldShop?.activities ?? [])],
        openingHours,
        linkedTeamId: team as string,
        totalStaffCount: oldShop?.totalStaffCount ?? 0,
    }
}