import {Immutable} from "@witivio_teamspro/use-reducer";
import {GroupData} from "../interfaces/GroupData";
import {WeekDaysModule} from "./WeekDays.module";
import moment from "moment";

const sortGroupsByOrder = (groups: Immutable<Array<GroupData>> | undefined) => {
    if (!groups) return;
    return [...groups].sort((a, b) => a.index - b.index);
}

const getTotalThresholdByDate = (group: Immutable<GroupData> | undefined, date: string, categoriesIds?: Array<string> | undefined): number => {
    if (!group || !date) return 0;
    return group.thresholds.shifts.reduce((acc, shiftThresholds) => {
        if (categoriesIds && !categoriesIds.includes(shiftThresholds.categoryId)) return acc;
        return getShiftCategoryThresholdByDate(group, shiftThresholds.categoryId, date) + acc;
    }, 0);
}

const getShiftCategoryThresholdByDate = (group: Immutable<GroupData> | undefined, shiftCategoryId: string | undefined, date: string): number => {
    if (!group || !shiftCategoryId || !date) return 0;
    const dayOfWeek = WeekDaysModule.getWeekDay(date);
    const dateCustomThreshold = group.thresholds.custom.find(c => c.categoryId === shiftCategoryId && moment(date).isBetween(moment(c.startDate), moment(c.endDate), "day", "[]"));
    if (dateCustomThreshold) return dateCustomThreshold.thresholds.find(t => t.day === dayOfWeek)?.threshold ?? 0;
    const shiftThreshold = group.thresholds.shifts.find(s => s.categoryId === shiftCategoryId);
    if (!shiftThreshold) return 0;
    return shiftThreshold.thresholds.find(t => t.day === dayOfWeek)?.threshold ?? 0;
}

export const GroupModule = {
    sortGroupsByOrder,
    getShiftCategoryThresholdByDate,
    getTotalThresholdByDate,
}