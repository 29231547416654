import {Props, State} from "./ActivitiesList.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {DialogContextValue} from "services/DialogContext/DialogContext.interfaces";
import {ShiftActivity} from "interfaces/ShiftData";

export const initialState: State = {}

export const reducer = (config: {
    props: Props,
    shiftActivityDialog: DialogContextValue["shiftActivityDialog"],
}) => ({
    openShiftActivityDialog: ({}, _, activity: Immutable<ShiftActivity | undefined>) => {
        const {props, shiftActivityDialog} = config;
        if (props.disabled) return;
        shiftActivityDialog.dispatch("open", {
            shopId: props.shopId,
            onDone: props.onUpdateActivity,
            activity
        })();
    },
}) satisfies MagicReducerObject<State>;