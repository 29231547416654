import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./OrderGroupsDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./OrderGroupsDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Button, Flex, SaveIcon} from "@fluentui/react-northstar";
import "./OrderGroupsDialog.styles.scss";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {Container} from "./Container/Container";
import {translations} from "../../../translations";
import {useShopGroupsCache} from "hooks/cache/groups/useShopGroupsCache";
import {ArrowMoveIcon} from "../../../assets/icons";
import {useDialogContext} from "../../../services/DialogContext/DialogContext";

export const OrderGroupsDialog = memo((props: Props): ReactElement | null => {
    const {confirmCancelChangesDialog} = useDialogContext();
    const dialogRef = useMagicReducerRef(Dialog);
    const containerRef = useMagicReducerRef(Container);
    const [state, dispatch] = useMagicReducer(reducer({
        dialogRef,
        containerRef,
        confirmCancelChangesDialog,
    }), initialState, props.externalRef);
    const {groups, updateIndexes} = useShopGroupsCache(state.shopId);

    const initialOrder = useMemo(() => {
        if (!groups) return [];
        return groups.sort((a, b) => a.index - b.index).map(g => g.id);
    }, [groups]);

    return (
        <Dialog
            externalRef={dialogRef}
            width={500}
            icon={<ArrowMoveIcon/>}
            onBeforeClose={dispatch("beforeClose", !state.isSaving && state.canSave)}
            title={translations.get("OrderGroups")}
            noPadding
            content={
                <Flex fill column gap={"gap.medium"}>
                    <DndProvider backend={HTML5Backend}>
                        <Container
                            disabled={state.isSaving}
                            externalRef={containerRef}
                            shopId={state.shopId}
                            groupId={state.groupId}
                            onOrderChange={dispatch("orderChange", initialOrder)}
                        />
                    </DndProvider>
                </Flex>
            }
            footer={
                <Flex fill hAlign={"end"}>
                    <Button
                        primary
                        icon={<SaveIcon outline size={"large"}/>}
                        content={translations.get("Save")}
                        disabled={!state.canSave || state.isSaving}
                        loading={state.isSaving}
                        onClick={dispatch("save", updateIndexes)}
                    />
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);