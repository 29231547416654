import {State} from "./DayNotesDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";

export const initialState: State = {
    date: "",
    shopId: "",
    notes: "",
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, {date, shopId, notes}: { date: string, shopId: string | undefined, notes: string }) => {
        if (!shopId || !date) return;
        setState({date, shopId, notes});
        config.dialogRef.dispatch("open")();
    },
    reset: ({setState}) => {
        setState({...initialState});
    }
}) satisfies MagicReducerObject<State>;