import {AxiosResponse} from "axios";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";
import {RequestData, RequestResponse} from "../../interfaces/RequestData";
import {ObjectModule} from "../../modules/Object.module";

const createRequest = async (request: Immutable<RequestData>) => {
    try {
        const response: AxiosResponse<string> = await AxiosConfig.instance.post(`/requests`, request);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't create request", error);
        return;
    }
}

const getRequests = async () => {
    try {
        const response: AxiosResponse<Immutable<Array<RequestData>>> = await AxiosConfig.instance.get(`/requests`);
        return response.data.map(r => ObjectModule.clearNullValues(r));
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get requests", error);
        return;
    }
}

const answerRequest = async (requestId: string, requestResponse: Immutable<RequestResponse>) => {
    try {
        await AxiosConfig.instance.post(`/requests/${requestId}/answer`, requestResponse);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't answer to request", error);
        return false;
    }
}

const approveSwapRequest = async (requestId: string, approbation: boolean): Promise<RequestData | undefined> => {
    try {
        const response = await AxiosConfig.instance.post(`/requests/${requestId}/swap-approve`, approbation);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't approve swap request", error);
        return undefined;
    }
}

const applyRetrieveHours = async (requestId: string, userId: string, usedHours: number): Promise<RequestData | undefined> => {
    try {
        const response = await AxiosConfig.instance.post(`/requests/${requestId}/apply-retrieve-hours`, {
            userId,
            usedHours
        });
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't apply retrieve hours", error);
        return undefined;
    }
}

export const RequestApi = {
    createRequest,
    getRequests,
    answerRequest,
    approveSwapRequest,
    applyRetrieveHours,
}