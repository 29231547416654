import {Props, State} from "./ShiftThresholdsPart.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {DialogContextValue} from "../../../../../services/DialogContext/DialogContext.interfaces";
import {
    PlanningConfigurationDialogTab
} from "../../../PlanningConfigurationDialog/PlanningConfigurationDialog.interfaces";
import {ThresholdsData} from "../../../../../interfaces/GroupData";
import {useGroupCache} from "../../../../../hooks/cache/groups/useGroupCache";

export const initialState: State = {
    updatedThresholds: {}
}

export const reducer = (config: {
    props: Props,
    customShiftThresholdDialog: DialogContextValue["customShiftThresholdDialog"],
    planningConfigurationDialog: DialogContextValue["planningConfigurationDialog"],
    upsertThreshold: ReturnType<typeof useGroupCache>["upsertThreshold"],
}) => ({
    openCustomShiftThresholdDialog: (_, __, categoryId: string) => {
        const {customShiftThresholdDialog, props} = config;
        customShiftThresholdDialog.dispatch("open", {groupId: props.groupId, categoryId})();
    },
    addCategory: ({}, _, shopId: string | undefined) => {
        if (!shopId) return;
        const {props, planningConfigurationDialog} = config;
        props.dialogRef.dispatch("close")();
        planningConfigurationDialog.dispatch("open", {
            shopId,
            tab: PlanningConfigurationDialogTab.Categories
        })();
    },
    saveShiftThresholds: ({state}) => {
        Object.entries(state.updatedThresholds).forEach(([categoryId, thresholds]) => {
            config.upsertThreshold({categoryId: categoryId, thresholds}).then();
        });
    },
    thresholdsUpdated: ({state, setState}, _, {categoryId, thresholds, hasChanged}: {
        categoryId: string,
        thresholds: ThresholdsData,
        hasChanged: boolean,
    }) => {
        let newThresholds = {...state.updatedThresholds};
        if (hasChanged) newThresholds[categoryId] = thresholds;
        else delete newThresholds[categoryId];
        setState({updatedThresholds: newThresholds}, false);
    }
}) satisfies MagicReducerObject<State>;