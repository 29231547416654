import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {IAppDispatch} from "redux/store";
import {initializeAuth, setAuth, useAuthSelector} from "redux/reducers/AuthReducer/AuthReducer";
import {
    clearListeners,
    initializeMSTeams,
    setListeners,
    useMsTeamsSelector
} from "redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {initializeScope, useScopesSelector} from "redux/reducers/ScopesReducer/ScopesReducer";
import {setConfiguration} from "redux/reducers/ConfigurationReducer/ConfigurationReducer";
import {useDeeplinkContext} from "./useDeeplinkContext";
import {useMsTeamsCache} from "./cache/useMsTeamsCache";
import {useConfigurationCache} from "./cache/useConfigurationCache";
import {useFormSettingsUpdater} from "@witivio_teamspro/northstar-form";

export const useAppInitializer = () => {
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch<IAppDispatch>();
    const deeplinkContext = useDeeplinkContext();
    const updateUseFormSettings = useFormSettingsUpdater();

    const auth = useAuthSelector("accessToken", "isTeamsApp");
    const teams = useMsTeamsSelector("loaded", "locale", "isOnMobile", "themeClass", "tenantId", "userId", "isTeamsIframe", "hostClientType", "isConfiguringApp", "isInMeeting");
    const scopes = useScopesSelector("loaded");

    const {isTeamsApp} = useMsTeamsCache();
    const {config} = useConfigurationCache();

    useEffect(function initializeMsTeamsListeners() {
        dispatch(setListeners());
        return () => dispatch(clearListeners());
    }, []);

    useEffect(function onTeamsApp() {
        if (isTeamsApp !== undefined) dispatch(setAuth({isTeamsApp}));
    }, [isTeamsApp]);

    useEffect(function onCachedConfiguration() {
        if (config) dispatch(setConfiguration(config));
    }, [config]);

    useEffect(function onConfigurationLoaded() {
        if (!config?.clientId || isTeamsApp == undefined) return;
        dispatch(initializeAuth({isTeamsApp: isTeamsApp}));
    }, [config?.clientId, isTeamsApp]);

    useEffect(function onAccessTokenRetrieved() {
        if (!auth.accessToken || isTeamsApp == undefined) return;
        dispatch(initializeMSTeams({isTeamsApp: isTeamsApp, accessToken: auth.accessToken}));
    }, [auth.accessToken, isTeamsApp]);

    useEffect(function onMsTeamsInitialized() {
        if (!teams.loaded) return;
        if (teams.isConfiguringApp) return setLoaded(true);
        updateUseFormSettings({locale: teams.locale});
        const scopesServiceConfiguration = {
            clientId: config?.clientId,
            apiBaseUrl: window.location.origin,
            scopes: config ? config?.graphScopes.split(" ") : [],
            inTeams: auth.isTeamsApp,
            locale: teams.locale,
            isWidget: false,
            isOnMobile: teams.isOnMobile,
            theme: teams.themeClass,
            tenantId: teams.tenantId
        }
        dispatch(initializeScope({initArgs: scopesServiceConfiguration}))
    }, [teams.loaded]);

    useEffect(function onScopesServiceInitialized() {
        if (!scopes.loaded || !config) return;
        setLoaded(true);
    }, [scopes.loaded]);

    useEffect(function onDeeplinkContext() {
        if (!deeplinkContext) return;
        // Action to do if deeplink context is present
    }, [deeplinkContext]);

    return {loaded}
};