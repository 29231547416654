import React, {memo, ReactElement, useEffect, useMemo} from "react";
import {Props} from "./Toolbar.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Toolbar.reducer";
import {CompareModule} from "modules/Compare.module";
import "./Toolbar.styles.scss";
import {Button, Flex, SearchIcon,} from "@fluentui/react-northstar";
import {DateRangeSelector} from "components/others/DateRangeSelector/DateRangeSelector";
import {useDialogContext} from "services/DialogContext/DialogContext";
import {usePlanningDateRangeCache} from "../../../hooks/cache/usePlanningDateRangeCache";
import {ExportIcon} from "../../../assets/icons";
import {translations} from "../../../translations";
import {useForm} from "@witivio_teamspro/northstar-form";
import {useMsTeamsSelector} from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {GraphService} from "../../../services/GraphService/GraphService";

export const Toolbar = memo((props: Props): ReactElement | null => {
    const {isLightTheme} = useMsTeamsSelector("isLightTheme");
    const dateRangeRef = useMagicReducerRef(DateRangeSelector);
    const {userReportDialog} = useDialogContext();
    const {planningDateRange, setPlanningDateRange} = usePlanningDateRangeCache();
    const [state, dispatch] = useMagicReducer(reducer({
        props, setPlanningDateRange, planningDateRange
    }), initialState, props.externalRef);

    const searchUserForm = useForm({
        items: {
            searchUser: {
                type: "peoplePicker",
                inputIcon: <SearchIcon outline styles={{color: "darkgray"}}/>,
                placeholder: translations.get("SearchUser"),
                fetchUsers: (search) => {
                    if (!search) return Promise.resolve([]);
                    return GraphService.searchForUsersAsync(search)
                },
                fetchInitialUsers: (ids) => GraphService.getUsersAsync(ids),
                inverted: !isLightTheme,
            }
        }
    });

    const selectedSearchUserId = searchUserForm.state.data.searchUser?.[0];

    useEffect(function onSearchUserSelected() {
        if (!selectedSearchUserId) return;
        userReportDialog.dispatch("open", selectedSearchUserId)();
        searchUserForm.reset();
    }, [selectedSearchUserId]);

    const exportButton = useMemo(() => (
        <Button
            icon={<ExportIcon/>}
            content={translations.get("Export")}
            disabled={state.isExporting}
            loading={state.isExporting}
            onClick={dispatch("export")}
        />
    ), [state.isExporting]);

    const dateRange = useMemo(() => (
        <DateRangeSelector
            externalRef={dateRangeRef}
            className={"planning-date-range"}
            onDateRangeChange={dispatch("dateRangeChange")}
        />
    ), []);

    return (
        <Flex className={"toolbar"}>
            <Flex fill/>
            <Flex styles={{width: "100%", maxWidth: "500px"}}>
                {searchUserForm.formItems.searchUser}
            </Flex>
            <Flex fill hAlign={"end"} gap={"gap.small"} vAlign={"center"}>
                {dateRange}
                {exportButton}
            </Flex>
        </Flex>
    )
}, CompareModule.areObjectsEqual);