import {useQuery} from "react-query";
import {PlanningApi} from "../../apis/Planning/PlanningApi";

export const planningPresiftsCountCacheKey = "planningPreshiftsCount";

export const usePlanningPreshiftsCountCache = (shopId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    const {
        data: preshiftsCount,
        isLoading,
        isFetching,
    } = useQuery([planningPresiftsCountCacheKey, shopId, startDate, endDate], () => PlanningApi.getPreshiftsCount(shopId, startDate, endDate), {
        staleTime: 0,
        enabled: !!shopId && !!startDate && !!endDate
    });

    return {
        isLoading: isLoading || isFetching,
        preshiftsCount,
    }
}