import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./ShiftsList.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShiftsList.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ShiftsList.styles.scss";
import moment from "moment/moment";
import {Shift} from "../../../../classes/Shift";
import {Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../../../translations";
import {ShiftCard} from "../../../../components/others/ShiftCard/ShiftCard";

export const ShiftsList = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    const orderedShifts = useMemo(() => {
        if (!props.shifts) return Array.from({length: 10}).map(() => undefined);
        return [...props.shifts]
            .filter(s => !!s.getDate() && !!s.getShopId() && !!s.getUserId())
            .sort((a, b) => moment(a.getDateWithTime()).isBefore(moment(b.getDateWithTime())) ? -1 : 1);
    }, [props.shifts]);

    const groupedShiftsByMonthAndDate = useMemo(() => {
        if (!props.shifts || !orderedShifts) return [];
        const groupedShifts = orderedShifts.reduce((acc, shift) => {
            const month = moment(shift?.getDateWithTime()).format("MMMM").toUpperCase();
            if (!acc[month]) acc[month] = {};
            const shiftDate = moment(shift?.getDateWithTime()).format("DD");
            if (shift && shiftDate) {
                if (!acc[month]![shiftDate]) acc[month]![shiftDate] = [];
                acc[month]![shiftDate]!.push(shift);
            }
            return acc;
        }, {} as Record<string, Record<string, Array<Shift>>>);
        return Object.entries(groupedShifts).map(([month, shiftsByDate]) => ({month, shiftsByDate}));
    }, [orderedShifts]);

    const hasNoShift = orderedShifts.length === 0;

    return (
        <Flex fill column gap={"gap.medium"} className={"shifts-list"}>
            {hasNoShift && (
                <Flex fill hAlign={"center"} vAlign={"center"}>
                    <Text size={"large"} content={translations.get("NoShiftAvailable")}
                          style={{color: "darkgray"}}/>
                </Flex>
            )}
            {groupedShiftsByMonthAndDate?.length === 0 ?
                orderedShifts.map((s, i) => (
                    <Flex key={s?.getId() ?? ("skeleton" + i)}>
                        <ShiftCard readonly showDate={{showMonth: false}} shift={s}/>
                    </Flex>
                ))
                :
                groupedShiftsByMonthAndDate?.map(({month, shiftsByDate}) => (
                    <Flex column gap={"gap.small"} key={month}>
                        <Text content={month}/>
                        {Object.values(shiftsByDate).map((shifts) => (
                            shifts.map((s, i) => (
                                <Flex key={s?.getId() ?? ("skeleton" + i)}
                                      style={{marginLeft: i > 0 ? "37px" : undefined}}>
                                    <ShiftCard
                                        small={props.small}
                                        showAvatar={props.showAvatar}
                                        readonly showDate={i === 0 ? {showMonth: false} : false}
                                        shift={s}
                                        onClick={props.shiftDetailsSideViewRef.dispatch("open", s)}
                                    />
                                </Flex>
                            ))
                        ))}
                    </Flex>
                ))
            }
        </Flex>
    )
}, CompareModule.areObjectsEqual);