import {Props, ShiftEditType, State} from "./ShiftCard.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {DialogContextValue} from "services/DialogContext/DialogContext.interfaces";
import {useShiftsCache} from "../../../hooks/cache/useShiftsCache";
import {translations} from "../../../translations";

export const initialState: State = {
    doubleClickTimeout: undefined,
}

export const reducer = (config: {
    props: Props,
    updateClockingDialog: DialogContextValue["updateClockingDialog"],
    shiftDialog: DialogContextValue["shiftDialog"],
    notifyShift: ReturnType<typeof useShiftsCache>["notifyShift"],
    deleteShift: ReturnType<typeof useShiftsCache>["deleteShift"],
    confirmDeleteDialog: DialogContextValue["confirmDeleteDialog"],
    shiftCategoryName: string,
}) => ({
    editShift: () => {
        const {shift, currentShopId, userId, date, readonly} = config.props;
        if (!currentShopId) return;
        config.shiftDialog.dispatch("open", {
            shift,
            currentShopId,
            userId: userId ?? shift?.getUserId(),
            date,
            readOnly: readonly,
        })();
    },
    updateClocking: () => {
        const {currentShopId, shift, readonly} = config.props;
        if (!shift || readonly) return;
        config.updateClockingDialog.dispatch("open", {shift, currentShopId})();
    },
    copy: () => {
        config.props.onCopy?.();
    },
    paste: () => {
        config.props.onPaste?.();
    },
    notify: () => {
        if (!config.props.shift) return;
        config.notifyShift({shift: config.props.shift, shopId: config.props.currentShopId}).then();
    },
    click: (reducerData) => {
        const {setState, state} = reducerData;
        if (config.props.onClick) return config.props.onClick();
        if (state.doubleClickTimeout) {
            clearTimeout(state.doubleClickTimeout);
            setState({doubleClickTimeout: undefined}, false);
            reducer(config).editShift();
        }
        const doubleClickTimeout = setTimeout(() => {
            setState({doubleClickTimeout: undefined}, false);
        }, 400);
        setState({doubleClickTimeout}, false);
    },
    delete: ({}, _, deleteType?: ShiftEditType) => {
        if (!config.props.shift) return;
        const name = config.props.shift.getCustomWording() ?? config.shiftCategoryName;
        const deleteSeries = deleteType === ShiftEditType.Series;
        config.confirmDeleteDialog.dispatch("open", {
            title: translations.get(deleteSeries ? "DeleteSeries" : "DeleteShift"),
            subtitle: translations.get(deleteSeries ? "AreYouSureToDeleteSeries" : "AreYouSureToDeleteShift", {name}),
            callback: async () => {
                const shift = config.props.shift!;
                await config.deleteShift({shiftId: shift.getId(), deleteSeries});
            }
        })();
    }
}) satisfies MagicReducerObject<State>;