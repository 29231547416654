import {memo, ReactElement} from "react";
import {Props} from "./DayNotesDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./DayNotesDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import "./DayNotesDialog.styles.scss";
import {translations} from "../../../translations";
import {useDayNotesForm} from "../../../hooks/forms/useDayNotesForm";
import moment from "moment";
import {Flex} from "@fluentui/react-northstar";

export const DayNotesDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    const form = useDayNotesForm(state.shopId, state.date, state.notes);

    const dateLabel = moment(state.date).toDate().toLocaleDateString(moment.locale(), {
        month: 'numeric',
        day: 'numeric',
    });

    return (
        <Dialog
            externalRef={dialogRef}
            width={350}
            onClose={dispatch("reset")}
            title={translations.get("NotesOfDay", {day: dateLabel})}
            content={form.renderForm()}
            footer={
                <Flex hAlign={"end"}>
                    {form.renderSaveButton(dialogRef.dispatch("close"))}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);