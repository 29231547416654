import React, {memo, ReactElement, useCallback, useEffect} from "react";
import {Props} from "./Container.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Container.reducer";
import {CompareModule} from "modules/Compare.module";
import "./Container.styles.scss";
import {useDrop} from "react-dnd";
import {Flex, Loader} from "@fluentui/react-northstar";
import {Card} from "../Card/Card";
import {useShopGroupsCache} from "hooks/cache/groups/useShopGroupsCache";

export const Container = memo((props: Props): ReactElement | null => {
    const {groups, groupsIds, isLoading} = useShopGroupsCache(props.shopId);
    const [state, dispatch] = useMagicReducer(reducer({props}), initialState, props.externalRef);

    useEffect(() => {
        if (!groups || isLoading) return;
        const ids = groups.sort((a, b) => a.index - b.index).map(g => g.id);
        dispatch("updateOrder", ids)();
    }, [groupsIds, isLoading]);

    const findGroupIndex = useCallback((id: string): number => {
        return state.groupsOrder.indexOf(id);
    }, [state.groupsOrder]);

    const moveGroup = useCallback((id: string, atIndex: number | undefined) => {
        const groupIndex = findGroupIndex(id);
        if (groupIndex === -1 || atIndex === undefined) return;
        const newOrder = [...state.groupsOrder];
        newOrder.splice(groupIndex, 1);
        newOrder.splice(atIndex, 0, id);
        dispatch("updateOrder", newOrder)();
    }, [findGroupIndex]);

    const [, drop] = useDrop(() => ({
        accept: "group-card",
        drop: dispatch("notifyParent"),
    }), []);

    if (!groups) return (
        <Flex fill vAlign={"center"} hAlign={"center"} styles={{minHeight: "250px"}}>
            <Loader/>
        </Flex>
    )

    return (
        <Flex fill className={"overflow-scroll"} column ref={drop}
              styles={{padding: "20px 25px 8px 25px", gap: "10px"}}>
            {state.groupsOrder.map((groupId) => (
                <Card
                    key={groupId}
                    groupId={groupId}
                    moveGroup={moveGroup}
                    findGroupIndex={findGroupIndex}
                    highlight={props.groupId === groupId}
                    disabled={props.disabled}
                />
            ))}
        </Flex>
    )
}, CompareModule.areObjectsEqual);