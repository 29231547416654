import {AxiosResponse} from "axios";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {
    CustomShiftThresholdsData,
    CustomUserLegalRules,
    GroupData,
    GroupLegalRules,
    ShiftThresholdsData
} from "interfaces/GroupData";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";
import {ObjectModule} from "../../modules/Object.module";

const create = async (group: Immutable<GroupData>): Promise<GroupData | undefined> => {
    try {
        const response: AxiosResponse<GroupData> = await AxiosConfig.instance.post("/staffgroup", group);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't create group", error);
        return undefined;
    }
}

const getById = async (id: string | undefined) => {
    if (!id) return;
    try {
        const response: AxiosResponse<Immutable<GroupData>> = await AxiosConfig.instance.get("/staffgroup/" + id);
        return ObjectModule.clearNullValues(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get group by id " + id, error);
        return undefined;
    }
}

const assignStaffs = async (groupId: string, staffIds: Array<string>): Promise<boolean> => {
    try {
        if (staffIds.length === 0) return true;
        const tasks = staffIds.map(id => AxiosConfig.instance.get(`/staffgroup/${groupId}/assign/${id}`));
        await Promise.all(tasks);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't assign staff to group", error);
        return false;
    }
}

const removeStaff = async (groupId: string, staffId: string): Promise<boolean> => {
    try {
        await AxiosConfig.instance.get(`/staffgroup/${groupId}/dismiss/${staffId}`);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert(`Can't remove staff with id ${staffId} from group ${groupId}`, error);
        return false;
    }
}

const updateLegalRules = async (groupId: string, legalRules: Partial<GroupLegalRules>): Promise<boolean> => {
    try {
        const eligibleFieldsForUpdate: Array<keyof GroupLegalRules> = [
            "maximumDailyWorkingHours",
            "maximumWeeklyWorkingHours",
            "maximumConsecutiveWorkingDays",
            "minimalBreakHoursBetweenShifts",
            "badge"
        ];
        const updatedFieldsArray = Object.entries(legalRules)
            .filter(([field]) => eligibleFieldsForUpdate.includes(field as keyof GroupLegalRules))
            .map(([field, value]) => ({field, value: value + ""}));
        if (updatedFieldsArray.length === 0) return true;
        await AxiosConfig.instance.post(`/staffgroup/${groupId}/legalrules`, updatedFieldsArray);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't update group legal rules", error);
        return false;
    }
}

const updateIndex = async (groupId: string, index: number): Promise<boolean> => {
    try {
        await AxiosConfig.instance.put(`/staffgroup/${groupId}/index/${index}`);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert(`Can't update index of group ${groupId}`, error);
        return false;
    }
}

const upsertShiftThresholds = async (groupId: string, shiftThresholds: Immutable<ShiftThresholdsData>): Promise<boolean> => {
    try {
        await AxiosConfig.instance.post(`/staffgroup/${groupId}/thresholds/shifts`, shiftThresholds);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert(`Can't update group shift thresholds`, error);
        return false;
    }
}

const createCustomLegalRules = async (groupId: string, rules: Immutable<CustomUserLegalRules>): Promise<boolean> => {
    try {
        await AxiosConfig.instance.post(`/staffgroup/${groupId}/legal-rules/custom`, rules);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert(`Can't create group custom legal rules`, error);
        return false;
    }
}

const updateCustomLegalRules = async (groupId: string, userId: string, fields: Partial<CustomUserLegalRules>): Promise<boolean> => {
    try {
        const updatedFieldsArray = ObjectModule.mapUpdatedFields(fields);
        if (updatedFieldsArray.length === 0) return true;
        await AxiosConfig.instance.put(`/staffgroup/${groupId}/legal-rules/custom/${userId}`, updatedFieldsArray);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert(`Can't update group custom legal rules`, error);
        return false;
    }
}

const deleteCustomLegalRules = async (groupId: string, userId: string): Promise<boolean> => {
    try {
        await AxiosConfig.instance.delete(`/staffgroup/${groupId}/legal-rules/custom/${userId}`);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert(`Can't delete group custom legal rules`, error);
        return false;
    }
}

const createCustomThresholds = async (groupId: string, thresholds: Immutable<CustomShiftThresholdsData>): Promise<string> => {
    try {
        const response = await AxiosConfig.instance.post(`/staffgroup/${groupId}/thresholds/custom`, thresholds);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert(`Can't create group custom thresholds`, error);
        return "";
    }
}

const updateCustomThresholds = async (groupId: string, thresholdsId: string, fields: Partial<Immutable<CustomShiftThresholdsData>>): Promise<boolean> => {
    try {
        const updatedFieldsArray = ObjectModule.mapUpdatedFields(fields);
        if (updatedFieldsArray.length === 0) return true;
        await AxiosConfig.instance.put(`/staffgroup/${groupId}/thresholds/custom/${thresholdsId}`, updatedFieldsArray);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert(`Can't update group custom thresholds`, error);
        return false;
    }
}

const deleteCustomThresholds = async (groupId: string, thresholdsId: string): Promise<boolean> => {
    try {
        await AxiosConfig.instance.delete(`/staffgroup/${groupId}/thresholds/custom/${thresholdsId}`);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert(`Can't delete group custom thresholds`, error);
        return false;
    }
}

const rename = async (groupId: string, name: string): Promise<boolean> => {
    try {
        await AxiosConfig.instance.put(`/staffgroup/${groupId}/rename`, name);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert(`Can't rename group ${groupId}`, error);
        return false;
    }
}

const remove = async (groupId: string): Promise<boolean> => {
    try {
        await AxiosConfig.instance.delete(`/staffgroup/${groupId}`);
        return true;
    } catch (error) {
        ErrorModule.showErrorAlert(`Can't delete group ${groupId}`, error);
        return false;
    }
}

export const GroupApi = {
    getById,
    assignStaffs,
    updateLegalRules,
    create,
    removeStaff,
    updateIndex,
    upsertShiftThresholds,
    createCustomLegalRules,
    updateCustomLegalRules,
    deleteCustomLegalRules,
    createCustomThresholds,
    updateCustomThresholds,
    deleteCustomThresholds,
    rename,
    remove,
}