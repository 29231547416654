import React, {ReactElement, useEffect} from "react";
import {Props, RequestsListData, State} from "./Requests.interfaces";
import {Immutable, MagicDispatch, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Requests.reducer";
import "./Requests.styles.scss";
import {Flex, Text} from "@fluentui/react-northstar";
import {Toolbar} from "./Toolbar/Toolbar";
import {translations} from "../../translations";
import {ArrowSyncCircleIcon, CheckmarkCircleIcon} from "../../assets/icons";
import {RequestCard} from "../../components/others/RequestCard/RequestCard";
import {RequestData, RequestType} from "interfaces/RequestData";
import {TypeModule} from "../../modules/Type.module";
import {RequestPreview} from "../../components/others/RequestPreview/RequestPreview";
import moment from "moment";
import {useUserShopCache} from "../../hooks/cache/useShopsCache";
import {useRequestsCache} from "../../hooks/cache/useRequestsCache";
import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {RequestPreviewSideView} from "./RequestPreviewSideView/RequestPreviewSideView";
import {MobileAddButton} from "../../components/buttons/MobileAddButton/MobileAddButton";
import {AddRequestSideView} from "./AddRequestSideView/AddRequestSideView";
import {useDeeplinkContext} from "../../hooks/useDeeplinkContext";
import {DeeplinkContextType} from "../../interfaces/DeeplinkContext";
import {useUserRolesCache} from "../../hooks/cache/useUserRoleCache";

export const Requests = (props: Props): ReactElement => {
    const {isOnMobile} = useMsTeamsSelector("isOnMobile");
    const requestPreviewSideViewRef = useMagicReducerRef(RequestPreviewSideView);
    const addRequestSideViewRef = useMagicReducerRef(AddRequestSideView);
    const {requests} = useRequestsCache();
    const {isLoading: isUserShopLoading} = useUserShopCache();
    const {isLoading: isUserRoleLoading} = useUserRolesCache();
    const {deeplinkContext, clearDeeplinkContext} = useDeeplinkContext();
    const [state, dispatch] = useMagicReducer(reducer({isOnMobile, requestPreviewSideViewRef}), initialState);

    const isLoading = isUserShopLoading || isUserRoleLoading;

    useEffect(function onMount() {
        if (isLoading || !requests || !deeplinkContext || deeplinkContext.type !== DeeplinkContextType.Request) return;
        const request = requests.find(r => r.id === deeplinkContext.id);
        dispatch("selectRequest", request)();
        clearDeeplinkContext();
    }, [deeplinkContext, requests, isLoading]);

    const className = [
        "requests-view",
        "fade",
        isOnMobile && "requests-view-mobile"
    ].filter(Boolean).join(" ");

    return (
        <Flex fill column className={className}>
            {!isOnMobile && <Toolbar/>}
            <Flex fill className={"overflow-hidden"}>
                <Flex column className={"requests-list"}>
                    {TypeModule.typedKeys(requestsLists).map(i => renderRequestsList(state, dispatch, i, isLoading ? undefined : requests))}
                </Flex>
                {isOnMobile ?
                    <RequestPreviewSideView
                        externalRef={requestPreviewSideViewRef}
                        onClose={dispatch("selectRequest")}
                    />
                    :
                    <Flex fill className={"request-preview"} hAlign={"center"}>
                        <RequestPreview requestId={state.selectedRequest?.id}/>
                    </Flex>
                }
            </Flex>
            {isOnMobile && <>
                <MobileAddButton onClick={addRequestSideViewRef.dispatch("open")}/>
                <AddRequestSideView externalRef={addRequestSideViewRef}/>
            </>
            }
        </Flex>
    )
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

export const requestsLists = {
    inProgress: {
        titleId: "InProgress",
        icon: <ArrowSyncCircleIcon color={"#FF9040"}/>,
        filterRequests: list => list.filter(r => !isRequestDone(r)).sort((a, b) => moment(a.date).isAfter(b.date) ? -1 : 1),
    },
    done: {
        titleId: "Done",
        icon: <CheckmarkCircleIcon color={"#82D152"}/>,
        filterRequests: list => list.filter(r => isRequestDone(r)).sort((a, b) => moment(a.date).isAfter(b.date) ? -1 : 1),
    }
} satisfies Record<string, RequestsListData>;

const isRequestDone = (request: Immutable<RequestData> | undefined) => {
    if (!request) return false;
    if (request.response) return true;
    if (request.type === RequestType.Swap && request.toUserResponse === false) return true;
    return false;
}

export const renderRequestsList = (
    state: State,
    dispatch: MagicDispatch<typeof reducer>,
    listType: keyof typeof requestsLists,
    items: Immutable<Array<RequestData>> | undefined,
) => {
    const listData = requestsLists[listType];
    const filteredItems = !items ? Array.from({length: 5}).map(() => undefined) : listData.filterRequests(items);
    return (
        <Flex key={listType} column gap={"gap.small"}>
            <Flex vAlign={"center"} styles={{gap: "5px"}}>
                {listData.icon}
                <Text size={"large"} weight={"semibold"} content={translations.get(listData.titleId)}/>
            </Flex>
            <Flex column gap={"gap.small"}>
                {filteredItems.length === 0 ?
                    <Text content={translations.get("NoRequest")} style={{color: "darkgray"}}/>
                    :
                    filteredItems.map((r, i) => (
                        <RequestCard
                            key={r?.id ?? "skeleton" + i}
                            request={r}
                            primary={state.selectedRequest?.id === r?.id}
                            onClick={dispatch("selectRequest", r)}
                        />
                    ))
                }
            </Flex>
        </Flex>
    )
}