import * as microsoftTeams from "@microsoft/teams-js";
import {FrameContexts, HostClientType, UserTeamRole} from "@microsoft/teams-js";
import moment from "moment";
import 'moment/locale/fr';
import {teamsDarkTheme, teamsHighContrastTheme, teamsTheme} from "@fluentui/react-northstar";
import {translations} from "translations";
import {TokenApi} from "apis/Token/TokenApi";
import {TokenResult} from "interfaces/TokenResult";
import {ErrorModule} from "components/others/ErrorBoundary/ErrorBoundary";
import {
    MicrosoftTeamsReducerState,
    ThemeClass
} from "redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer.interfaces";

export const initializeFromTeams = async (accessToken: TokenResult | undefined) => {
    const context = await microsoftTeams.app.getContext();
    const locale = context.app.locale;
    moment.locale(locale);
    const stateUpdate: Partial<MicrosoftTeamsReducerState> = {
        loaded: !!accessToken,
        locale: locale.substring(0, 2).toLowerCase(),
        fullLocale: locale,
        groupId: context.team?.groupId as string,
        channelId: context.channel?.id as string,
        hostClientType: context.app.host.clientType,
        teamId: context.team?.internalId as string,
        teamName: context.team?.displayName as string,
        tenantId: context.user?.tenant?.id as string,
        channelName: context.channel?.displayName as string,
        userMail: (context.user?.loginHint as string).toLowerCase(),
        userId: context.user?.id as string,
        isOnMobile: [HostClientType.ios, HostClientType.android, HostClientType.ipados]
            .includes(context.app.host.clientType),
        isInPersonalApp: context.page.id === "personnal",
        subEntityId: context.page.subPageId as string,
        isConfiguringApp: context.page.frameContext === FrameContexts.settings,
        isDeletingApp: context.page.frameContext === FrameContexts.remove,
        isInTaskModule: context.page.frameContext === microsoftTeams.FrameContexts.task,
        userName: !accessToken ? "" : await getUserName(accessToken),
        meetingId: context.meeting?.id ?? "",
        isInMeeting: !!context.meeting?.id,
        isInMeetingSidePanel: context.page.frameContext === FrameContexts.sidePanel,
        userRole: context.team?.userRole ?? UserTeamRole.User,
        isTeamsIframe: true,
    }
    translations.locale = stateUpdate.locale ?? "en";
    if (stateUpdate.isOnMobile) window.document.body.className = "on-mobile";
    stateUpdate.isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    if (stateUpdate.isConfiguringApp) stateUpdate.entityId = await getEntityId();
    if (accessToken && !stateUpdate.isInTaskModule) await TokenApi.saveToken();
    return stateUpdate;
}

export const initializeOutsideOfTeams = async (accessToken: TokenResult | undefined): Promise<Partial<MicrosoftTeamsReducerState>> => {
    const fullLocale = window.navigator.language;
    const locale = fullLocale.substring(0, 2).toLowerCase();
    translations.locale = locale;
    moment.locale(fullLocale);
    const parentUrl = (window.location != window.parent.location) ? window.document.referrer : window.document.location.href;
    const isTeamsIframe = parentUrl.includes("teams.microsoft.com") || parentUrl.includes("teams.cloud.microsoft");
    if (accessToken) await TokenApi.saveToken();
    return {
        loaded: !!accessToken,
        isTeamsIframe,
        tenantId: accessToken?.tenantId ?? "",
        userId: accessToken?.userId ?? "",
        userMail: accessToken?.userMail?.toLowerCase() ?? "",
        userName: accessToken?.userName ?? "",
        fullLocale,
        locale,
    };
}

const getEntityId = async () => {
    const settings = await microsoftTeams.pages.getConfig();
    return settings.entityId ?? "";
}

const getUserName = async (accessToken: TokenResult) => {
    if (!accessToken.token) {
        console.error("Can't get user name");
        return "";
    }
    const tokenData = parseJWTToken(accessToken.token);
    return tokenData.name;
};

const parseJWTToken = (token: string) => {
    const base64Url = token.split('.')[1];
    if (!base64Url) return ErrorModule.showErrorAlert("Token base64 url is undefined");
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

export const getCurrentTheme = (themeClass: ThemeClass) => {
    let currentTheme = teamsTheme;
    switch (themeClass) {
        case ThemeClass.Dark:
            currentTheme = teamsDarkTheme;
            break;
        case ThemeClass.HighContrast:
            currentTheme = teamsHighContrastTheme;
            break;
    }
    return currentTheme;
}