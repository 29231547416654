import {Props, State} from "./Dialog.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import React from "react";

export const initialState: State = {
    initialized: false,
    isOpen: false,
    hideBlurMask: true,
    transitionTimeout: undefined,
    mouseDownTargetClassName: undefined,
}

export const reducer = (props: Props) => ({
    open: ({setState}) => {
        setState({isOpen: true, hideBlurMask: false});
    },
    maskMouseDown: ({setState}, [e]: [React.SyntheticEvent]) => {
        const className = ((e.target as HTMLElement).className ?? "") + "";
        setState({mouseDownTargetClassName: className}, false);
    },
    maskMouseUp: (reducerData) => {
        const {state} = reducerData;
        if (!state.mouseDownTargetClassName?.includes("dialog-mask")) return;
        reducer(props).close(reducerData);
    },
    close: async ({state, setState}, _?: any, callback?: () => void) => {
        const close = () => {
            if (state.transitionTimeout) {
                clearTimeout(state.transitionTimeout);
                callback?.();
            }
            const transitionTimeout = setTimeout(() => {
                setState({hideBlurMask: true});
                callback?.();
            }, getTransitionDelay(props));
            setState({isOpen: false, transitionTimeout});
        }
        if (!props.onBeforeClose) return close();
        props.onBeforeClose(canClose => {
            if (!canClose) return;
            close();
        });
    },
    initialize: ({state, setState}) => {
        if (state.initialized) return;
        setState({initialized: true}, false);
    },
}) satisfies MagicReducerObject<State>;

const getTransitionDelay = (props: Props) => {
    if (props.popup) return 250;
    return 150;
}