import React, {ReactElement, useReducer} from "react";
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import TelemetryService from "services/TelemetryService/TelemetryService";
import {AppView} from "interfaces/AppView/AppView"
import {CallControlPresentNewIcon, Flex, Provider, Text, ThemeInput} from "@fluentui/react-northstar";
import {useAppInitializer} from "hooks/useAppInitializer";
import {wrapWithErrorBoundary} from "components/others/ErrorBoundary/ErrorBoundary";
import {Consent} from "views/Consent/Consent";
import {AppViewData} from "interfaces/AppView/AppViewData";
import {useMsTeamsSelector} from "redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {translations} from "./translations";
import {LoadingPage} from "./views/LoadingPage/LoadingPage";
import {AppViews} from "./const/AppViews";
import {Configuration} from "./views/Configuration/Configuration";
import {DialogProvider} from "./services/DialogContext/DialogContext";

export const App = () => {
    const [renderKey] = useReducer(() => Math.random(), Math.random());
    const {loaded} = useAppInitializer();
    const {
        locale,
        theme,
        isConfiguringApp,
        isInMeetingSidePanel
    } = useMsTeamsSelector("locale", "theme", "isConfiguringApp", "isInMeetingSidePanel");

    const wrapWithFluentUI = getFluentUIWrapper(locale, theme);

    if (isConfiguringApp) return wrapWithFluentUI(<Configuration/>);

    if (isInMeetingSidePanel) return wrapWithFluentUI(renderMeetingSidePanelMessage());

    if (!loaded) return wrapWithFluentUI(<Consent/>);

    return wrapWithFluentUI(
        <BrowserRouter>
            <TelemetryService>
                {loaded ?
                    <DialogProvider>
                        <Flex fill column className={"overflow-hidden no-select"}>
                            <Routes>
                                {renderRoutes(AppViews, renderKey)}
                                <Route path="*" element={<Navigate to={AppViews[AppView.Schedules].path} replace/>}/>
                            </Routes>
                        </Flex>
                    </DialogProvider>
                    :
                    <LoadingPage/>
                }
            </TelemetryService>
        </BrowserRouter>
    );
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderRoutes = (appViews: Record<AppView, AppViewData>, renderKey: number) => {
    return Object.values(appViews).map(view => (
        <Route key={view.path} path={view.path} element={wrapWithErrorBoundary(<view.element renderKey={renderKey}/>)}/>
    ))
}

const getFluentUIWrapper = (locale: string, theme: ThemeInput) => (children: ReactElement) => (
    <Provider lang={locale} theme={theme}>
        {children}
    </Provider>
)

const renderMeetingSidePanelMessage = () => (
    <Text>
        {translations.get("MeetingSidePanelMessage")}
        <CallControlPresentNewIcon size={"large"} styles={{marginLeft: "5px"}} outline/>
    </Text>
)