import {FormItem, useForm} from "@witivio_teamspro/northstar-form";
import {useLayoutEffect, useMemo} from "react";
import {translations} from "translations";
import {GroupLegalRules} from "interfaces/GroupData";
import {useGroupCache} from "../cache/groups/useGroupCache";
import {useSettingsCache} from "../cache/useSettingsCache";
import {useShopCache} from "../cache/useShopsCache";

export const useGroupLegalRulesForm = (groupId: string) => {
    const {group} = useGroupCache(groupId);
    const {shop} = useShopCache(group?.shopId);
    const {settings} = useSettingsCache();

    useLayoutEffect(function onGroupChange() {
        if (!group || !settings || !shop) return;
        const shopSettings = settings?.shopsSettings?.find(ss => ss.type === shop?.type);
        groupLegalRulesForm.setFieldsInitialValues({
            ...group.legalRules,
            minimalBreakHoursBetweenShifts: settings?.minimalBreakHoursBetweenShifts,
            maximumConsecutiveWorkingDays: settings?.maximumConsecutiveWorkingDays,
            maximumDailyWorkingHours: shopSettings?.maximumDailyWorkingHours,
        });
        groupLegalRulesForm.reset();
    }, [group, settings, shop]);

    const items: Record<keyof Omit<GroupLegalRules, "customRules">, FormItem> = useMemo(() => ({
        maximumConsecutiveWorkingDays: {
            type: "input",
            inputMode: "decimal",
            min: 0,
            max: 365,
            required: true,
            label: translations.get("MaximumConsecutiveWorkingDays"),
            initialValue: 7,
            disabled: true,
        },
        minimalBreakHoursBetweenShifts: {
            type: "input",
            inputMode: "decimal",
            min: 0,
            max: 24,
            required: true,
            label: translations.get("MinimalBreakHoursBetweenShifts"),
            initialValue: 11,
            disabled: true,
        },
        maximumDailyWorkingHours: {
            type: "input",
            inputMode: "decimal",
            min: 0,
            max: 24,
            required: true,
            label: translations.get("MaximumDailyWorkingHours"),
            initialValue: 8,
            disabled: true,
        },
        maximumWeeklyWorkingHours: {
            type: "input",
            inputMode: "decimal",
            min: 0,
            max: 70,
            required: true,
            label: translations.get("MaximumWeeklyWorkingHours"),
        },
        badge: {
            type: "checkbox",
            toggle: true,
            label: translations.get("Badge"),
        }
    }), []);

    const groupLegalRulesForm = useForm({items});

    return groupLegalRulesForm;
}