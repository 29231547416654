import {memo, ReactElement, useMemo} from "react";
import {Props} from "./ActivitiesList.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ActivitiesList.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ActivitiesList.styles.scss";
import {AddIcon, Button, Flex, Text} from "@fluentui/react-northstar";
import {translations} from "translations";
import {useDialogContext} from "services/DialogContext/DialogContext";
import {ActivityItem} from "./ActivityItem/ActivityItem";

export const ActivitiesList = memo((props: Props): ReactElement | null => {
    const {shiftActivityDialog} = useDialogContext();
    const [state, dispatch] = useMagicReducer(reducer({shiftActivityDialog, props}), initialState);

    const addButton = useMemo(() => props.readOnly ? null : (
        <Flex styles={{marginLeft: "-8px"}}>
            <Button
                text primary
                content={translations.get("AddActivity")}
                icon={<AddIcon/>}
                onClick={dispatch("openShiftActivityDialog")}
                disabled={props.disabled ?? false}
            />
        </Flex>
    ), [props.shopId, props.onUpdateActivity, props.disabled, props.readOnly]);

    if (props.readOnly && !props.items.length) return null;

    return (
        <Flex column>
            <Text content={translations.get("ShiftActivities")}/>
            {props.items.map((activity, index) => (
                <ActivityItem
                    key={activity.key}
                    shopId={props.shopId}
                    activity={activity}
                    onDelete={props.onDelete}
                    onClick={(props.disabled || props.readOnly) ? undefined : dispatch("openShiftActivityDialog", activity)}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    showDivider={index < props.items.length - 1}
                />
            ))}
            {addButton}
        </Flex>
    )
}, CompareModule.areObjectsEqual);