import {Props, State} from "./SideView.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";

const openAnimationDelay = 400;
const closeAnimationDelay = 250;

export const initialState: State = {
    open: false,
    blockClick: false,
    isAnimating: false,
}

export const reducer = (config: {
    props: Props,
}) => ({
    open: ({state, setState}) => {
        blurFocusedElement();
        if (state.isAnimating) return;
        config.props.onOpen?.();
        setState({isAnimating: true, blockClick: true, open: true});
        setTimeout(() => {
            setState({isAnimating: false, blockClick: false});
        }, openAnimationDelay);
    },
    close: ({state, setState}) => {
        blurFocusedElement();
        if (state.isAnimating) return;
        setState({isAnimating: true, blockClick: true, open: false});
        setTimeout(() => {
            setState({isAnimating: false, blockClick: false});
        }, closeAnimationDelay);
        config.props.onClose?.();
    }
}) satisfies MagicReducerObject<State>;

const blurFocusedElement = () => {
    (window.document.activeElement as HTMLInputElement | null)?.blur?.();
}