import {Props, State} from "./ShiftStaffs.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {ShiftCardFormat} from "../ShiftCard/ShiftCard.interfaces";
import {startTransition} from "react";

export const initialState = (daysCount: number): State => ({
    cardsFormat: getShiftsCardsFormat(daysCount),
    showSkeletons: false,
    container: null,
    visible: true,
});

export const reducer = (config: {
    isVisible: Props["isVisible"],
    daysCount: number
}) => ({
    updateCardsFormat: ({state, setState}) => {
        setState({showSkeletons: true});
        const cardsFormat = getShiftsCardsFormat(config.daysCount);
        if (state.cardsFormat === cardsFormat) return setState({showSkeletons: false});
        startTransition(() => setState({cardsFormat, showSkeletons: false}));
    },
    hideSkeletons: ({setState}) => {
        setState({showSkeletons: false});
    },
    containerRef: ({state, setState}, [ref]: [HTMLDivElement | null]) => {
        setState({container: ref}, false);
        if (!ref || !config.isVisible) return;
        const isVisible = config.isVisible?.(ref);
        if (state.visible === isVisible) return;
        startTransition(() => setState({visible: isVisible}));
    }
}) satisfies MagicReducerObject<State>;

const getShiftsCardsFormat = (daysCount: number) => {
    const cardPadding = 5;
    const cardWidth = Math.floor((window.innerWidth / daysCount) - (cardPadding * daysCount));
    if (cardWidth <= 60) return ShiftCardFormat.Mini;
    if (cardWidth <= 130) return ShiftCardFormat.Small;
    return ShiftCardFormat.Classic;
}