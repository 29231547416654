import {State} from "./Badge.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import moment from "moment";
import {CryptoModule} from "../../modules/Crypto.module";

export const initialState: State = {
    value: "",
}

export const reducer = {
    generateValue: ({setState}, _, shopId: string | undefined) => {
        if (!shopId) return;
        setState({value: generateEncryptedValue(shopId)});
    }
} satisfies MagicReducerObject<State>;

const generateEncryptedValue = (shopId: string | undefined): string => {
    if (!shopId) return "";
    const today = moment().startOf("day").toISOString(false).split("T")[0];
    const value = `${today}.${shopId}`;
    const encryptResult = CryptoModule.encryptValue(value, process.env.REACT_APP_BADGE_SECRET_KEY);
    if (!encryptResult) return "";
    return `${encryptResult.iv}_${encryptResult.hexValue}`;
}