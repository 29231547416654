import {ITableColumn} from "../../Table/Table.interfaces";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {Flex, Skeleton, Text} from "@fluentui/react-northstar";
import React from "react";
import {ShopShiftActivityWithShopId} from "../ActivitiesTable.interfaces";

export const useActivitiesTableColumns = (
    shopId: string | undefined,
    handleEditActivity: (activity: Immutable<ShopShiftActivityWithShopId> | null) => void
): Array<ITableColumn<Immutable<ShopShiftActivityWithShopId> | null>> => {
    return [
        generateNameColumn(shopId, handleEditActivity),
    ];
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const generateNameColumn = (
    shopId: string | undefined,
    handleEditActivity: (activity: Immutable<ShopShiftActivityWithShopId> | null) => void
): ITableColumn<Immutable<ShopShiftActivityWithShopId> | null> => ({
    field: "name",
    label: "Name",
    render: item => {
        const className = [
            "overflow-hidden",
            item?.shopId !== shopId && "cursor-disabled",
        ].filter(Boolean).join(" ");
        return (
            <Flex fill className={className} title={item?.name} vAlign={"center"}>
                <Text truncated content={item?.name}/>
            </Flex>
        )
    },
    sort: (a, b) => {
        return (a?.name ?? "").localeCompare(b?.name ?? "") ?? 0;
    },
    minWidth: "100px",
    maxWidth: "100%",
    skeleton: (
        <Skeleton animation="pulse" className={"overflow-hidden"}>
            <Flex styles={{marginLeft: "5px"}} fill vAlign={"center"} gap={"gap.small"}>
                <Skeleton.Text width="100%"/>
            </Flex>
        </Skeleton>
    ),
    onClick: handleEditActivity,
});