import React, {memo, ReactElement} from "react";
import {Props} from "./ResultStep.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ResultStep.reducer";
import {CompareModule} from "modules/Compare.module";
import {ReactComponent as ResultPlanningImage} from "assets/images/ResultPlanning.svg";
import {ReactComponent as ErrorPlanningImage} from "assets/images/ErrorPlanning.svg";
import "./ResultStep.styles.scss";
import {useMsTeamsSelector} from "../../../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {Flex, Image, Text} from "@fluentui/react-northstar";
import {translations} from "../../../../../translations";

export const ResultStep = memo((props: Props): ReactElement | null => {
    const {isLightTheme} = useMsTeamsSelector("isLightTheme");
    const [] = useMagicReducer(reducer, initialState);

    return (
        <Flex fill column vAlign={"center"} hAlign={"center"} gap={"gap.medium"}>
            {renderImage(isLightTheme, props.isSuccess)}
            <Flex column hAlign={"center"} styles={{gap: "5px", marginTop: "-10px"}}>
                <Text
                    size={"large"} weight={"bold"}
                    content={translations.get(props.isSuccess ? "PlanningImportedWithSuccess" : "AnErrorOccured")}
                />
                <Text content={translations.get(props.isSuccess ? "YourPlanningHasBeenUpdated" : "PleaseTryAgain")}/>
            </Flex>
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderImage = (isLightTheme: boolean, isSuccess: boolean) => {
    const Image = isSuccess ? ResultPlanningImage : ErrorPlanningImage;
    return <Image color={isLightTheme ? "#F5F5F5" : "#565656"} height={250} width={250} style={{marginTop: "-20px"}}/>
}