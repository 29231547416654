import {Props, State} from "./SwapRequestPreview.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {useRequestsCache} from "../../../../hooks/cache/useRequestsCache";

export const initialState: State = {
    isApproving: false,
    isDenying: false,
}

export const reducer = (config: {
    props: Props,
    approveSwapRequest: ReturnType<typeof useRequestsCache>["approveSwapRequest"],
}) => ({
    approveRequest: async ({setState}, _, approbation: boolean) => {
        if (!config.props.requestId) return;
        setState({isApproving: approbation, isDenying: !approbation});
        await config.approveSwapRequest(config.props.requestId, approbation);
        setState({isApproving: false, isDenying: false});
    },
}) satisfies MagicReducerObject<State>;