import React, {memo, ReactElement} from "react";
import {Props} from "./ShopReportDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShopReportDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Flex} from "@fluentui/react-northstar";
import "./ShopReportDialog.styles.scss";
import {DateRangeSelector} from "../../others/DateRangeSelector/DateRangeSelector";
import {usePlanningDateRangeCache} from "../../../hooks/cache/usePlanningDateRangeCache";
import {Kpi} from "../../others/Kpi/Kpi";
import {translations} from "../../../translations";
import {useShopReport} from "../../../hooks/reports/useShopReport";
import {ShopIcon} from "../../../assets/icons";
import {useShopCache} from "../../../hooks/cache/useShopsCache";

export const ShopReportDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const dateRangeRef = useMagicReducerRef(DateRangeSelector);
    const {planningDateRange} = usePlanningDateRangeCache();
    const [state, dispatch, render] = useMagicReducer(reducer({
        dialogRef, planningDateRange, dateRangeRef
    }), initialState, props.externalRef);
    const startDate = dateRangeRef.state?.selectedRange.startDate;
    const endDate = dateRangeRef.state?.selectedRange.endDate;
    const {shopReportItems, isLoading} = useShopReport(state.shopId, startDate, endDate);
    const {shop} = useShopCache(state.shopId);

    const kpis: Array<ReactElement> = Object.entries(shopReportItems).map(([key, value]) => (
        <Kpi key={key} {...value} showSkeletons={isLoading}/>
    ));

    return (
        <Dialog
            externalRef={dialogRef}
            width={900}
            headerStyles={{paddingBottom: "10px"}}
            onOpen={dispatch("setDateRangeDate")}
            headerElement={
                <DateRangeSelector
                    externalRef={dateRangeRef}
                    className={"planning-date-range"}
                    onDateRangeChange={render}
                />
            }
            icon={ShopIcon}
            title={shop?.name + " - " + translations.get("Report")}
            content={
                <Flex fill column styles={{gap: "15px"}}>
                    <Flex fill className={"kpis-grid"}>
                        {kpis.slice(0, 2)}
                    </Flex>
                    <Flex fill className={"small-kpis-grid"}>
                        {kpis.slice(2)}
                    </Flex>
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);