import React, {memo, ReactElement} from "react";
import {Props} from "./ActivityItem.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ActivityItem.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ActivityItem.styles.scss";
import {Button, Flex, Text, TrashCanIcon} from "@fluentui/react-northstar";
import {useShopCache} from "hooks/cache/useShopsCache";
import {TimeModule} from "modules/Time.module";

export const ActivityItem = memo((props: Props): ReactElement | null => {
    const {shop} = useShopCache(props.shopId);
    const [state, dispatch] = useMagicReducer(reducer(props), initialState);

    const {key, start, end} = props.activity;

    const activityName = shop?.activities.find(a => a.key === key)?.name;

    const className = [
        "activity-item",
        props.showDivider && "activity-item-divider",
    ].filter(Boolean).join(" ");

    return (
        <Flex className={className} vAlign={"center"} onClick={props.onClick}>
            <Flex className={"no-shrink"}>
                <Text styles={{color: "darkgray"}} content={TimeModule.getTimeRangeLabel(start, end)}/>
            </Flex>
            <Flex fill vAlign={"center"}>
                <Text content={activityName} truncated/>
            </Flex>
            {props.readOnly ? null : (
                <Button
                    text iconOnly
                    icon={<TrashCanIcon styles={{color: "red"}}/>}
                    onClick={dispatch("delete")}
                    disabled={props.disabled ?? false}
                />
            )}
        </Flex>
    )
}, CompareModule.areObjectsEqual);