import {translations} from "../translations";

const getHolidayIdLabel = (id: string | number | undefined) => {
    const holidays = [
        {id: "fr", label: translations.get("French")},
    ];
    return holidays.find(h => h.id === id)?.label ?? "";
}

export const ShopModule = {
    getHolidayIdLabel,
}