import {translations} from "../../translations";
import {CSSProperties, useMemo} from "react";
import {KpiPropsUnion} from "../../components/others/Kpi/Kpi.interfaces";
import {useShopReportCache} from "../cache/useShopReportCache";
import {TimeModule} from "../../modules/Time.module";

const numberKpiHeight = "140px";

const numberKpisStyles: CSSProperties = {
    minHeight: numberKpiHeight,
    maxHeight: numberKpiHeight,
}

export const useShopReport = (shopId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    const {shopReport, isLoading} = useShopReportCache(shopId, startDate, endDate);

    const totalShiftsCount = useMemo(() => shopReport?.shiftsCount.reduce((acc, curr) => acc + curr.count, 0) ?? 0, [shopReport?.shiftsCount]);

    return {
        isLoading,
        shopReportItems: {
            absencesPerType: {
                title: translations.get("AbsencesPerType"),
                type: "pieChart",
                labels: shopReport?.absencesCount.map(a => a.name) ?? [],
                values: shopReport?.absencesCount.map(a => a.count) ?? [],
                colors: shopReport?.absencesCount.map(a => a.color) ?? [],
                labelPosition: "left",
                padding: {top: 10, bottom: 10, left: 0, right: 0},
                styles: {
                    minHeight: "250px",
                }
            },
            shifts: {
                title: translations.get("Shifts"),
                type: "pieChart",
                labels: shopReport?.shiftsCount.map(a => a.name) ?? [],
                values: shopReport?.shiftsCount.map(a => a.count) ?? [],
                colors: shopReport?.shiftsCount.map(a => a.color) ?? [],
                labelPosition: "left",
                padding: {top: 10, bottom: 10, left: 0, right: 0},
                formatLabel: (value: number) => {
                    const percentage = Number(((value / totalShiftsCount) * 100).toFixed(0));
                    if (percentage <= 20) return "";
                    return percentage + "%"
                },
                styles: {
                    minHeight: "250px",
                }
            },
            overtimeHours: {
                type: "number",
                subtitle: translations.get("OvertimeHoursSingularPlural"),
                value: TimeModule.getLabelFromDecimalHours(shopReport?.overtime ?? 0),
                color: "rgb(115, 193, 250)",
                styles: numberKpisStyles,
            },
            delays: {
                type: "number",
                subtitle: translations.get("DelaysSingularPlural"),
                value: shopReport?.delays,
                color: "orange",
                styles: numberKpisStyles,
            },
            unClocked: {
                type: "number",
                subtitle: translations.get("UnclockedShiftsSingularPlural"),
                value: shopReport?.unClocked,
                color: "red",
                styles: numberKpisStyles,
            },
        } satisfies Record<string, KpiPropsUnion>
    }
}