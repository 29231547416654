import {Props, State} from "./Container.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {GroupData} from "interfaces/GroupData";

export const initialState: State = {
    groupsOrder: [],
}

export const reducer = (config: {
    props: Props,
}) => ({
    updateOrder: ({setState}, _, groupsOrder: Array<string>) => {
        setState({groupsOrder});
    },
    notifyParent: ({state}) => {
        config.props.onOrderChange(state.groupsOrder);
    }
}) satisfies MagicReducerObject<State>;