import React, {memo, ReactElement, useEffect, useLayoutEffect, useMemo} from "react";
import {Props} from "./StaffShifts.interfaces";
import {Immutable, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./StaffShifts.reducer";
import {CompareModule} from "modules/Compare.module";
import "./StaffShifts.styles.scss";
import {Flex} from "@fluentui/react-northstar";
import {Cell} from "components/others/Cell/Cell";
import {ShiftCard} from "components/others/ShiftCard/ShiftCard";
import {useUserCache} from "hooks/cache/useUsersCache";
import {useUserShiftsCache} from "hooks/cache/useShiftsCache";
import moment from "moment";
import {WeekDaysModule} from "modules/WeekDays.module";
import {useGroupCache} from "../../../hooks/cache/groups/useGroupCache";
import {usePlanningDateRangeCache} from "../../../hooks/cache/usePlanningDateRangeCache";
import {Shift} from "../../../classes/Shift";
import {useUserRolesCache} from "../../../hooks/cache/useUserRoleCache";
import {ShiftType} from "../../../interfaces/ShiftData";
import {StaffCell} from "./StaffCell/StaffCell";

export const StaffShifts = memo((props: Props): ReactElement | null => {
    const {planningDateRange} = usePlanningDateRangeCache();
    const {canUpdateShop} = useUserRolesCache();
    const {selectedRange} = planningDateRange;
    const rangeDays = useMemo(() => WeekDaysModule.getRangeDays(selectedRange.startDate, selectedRange.endDate), [selectedRange.startDate, selectedRange.endDate]);
    const daysCount = rangeDays.length;
    const [state, dispatch] = useMagicReducer(reducer({
        daysCount,
        props,
    }), initialState(daysCount));
    const {user} = useUserCache(props.userId);
    const {
        shifts,
        isLoading,
        getTotalShiftsTime
    } = useUserShiftsCache(props.userId, selectedRange.startDate, selectedRange.endDate, state.visible);
    const {group, getUserMaximumWorkingHours} = useGroupCache(props.groupId);

    useEffect(function onResize() {
        const handler = dispatch("updateCardsFormat");
        window.addEventListener("resize", handler);
        return () => window.removeEventListener("resize", handler);
    }, []);

    useLayoutEffect(function onDaysCountChange() {
        dispatch("updateCardsFormat")();
    }, [daysCount]);

    const readOnly = !canUpdateShop(group?.shopId) || props.readOnly;

    const totalShiftsTime = useMemo(() => getTotalShiftsTime(), [shifts]);

    const maximumWorkingHours = useMemo(() => {
        return getUserMaximumWorkingHours(user?.id, rangeDays.length);
    }, [group?.legalRules, user?.id, rangeDays.length]);

    const showSkeletons = state.showSkeletons || isLoading || !state.visible;

    const shiftsGroupedByDays = useMemo(() => {
        const items: Record<string, Array<Immutable<Shift> | undefined>> = {};
        if (!rangeDays.length) return items;
        rangeDays.forEach(day => {
            const isoDate = day.toISOString(false);
            if (showSkeletons) items[isoDate] = [undefined];
            else items[isoDate] = shifts?.filter(s => moment(s.getDate()).isSame(day)) ?? [];
        });
        return items;
    }, [showSkeletons, shifts, rangeDays]);

    useEffect(function onVisibilityChange() {
        if (!props.selectionAreaRef.state) return;
        const listener = () => dispatch("updateSelectedItems")(shiftsGroupedByDays);
        if (state.visible) props.selectionAreaRef.dispatch("addListener")(listener);
        else {
            props.selectionAreaRef.dispatch("removeListener")(listener);
            props.onSelectCells(undefined);
        }
        return () => {
            props.selectionAreaRef.dispatch("removeListener")(listener);
            props.onSelectCells(undefined);
        }
    }, [props.selectionAreaRef.state, state.visible, shiftsGroupedByDays]);

    const shiftsCells = Object.entries(shiftsGroupedByDays).map(([day, shifts]) => {
        const isSelected = !props.selectedCellData ? false :
            moment(day).isBetween(props.selectedCellData?.starDate, props.selectedCellData?.endDate, "day", "[]");
        const isBeforeToday = moment(day).isBefore(moment(), "day");
        const isWeekend = WeekDaysModule.isDateWeekEnd(day);
        return (
            <Flex key={"day" + day} fill className={"overflow-hidden"}>
                <Cell fluid noBorder inline selected={isSelected}
                      className={(!isSelected && isWeekend) ? "planning-weekend-cell" : ""}>
                    <Flex fill column gap={"gap.smaller"} styles={{minHeight: "60px"}}>
                        {shifts.length === 0 ?
                            (isBeforeToday || readOnly) ? null :
                                <ShiftCard
                                    addCard
                                    currentShopId={props.currentShopId}
                                    shift={undefined}
                                    format={state.cardsFormat}
                                    userId={props.userId}
                                    date={day}
                                />
                            :
                            shifts.map(((s, index) => (
                                <ShiftCard
                                    key={s?.getId() ?? index}
                                    currentShopId={props.currentShopId}
                                    shift={s}
                                    format={state.cardsFormat}
                                    transparent={isCardTransparent(s, props.visibleCategoriesIds, props.showOnlyUnClockedShifts)}
                                    onCopy={dispatch("copyShift", s)}
                                    onPaste={dispatch("pasteShift", s)}
                                    userId={props.userId}
                                    date={day}
                                    readonly={readOnly}
                                />
                            )))
                        }
                    </Flex>
                </Cell>
            </Flex>
        )
    });

    return (
        <Flex className={"staff-shifts"} ref={dispatch("containerRef")}>
            <StaffCell
                user={user}
                rangeDays={rangeDays}
                maximumWorkingHours={maximumWorkingHours}
                totalShiftsTime={totalShiftsTime}
            />
            {shiftsCells}
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const isCardTransparent = (
    shift: Immutable<Shift> | undefined,
    visibleCategoriesIds: string[] | undefined,
    showOnlyUnClockedShifts: boolean | undefined
): boolean => {
    if (!shift) return true;
    let isTransparent = false;
    if (visibleCategoriesIds) isTransparent = !visibleCategoriesIds?.includes(shift?.getCategoryId() ?? "");
    if (!isTransparent && showOnlyUnClockedShifts) {
        if (shift.getType() == ShiftType.Absence) isTransparent = true;
        else isTransparent = shift.isClocked();
    }
    return isTransparent;
}