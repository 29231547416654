import {QueryClient, useQueries, useQuery, useQueryClient} from "react-query";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {GroupData} from "../../../interfaces/GroupData";
import {GroupsCache} from "./helpers";

export const useShopGroupsCache = (shopId: string | undefined) => {
    const queryClient = useQueryClient();

    const {
        data: groupsIds,
    } = useQuery(GroupsCache.getShopGroupsIdsKey(shopId), () => GroupsCache.getShopGroupsIds(queryClient, shopId), {
        staleTime: Infinity,
        enabled: !!shopId,
    });

    const results = useQueries(groupsIds?.map(groupId => GroupsCache.getGroupQueryArgs(groupId)) ?? []);

    const isLoading = !groupsIds || results.some(r => r.isLoading || r.isFetching);

    const groups = (isLoading || results.length === 0) ? undefined :
        results.map(r => r.data).filter(Boolean) as Immutable<GroupData>[];

    return {
        groupsIds,
        isLoading,
        groups,
        updateIndexes: GroupsCache.updateIndexes(queryClient),
        deleteStaff: deleteStaff(queryClient),
    };
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const deleteStaff = (queryClient: QueryClient) => (groupId: string, staffId: string) => {
    GroupsCache.deleteStaff(queryClient, groupId)(staffId);
}