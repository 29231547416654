import React, {ReactElement} from "react";
import {Props} from "./SideView.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./SideView.reducer";
import "./SideView.styles.scss";
import {ArrowLeftIcon, Button, Divider, Flex, Text} from "@fluentui/react-northstar";

export const SideView = (props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer({props}), initialState, props.externalRef);

    const sideViewClass = [
        "side-view",
        state.open ? "open-view" : "close-view",
        state.blockClick && "blocked-side-view",
    ].filter(i => i).join(" ");

    return (
        <Flex fill column className={sideViewClass} style={props.style}>
            <Flex column className={"no-shrink side-view-header"} vAlign={"center"}>
                <Flex className={"overflow-hidden"} gap={"gap.smaller"} vAlign={"center"}>
                    <Button text iconOnly icon={<ArrowLeftIcon/>} onClick={dispatch("close")}/>
                    {props.icon}
                    <Text size={"large"} weight={"semibold"} content={props.title}/>
                    {props.subTitle && <Text styles={{color: "darkgray"}} content={props.subTitle}/>}
                </Flex>
                {!!props.headerElement && <Divider style={{margin: "3px -20px 3px -20px"}}/>}
                {props.headerElement}
            </Flex>
            <Flex fill column className={"overflow-hidden"}>
                {props.children}
            </Flex>
        </Flex>
    )
}