import React, {ReactElement, useMemo} from "react";
import {Props} from "./GroupAccordion.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./GroupAccordion.reducer";
import "./GroupAccordion.styles.scss";
import {Button, Flex, Text} from "@fluentui/react-northstar";
import {Accordion} from "components/others/Accordion/Accordion";
import {ArrowMoveIcon, PeopleIcon, PersonAddIcon, SettingsIcon, WindowBulletListIcon} from "assets/icons";
import {useDialogContext} from "services/DialogContext/DialogContext";
import {useGroupCache} from "../../../hooks/cache/groups/useGroupCache";
import {useUserRolesCache} from "../../../hooks/cache/useUserRoleCache";
import {useShopCache} from "../../../hooks/cache/useShopsCache";
import {ShopType} from "../../../interfaces/ShopData";

export const GroupAccordion = (props: Props): ReactElement | null => {
    const {peopleShiftsDialog, groupConfigurationDialog, orderGroupsDialog, selectPeopleDialog} = useDialogContext();
    const {group, addStaffs} = useGroupCache(props.groupId);
    const {shop} = useShopCache(group?.shopId);
    const {canUpdateShop, isLoading} = useUserRolesCache();
    const [state, dispatch] = useMagicReducer(reducer({
        props,
        peopleShiftsDialog,
        groupConfigurationDialog,
        orderGroupsDialog,
        addStaffs,
        selectPeopleDialog
    }), initialState);

    const readOnly = !canUpdateShop(group?.shopId) || isLoading;

    const isRotatingStaffShop = shop?.type === ShopType.RotatingStaff;

    const headerActions = useMemo(() => readOnly ? null : (
        <Flex className={"hover-group-accordion-item"} styles={{gap: "5px"}}>
            <Button text icon={<PersonAddIcon/>} iconOnly onClick={dispatch("addPerson")}/>
            <Button text icon={<ArrowMoveIcon/>} iconOnly onClick={dispatch("moveGroup")}/>
            <Button text icon={<SettingsIcon/>} iconOnly onClick={dispatch("showSettings", props.groupId)}/>
        </Flex>
    ), [readOnly]);

    const header = useMemo(() => (
        <Flex fill vAlign={"center"}>
            <Flex vAlign={"center"} className={"no-shrink"}>
                {!props.card &&
                    <Flex gap={"gap.small"} vAlign={"center"}>
                        <Flex className={"no-shrink"} styles={{gap: "2px", color: "darkgray"}}>
                            <PeopleIcon width={20}/>
                            <Text content={group?.usersIds.length} weight={"regular"}/>
                        </Flex>
                        {!isRotatingStaffShop &&
                            <Button
                                className={"hover-group-accordion-item"}
                                text icon={<WindowBulletListIcon/>} iconOnly
                                onClick={dispatch("showPeopleList")}
                                disabled={!group?.usersIds.length}
                            />
                        }
                    </Flex>
                }
            </Flex>
            <Flex fill/>
            {headerActions}
        </Flex>
    ), [props.card, group?.usersIds.length, headerActions, isRotatingStaffShop]);

    const content = !group?.usersIds.length ? undefined : (
        <Flex fill column>
            {props.children}
        </Flex>
    )

    const title = group?.name ?? "";

    return (
        <Flex className={"group-accordion"}>
            <Accordion
                card={props.card}
                title={props.renderTitle ? props.renderTitle(title) : title}
                headerContent={props.hideActions ? undefined : header}
                content={content}
                expandable={!!group?.usersIds.length}
                defaultOpen={props.defaultOpen}
            />
        </Flex>
    )
}