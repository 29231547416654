import {QueryClient, useQuery, useQueryClient} from "react-query";
import {ShopApi} from "apis/Shop/ShopApi";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {ShopData, ShopShiftActivity, ShopShiftCategory} from "interfaces/ShopData";
import {GroupData} from "interfaces/GroupData";
import {GroupsCache} from "./groups/helpers";
import {GroupApi} from "../../apis/Group/GroupApi";
import {ObjectModule} from "../../modules/Object.module";

export type UseShopCache = ReturnType<typeof useShopCache>;

export const shopsCacheKey = "shops";

export const useShopsCache = () => {
    const queryClient = useQueryClient();

    const {data: shops, isLoading} = useQuery([shopsCacheKey], () => getAllShops(queryClient), {
        staleTime: Infinity,
    });

    return {
        shops,
        isLoading,
        upsertShop: upsertShop(queryClient),
        deleteShop: deleteShop(queryClient),
    }
}

export const useShopCache = (id: string | undefined) => {
    const queryClient = useQueryClient();

    const {data: shop, isLoading} = useQuery([shopsCacheKey, id], () => ShopApi.getById(id), {
        staleTime: Infinity,
        enabled: !!id
    });

    return {
        shop,
        isLoading,
        addGroupToShop: addGroupToShop(queryClient, id),
        upsertActivity: upsertActivity(queryClient, id),
        upsertCategory: upsertCategory(queryClient, id),
        deleteCategory: deleteCategory(queryClient, id),
        deleteActivity: deleteActivity(queryClient, id),
        upsertShop: upsertShop(queryClient),
        deleteShop: deleteShop(queryClient),
    };
}

export const useUserShopCache = () => {
    const queryClient = useQueryClient();

    const {data: shop, isLoading} = useQuery([shopsCacheKey, "user-shop"], () => getUserShop(queryClient), {
        staleTime: Infinity,
    });

    return {
        shop,
        isLoading,
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getAllShops = async (queryClient: QueryClient) => {
    const shops = await ShopApi.getAll();
    shops.forEach(shop => queryClient.setQueryData([shopsCacheKey, shop.id], {...shop}));
    return shops;
}

const getUserShop = async (queryClient: QueryClient) => {
    const userShop = await ShopApi.getUserShop();
    if (!userShop) return;
    queryClient.setQueryData([shopsCacheKey, userShop.id], {...userShop});
    return userShop;
}

const getLocalShop = (queryClient: QueryClient, shopId: string | undefined) => {
    return queryClient.getQueryData<Immutable<ShopData>>([shopsCacheKey, shopId]);
}

const upsertShop = (queryClient: QueryClient) => async (shop: ShopData) => {
    if (!shop) return;
    if (!shop.id) {
        const result = await ShopApi.create(shop) ?? "";
        if (!result) return;
        shop.id = result.shopId;
        shop.totalStaffCount = result.collaboratorsCount;
        if (!shop.id) return;
    } else {
        const oldShop = queryClient.getQueryData<Immutable<ShopData>>([shopsCacheKey, shop.id]);
        if (!oldShop) return;
        const updatedFields = ObjectModule.findUpdatedFields(oldShop, shop);
        const result = await ShopApi.update(shop.id, updatedFields);
        if (!result) return;
    }
    queryClient.setQueryData<Immutable<ShopData>>([shopsCacheKey, shop.id], shop);
    let shops = queryClient.getQueryData<Array<Immutable<ShopData>>>([shopsCacheKey]);
    if (!shops) shops = [];
    const index = shops?.findIndex(s => s.id === shop.id);
    if (index === -1) shops?.push(shop);
    else shops[index] = shop;
    queryClient.setQueryData([shopsCacheKey], [...shops]);
}

const upsertActivity = (queryClient: QueryClient, shopId: string | undefined) => async (activity: Immutable<ShopShiftActivity>) => {
    if (!shopId) return;
    const shop = queryClient.getQueryData<ShopData>([shopsCacheKey, shopId]);
    if (!shop) return;
    const index = shop.activities.findIndex(c => c.key === activity.key);
    if (index === -1) {
        const id = await ShopApi.createActivity(shopId, activity);
        if (!id) return;
        shop.activities.push({...activity, key: id});
    } else {
        const updatedFields = ObjectModule.findUpdatedFields(shop.activities[index], activity);
        const result = await ShopApi.updateActivity(shopId, activity.key, updatedFields);
        if (!result) return;
        shop.activities[index] = activity;
    }
    queryClient.setQueryData([shopsCacheKey, shopId], {...shop});
}

const upsertCategory = (queryClient: QueryClient, shopId: string | undefined) => async (
    category: Immutable<ShopShiftCategory> | undefined
) => {
    if (!shopId || !category) return;
    const shop = queryClient.getQueryData<ShopData>([shopsCacheKey, shopId]);
    if (!shop) return;
    const index = shop.categories.findIndex(c => c.key === category.key);
    if (index === -1) {
        const key = await ShopApi.createCategory(shopId, category);
        if (!key) return;
        shop.categories.push({...category, key});
    } else {
        const updatedFields = ObjectModule.findUpdatedFields(shop.categories[index], category);
        const result = await ShopApi.updateCategory(shopId, category.key, updatedFields);
        if (!result) return;
        shop.categories[index] = category;
    }
    queryClient.setQueryData([shopsCacheKey, shopId], {...shop});
}

const deleteShop = (queryClient: QueryClient) => async (shopId: string | undefined) => {
    if (!shopId) return;
    const result = await ShopApi.deleteShop(shopId);
    if (!result) return;
    let shops = queryClient.getQueryData<Array<Immutable<ShopData>>>([shopsCacheKey]);
    if (!shops) return;
    shops = shops.filter(s => s.id !== shopId);
    queryClient.setQueryData([shopsCacheKey, shopId], undefined);
    queryClient.setQueryData([shopsCacheKey], [...shops]);
}

const deleteCategory = (queryClient: QueryClient, shopId: string | undefined) => async (categoryId: string | undefined) => {
    if (!shopId || !categoryId) return;
    const shop = queryClient.getQueryData<ShopData>([shopsCacheKey, shopId]);
    if (!shop) return;
    const result = await ShopApi.deleteCategory(shopId, categoryId);
    if (!result) return;
    shop.categories = shop.categories.filter(c => c.key !== categoryId);
    queryClient.setQueryData([shopsCacheKey, shopId], {...shop});
}

const deleteActivity = (queryClient: QueryClient, shopId: string | undefined) => async (activityId: string | undefined) => {
    if (!shopId || !activityId) return;
    const shop = queryClient.getQueryData<ShopData>([shopsCacheKey, shopId]);
    if (!shop) return;
    const result = await ShopApi.deleteActivity(shopId, activityId);
    if (!result) return;
    shop.activities = shop.activities.filter(c => c.key !== activityId);
    queryClient.setQueryData([shopsCacheKey, shopId], {...shop});
}

const addGroupToShop = (queryClient: QueryClient, shopId: string | undefined) => async (name: string, index: number) => {
    if (!shopId) return;
    let group: GroupData | undefined = {
        id: "",
        name,
        index,
        shopId,
        usersIds: [],
        legalRules: {
            maximumConsecutiveWorkingDays: 0,
            minimalBreakHoursBetweenShifts: 0,
            maximumDailyWorkingHours: 0,
            maximumWeeklyWorkingHours: 0,
            customRules: [],
            badge: true,
        },
        thresholds: {
            shifts: [],
            custom: [],
        },
    };
    group = await GroupApi.create(group);
    if (!group?.id) return;
    queryClient.setQueryData([GroupsCache.groupsCacheKey, group.id], group);
    const shopGroupsIds = await GroupsCache.getShopGroupsIds(queryClient, shopId) ?? [];
    const newGroupsIds = [...shopGroupsIds, group.id];
    queryClient.setQueryData(GroupsCache.getShopGroupsIdsKey(shopId), newGroupsIds);
}

export const ShopsCache = {
    getLocalShop,
}