const downloadFile = (file: Blob | undefined, fileName: string) => {
    if (!file) return;
    const link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.download = fileName;
    link.click();
    link.remove();
}

export const FileModule = {
    downloadFile,
}