import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {ErrorModule} from "../../components/others/ErrorBoundary/ErrorBoundary";
import {BadgeRequest} from "../../interfaces/BadgeRequest";
import {ShiftClocking} from "../../interfaces/ShiftData";
import {AxiosResponse} from "axios";

const badge = async (request: BadgeRequest): Promise<ShiftClocking | undefined> => {
    try {
        const response = await AxiosConfig.instance.post(`/badge`, request);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't badge", error);
        return;
    }
}

const canUserBadge = async (): Promise<boolean> => {
    try {
        const response: AxiosResponse<boolean> = await AxiosConfig.instance.get(`/badge/can-badge`);
        return response.data;
    } catch (error) {
        ErrorModule.showErrorAlert("Can't know if user can badge", error);
        return false;
    }
}

export const BadgeApi = {
    badge,
    canUserBadge
}