import React, {memo, ReactElement, useEffect} from "react";
import {Props} from "./SelectStep.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./SelectStep.reducer";
import {CompareModule} from "modules/Compare.module";
import {ReactComponent as SelectPlanningImage} from "assets/images/SelectPlanning.svg";
import "./SelectStep.styles.scss";
import {Button, ExcelColorIcon, Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../../../../translations";
import {ImportIcon} from "assets/icons";
import {useMsTeamsSelector} from "../../../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {useForm} from "@witivio_teamspro/northstar-form";
import {useDialogContext} from "../../../../../services/DialogContext/DialogContext";

export const SelectStep = memo((props: Props): ReactElement | null => {
    const {isLightTheme} = useMsTeamsSelector("isLightTheme");
    const {confirmDialog} = useDialogContext();
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    const fileForm = useForm({
        items: {
            file: {
                type: "filePicker",
                buttonIcon: <ExcelColorIcon/>,
                buttonLabel: translations.get("SelectAPlanning"),
                formats: [".xls", ".xlsx"],
                primaryButton: true,
                readAs: "DataURL",
            }
        }
    });

    const selectedFile = fileForm.state.data.file;

    useEffect(function onFileChange() {
        if (!selectedFile) return;
        fileForm.reset();
        const file = {...selectedFile};
        confirmDialog.dispatch("open", {
            title: translations.get("ConfirmImport"),
            subtitle: translations.get("ConfirmImportWarningMessage"),
            buttonTitle: translations.get("Import"),
            buttonIcon: <ImportIcon/>,
            callback: (confirmed) => {
                if (confirmed) props.onFileSelected(file);
            }
        })();
    }, [selectedFile]);

    return (
        <Flex fill column vAlign={"center"} hAlign={"center"} gap={"gap.medium"}>
            <SelectPlanningImage
                height={250} width={250}
                color={isLightTheme ? "#F5F5F5" : "#565656"}
                style={{marginTop: "-10px"}}
            />
            <Flex column hAlign={"center"} styles={{gap: "5px", marginTop: "-10px"}}>
                <Text size={"large"} weight={"bold"} content={translations.get("ImportYourPlanning")}/>
                <Text content={translations.get("PleaseSelectAPlanning")}/>
            </Flex>
            <Flex column gap={"gap.small"}>
                <Button
                    content={translations.get("DownloadTemplate")}
                    icon={<ImportIcon/>}
                    onClick={dispatch("downloadTemplate")}
                />
                {fileForm.formItems.file}
            </Flex>
        </Flex>
    )
}, CompareModule.areObjectsEqual);