import {State} from "./GroupReportDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {DateRangeSelector} from "../../others/DateRangeSelector/DateRangeSelector";
import {PlanningDateRangeCache} from "../../../hooks/cache/usePlanningDateRangeCache";

export const initialState: State = {
    groupId: undefined,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
    dateRangeRef: MagicReducerRef<typeof DateRangeSelector>,
    planningDateRange: PlanningDateRangeCache,
}) => ({
    open: ({setState}, _, groupId: string) => {
        setState({groupId});
        config.dialogRef.dispatch("open")();
    },
    setDateRangeDate: () => {
        const date = config.planningDateRange.selectedDate;
        config.dateRangeRef.dispatch("changeDate", date)();
    }
}) satisfies MagicReducerObject<State>;