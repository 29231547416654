import React, {memo, ReactElement} from "react";
import {Props} from "./CopyPlanningDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./CopyPlanningDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import "./CopyPlanningDialog.styles.scss";
import {Dialog} from "../Dialog/Dialog";
import {translations} from "translations";
import {Flex} from "@fluentui/react-northstar";
import {useCopyPlanningForm} from "hooks/forms/useCopyPlanningForm";
import {CopyIcon} from "assets/icons";

export const CopyPlanningDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);

    const form = useCopyPlanningForm(state.shopId);

    return (
        <Dialog
            width={500}
            icon={<CopyIcon/>}
            title={translations.get("CopyPlanning")}
            externalRef={dialogRef}
            onClose={dispatch("reset", form)}
            showOverflow
            content={form.renderForm()}
            footer={
                <Flex fill hAlign={"end"}>
                    {form.renderCopyButton(dialogRef.dispatch("close"))}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);