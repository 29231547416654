import {IColor} from "interfaces/IColor";
import {ReactElement} from "react";
import {Immutable, MagicDispatch, MagicReducerExternalRef} from "@witivio_teamspro/use-reducer";
import {reducer} from "./Table.reducer";
import {ComponentSlotStyle} from "@fluentui/react-northstar";

export type State = {
    mounted: boolean,
    columnSort: string | null,
    columnFilters: Map<string, ColumnFilterData>,
    sortAscending: boolean,
    visibleItemsCount: number,
    visibleRange: [number, number],
    editedItem: ITableEditedItem | undefined,
    prevSkip: number | undefined,
    skip: number | undefined,
    columnFiltersTimeout: NodeJS.Timeout | undefined,
    tableContainer: HTMLDivElement | null,
    table: HTMLDivElement | null,
}

export type ITableActions<T> = {
    items: Array<ITableRowAction<ITableItem<T>>>,
    icon?: ReactElement | ((item: ITableItem<T>) => ReactElement),
    backgroundColor?: IColor | ((item: ITableItem<T>) => IColor | undefined),
    disabled?: boolean | ((item: ITableItem<T>) => boolean),
}

export type Props<T> = {
    show?: boolean,
    items: Immutable<Array<ITableItem<T>>> | undefined,
    columns: Array<ITableColumn<ITableItem<T>>>,
    actions?: ITableActions<T> | undefined,
    rowHeight?: number,
    debugMode?: boolean,
    initialSortedColumn?: string | undefined,
    initialSortOrder?: "ascending" | "descending" | undefined,
    fill?: boolean,
    fetchOnScroll?: boolean | undefined,
    fetchNextItems?: ((skip: number, take: number) => void | Promise<void>) | undefined,
    onFilterByColumn?: ((columnFilters: Record<string, string>) => void) | undefined,
    onSortColumn?: ((columnSort: string | null, sortAscending: boolean) => void) | undefined,
    filterByColumnThrottlingInMs?: number | undefined,
    defaultColumnFilters?: Record<string, string> | undefined,
    externalRef?: MagicReducerExternalRef<typeof reducer<T>>,
    sortLocally?: boolean | undefined,
}

export type TableRef<T> = Exclude<Props<T>["externalRef"], undefined>;

export type TableProps<T> = Props<T>;

export type Logic<T> = {
    show: boolean,
    columns: Array<ITableColumn<ITableItem<T>>>
    sortAscending: boolean
    columnSort: string | null
    getColumnByField: (field: string) => ITableColumn<ITableItem<T>>
    visibleRange: Immutable<State["visibleRange"]>,
    actionColumnWidth: number
    editedItem: ITableEditedItem | undefined
    showActionColumn: boolean
    items: Array<ITableItem<any>>
    actions?: ITableActions<T> | undefined,
    columnFilters: Immutable<State["columnFilters"]>,
    isTouchScreen: boolean,
    hideFilters: boolean,
    dispatch: MagicDispatch<ReturnType<typeof reducer<T>>>,
}

export type ColumnFilterData = { type: TableColumnFilterType, text: string, regexp?: RegExp };

export enum TableColumnFilterType {
    Contains, StartWith, EndWith
}

export interface ITableEditedItem {
    itemId: string,
    field: string,
}

export type ITableColumnWidth = `${number}px` | `${number}%` | "0";

export type ITableColumnLabelFunc = (sortIndicator: ReactElement | null) => ReactElement;

export interface ITableColumn<T> {
    field: string,
    label: string | ITableColumnLabelFunc,
    labelClassName?: string,
    labelStyles?: ComponentSlotStyle | undefined,
    icon?: ReactElement,
    minWidth?: ITableColumnWidth,
    maxWidth?: ITableColumnWidth,
    render: (item: ITableItem<T>) => string | number | ReactElement | null,
    sort?: (a: ITableItem<T>, b: ITableItem<T>) => number,
    skeleton?: ReactElement,
    backgroundColor?: IColor | ((item: ITableItem<T>) => IColor | undefined),
    renderEditField?: (item: ITableItem<T>, onEditDone: () => void) => ReactElement,
    onClick?: (item: ITableItem<T>) => void,
    getFilterValue?: (item: ITableItem<T>) => string,
    filterPlaceholder?: string | undefined,
    itemClassName?: string | undefined,
    itemStyles?: ComponentSlotStyle | undefined,
}

export interface ITableRowAction<T> {
    label: string | ((item: ITableItem<T>) => string),
    icon?: ReactElement | ((item: ITableItem<T>) => ReactElement | null) | null,
    onClick?: (item: ITableItem<T>) => void,
    isVisible?: (item: ITableItem<T>) => boolean,
    menu?: Array<ITableRowAction<T>>,
}

export type ITableItem<T> = null | (T & ({ id: string } | { key: string }));