import React, {memo, ReactElement, useMemo} from "react";
import {Props, SettingsDialogTab, State} from "./SettingsDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./SettingsDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {Flex, Loader, Menu} from "@fluentui/react-northstar";
import "./SettingsDialog.styles.scss";
import {SettingsIcon} from "../../../assets/icons";
import {translations} from "../../../translations";
import {useSettingsCache} from "../../../hooks/cache/useSettingsCache";
import {CategoriesPart} from "../PlanningConfigurationDialog/parts/CategoriesPart/CategoriesPart";
import {ActivitiesPart} from "../PlanningConfigurationDialog/parts/ActivitiesPart/ActivitiesPart";
import {useShopSettingsForm} from "../../../hooks/forms/useShopSettingsForm";

export const SettingsDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef(Dialog);
    const [state, dispatch] = useMagicReducer(reducer({dialogRef}), initialState, props.externalRef);
    const {isLoading} = useSettingsCache();
    const settingsForm = useShopSettingsForm();

    const menuItems = useMemo(() => generateMenuItems(), []);

    const activeIndex = menuItems.findIndex(item => item.id === state.activeTab);

    const menu = (
        <Menu
            styles={{paddingTop: "10px !important"}}
            className={"create-request-dialog-menu"}
            activeIndex={activeIndex}
            items={menuItems}
            underlined
            primary
            onItemClick={dispatch("changeTab")}
        />
    )

    return (
        <Dialog
            externalRef={dialogRef}
            width={800}
            height={600}
            noPadding
            onClose={settingsForm.updateSettings}
            icon={SettingsIcon}
            title={translations.get("Settings")}
            className={"create-request-dialog"}
            content={isLoading ? renderLoader() :
                <Flex fill column>
                    {menu}
                    {renderDialogContent(state.activeTab, settingsForm)}
                </Flex>
            }
        />
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderLoader = () => {
    return (
        <Flex fill hAlign={"center"} vAlign={"center"}>
            <Loader/>
        </Flex>
    )
}

const renderDialogContent = (
    activeTab: State["activeTab"],
    settingsForm: ReturnType<typeof useShopSettingsForm>
) => {
    let content: ReactElement | null = null;
    switch (activeTab) {
        case SettingsDialogTab.Global:
            content = settingsForm.renderForm();
            break;
        case SettingsDialogTab.Shops:
            content = settingsForm.renderRetrieveHoursMultiplicationForm();
            break;
        case SettingsDialogTab.Categories:
            content = <CategoriesPart shopId={"settings"}/>
            break;
        case SettingsDialogTab.Activities:
            content = <ActivitiesPart shopId={"settings"}/>
            break;
        default:
            return null;
    }
    return (
        <Flex fill column className={"create-request-dialog-content"}>
            {content}
        </Flex>
    )
}

const generateMenuItems = () => {
    return [
        {
            key: "global",
            id: SettingsDialogTab.Global,
            content: translations.get("General")
        },
        {
            key: "shops",
            id: SettingsDialogTab.Shops,
            content: translations.get("Shops")
        },
        {
            key: "categories",
            id: SettingsDialogTab.Categories,
            content: translations.get("Categories")
        },
        {
            key: "activities",
            id: SettingsDialogTab.Activities,
            content: translations.get("Activities")
        },
    ]
}