import {Props, State} from "./ManagerResponseItem.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {useRequestsCache} from "../../../../hooks/cache/useRequestsCache";
import moment from "moment";
import {RequestResponse} from "../../../../interfaces/RequestData";

export const initialState: State = {
    isApproving: false,
    isDenying: false,
}

export const reducer = (config: {
    props: Props,
    answerRequest: ReturnType<typeof useRequestsCache>["answerRequest"],
    currentUserId: string,
}) => ({
    approve: async ({setState}, _, notes: string | undefined) => {
        if (!config.props.requestId) return;
        setState({isApproving: true});
        await config.answerRequest(config.props.requestId, generateResponse(config.currentUserId, true, notes));
        setState({isApproving: false});
    },
    deny: async ({setState}, _, notes: string | undefined) => {
        if (!config.props.requestId) return;
        setState({isDenying: true});
        await config.answerRequest(config.props.requestId, generateResponse(config.currentUserId, false, notes));
        setState({isDenying: false});
    }
}) satisfies MagicReducerObject<State>;

const generateResponse = (managerId: string, accepted: boolean, notes: string | undefined): RequestResponse => {
    return {
        managerId,
        accepted,
        date: moment().toISOString(false),
        note: notes,
    }
}