import {QueryClient, useQuery, useQueryClient} from "react-query";
import {GroupsCache} from "./helpers";
import {ShopApi} from "../../../apis/Shop/ShopApi";

export const useRotatingStaffGroupsCache = (enabled?: boolean) => {
    const queryClient = useQueryClient();

    const {
        data: groups,
        isLoading
    } = useQuery(GroupsCache.getShopGroupsIdsKey("head-office"), getRotatingStaffGroups(queryClient), {
        staleTime: Infinity,
        enabled: enabled ?? false,
    });

    return {
        groups: !enabled ? undefined : groups,
        isLoading,
    };
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getRotatingStaffGroups = (queryClient: QueryClient) => async () => {
    const groups = await ShopApi.getRotatingStaffGroups();
    groups?.forEach(g => queryClient.setQueryData(GroupsCache.getGroupCacheKey(g.id), g));
    return groups;
}

const clearRotatingStaffGroupsCache = (queryClient: QueryClient) => {
    queryClient.removeQueries(GroupsCache.getShopGroupsIdsKey("head-office"));
}

export const RotatingStaffGroupsCache = {
    clearRotatingStaffGroupsCache,
}