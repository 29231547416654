import {WeekDay} from "interfaces/WeekDay";
import React, {useCallback, useLayoutEffect, useMemo, useState} from "react";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {Dropdown, DropdownProps, Flex, Input, InputProps, Text} from "@fluentui/react-northstar";
import {translations} from "translations";
import {CustomShiftThresholdsData, GroupData, ShiftThresholdsData, ThresholdsData} from "interfaces/GroupData";
import {WeekDaysModule} from "../../modules/WeekDays.module";
import {useGroupCache} from "../cache/groups/useGroupCache";
import {CompareModule} from "../../modules/Compare.module";

type GroupedDaysThresholds = {
    days: Array<WeekDay>,
    threshold: number,
}

export const useShiftThresholdsForm = (props: {
    groupId: string,
    categoryId: string | undefined,
    categoryThresholds?: Immutable<ThresholdsData> | undefined,
    customId?: string | undefined,
    disabled?: boolean | undefined,
}) => {
    const {customId, categoryId, groupId, disabled = false} = props;

    const {group} = useGroupCache(groupId);
    const [thresholds, setThresholds] = useState<Immutable<GroupedDaysThresholds[]>>(getInitialThresholds());

    const groupThresholds = useMemo(() => (
        props.categoryThresholds ?? getGroupThresholds(group, categoryId, customId)
    ), [group, categoryId, customId, props.categoryThresholds]);

    useLayoutEffect(() => {
        if (!group || !categoryId) return;
        if (!groupThresholds) {
            setThresholds(getInitialThresholds());
            return;
        }
        const groupedDaysThresholds = groupShiftThresholdsByDays(groupThresholds);
        setThresholds(groupedDaysThresholds);
    }, [groupId, categoryId, customId]);

    const renderThresholdForm = useCallback((item: Immutable<GroupedDaysThresholds>, index: number) => (
        <Flex fill key={index} gap={"gap.small"} vAlign={"center"}>
            <Flex fill>
                <Dropdown
                    multiple
                    className={"w-100"}
                    fluid
                    items={getDropdownDaysItems(item.days)}
                    value={getDropdownDaysValue(item.days)}
                    onChange={handleChangeThresholdDays(thresholds, setThresholds, index)}
                    disabled={disabled}
                />
            </Flex>
            <Flex fill style={{width: "90px"}}>
                <Input
                    fluid
                    type={"number"}
                    inputMode={"numeric"}
                    placeholder={"0"}
                    value={item.threshold}
                    min={0}
                    max={300}
                    onChange={handleChangeThresholdValue(thresholds, setThresholds, index)}
                    autoComplete={"off"}
                    disabled={disabled}
                />
            </Flex>
        </Flex>
    ), [thresholds, disabled]);

    const renderForm = useCallback(() => (
        <Flex fill column styles={{gap: "3px"}}>
            <Text>
                {translations.get("WeekThresholds")}
                <span style={{color: "red"}}> *</span>
            </Text>
            <Flex fill column gap={"gap.smaller"}>
                {thresholds.map(renderThresholdForm)}
            </Flex>
        </Flex>
    ), [thresholds, renderThresholdForm])

    const thresholdsData = useMemo(() => flattenThresholds(thresholds), [thresholds]);

    const hasChanged = useMemo(() => {
        const groupedDaysThresholds = !groupThresholds ? getInitialThresholds() : groupShiftThresholdsByDays(groupThresholds);
        const groupFlattenThresholds = flattenThresholds(groupedDaysThresholds);
        return !CompareModule.areObjectsEqual(thresholdsData, groupFlattenThresholds);
    }, [groupThresholds, thresholdsData]);

    return {
        thresholdsData,
        renderForm: !categoryId ? () => null : renderForm,
        hasChanged,
    };
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getGroupThresholds = (group: Immutable<GroupData> | undefined, categoryId: string | undefined, customId: string | undefined): Immutable<ThresholdsData> | undefined => {
    if (!group || !categoryId) return;
    let shiftThresholds: Immutable<CustomShiftThresholdsData | ShiftThresholdsData> | undefined;
    if (customId !== undefined) shiftThresholds = group.thresholds.custom.find(s => s.categoryId === categoryId && s.id === customId);
    else shiftThresholds = group.thresholds.shifts.find(s => s.categoryId === categoryId);
    return shiftThresholds?.thresholds;
}

const getInitialThresholds = (): GroupedDaysThresholds[] => [{
    days: Object.values(WeekDay).filter(d => typeof d === "number") as WeekDay[],
    threshold: 0,
}];

const handleChangeThresholdDays = (
    thresholds: Immutable<GroupedDaysThresholds[]>,
    setThresholds: (thresholds: Immutable<GroupedDaysThresholds[]>) => void,
    thresholdIndex: number
) => (_: React.SyntheticEvent | null, data: DropdownProps) => {
    const previousThresholdDays = thresholds[thresholdIndex]?.days ?? [];
    const thresholdDays = (data.value as Array<{ value: WeekDay }>)?.flatMap(i => i.value);
    let concernedDay = getThresholdDaysUpdateConcernedDay(previousThresholdDays, thresholdDays);
    let newThresholds = new Array<Immutable<GroupedDaysThresholds>>(...thresholds);
    if (thresholdDays.length > 0) newThresholds[thresholdIndex] = {
        ...newThresholds[thresholdIndex]!,
        days: thresholdDays.sort((a, b) => a - b),
    };
    newThresholds = removeDayFromThresholds(newThresholds, concernedDay, thresholdIndex);
    const undefinedDays = getThresholdsUndefinedDays(newThresholds);
    if (undefinedDays.length > 0) newThresholds.push({days: undefinedDays, threshold: 0});
    setThresholds(newThresholds);
}

const handleChangeThresholdValue = (
    thresholds: Immutable<GroupedDaysThresholds[]>,
    setThresholds: (thresholds: Immutable<GroupedDaysThresholds[]>) => void,
    thresholdIndex: number
) => (_: React.SyntheticEvent | null, data: InputProps & { value: string } | undefined) => {
    let thresholdValue = Number(data?.value ?? 0);
    if (thresholdValue < 0) thresholdValue = 0;
    else if (thresholdValue > 300) thresholdValue = 300;
    const prevValue = thresholds[thresholdIndex]?.threshold ?? 0;
    if (prevValue === thresholdValue) return;
    const newThresholds = thresholds.map((t, i) => i === thresholdIndex ? {...t, threshold: thresholdValue} : t);
    setThresholds(newThresholds);
}

const getThresholdDaysUpdateConcernedDay = (prevDays: Immutable<Array<WeekDay>>, nextDays: Immutable<Array<WeekDay>>) => {
    let concernedDay: WeekDay;
    const isDayAdded = prevDays.length < nextDays.length;
    if (isDayAdded) concernedDay = nextDays.find(d => !prevDays.includes(d))!;
    else concernedDay = prevDays.find(d => !nextDays.includes(d))!;
    return concernedDay;
}

const removeDayFromThresholds = (thresholds: Immutable<GroupedDaysThresholds[]>, day: WeekDay, skipIndex: number) => {
    let newThresholds = thresholds.map((threshold, i) => {
        if (i === skipIndex) return threshold;
        if (threshold.days.includes(day)) {
            const days = threshold.days.filter(d => d !== day);
            if (days.length === threshold.days.length) return threshold;
            days.sort((a, b) => a - b);
            return {...threshold, days};
        }
        return threshold;
    });
    newThresholds = newThresholds.filter(t => t.days.length > 0);
    return newThresholds;
}

const getThresholdsUndefinedDays = (thresholds: Immutable<GroupedDaysThresholds[]>) => {
    const selectedDays = new Array<WeekDay>();
    thresholds.forEach((threshold) => selectedDays.push(...threshold.days));
    return Object.values(WeekDay).filter(d => typeof d === "number" && !selectedDays.includes(d)) as WeekDay[];
}

const getDropdownDaysItems = (selectedDays: Immutable<Array<WeekDay>>) => {
    return Object.values(WeekDay)
        .filter(d => typeof d === "number")
        .filter(d => !selectedDays.includes(d as WeekDay))
        .map(d => ({key: d + "", header: translations.get(WeekDay[d as WeekDay] + ""), value: d}));
}

const getDropdownDaysValue = (selectedDays: Immutable<Array<WeekDay>>) => {
    return selectedDays.map(i => ({
        key: i + "",
        header: translations.get(WeekDay[i] + "").slice(0, 3),
        value: i
    }));
}

const flattenThresholds = (thresholds: Immutable<GroupedDaysThresholds[]>) => {
    const weekDays = WeekDaysModule.getWeekDaysOrderPerCulture();
    const items: ThresholdsData = weekDays.map(day => ({day, threshold: 0}));
    thresholds.forEach(t => t.days.forEach(d => {
        const day = items.find(dd => dd.day === d);
        if (day) day.threshold = t.threshold;
    }));
    return items;
}

const groupShiftThresholdsByDays = (thresholds: Immutable<ThresholdsData>) => {
    const groupedThresholds = new Array<GroupedDaysThresholds>();
    thresholds.forEach((item) => {
        const existingGroup = groupedThresholds.find(g => g.threshold === item.threshold);
        if (existingGroup) existingGroup.days.push(item.day);
        else groupedThresholds.push({days: [item.day], threshold: item.threshold});
    });
    return groupedThresholds;
}