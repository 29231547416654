import * as microsoftTeams from "@microsoft/teams-js";

const startChat = async (upn: string | undefined) => {
    if (!upn) return;
    let deepLink = "https://teams.microsoft.com/l/chat/0/0?users=" + upn;
    await microsoftTeams.app.openLink(deepLink)
}

const startCall = async (upn: string | undefined) => {
    if (!upn) return;
    await microsoftTeams.app.openLink("https://teams.microsoft.com/l/call/0/0?users=" + upn + "&withVideo=false")
}

export const UserModule = {
    startChat,
    startCall
}