import * as React from "react";
import {ReactElement} from "react";
import {ReactComponent as ShopSvg} from "./Shop.svg";
import {ReactComponent as ExportSvg} from "./Export.svg";
import {ReactComponent as CopySvg} from "./Copy.svg";
import {ReactComponent as PrintSvg} from "./Print.svg";
import {ReactComponent as ShareSvg} from "./Share.svg";
import {ReactComponent as AgendaSvg} from "./Agenda.svg";
import {ReactComponent as ArrowMoveSvg} from "./ArrowMove.svg";
import {ReactComponent as ArrowTrendingLinesSvg} from "./ArrowTrendingLines.svg";
import {ReactComponent as PeopleSvg} from "./People.svg";
import {ReactComponent as PersonAddSvg} from "./PersonAdd.svg";
import {ReactComponent as SettingsSvg} from "./Settings.svg";
import {ReactComponent as ScalesSvg} from "./Scales.svg";
import {ReactComponent as ShiftsSvg} from "./Shifts.svg";
import {ReactComponent as WindowBulletListSvg} from "./WindowBulletList.svg";
import {ReactComponent as BellSvg} from "./Bell.svg";
import {ReactComponent as EditSvg} from "./Edit.svg";
import {ReactComponent as PasteSvg} from "./Paste.svg";
import {ReactComponent as TimerSvg} from "./Timer.svg";
import {ReactComponent as PeopleSettingsSvg} from "./PeopleSettings.svg";
import {ReactComponent as ImportSvg} from "./Import.svg";
import {ReactComponent as CartierShiftSvg} from "./CartierShift.svg";
import {ReactComponent as GlobeSvg} from "./Globe.svg";
import {ReactComponent as ArrowSyncCircleSvg} from "./ArrowSyncCircle.svg";
import {ReactComponent as CheckmarkCircleSvg} from "./CheckmarkCircle.svg";
import {ReactComponent as ShiftsQuestionSvg} from "./ShiftsQuestion.svg";
import {ReactComponent as ClockSvg} from "./Clock.svg";
import {ReactComponent as WarningSvg} from "./Warning.svg";
import {ReactComponent as ClockingPlaySvg} from "./ClockingPlay.svg";
import {ReactComponent as ClockingStopSvg} from "./ClockingStop.svg";
import {ReactComponent as PeopleTeamToolboxSvg} from "./PeopleTeamToolbox.svg";
import {ReactComponent as InvalidQrCodeSvg} from "./InvalidQrCode.svg";

export type IIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
export type IconProps = React.SVGProps<SVGSVGElement> & { title?: string };
export type IconFunc = (props: IconProps) => ReactElement;

const renderSvgIcon = (Icon: IIcon): IconFunc => (props) => {
    const propsClone = {...props};
    propsClone.width = propsClone.width ?? 20;
    propsClone.height = propsClone.height ?? 20;
    return <Icon className={"custom-icon"} {...propsClone}/>;
}

export const ShopIcon = renderSvgIcon(ShopSvg);
export const ExportIcon = renderSvgIcon(ExportSvg);
export const CopyIcon = renderSvgIcon(CopySvg);
export const PrintIcon = renderSvgIcon(PrintSvg);
export const ShareIcon = renderSvgIcon(ShareSvg);
export const AgendaIcon = renderSvgIcon(AgendaSvg);
export const ArrowMoveIcon = renderSvgIcon(ArrowMoveSvg);
export const ArrowTrendingLinesIcon = renderSvgIcon(ArrowTrendingLinesSvg);
export const PeopleIcon = renderSvgIcon(PeopleSvg);
export const PersonAddIcon = renderSvgIcon(PersonAddSvg);
export const SettingsIcon = renderSvgIcon(SettingsSvg);
export const ScalesIcon = renderSvgIcon(ScalesSvg);
export const ShiftsIcon = renderSvgIcon(ShiftsSvg);
export const WindowBulletListIcon = renderSvgIcon(WindowBulletListSvg);
export const BellIcon = renderSvgIcon(BellSvg);
export const EditIcon = renderSvgIcon(EditSvg);
export const PasteIcon = renderSvgIcon(PasteSvg);
export const TimerIcon = renderSvgIcon(TimerSvg);
export const PeopleSettingsIcon = renderSvgIcon(PeopleSettingsSvg);
export const ImportIcon = renderSvgIcon(ImportSvg);
export const CartierShiftIcon = renderSvgIcon(CartierShiftSvg);
export const GlobeIcon = renderSvgIcon(GlobeSvg);
export const ArrowSyncCircleIcon = renderSvgIcon(ArrowSyncCircleSvg);
export const CheckmarkCircleIcon = renderSvgIcon(CheckmarkCircleSvg);
export const ShiftsQuestionIcon = renderSvgIcon(ShiftsQuestionSvg);
export const ClockIcon = renderSvgIcon(ClockSvg);
export const WarningIcon = renderSvgIcon(WarningSvg);
export const ClockingPlayIcon = renderSvgIcon(ClockingPlaySvg);
export const ClockingStopIcon = renderSvgIcon(ClockingStopSvg);
export const PeopleTeamToolboxIcon = renderSvgIcon(PeopleTeamToolboxSvg);
export const InvalidQrCodeIcon = renderSvgIcon(InvalidQrCodeSvg);