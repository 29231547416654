import React, {memo, ReactElement, useEffect, useMemo} from "react";
import {Props} from "./ShiftThresholdsPart.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ShiftThresholdsPart.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ShiftThresholdsPart.styles.scss";
import {useShiftThresholdsForm} from "hooks/forms/useShiftThresholdsForm";
import {Accordion} from "components/others/Accordion/Accordion";
import {ShiftsIcon} from "assets/icons";
import {translations} from "translations";
import {AddIcon, ArchiveIcon, Button, Flex, Text} from "@fluentui/react-northstar";
import {useForm} from "@witivio_teamspro/northstar-form";
import {CustomShiftThresholdButton} from "components/buttons/CustomShiftThresholdButton/CustomShiftThresholdButton";
import {useDialogContext} from "services/DialogContext/DialogContext";
import {useShopCache} from "hooks/cache/useShopsCache";
import {useGroupCache} from "../../../../../hooks/cache/groups/useGroupCache";
import {ShopShiftCategoryType} from "../../../../../interfaces/ShopData";

export const ShiftThresholdsPart = memo((props: Props): ReactElement | null => {
    const {customShiftThresholdDialog, planningConfigurationDialog} = useDialogContext();
    const {group, upsertThreshold} = useGroupCache(props.groupId);
    const {shop} = useShopCache(group?.shopId);
    const [state, dispatch] = useMagicReducer(reducer({
        props,
        customShiftThresholdDialog,
        planningConfigurationDialog,
        upsertThreshold,
    }), initialState);

    useEffect(function onUnmount() {
        return dispatch("saveShiftThresholds");
    }, []);

    const shiftsCategories = useMemo(() => shop?.categories.filter(c => c.type === ShopShiftCategoryType.Shift), [shop?.categories]);

    const selectShiftForm = useForm({
        items: {
            categoryId: {
                label: translations.get("SelectedCategory"),
                type: "dropdown",
                items: shiftsCategories?.map(s => s.key) ?? [],
                clearable: false,
                initialValue: shiftsCategories?.[0]?.key,
                renderSelectedItem: (id) => shiftsCategories?.find(s => s.key === id)?.name ?? "",
                renderItem: (id) => shiftsCategories?.find(s => s.key === id)?.name ?? "",
                placeholder: translations.get("SelectACategory"),
            }
        }
    });

    const selectedCategoryId = (selectShiftForm.state.data.categoryId ?? "") + "";

    const shiftThresholdsForm = useShiftThresholdsForm({
        groupId: props.groupId,
        categoryId: selectedCategoryId,
        categoryThresholds: state.updatedThresholds[selectedCategoryId],
    });

    useEffect(function onThresholdsChange() {
        dispatch("thresholdsUpdated", {
            categoryId: selectedCategoryId,
            thresholds: shiftThresholdsForm.thresholdsData,
            hasChanged: shiftThresholdsForm.hasChanged,
        })();
    }, [selectedCategoryId, shiftThresholdsForm.thresholdsData, shiftThresholdsForm.hasChanged]);

    const customThresholds = !selectedCategoryId ? null : (
        <Flex fill column gap={"gap.smaller"} styles={{minHeight: "250px"}} className={"overflow-hidden"}>
            <Text content={translations.get("CustomThresholds")}/>
            <Button
                className={"no-shrink"} fluid tinted icon={<AddIcon/>}
                content={translations.get("AddCustomThreshold")}
                onClick={dispatch("openCustomShiftThresholdDialog", selectedCategoryId)}
            />
            <Flex column fill gap={"gap.smaller"} className={"overflow-scroll"}>
                <Flex column gap={"gap.smaller"}>
                    {group?.thresholds.custom.filter(t => t.categoryId === selectedCategoryId).map((t) => (
                        <CustomShiftThresholdButton
                            key={t.id}
                            groupId={props.groupId}
                            customThresholdId={t.id}
                            categoryId={selectedCategoryId}
                        />
                    ))}
                </Flex>
            </Flex>
        </Flex>
    );

    const content = !selectedCategoryId ? renderDefaultMessage(dispatch("addCategory", group?.shopId)) : (
        <Flex fill column gap={"gap.medium"} className={"overflow-scroll"}>
            <Flex column gap={"gap.medium"} className={"no-shrink"}>
                {selectShiftForm.renderForm()}
                {shiftThresholdsForm.renderForm()}
            </Flex>
            {customThresholds}
        </Flex>
    );

    return (
        <Accordion
            card
            icon={ShiftsIcon}
            expandable={false}
            title={translations.get("ShiftThresholds")}
            content={content}
        />
    )
}, CompareModule.areObjectsEqual);

const renderDefaultMessage = (onAddCategory: () => void) => {
    return (
        <Flex fill column gap={"gap.medium"} vAlign={"center"} hAlign={"center"}>
            <ArchiveIcon outline size={"largest"}/>
            <Flex column hAlign={"center"} styles={{gap: "5px"}}>
                <Text size={"large"} weight={"semibold"} content={translations.get("NoCategoryAvailable")}/>
                <Text content={translations.get("CreateYourFirstCategory")}/>
            </Flex>
            <Button
                tinted content={translations.get("AddCategory")}
                icon={<AddIcon/>}
                onClick={onAddCategory}
            />
        </Flex>
    );
}