import React, {memo, ReactElement} from "react";
import {Props} from "./RequestPreview.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./RequestPreview.reducer";
import {CompareModule} from "modules/Compare.module";
import "./RequestPreview.styles.scss";
import {Flex, Text} from "@fluentui/react-northstar";
import {RequestType} from "../../../interfaces/RequestData";
import {SwapRequestPreview} from "./SwapRequestPreview/SwapRequestPreview";
import {ClockingUpdateRequestPreview} from "./ClockingUpdateRequestPreview/ClockingUpdateRequestPreview";
import {TimeOffRequestPreview} from "./TimeOffRequestPreview/TimeOffRequestPreview";
import {translations} from "../../../translations";
import {ShiftsQuestionIcon} from "../../../assets/icons";
import {useRequestsCache} from "../../../hooks/cache/useRequestsCache";
import {WeekSummaryRequestPreview} from "./WeekSummaryRequestPreview/WeekSummaryRequestPreview";

export const RequestPreview = memo((props: Props): ReactElement | null => {
    const {requests} = useRequestsCache();
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    const request = requests?.find(r => r.id === props.requestId);

    let content: ReactElement | null;
    switch (request?.type) {
        case RequestType.Swap:
            content = <SwapRequestPreview requestId={props.requestId}/>;
            break;
        case RequestType.UpdateClocking:
            content = <ClockingUpdateRequestPreview requestId={props.requestId}/>;
            break;
        case RequestType.TimeOff:
            content = <TimeOffRequestPreview requestId={props.requestId}/>;
            break;
        case RequestType.WeekSummary:
            content = <WeekSummaryRequestPreview requestId={props.requestId}/>;
            break;
        default:
            content = renderDefaultContent();
            break;
    }

    return (
        <Flex fill className={"request-preview fade"}>
            {content}
        </Flex>
    );
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderDefaultContent = () => {
    return (
        <Flex fill column vAlign={"center"} hAlign={"center"} gap={"gap.smaller"}>
            <ShiftsQuestionIcon width={80} height={80} color={"darkgray"}/>
            <Text styles={{color: "darkgray"}} size={"larger"} content={translations.get("SelectARequest")}/>
        </Flex>
    )
}