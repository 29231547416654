import {useQuery, useQueryClient} from "react-query";
import {GroupsCache} from "./helpers";

export const useGroupCache = (id: string | undefined) => {
    const queryClient = useQueryClient();

    const {data: group, isLoading} = useQuery(GroupsCache.getGroupQueryArgs(id));

    return {
        group,
        isLoading,
        getUserMaximumWorkingHours: GroupsCache.getUserMaximumWorkingHours(queryClient, id),
        upsertCustomThreshold: GroupsCache.upsertCustomThreshold(queryClient, id),
        deleteCustomThreshold: GroupsCache.deleteCustomThreshold(queryClient, id),
        upsertCustomUserLegalRules: GroupsCache.upsertCustomUserLegalRules(queryClient, id),
        deleteCustomUserLegalRules: GroupsCache.deleteCustomUserLegalRules(queryClient, id),
        addStaffs: GroupsCache.addStaffs(queryClient, id),
        deleteStaff: GroupsCache.deleteStaff(queryClient, id),
        updateLegalRules: GroupsCache.updateLegalRules(queryClient, id),
        upsertThreshold: GroupsCache.upsertThreshold(queryClient, id),
        rename: GroupsCache.rename(queryClient, id),
        remove: GroupsCache.remove(queryClient, id),
    };
}