import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {Immutable, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {ShiftPicker} from "../../components/others/ShiftPicker/ShiftPicker";
import React, {useState} from "react";
import {useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "../../translations";
import {Button, Flex, SendIcon} from "@fluentui/react-northstar";
import {useRequestsCache} from "../cache/useRequestsCache";
import {RequestType, UpdateClockingRequestData} from "../../interfaces/RequestData";
import moment from "moment/moment";
import {useUserShopCache} from "../cache/useShopsCache";
import {FormModule} from "../../modules/Form.module";
import {Shift} from "../../classes/Shift";

export const useUpdateClockingRequestForm = () => {
    const {shop} = useUserShopCache();
    const {createRequest} = useRequestsCache();
    const {userId, isOnMobile} = useMsTeamsSelector("userId", "isOnMobile");
    const shiftPickerRef = useMagicReducerRef(ShiftPicker);
    const [isSaving, setIsSaving] = useState(false);

    const form = useForm({
        disabled: isSaving,
        items: {
            ...FormModule.generateTimeRangeItems({required: true, showLabel: true}),
            justification: {
                type: "textArea",
                required: true,
                label: translations.get("Justification"),
                placeholder: translations.get("EnterJustification"),
                maxLength: 300,
                minHeight: "100px",
                maxHeight: "100px",
            },
        }
    });

    const isValid = form.isValid && shiftPickerRef.state?.selectedShift;

    const renderSendButton = (callback: () => void) => (
        <Button
            className={"no-shrink"}
            primary icon={<SendIcon outline/>}
            content={translations.get("SendRequest")}
            disabled={!isValid || isSaving}
            loading={isSaving}
            fluid={isOnMobile}
            onClick={handleSendRequest(
                userId, shop?.id, form.state.data,
                shiftPickerRef.state?.selectedShift,
                createRequest, setIsSaving, callback, form.reset
            )}
        />
    )

    const renderForm = () => (
        <Flex column fill gap={"gap.medium"}>
            <ShiftPicker
                externalRef={shiftPickerRef}
                label={translations.get("ShiftToFix")}
                onlyMyShifts
                onlyDoneShifts
                onShiftSelected={handleShiftSelected(form)}
            />
            <Flex column gap={"gap.medium"}>
                <Flex gap={"gap.medium"}>
                    {form.formItems.startTime}
                    {form.formItems.endTime}
                </Flex>
                {form.formItems.justification}
            </Flex>
        </Flex>
    )

    return {
        ...form,
        renderForm,
        renderSendButton
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleShiftSelected = (
    form: Omit<ReturnType<typeof useUpdateClockingRequestForm>, "renderSendButton">,
) => (shift: Immutable<Shift>) => {
    const clocking = shift.getClocking();
    if (!clocking) return;
    form.setFieldsValues({
        startTime: clocking?.start,
        endTime: clocking?.end,
    });
}

const handleSendRequest = (
    userId: string,
    shopId: string | undefined,
    formData: ReturnType<typeof useUpdateClockingRequestForm>["state"]["data"],
    shift: Immutable<Shift> | undefined,
    createRequest: ReturnType<typeof useRequestsCache>["createRequest"],
    setIsSaving: React.Dispatch<React.SetStateAction<boolean>>,
    callback: () => void,
    reset: () => void,
) => async () => {
    if (!shopId || !shift) return;
    const request = generateRequest(userId, shopId, shift, formData);
    setIsSaving(true);
    await createRequest(request);
    reset();
    callback();
    setIsSaving(false);
}

const generateRequest = (
    userId: string,
    shopId: string,
    shift: Immutable<Shift> | undefined,
    formData: ReturnType<typeof useUpdateClockingRequestForm>["state"]["data"],
): UpdateClockingRequestData => {
    return {
        id: "",
        type: RequestType.UpdateClocking,
        userId,
        shopId,
        date: moment().toISOString(false),
        shiftId: shift?.getId() as string,
        justification: formData.justification as string,
        prevStartTime: shift?.getClocking()?.start,
        prevEndTime: shift?.getClocking()?.end,
        startTime: formData.startTime,
        endTime: formData.endTime,
        clockedOutsideStore: false,
        response: undefined,
    }
}