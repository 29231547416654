import {useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "translations";
import {ArrowRightIcon, Button, Flex, FormLabel, Text} from "@fluentui/react-northstar";
import moment from "moment";
import {WeekDaysModule} from "../../modules/WeekDays.module";
import {useShopGroupsCache} from "../cache/groups/useShopGroupsCache";
import {usePlanningDateRangeCache} from "../cache/usePlanningDateRangeCache";
import {useEffect, useState} from "react";
import {CopyIcon} from "../../assets/icons";
import {CopyPlanningRequest} from "../../interfaces/CopyPlanningRequest";
import {useShiftsCache} from "../cache/useShiftsCache";
import {FormModule} from "../../modules/Form.module";

export const useCopyPlanningForm = (shopId: string | undefined) => {
    const {copyShiftsRange} = useShiftsCache();
    const {planningDateRange: {selectedRange}} = usePlanningDateRangeCache();
    const {groups, groupsIds} = useShopGroupsCache(shopId);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        form.setFieldsInitialValues({
            startDate: selectedRange.startDate,
            endDate: selectedRange.endDate,
            copy: 1,
            includeOpenShifts: true,
            includeActivities: true,
            includeAbsences: true,
            includeNotes: true,
            destinationDate: undefined,
            copyScheduleGroups: (groupsIds ?? []) as string[],
        });
        form.reset();
    }, [shopId, selectedRange, groupsIds]);

    const form = useForm({
        disabled: isSaving,
        items: {
            ...FormModule.generateDateRangeItems({showLabel: true, required: true}),
            includeNotes: {
                type: "checkbox",
                label: translations.get("Notes"),
                initialValue: true,
            },
            includeActivities: {
                type: "checkbox",
                label: translations.get("Activities"),
                initialValue: true,
            },
            includeAbsences: {
                type: "checkbox",
                label: translations.get("Absences"),
                initialValue: true,
            },
            includeOpenShifts: {
                type: "checkbox",
                label: translations.get("OpenShifts"),
                initialValue: true,
            },
            copyScheduleGroups: {
                type: "dropdown",
                multiple: true,
                items: (groupsIds ?? []) as string[],
                renderItem: (id) => groups?.find(g => g.id === id)?.name ?? "",
                renderSelectedItem: (id) => groups?.find(g => g.id === id)?.name ?? "",
                label: translations.get("CopyScheduleGroups"),
                required: true,
                placeholder: translations.get("SelectGroupsToCopy"),
                clearable: false,
            },
            destinationDate: {
                type: "datePicker",
                required: true,
                label: translations.get("DestinationDate"),
                placeholder: translations.get("SelectADate"),
            },
            copy: {
                type: "input",
                inputMode: "numeric",
                label: translations.get("Copy"),
                required: true,
                min: 1,
                max: 10,
                initialValue: 1,
                clearable: false,
                inputIcon: <Text styles={{color: "darkgray"}} content={translations.get("Time(s)").toLowerCase()}/>,
            }
        }
    });

    const {
        startDate,
        endDate,
        includeNotes,
        includeAbsences,
        includeActivities,
        includeOpenShifts,
        copyScheduleGroups,
        destinationDate,
        copy
    } = form.formItems;

    const renderCopyButton = (callback: () => void) => (
        <Button
            primary icon={<CopyIcon/>}
            content={translations.get("Copy")}
            onClick={handleCopyPlanning({
                shopId,
                formDate: form.state.data,
                copyShiftsRange,
                callback,
                setIsSaving,
            })}
            disabled={!form.isValid || isSaving}
            loading={isSaving}
        />
    )

    const renderForm = () => (
        <Flex column fill gap={"gap.medium"}>
            <Flex vAlign={"end"} gap={"gap.small"}>
                {startDate}
                <ArrowRightIcon styles={{color: "darkgray", marginBottom: "8px"}}/>
                {endDate}
            </Flex>
            <Flex column>
                <FormLabel content={translations.get("Include")}/>
                <Flex wrap gap={"gap.medium"}>
                    {includeNotes}
                    {includeActivities}
                    {includeAbsences}
                    {includeOpenShifts}
                </Flex>
            </Flex>
            {copyScheduleGroups}
            <Flex gap={"gap.medium"}>
                {destinationDate}
                <Flex fill styles={{maxWidth: "100px"}}>
                    {copy}
                </Flex>
            </Flex>
            {renderCopyRangeLabel(form.state.data)}
        </Flex>
    )

    return {
        ...form,
        renderForm,
        renderCopyButton
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderCopyRangeLabel = (formData: ReturnType<typeof useCopyPlanningForm>["state"]["data"]) => {
    const startDate = formData.startDate as string | undefined;
    const endDate = formData.endDate as string | undefined;
    const destinationDate = formData.destinationDate as string | undefined;
    const copy = formData.copy as number;
    if (!startDate || !endDate || !destinationDate) return null;
    const firstRangeLabel = `${formatDate(startDate)} - ${formatDate(endDate)}`;
    const rangeDaysCount = WeekDaysModule.getRangeDays(startDate, endDate).length;
    const finalCopyDate = moment(destinationDate).add((copy * rangeDaysCount) - 1, "days").toISOString(false);
    const secondRangeLabel = `${formatDate(destinationDate)} - ${formatDate(finalCopyDate)}`;
    return (
        <Text
            content={translations.get("YouCopyRangeFromTo", {from: firstRangeLabel, to: secondRangeLabel})}
            styles={{color: "darkgray"}}
        />
    )
}

const formatDate = (date: string) => {
    return moment(date).toDate().toLocaleDateString(moment.locale(), {
        month: 'numeric',
        day: 'numeric',
    });
}

const handleCopyPlanning = (params: {
    shopId: string | undefined,
    formDate: ReturnType<typeof useCopyPlanningForm>["state"]["data"],
    copyShiftsRange: ReturnType<typeof useShiftsCache>["copyShiftsRange"],
    callback: () => void,
    setIsSaving: (value: boolean) => void,
}) => async () => {
    const {shopId, formDate, copyShiftsRange, callback, setIsSaving} = params;
    const {
        startDate,
        endDate,
        destinationDate,
        copy,
        includeNotes,
        includeAbsences,
        includeActivities,
        includeOpenShifts,
        copyScheduleGroups
    } = formDate;
    const copyRequest: CopyPlanningRequest = {
        startDate: startDate as string,
        endDate: endDate as string,
        destinationDate: destinationDate as string,
        occurrences: copy as number,
        includeNotes: includeNotes as boolean,
        includeAbsences: includeAbsences as boolean,
        includeActivities: includeActivities as boolean,
        includeOpenShifts: includeOpenShifts as boolean,
        groupsIds: copyScheduleGroups as string[],
    }
    setIsSaving(true);
    await copyShiftsRange({shopId, copyRequest});
    setIsSaving(false);
    callback();
}