import {Props, Rect, State} from "./SelectionArea.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";

export const initialState: State = {
    isSelecting: false,
    selectionRect: undefined,
    listeners: [],
}

export const reducer = (props: Props) => ({
    mouseDown: (reducerData, [e]: [MouseEvent]) => {
        const {setState} = reducerData;
        if (e.button !== 0) return;
        e.stopPropagation();
        const selectionRect: Rect = {startX: e.clientX, startY: e.clientY, endX: e.clientX, endY: e.clientY};
        const isValid = isInsideArea(props.restrictedArea, e.clientX, e.clientY);
        if (!isValid) setState({isSelecting: false, selectionRect: undefined});
        else setState({isSelecting: true, selectionRect});
        reducer(props).notifySelection(reducerData, undefined, isValid ? selectionRect : undefined);
    },
    mouseMove: (reducerData, [e]: [MouseEvent]) => {
        const {state, setState} = reducerData;
        if (!state.isSelecting || !state.selectionRect) return;
        const selectionRect: Rect = {
            ...state.selectionRect,
            endX: e.clientX,
            endY: e.clientY,
        };
        setState({isSelecting: true, selectionRect: selectionRect});
        reducer(props).notifySelection(reducerData, undefined, selectionRect);
    },
    mouseUp: ({state, setState}, [_]: [MouseEvent]) => {
        if (!state.isSelecting) return;
        setState({isSelecting: false});
    },
    clearSelection: (reducerData, [e]: [MouseEvent]) => {
        const {state, setState} = reducerData;
        if (e.button !== 0) return;
        if (!state.selectionRect) return;
        setState({isSelecting: false, selectionRect: undefined});
        reducer(props).notifySelection(reducerData, undefined, undefined);
    },
    notifySelection: ({state}, __, rect: Rect | undefined) => {
        state.listeners.forEach(l => l(rect));
    },
    addListener: ({state, setState}, [listener]: [(rect: Rect | undefined) => void]) => {
        setState({listeners: [...state.listeners, listener]}, false);
    },
    removeListener: ({state, setState}, [listener]: [(rect: Rect | undefined) => void]) => {
        setState({listeners: state.listeners.filter(l => l !== listener)}, false);
    },
}) satisfies MagicReducerObject<State>;

const isInsideArea = (rect: Rect | undefined, x: number, y: number) => {
    if (!rect) return true;
    return x >= rect.startX && x <= rect.endX && y >= rect.startY && y <= rect.endY;
}