import {MagicReducerExternalRef} from "@witivio_teamspro/use-reducer";
import {reducer} from "./PlanningConfigurationDialog.reducer";
import {DialogProps} from "../Dialog/Dialog.interfaces";

export enum PlanningConfigurationDialogTab {
    Shop = 0,
    Categories = 1,
    Activities = 2,
    Groups = 3
}

export type State = {
    shopId: string,
    activeTab: PlanningConfigurationDialogTab,
    onBeforeClose: DialogProps["onBeforeClose"],
}

export type Props = {
    externalRef: MagicReducerExternalRef<typeof reducer>,
}