import {useQuery} from "react-query";
import {BadgeApi} from "../../apis/Badge/BadgeApi";

export const userCanBadgeCacheKey = "userCanBadge";

export const useUserBadgeCache = () => {
    const {data: canBadge, isLoading} = useQuery([userCanBadgeCacheKey], BadgeApi.canUserBadge, {
        staleTime: Infinity,
    });

    return {
        canBadge,
        isLoading,
    };
}