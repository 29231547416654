import {State} from "./PersonLegalRulesDialog.interfaces";
import {MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";

export const initialState: State = {
    groupId: "",
    userId: "",
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, {groupId, userId}: { groupId: string, userId: string }) => {
        setState({groupId, userId});
        config.dialogRef.dispatch("open")();
    },
    reset: ({setState}) => {
        setState({...initialState});
    },
}) satisfies MagicReducerObject<State>;