import {memo, ReactElement, useEffect, useMemo} from "react";
import {Props} from "./Header.interfaces";
import {Immutable, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Header.reducer";
import "./Header.styles.scss";
import {ComponentSlotStyle, Flex, Text, Tooltip} from "@fluentui/react-northstar";
import {Cell} from "components/others/Cell/Cell";
import {translations} from "translations";
import {WeekDaysModule} from "modules/WeekDays.module";
import moment, {Moment} from "moment";
import {useDaysNotesRangeCache} from "hooks/cache/useDaysNotesCache";
import {GroupData} from "../../../../interfaces/GroupData";
import {DayNoteData} from "../../../../interfaces/DayNoteData";
import {GroupModule} from "../../../../modules/Group.module";
import {useDialogContext} from "../../../../services/DialogContext/DialogContext";
import {DialogContextValue} from "../../../../services/DialogContext/DialogContext.interfaces";
import {usePlanningStaffInfoCache} from "../../../../hooks/cache/useStaffsCountCache";
import {StaffInfoData} from "../../../../interfaces/StaffsCountData";
import {CompareModule} from "../../../../modules/Compare.module";
import {usePlanningDateRangeCache} from "../../../../hooks/cache/usePlanningDateRangeCache";
import {useUserRolesCache} from "../../../../hooks/cache/useUserRoleCache";

export const Header = memo((props: Props): ReactElement | null => {
    const {dayNotesDialog} = useDialogContext();
    const {planningDateRange} = usePlanningDateRangeCache();
    const {canUpdateShop} = useUserRolesCache();
    const {selectedRange} = planningDateRange;
    const {daysNotes} = useDaysNotesRangeCache(props.shopId, selectedRange.startDate, selectedRange.endDate);
    const {staffInfo} = usePlanningStaffInfoCache(props.shopId, selectedRange.startDate, selectedRange.endDate);
    const [state, dispatch] = useMagicReducer(reducer, initialState);

    useEffect(() => {
        const handler = dispatch("windowResize");
        window.addEventListener("resize", handler);
        handler();
        return () => window.removeEventListener("resize", handler);
    }, []);

    const styles: ComponentSlotStyle = {
        paddingRight: props.scrollbarWidth + "px",
    }

    const readOnly = !canUpdateShop(props.shopId);

    const daysRange = WeekDaysModule.getRangeDays(selectedRange.startDate, selectedRange.endDate);

    const groupsIds = useMemo(() => props.groups?.map(g => g.id) ?? [], [props.groups]);

    const filteredStaffInfos = useMemo(() => (
        staffInfo?.filter(sc => groupsIds.includes(sc.groupId) && (!props.categoriesIds || props.categoriesIds.includes(sc.categoryId)))
    ), [staffInfo, groupsIds, props.categoriesIds]);

    return (
        <Flex className={"planning-header"} column styles={styles}>
            <Flex fill>
                <Cell className={"first-cell"} fluid primary>
                    <Flex fill className={"overflow-hidden"}>
                        <Text className={"no-shrink"} content={translations.get("Date")}/>
                    </Flex>
                </Cell>
                {daysRange.map(day => renderDateCell(props.shopId, day, daysRange.length, state.visibleCells))}
            </Flex>
            <Flex fill>
                <Cell className={"first-cell"} fluid primary><Text content={translations.get("Staff")}/></Cell>
                {daysRange.map(day => renderStaffCountCell(props.shopId, day, props.groups, filteredStaffInfos))}
            </Flex>
            <Flex fill>
                <Cell className={"first-cell"} fluid primary><Text content={translations.get("Notes")}/></Cell>
                {daysRange.map(day => renderNotesCell(props.shopId, day, daysNotes, dayNotesDialog, readOnly))}
            </Flex>
        </Flex>
    )
}, (pp, np) => {
    return CompareModule.areObjectsEqual(pp, np) && pp.groups === np.groups;
});

const renderDateCell = (
    shopId: string | undefined,
    date: Moment,
    daysCount: number,
    visibleCells: number,
) => {
    if (!shopId) return null;
    const isoDate = date.toISOString(false);
    const isWeekend = WeekDaysModule.isDateWeekEnd(isoDate);
    const isToday = WeekDaysModule.isToday(isoDate);
    const cellClassName = isToday ? "planning-today-cell" : isWeekend ? "planning-weekend-cell" : "";
    if (daysCount >= visibleCells) return (
        <Cell key={shopId + isoDate} fluid className={cellClassName}>
            <Flex fill column hAlign={"center"}>
                <Text weight={"bold"} content={date.format("D")}/>
                <Text content={date.format("ddd")}/>
            </Flex>
        </Cell>
    )
    const dayLabel = date.format("ddd");
    const dateLabel = date.toDate().toLocaleDateString(moment.locale(), {
        month: 'numeric',
        day: 'numeric',
    });
    return (
        <Cell key={shopId + isoDate} fluid className={cellClassName}>
            <Flex fill gap={"gap.smaller"} className={"overflow-hidden"}>
                <Text truncated className={"no-shrink"}>
                    <Text content={dayLabel + " "}/>
                    <Text content={dateLabel} weight={"bold"}/>
                </Text>
            </Flex>
        </Cell>
    )
}

const renderStaffCountCell = (
    shopId: string | undefined,
    date: Moment,
    groups: Immutable<Array<GroupData>> | undefined,
    staffsInfo: Immutable<Array<StaffInfoData>> | undefined,
) => {
    if (!shopId) return null;
    const isoDate = date.toISOString(false);
    const isWeekend = WeekDaysModule.isDateWeekEnd(isoDate);
    const isToday = WeekDaysModule.isToday(isoDate);
    const categoriesIds = Array.from(new Set(staffsInfo?.map(sc => sc.categoryId)));
    const dateStaffCount = staffsInfo?.filter(sc => moment(sc.date).isSame(date)) ?? [];
    const totalStaffCount = dateStaffCount.reduce((acc, sc) => acc + (sc.shiftsCount > sc.plannedStaffCount ? 0 : sc.shiftsCount), 0);
    const totalMaxStaffCount = groups?.reduce((acc, g) => {
        return GroupModule.getTotalThresholdByDate(g, isoDate, categoriesIds) + acc;
    }, 0) ?? 0;
    const isUnderstaffed = totalStaffCount < totalMaxStaffCount;
    const label = `${totalStaffCount}/${totalMaxStaffCount}`;
    const labelStyles: ComponentSlotStyle = isUnderstaffed ? {color: "red"} : {};
    const sortedGroups = GroupModule.sortGroupsByOrder(groups);
    const cellClassName = isToday ? "planning-today-cell" : isWeekend ? "planning-weekend-cell" : "";
    return (
        <Cell className={cellClassName} key={shopId + isoDate} fluid>
            <Tooltip
                position={"below"}
                align={"start"}
                trigger={<Text className={"w-100"} content={label} styles={labelStyles}/>}
                offset={[-10, 5]}
                content={
                    <Flex fill gap={"gap.medium"}>
                        <Flex column gap={"gap.small"}>
                            {sortedGroups?.map(g => (
                                <Text size={"medium"} weight={"semibold"} key={shopId + g.id} content={g.name}/>
                            ))}
                        </Flex>
                        <Flex column gap={"gap.small"}>
                            {sortedGroups?.map(g => {
                                const groupStaffCount = dateStaffCount.find(sc => sc.groupId === g.id);
                                const count = groupStaffCount?.shiftsCount ?? 0;
                                const maxCount = GroupModule.getTotalThresholdByDate(g, isoDate, categoriesIds);
                                return <Text size={"medium"} key={shopId + g.id} content={`${count}/${maxCount}`}/>;
                            })}
                        </Flex>
                    </Flex>
                }
            />
        </Cell>
    )
}

const renderNotesCell = (
    shopId: string | undefined,
    date: Moment,
    daysNotes: readonly Immutable<DayNoteData>[] | undefined,
    dayNotesDialog: DialogContextValue["dayNotesDialog"],
    readOnly: boolean,
) => {
    const isoDate = date.toISOString(false);
    const isWeekend = WeekDaysModule.isDateWeekEnd(isoDate);
    const isToday = WeekDaysModule.isToday(isoDate);
    const notes = daysNotes?.find(dn => moment(dn.date).isSame(date, "day"))?.notes ?? "";
    const cellClassName = isToday ? "planning-today-cell" : isWeekend ? "planning-weekend-cell" : "";
    const onClick = readOnly ? undefined : dayNotesDialog.dispatch("open", {date: isoDate, shopId, notes});
    return (
        <Cell key={shopId + isoDate} fluid className={"cursor-pointer " + cellClassName} onClick={onClick}>
            <Tooltip
                position={"below"}
                align={"start"}
                offset={[0, 5]}
                trigger={<Text content={notes} truncated/>}
                content={<Text content={notes}/>}
            />
        </Cell>
    )
}