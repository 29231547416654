import {Immutable, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {ShiftPicker} from "../../components/others/ShiftPicker/ShiftPicker";
import {useUserCache} from "../cache/useUsersCache";
import {ArrowSyncIcon, Button, Flex, SendIcon, Text} from "@fluentui/react-northstar";
import {translations} from "../../translations";
import React, {useReducer, useState} from "react";
import {useForm} from "@witivio_teamspro/northstar-form";
import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {useRequestsCache} from "../cache/useRequestsCache";
import {RequestType, SwapRequestData, SwapRequestShiftData} from "../../interfaces/RequestData";
import moment from "moment/moment";
import {useUserShopCache} from "../cache/useShopsCache";
import {Shift} from "../../classes/Shift";

export const useSwapRequestForm = (options?: {
    defaultFromShift?: Immutable<Shift> | undefined,
    defaultToShift?: Immutable<Shift> | undefined,
} | undefined) => {
    const {shop} = useUserShopCache();
    const {userId, isOnMobile} = useMsTeamsSelector("userId", "isOnMobile");
    const {createRequest} = useRequestsCache();
    const fromShiftPickerRef = useMagicReducerRef(ShiftPicker);
    const toShiftPickerRef = useMagicReducerRef(ShiftPicker);
    const {user: toUser} = useUserCache(toShiftPickerRef.state?.selectedShift?.getUserId());
    const [, render] = useReducer(() => ({}), {});
    const [isSaving, setIsSaving] = useState(false);

    const form = useForm({
        disabled: isSaving,
        items: {
            notes: {
                type: "textArea",
                label: translations.get("Notes"),
                placeholder: translations.get("AddANote"),
                maxLength: 300,
                minHeight: "100px",
                maxHeight: "100px",
            }
        }
    });

    const isValid = form.isValid && fromShiftPickerRef.state?.selectedShift && toShiftPickerRef.state?.selectedShift;

    const renderSendButton = (callback: () => void) => (
        <Button
            className={"no-shrink"}
            primary icon={<SendIcon outline/>}
            content={translations.get("SendRequest")}
            disabled={!isValid || isSaving}
            loading={isSaving}
            fluid={isOnMobile}
            onClick={handleSendRequest(
                userId, shop?.id, form.state.data,
                fromShiftPickerRef.state?.selectedShift,
                toShiftPickerRef.state?.selectedShift,
                createRequest, setIsSaving, callback, form.reset
            )}
        />
    )

    const renderForm = () => (
        <Flex column fill gap={"gap.medium"}>
            <ShiftPicker
                externalRef={fromShiftPickerRef}
                label={translations.get("IWantToExchangeThisShift")}
                onlyMyShifts
                defaultSelectedShift={options?.defaultFromShift}
            />
            {!toUser ? null :
                <Flex className={"no-shrink"} hAlign={"center"} style={{marginBottom: isOnMobile ? "15px" : "-5px"}}
                      gap={"gap.small"}>
                    <ArrowSyncIcon outline size={"large"}/>
                    <Flex style={{gap: "5px"}}>
                        <Text content={translations.get("SwapWith")}/>
                        <Text weight={"semibold"} content={toUser?.displayName}/>
                    </Flex>
                </Flex>
            }
            <ShiftPicker
                externalRef={toShiftPickerRef}
                label={translations.get("AgainstThisShift")}
                onShiftSelected={render}
                defaultSelectedShift={options?.defaultToShift}
            />
            <Flex>
                {form.formItems.notes}
            </Flex>
        </Flex>
    )

    return {
        ...form,
        renderForm,
        renderSendButton
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleSendRequest = (
    userId: string,
    shopId: string | undefined,
    formData: ReturnType<typeof useSwapRequestForm>["state"]["data"],
    fromShift: Immutable<Shift> | undefined,
    toShift: Immutable<Shift> | undefined,
    createRequest: ReturnType<typeof useRequestsCache>["createRequest"],
    setIsSaving: React.Dispatch<React.SetStateAction<boolean>>,
    callback: () => void,
    reset: () => void,
) => async () => {
    if (!shopId || !fromShift || !toShift) return;
    const request = generateRequest(userId, shopId, fromShift, toShift, formData);
    setIsSaving(true);
    await createRequest(request);
    reset();
    callback();
    setIsSaving(false);
}

const generateRequest = (
    userId: string,
    shopId: string,
    fromShift: Immutable<Shift>,
    toShift: Immutable<Shift>,
    formData: ReturnType<typeof useSwapRequestForm>["state"]["data"],
): SwapRequestData => {
    return {
        id: "",
        type: RequestType.Swap,
        userId,
        shopId,
        date: moment().toISOString(false),
        note: formData.notes as string,
        fromShift: mapShiftToSwapRequestShiftData(fromShift),
        toShift: mapShiftToSwapRequestShiftData(toShift),
        toUserResponse: undefined,
        response: undefined,
    }
}

const mapShiftToSwapRequestShiftData = (shift: Immutable<Shift>): SwapRequestShiftData => ({
    id: shift.getId(),
    shopId: shift.getShopId()!,
    date: shift.getDate()!,
    start: shift.getStart()!,
    end: shift.getEnd()!,
    userId: shift.getUserId()!,
    type: shift.getType()!,
    categoryId: shift.getCategoryId()!,
    customWording: shift.getCustomWording(),
});