import React, {memo, ReactElement, useEffect, useMemo} from "react";
import {Props} from "./SchedulesMobile.interfaces";
import {MagicReducerRef, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./SchedulesMobile.reducer";
import {CompareModule} from "modules/Compare.module";
import "./SchedulesMobile.styles.scss";
import {Button, Flex, SearchIcon, Text} from "@fluentui/react-northstar";
import {Clocking} from "../../../components/others/Clocking/Clocking";
import {translations} from "../../../translations";
import {PeopleTeamToolboxIcon, ShiftsIcon, ShopIcon} from "../../../assets/icons";
import {MobileAddButton} from "../../../components/buttons/MobileAddButton/MobileAddButton";
import {AddShiftSideView} from "./AddShiftSideView/AddShiftSideView";
import {useForm} from "@witivio_teamspro/northstar-form";
import {useMsTeamsSelector} from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {GraphService} from "../../../services/GraphService/GraphService";
import {useShopsCache, useUserShopCache} from "../../../hooks/cache/useShopsCache";
import {useShopGroupsCache} from "../../../hooks/cache/groups/useShopGroupsCache";
import {UserShiftsSideView} from "./UserShiftsSideView/UserShiftsSideView";
import {useUserLastShiftCache} from "../../../hooks/cache/useUserLastShiftCache";
import {Shift} from "../../../classes/Shift";
import {ShiftCard} from "../../../components/others/ShiftCard/ShiftCard";
import moment from "moment";
import {LoadingPage} from "../../LoadingPage/LoadingPage";
import {ShopShiftsSideView} from "./ShopShiftsSideView/ShopShiftsSideView";
import {ShiftDetailsSideView} from "./ShiftDetailsSideView/ShiftDetailsSideView";
import {useUserRolesCache} from "../../../hooks/cache/useUserRoleCache";

export const SchedulesMobile = memo((props: Props): ReactElement | null => {
    const {userId} = useMsTeamsSelector("userId");
    const userShiftsSideViewRef = useMagicReducerRef(UserShiftsSideView);
    const shopShiftsSideViewRef = useMagicReducerRef(ShopShiftsSideView);
    const addShiftSideViewRef = useMagicReducerRef(AddShiftSideView);
    const shiftDetailsSideViewRef = useMagicReducerRef(ShiftDetailsSideView);
    const {shops} = useShopsCache();
    const {shop: userShop} = useUserShopCache();
    const {groups, groupsIds, isLoading: areGroupsLoading} = useShopGroupsCache(userShop?.id);
    const {canUpdateShop, isLoading: areUserRolesLoading} = useUserRolesCache();
    const {lastUserShift, isLoading: isLoadingShift} = useUserLastShiftCache();
    const [] = useMagicReducer(reducer, initialState);

    const readOnly = !canUpdateShop(userShop?.id) || areUserRolesLoading;

    const allGroupsUsersIds = useMemo(() => groups?.flatMap(g => g.usersIds) ?? [], [groupsIds, areGroupsLoading]);

    const form = useForm({
        items: {
            findStaff: {
                type: "peoplePicker",
                inputIcon: <SearchIcon outline styles={{color: "darkgray"}}/>,
                placeholder: translations.get("SearchStaffPlanning"),
                fetchUsers: (search) => {
                    if (!search) return GraphService.getUsersAsync(allGroupsUsersIds.slice(0, 5));
                    return GraphService.searchForUsersAsync(search)
                },
                fetchInitialUsers: (ids) => GraphService.getUsersAsync(ids),
            },
            findShop: {
                type: "dropdown",
                search: true,
                items: shops?.map(s => s.id) ?? [],
                renderItem: (id) => shops?.find(s => s.id === id)?.name ?? "",
                renderSelectedItem: (id) => shops?.find(s => s.id === id)?.name ?? "",
                placeholder: translations.get("SearchShopPlanning"),
            }
        }
    });

    const selectedSearchUserId = form.state.data.findStaff?.[0];
    const selectedShopId = form.state.data.findShop;

    useEffect(function onSearchUserSelected() {
        if (!selectedSearchUserId) return;
        userShiftsSideViewRef.dispatch("open", selectedSearchUserId)();
        form.reset();
    }, [selectedSearchUserId]);

    useEffect(function onSearchShopSelected() {
        if (!selectedShopId) return;
        shopShiftsSideViewRef.dispatch("open", selectedShopId as string)();
        form.reset();
    }, [selectedShopId]);

    if (isLoadingShift) return <LoadingPage/>;

    return (
        <Flex fill column className={"schedules-mobile-view"} styles={{gap: "20px"}}>
            <Clocking/>
            {renderNextShiftPart({
                userShiftsSideViewRef,
                userId,
                lastUserShift,
                isLoadingShift,
                shiftDetailsSideViewRef
            })}
            {renderStaffPart({staffInput: form.formItems.findStaff})}
            {renderShopsPart({
                shopsInput: form.formItems.findShop,
                myShopShiftsSideViewRef: shopShiftsSideViewRef,
                userShopId: userShop?.id ?? ""
            })}
            {!readOnly && <MobileAddButton onClick={addShiftSideViewRef.dispatch("open")}/>}
            <UserShiftsSideView shiftDetailsSideViewRef={shiftDetailsSideViewRef} externalRef={userShiftsSideViewRef}/>
            <ShopShiftsSideView shiftDetailsSideViewRef={shiftDetailsSideViewRef} externalRef={shopShiftsSideViewRef}/>
            {!readOnly && <AddShiftSideView externalRef={addShiftSideViewRef} currentShopId={userShop?.id}/>}
            <ShiftDetailsSideView externalRef={shiftDetailsSideViewRef}/>
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderNextShiftPart = (config: {
    userId: string,
    userShiftsSideViewRef: MagicReducerRef<typeof UserShiftsSideView>,
    lastUserShift: Shift | undefined,
    isLoadingShift: boolean,
    shiftDetailsSideViewRef: MagicReducerRef<typeof ShiftDetailsSideView>
}) => {
    const shiftDate = config.lastUserShift?.getDate();
    const now = moment().startOf("day");
    const isNextShift = !shiftDate ? true : moment(shiftDate).isAfter(now, "day");
    const showShiftCard = config.isLoadingShift || !!config.lastUserShift;
    return (
        <Flex column gap={"gap.small"}>
            <Flex styles={{gap: "5px"}} vAlign={"center"}>
                <ShiftsIcon/>
                <Text size={"large"} weight={"semibold"}
                      content={translations.get(isNextShift ? "NextShift" : "Today")}/>
            </Flex>
            <Flex>
                {showShiftCard ?
                    <ShiftCard
                        showDate={isNextShift} readonly shift={config.lastUserShift}
                        onClick={config.shiftDetailsSideViewRef.dispatch("open", config.lastUserShift)}
                    />
                    :
                    <Text content={translations.get("NoUpcomingShift")} style={{color: "darkgray"}}/>
                }
            </Flex>
            <Button
                fluid icon={<ShiftsIcon/>} content={translations.get("AllMyShifts")}
                onClick={config.userShiftsSideViewRef.dispatch("open", config.userId)}
            />
        </Flex>
    )
}

const renderStaffPart = (config: {
    staffInput: ReactElement | null,
}) => {
    return (
        <Flex column gap={"gap.smaller"}>
            <Flex styles={{gap: "5px"}} vAlign={"center"}>
                <PeopleTeamToolboxIcon/>
                <Text size={"large"} weight={"semibold"} content={translations.get("Staff")}/>
            </Flex>
            {config.staffInput}
        </Flex>
    )
}

const renderShopsPart = (config: {
    userShopId: string,
    shopsInput: ReactElement | null,
    myShopShiftsSideViewRef: MagicReducerRef<typeof ShopShiftsSideView>
}) => {
    return (
        <Flex column gap={"gap.small"}>
            <Flex styles={{gap: "5px"}} vAlign={"center"}>
                <ShopIcon/>
                <Text size={"large"} weight={"semibold"} content={translations.get("Shops")}/>
            </Flex>
            {config.shopsInput}
            <Button
                disabled={!config.userShopId}
                fluid icon={<ShopIcon/>} content={translations.get("MyShop")}
                onClick={config.myShopShiftsSideViewRef.dispatch("open", config.userShopId)}
            />
        </Flex>
    )
}