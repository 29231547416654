import {Immutable, MagicReducerExternalRef} from "@witivio_teamspro/use-reducer";
import {reducer} from "./ShiftRecurrence.reducer";

export enum RecurrenceType {
    Day,
    Week,
    Month,
    Year,
}

export type Recurrence = {
    id: string,
    type: RecurrenceType,
    interval: number,
    days: Array<number>,
    endDate: string,
}

export type State = Recurrence & {
    hasChanged: boolean,
};

export type Props = {
    shiftDate: string | undefined,
    initialRecurrence: Immutable<Recurrence> | undefined,
    externalRef: MagicReducerExternalRef<typeof reducer>,
    disabled?: boolean | undefined,
    onChange?: ((hasChanged: boolean) => void) | undefined,
}