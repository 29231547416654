import React, {memo, ReactElement} from "react";
import {Props, UserShiftsCountData} from "./GroupShiftsTable.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./GroupShiftsTable.reducer";
import {CompareModule} from "modules/Compare.module";
import "./GroupShiftsTable.styles.scss";
import {useGroupShiftsTableColumns} from "./hooks/useGroupShiftsTableColumns";
import {Table} from "../Table/Table";
import {Flex} from "@fluentui/react-northstar";

export const GroupShiftsTable = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState);
    const columns = useGroupShiftsTableColumns(props.shopId, props.items);

    return (
        <Flex fill className={"group-shifts-table"}>
            <Table<UserShiftsCountData>
                items={props.items}
                columns={columns}
                sortLocally
            />
        </Flex>
    )
}, CompareModule.areObjectsEqual);