import {Props, State} from "./ShopPopup.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";

export const initialState: State = {
    open: false,
    selectedShopId: undefined,
    closeTimeout: undefined,
}

export const reducer = (props: Props) => ({
    selectShop: ({setState}, _, shopId: string) => {
        setState({selectedShopId: shopId, open: false});
        props.onShopSelected(shopId);
    },
    setOpen: ({setState}, _, open: boolean) => {
        setState({open});
    },
    mouseEnter: ({state, setState}) => {
        if (state.closeTimeout) clearTimeout(state.closeTimeout);
        setState({closeTimeout: undefined}, false);
        if (state.open) return;
        setState({open: true});
    },
    mouseLeave: ({state, setState}) => {
        if (state.closeTimeout) clearTimeout(state.closeTimeout);
        const closeTimeout = setTimeout(() => {
            setState({open: false});
        }, 300);
        setState({closeTimeout}, false);
    }
}) satisfies MagicReducerObject<State>;