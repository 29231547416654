import {useShopCache} from "hooks/cache/useShopsCache";
import {translations} from "translations";
import {IFilterItem} from "components/others/Filter/Filter.interfaces";
import {useMemo} from "react";
import {useUsersCache} from "hooks/cache/useUsersCache";
import {useShopGroupsCache} from "hooks/cache/groups/useShopGroupsCache";

export const useToolbarFilterItems = (shopId: string | undefined): Array<IFilterItem> => {
    const {shop} = useShopCache(shopId);
    const {groups} = useShopGroupsCache(shopId);
    const usersIds = useMemo(() => Array.from(new Set(groups?.flatMap(g => g.usersIds))), [groups]);
    const {users} = useUsersCache(usersIds);

    const categoriesItem = useMemo((): IFilterItem => ({
        key: "category",
        label: translations.get("Category"),
        menu: {
            items: [...(shop?.categories ?? [])]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(c => ({
                    key: c.key,
                    label: c.name,
                    value: c.key,
                })) ?? [],
        }
    }), [shop?.categories]);

    const groupsItem = useMemo((): IFilterItem => ({
        key: "group",
        label: translations.get("Group"),
        menu: {
            items: groups?.sort((a, b) => a.index - b.index).map(g => ({
                key: g.id,
                label: g.name,
                value: g.id,
            })) ?? [],
        }
    }), [groups]);

    const staffItem = useMemo((): IFilterItem => ({
        key: "staff",
        label: translations.get("Staff"),
        menu: {
            items: [...(users ?? [])]
                .sort((a, b) => a.displayName.localeCompare(b.displayName))
                .map(u => ({
                    key: u.id,
                    label: u.displayName,
                    value: u.id,
                })) ?? [],
        }
    }), [users]);

    const unClockedShiftsItem = useMemo((): IFilterItem => ({
        key: "unclockedShifts",
        label: translations.get("UnclockedShifts"),
    }), []);

    return [
        categoriesItem,
        groupsItem,
        staffItem,
        unClockedShiftsItem
    ]
}