import {SetDataOptions,} from "react-query/types/core/types";
import {QueryClient} from "react-query";
import {shiftsCacheKey} from "../hooks/cache/useShiftsCache";
import {GroupsCache} from "../hooks/cache/groups/helpers";
import {GuidModule} from "./Guid.module";

export type QueryCacheNotifyEvent = Exclude<Parameters<Exclude<Parameters<ReturnType<QueryClient['getQueryCache']>['subscribe']>[0], undefined>>[0], undefined>;

export type NotifyEventQueryUpdated = QueryCacheNotifyEvent & {
    action: {
        data: any;
        dataUpdatedAt?: number;
    }
}

const ignoredQueryEventOptions: SetDataOptions = {
    updatedAt: -1,
}

const isUpdateQueryEvent = (event: QueryCacheNotifyEvent): event is NotifyEventQueryUpdated => {
    return event && event.type === 'queryUpdated';
}

const isQueryEventIgnored = (event: QueryCacheNotifyEvent): boolean => {
    if (!event || event.type !== 'queryUpdated') return true;
    if (event.action?.type !== 'success') return true;
    return event.action.dataUpdatedAt === ignoredQueryEventOptions.updatedAt;
}

const isUserShiftsQueryUpdate = (event: QueryCacheNotifyEvent): boolean => {
    return event.query.queryKey[0] === shiftsCacheKey && event.query.queryKey[1] === "user";
}

const isGroupQueryUpdate = (event: QueryCacheNotifyEvent): boolean => {
    return event.query.queryKey[0] === GroupsCache.groupsCacheKey
        && GuidModule.isValidGuid(event.query.queryKey[1]);
}

export const QueryModule = {
    ignoredQueryEventOptions,
    isQueryEventIgnored,
    isUserShiftsQueryUpdate,
    isGroupQueryUpdate,
    isUpdateQueryEvent,
}