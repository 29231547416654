import {QueryClient, useQuery, useQueryClient} from "react-query";
import {PlanningApi} from "../../apis/Planning/PlanningApi";
import {DayNoteData} from "../../interfaces/DayNoteData";
import moment from "moment/moment";
import {GuidModule} from "../../modules/Guid.module";

export const daysNotesCacheKey = "daysNotes";

export const useDaysNotesRangeCache = (shopId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    const queryClient = useQueryClient();

    const {data: daysNotes, isLoading} = useQuery(
        [daysNotesCacheKey, shopId, startDate, endDate],
        () => PlanningApi.getDaysNotes(shopId, startDate, endDate), {
            staleTime: Infinity,
            enabled: !!shopId && !!startDate && !!endDate
        }
    );

    return {
        daysNotes,
        isLoading,
        updateDayNotes: updateDayNotes(queryClient, shopId),
    };
}

export const useDaysNotesCache = (shopId: string) => {
    const queryClient = useQueryClient();

    return {
        updateDayNotes: updateDayNotes(queryClient, shopId),
    }

}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const getLocalDayNotesRangeByDate = (queryClient: QueryClient, shopId: string | undefined, date: string | undefined) => {
    const allDaysNotes = queryClient.getQueriesData<Array<DayNoteData>>([daysNotesCacheKey, shopId]);
    const momentDate = moment(date);
    return allDaysNotes.find(([key]) => {
        const startDate = moment(key[2] as string);
        const endDate = moment(key[3] as string);
        return startDate <= momentDate && endDate >= momentDate;
    });
}

const updateDayNotes = (queryClient: QueryClient, shopId: string | undefined) => async (date: string, notes: string) => {
    if (!shopId || !date) return;
    const notesRange = getLocalDayNotesRangeByDate(queryClient, shopId, date);
    if (!notesRange) return;
    const result = await PlanningApi.updateDayNotes(shopId, date, notes);
    if (!result) return;
    const newDaysNotes = [...notesRange[1]];
    const index = notesRange[1].findIndex(d => d.date === date);
    if (index >= 0) newDaysNotes[index]!.notes = notes;
    else {
        newDaysNotes.push({
            id: GuidModule.generateGUID(),
            date,
            notes
        });
    }
    queryClient.setQueryData(notesRange[0], newDaysNotes);
}