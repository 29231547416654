import {State} from "./ShiftActivityDialog.interfaces";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {Dialog} from "components/dialogs/Dialog/Dialog";
import {ShiftActivity} from "interfaces/ShiftData";
import {useShiftActivityForm} from "../../../hooks/forms/useShiftActivityForm";

export const initialState: State = {
    shopId: "",
    onDone: undefined,
    activity: undefined,
}

export const reducer = (config: {
    dialogRef: MagicReducerRef<typeof Dialog>,
}) => ({
    open: ({setState}, _, {shopId, onDone, activity}: {
        shopId: string,
        onDone: State["onDone"],
        activity?: State["activity"]
    }) => {
        setState({shopId, onDone, activity});
        config.dialogRef.dispatch("open")();
    },
    addActivity: ({state}, [activity]: [Immutable<ShiftActivity> | undefined]) => {
        state.onDone?.(activity);
        config.dialogRef.dispatch("close")();
    },
    reset: ({setState}, _, form: ReturnType<typeof useShiftActivityForm>) => {
        setState({...initialState});
        form.reset();
    },
}) satisfies MagicReducerObject<State>;