import {Immutable} from "@witivio_teamspro/use-reducer";
import {RequestData, RequestType} from "../interfaces/RequestData";
import {translations} from "../translations";
import moment from "moment/moment";
import {WeekDaysModule} from "./WeekDays.module";

const generateRequestTitle = (request: Immutable<RequestData> | undefined, userName: string | undefined) => {
    if (!request) return "";
    const simplifiedName = userName?.split(" ")[0] ?? "";
    switch (request.type) {
        case RequestType.Swap:
            return translations.get("SwapRequestTitle", {name: simplifiedName});
        case RequestType.TimeOff:
            return translations.get("TimeOffRequestTitle", {name: simplifiedName});
        case RequestType.UpdateClocking:
            return translations.get(request.clockedOutsideStore ?
                "ClockingUpdateRequestOutsideStoreTitle" : "ClockingUpdateRequestTitle", {name: simplifiedName});
        case RequestType.WeekSummary:
            return translations.get("WeekSummary");
        default:
            return "";
    }
}

const generateRequestSubTitle = (request: Immutable<RequestData> | undefined) => {
    if (!request) return;
    switch (request.type) {
        case RequestType.Swap:
            const fromShiftDate = moment(request.fromShift.date);
            const toShiftDate = moment(request.toShift.date);
            const date = fromShiftDate.isAfter(toShiftDate) ? toShiftDate : fromShiftDate;
            return translations.get("ForTheDate", {date: date.format("L")});
        case RequestType.TimeOff:
            return WeekDaysModule.getPeriodLabel({...request});
        case RequestType.UpdateClocking:
            return request.justification;
        case RequestType.WeekSummary:
            return moment(request.weekDate).format("L");
        default:
            return "";
    }
}

export const RequestModule = {
    generateRequestTitle,
    generateRequestSubTitle,
}