import React, {memo, ReactElement, useEffect} from "react";
import {Props} from "./SelectionArea.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./SelectionArea.reducer";
import "./SelectionArea.styles.scss";
import {CompareModule} from "../../../modules/Compare.module";

export const SelectionArea = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer(props), initialState, props.externalRef);

    useEffect(function initializeListeners() {
        if (!props.element) return;
        const mouseup = dispatch("mouseUp");
        const mousedown = dispatch("mouseDown");
        const mousemove = dispatch("mouseMove");
        const clearSelection = dispatch("clearSelection");
        props.element?.addEventListener('mouseup', mouseup);
        props.element?.addEventListener('mousedown', mousedown);
        props.element?.addEventListener('mousemove', mousemove);
        window.addEventListener("mousedown", clearSelection);
        return () => {
            props.element?.removeEventListener('mouseup', mouseup);
            props.element?.removeEventListener('mousedown', mousedown);
            props.element?.removeEventListener('mousemove', mousemove);
            window.removeEventListener("mousedown", clearSelection);
        };
    }, [props.element]);

    if (!state.isSelecting || !state.selectionRect) return null;

    return (
        <div
            className={"selection-area"}
            style={{
                left: Math.min(state.selectionRect.startX, state.selectionRect.endX),
                top: Math.min(state.selectionRect.startY, state.selectionRect.endY),
                width: Math.abs(state.selectionRect.startX - state.selectionRect.endX),
                height: Math.abs(state.selectionRect.startY - state.selectionRect.endY),
            }}
        />
    )
}, CompareModule.areObjectsEqual);