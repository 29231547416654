import React, {memo, ReactElement} from "react";
import {Props} from "./ClockingUpdateRequestPreview.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ClockingUpdateRequestPreview.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ClockingUpdateRequestPreview.styles.scss";
import {ArrowRightIcon, Flex, Text} from "@fluentui/react-northstar";
import {RequestPreviewItem} from "../RequestPreviewItem/RequestPreviewItem";
import moment from "moment";
import {ManagerResponseItem} from "../ManagerResponseItem/ManagerResponseItem";
import {WarningIcon} from "../../../../assets/icons";
import {translations} from "../../../../translations";
import {TimeModule} from "../../../../modules/Time.module";
import {useShiftsIdsCache} from "../../../../hooks/cache/useShiftsCache";
import {UpdateClockingRequestData} from "../../../../interfaces/RequestData";
import {useRequestsCache} from "../../../../hooks/cache/useRequestsCache";
import {ShiftCard} from "../../ShiftCard/ShiftCard";
import {Time} from "@witivio_teamspro/northstar-form/dist/cjs/components/Form/TimePicker/TimePicker";

export const ClockingUpdateRequestPreview = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState);
    const {requests} = useRequestsCache();

    const request = requests?.find(r => r.id === props.requestId) as UpdateClockingRequestData | undefined;

    const {shifts} = useShiftsIdsCache(!request ? [] : [request.shiftId]);

    if (!request) return null;

    const shift = shifts.find(s => s.getId() == request?.shiftId);

    return (
        <Flex fill column hAlign={"center"}>
            <RequestPreviewItem
                userId={request.userId}
                date={moment(request.date).toISOString(false)}
                content={
                    <Flex fill column gap={"gap.small"}>
                        {request.clockedOutsideStore && renderClockedOutsideStoreBanner()}
                        <ShiftCard small readonly showDate shift={shift}/>
                        {renderClockingTime(request.prevStartTime, request.prevEndTime, translations.get("PreviousClockingTime"))}
                        {renderClockingTime(request.startTime, request.endTime, translations.get("NewClockingTime"))}
                        <Flex column>
                            <Text style={{color: "darkgray"}} content={translations.get("Justification")}/>
                            <Text content={request.justification}/>
                        </Flex>
                    </Flex>
                }
            />
            <ManagerResponseItem requestId={props.requestId}/>
        </Flex>
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderClockedOutsideStoreBanner = () => (
    <Flex hAlign={"center"} className={"clocking-update-request-banner"}>
        <WarningIcon/>
        <Text content={translations.get("ClockedOutOutsideStore")}/>
    </Flex>
)

const renderClockingTime = (startTime: Time | undefined, endTime: Time | undefined, label: string) => {
    let content: ReactElement | null;
    if (!startTime && !endTime) content = (
        <Text content={translations.get("Undefined")}/>
    );
    else content = (
        <Flex gap={"gap.small"} vAlign={"center"}>
            <Text content={TimeModule.getTimeLabel(startTime)}/>
            <ArrowRightIcon outline styles={{color: "darkgray"}}/>
            <Text content={!endTime ? translations.get("Undefined") : TimeModule.getTimeLabel(endTime)}/>
        </Flex>
    );
    return (
        <Flex column>
            <Text style={{color: "darkgray"}} content={label}/>
            <Flex vAlign={"center"} gap={"gap.small"}>
                {content}
            </Flex>
        </Flex>
    )
}