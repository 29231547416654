import {useForm} from "@witivio_teamspro/northstar-form";
import {ArrowRightIcon, Button, Flex} from "@fluentui/react-northstar";
import {translations} from "translations";
import {ShareIcon} from "../../assets/icons";
import React, {useEffect, useState} from "react";
import {useShiftsCache} from "../cache/useShiftsCache";
import {FormModule} from "../../modules/Form.module";

export const useSharePlanningForm = (shopId: string | undefined, startDate: string | undefined, endDate: string | undefined) => {
    const {notifyShiftsRange} = useShiftsCache();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        sharePlanningForm.setFieldsInitialValues({startDate, endDate, notifyMembers: true});
        sharePlanningForm.reset();
    }, [shopId, startDate, endDate]);

    const sharePlanningForm = useForm({
        disabled: isLoading,
        items: {
            ...FormModule.generateDateRangeItems({showLabel: true, required: true}),
            notifyMembers: {
                type: "checkbox",
                toggle: true,
                label: translations.get("NotifyAffectedMembers"),
                initialValue: true,
            }
        }
    });

    const renderShareButton = (callback: () => void, disabled: boolean) => (
        <Button
            primary icon={<ShareIcon/>}
            content={translations.get("Share")}
            onClick={handleSharePlanning({
                shopId,
                formState: sharePlanningForm.state,
                setIsLoading,
                callback,
                notifyShiftsRange
            })}
            disabled={isLoading || disabled}
            loading={isLoading}
        />
    )

    const renderForm = () => (
        <Flex column fill gap={"gap.small"}>
            <Flex vAlign={"end"} gap={"gap.small"}>
                {sharePlanningForm.formItems.startDate}
                <ArrowRightIcon styles={{color: "darkgray", marginBottom: "8px"}}/>
                {sharePlanningForm.formItems.endDate}
            </Flex>
        </Flex>
    )

    return {
        ...sharePlanningForm,
        renderForm,
        renderShareButton
    }
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const handleSharePlanning = (config: {
    shopId: string | undefined,
    formState: ReturnType<typeof useSharePlanningForm>["state"],
    setIsLoading: (loading: boolean) => void,
    callback: () => void,
    notifyShiftsRange: ReturnType<typeof useShiftsCache>["notifyShiftsRange"],
}) => async () => {
    const {formState, setIsLoading, shopId, callback, notifyShiftsRange} = config;
    if (!shopId) return;
    const {startDate, endDate, notifyMembers} = formState.data;
    setIsLoading(true);
    await notifyShiftsRange({
        shopId,
        startDate: startDate as string,
        endDate: endDate as string,
        notifyConcernedMembers: notifyMembers as boolean
    });
    callback();
    setIsLoading(false);
}