import {Props, State} from "./StaffShifts.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {ShiftCardFormat} from "../ShiftCard/ShiftCard.interfaces";
import {startTransition} from "react";
import {Rect} from "../SelectionArea/SelectionArea.interfaces";
import {SelectedCellData} from "../../../views/Schedules/Planning/Planning.interfaces";
import moment from "moment";
import {Shift} from "../../../classes/Shift";
import {CompareModule} from "../../../modules/Compare.module";

export const initialState = (daysCount: number): State => ({
    cardsFormat: getShiftsCardsFormat(daysCount),
    showSkeletons: false,
    container: null,
    visible: true,
    prevCellData: undefined,
});

export const reducer = (config: {
    daysCount: number,
    props: Props,
}) => ({
    updateCardsFormat: ({state, setState}) => {
        setState({showSkeletons: true});
        const cardsFormat = getShiftsCardsFormat(config.daysCount);
        if (state.cardsFormat === cardsFormat) return setState({showSkeletons: false});
        startTransition(() => setState({cardsFormat, showSkeletons: false}));
    },
    hideSkeletons: ({state, setState}) => {
        if (!state.showSkeletons) return;
        setState({showSkeletons: false});
    },
    containerRef: ({state, setState}, [ref]: [HTMLDivElement | null]) => {
        setState({container: ref}, false);
        if (!ref || !config.props.isVisible) return;
        const isVisible = config.props.isVisible?.(ref);
        if (state.visible === isVisible) return;
        startTransition(() => setState({visible: isVisible}));
    },
    updateSelectedItems: (reducerData, [shiftsGroupedByDays]: [Record<string, (Immutable<Shift> | undefined)[]>]) => {
        if (config.props.readOnly) return;
        const {state} = reducerData;
        const selectionRect = config.props.selectionAreaRef.state?.selectionRect;
        const boundingRect = state.container?.getBoundingClientRect();
        if (!boundingRect || !selectionRect) {
            reducer(config).notifySelectedCells(reducerData, undefined, undefined);
            return;
        }
        const isOverlapping = doRectsOverlap(selectionRect, {
            startX: boundingRect.left,
            startY: boundingRect.top,
            endX: boundingRect.right,
            endY: boundingRect.bottom
        });
        if (!isOverlapping) {
            reducer(config).notifySelectedCells(reducerData, undefined, undefined);
            return;
        }
        const cellData: SelectedCellData = {
            userId: config.props.userId,
            starDate: "",
            endDate: "",
            shifts: [],
        }
        Array.from(state.container?.children ?? []).slice(1).forEach((child, dayIndex) => {
            const childBoundingRect = child.getBoundingClientRect();
            const isChildOverlapping = doRectsOverlap(selectionRect, {
                startX: childBoundingRect.left,
                startY: childBoundingRect.top,
                endX: childBoundingRect.right,
                endY: childBoundingRect.bottom
            });
            if (!isChildOverlapping) return;
            const day = Object.keys(shiftsGroupedByDays)[dayIndex];
            if (!day) return;
            if (!cellData.starDate) cellData.starDate = day;
            else if (moment(cellData.starDate).isAfter(moment(day))) cellData.starDate = day;
            if (!cellData.endDate) cellData.endDate = day;
            else if (moment(cellData.endDate).isBefore(moment(day))) cellData.endDate = day;
            const shifts: Array<Immutable<Shift> | undefined> = Object.values(shiftsGroupedByDays)[dayIndex]?.filter(Boolean) ?? [];
            if (shifts.length === 0) shifts.push(undefined);
            cellData.shifts.push(...shifts);
        });
        reducer(config).notifySelectedCells(reducerData, undefined, cellData);
    },
    notifySelectedCells: ({state, setState}, _, cellData: Immutable<SelectedCellData> | undefined) => {
        if (CompareModule.areObjectsEqual(state.prevCellData, cellData)) return;
        setState({prevCellData: cellData}, false);
        config.props.onSelectCells(cellData);
    },
    copyShift: ({}, _, shift: Immutable<Shift> | undefined) => {
        if (!shift) return;
        config.props.onCopyShift(shift);
    },
    pasteShift: ({}, _, shift: Immutable<Shift> | undefined) => {
        if (!shift) return;
        config.props.onPasteShift(shift);
    }
}) satisfies MagicReducerObject<State>;

const getShiftsCardsFormat = (daysCount: number) => {
    const cardPadding = 5;
    const cardWidth = Math.floor((window.innerWidth / daysCount) - (cardPadding * daysCount));
    if (cardWidth <= 60) return ShiftCardFormat.Mini;
    if (cardWidth <= 130) return ShiftCardFormat.Small;
    return ShiftCardFormat.Classic;
}

const doRectsOverlap = (rectA: Rect | undefined, rectB: Rect | undefined): boolean => {
    if (!rectA || !rectB) return false;

    const rectALeft = Math.min(rectA.startX, rectA.endX);
    const rectARight = Math.max(rectA.startX, rectA.endX);
    const rectATop = Math.min(rectA.startY, rectA.endY);
    const rectABottom = Math.max(rectA.startY, rectA.endY);

    const rectBLeft = Math.min(rectB.startX, rectB.endX);
    const rectBRight = Math.max(rectB.startX, rectB.endX);
    const rectBTop = Math.min(rectB.startY, rectB.endY);
    const rectBBottom = Math.max(rectB.startY, rectB.endY);

    return !(rectBLeft >= rectARight ||
        rectBRight <= rectALeft ||
        rectBTop >= rectABottom ||
        rectBBottom <= rectATop);
}