import {State} from "./SwapShiftSideView.interfaces";
import {Immutable, MagicReducerObject, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {SideView} from "../../../../../components/dialogs/SideView/SideView";
import {Shift} from "../../../../../classes/Shift";

export const initialState: State = {
    shift: undefined,
    renderKey: 0,
}

export const reducer = (config: {
    sideViewRef: MagicReducerRef<typeof SideView>,
}) => ({
    open: ({setState}, _, shift: Immutable<Shift> | undefined) => {
        setState({shift, renderKey: Math.random()});
        config.sideViewRef.dispatch("open")();
    },
}) satisfies MagicReducerObject<State>;